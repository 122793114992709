define('uabide/services/map-sellers-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    parseService: Ember.inject.service(),

    vendorStatus: 'showAll',
    vendorType: 'showAll',
    vendorStage: null,
    mapResultsLastCallAmount: 0,
    mapResults: [],
    mapProductsResultsLastCallAmount: 0,
    mapProductsResults: [],
    fileArray: [],
    mapProductsFileArray: [],
    allMAPProducts: [],
    numberDownloadedHistoryFileResults: 0,
    numberDownloadedMapProductsFileResults: 0,
    currentProductsSellerId: null,
    isActiveSku: null,
    mapSeller: null,
    isUnknownFilterOn: null,

    addMapResults: function addMapResults(newResults) {
      this.set('mapResultsLastCallAmount', newResults.length);
      this.get('mapResults').pushObjects(newResults);
    },
    addMapProductsResults: function addMapProductsResults(newResults) {
      this.set('mapProductsResultsLastCallAmount', newResults.length);
      this.get('mapProductsResults').pushObjects(newResults);
    },
    addAllMapProductsResults: function addAllMapProductsResults(newResults) {
      this.set('mapProductsResultsLastCallAmount', newResults.length);
      this.get('allMAPProducts').pushObjects(newResults);
    },
    addHistoryFileResults: function addHistoryFileResults(newResults) {
      this.set('numberDownloadedHistoryFileResults', newResults.length);
      this.get('fileArray').pushObjects(newResults);
    },
    resetFileForDownload: function resetFileForDownload(newResults) {
      this.set('numberDownloadedHistoryFileResults', 0);
      this.set('fileArray', []);
    },
    addMapProductsFileResults: function addMapProductsFileResults(newResults) {
      this.set('numberDownloadedMapProductsFileResults', newResults.length);
      this.get('mapProductsFileArray').pushObjects(newResults);
    },
    resetMapProductFileForDownload: function resetMapProductFileForDownload(newResults) {
      this.set('numberDownloadedMapProductsFileResults', 0);
      this.set('mapProductsFileArray', []);
    },
    clearAllNow: function clearAllNow() {
      this.set('mapResults', []);
    },
    clearAllProductsNow: function clearAllProductsNow() {
      this.set('mapProductsResults', []);
    },
    getCurrentMapSellers: function getCurrentMapSellers(params) {
      return this.get('parseService.cloud').run('getCurrentMAPSellers', params);
    },
    takeActionOnMAPSeller: function takeActionOnMAPSeller(params) {
      return this.get('parseService.cloud').run('takeActionOnMAPSeller', params);
    },
    getEmailTemplateForStage: function getEmailTemplateForStage(params) {
      return this.get('parseService.cloud').run('getEmailTemplateForStage', params);
    },
    sendEmailTemplateForStage: function sendEmailTemplateForStage(params) {
      return this.get('parseService.cloud').run('sendEmailTemplateForStage', params);
    },
    getMapInfringementsForMapSeller: function getMapInfringementsForMapSeller(params) {
      return this.get('parseService.cloud').run('getMapInfringementsForMapSeller', params);
    },
    getCurrentActivelistingsForMapSeller: function getCurrentActivelistingsForMapSeller(params) {
      return this.get('parseService.cloud').run('getCurrentActivelistingsForMapSeller', params);
    },
    getCurrentInActivelistingsForMapSeller: function getCurrentInActivelistingsForMapSeller(params) {
      return this.get('parseService.cloud').run('getCurrentInActivelistingsForMapSeller', params);
    },
    getCurrentActivelistingsForMapSellerCount: function getCurrentActivelistingsForMapSellerCount(params) {
      return this.get('parseService.cloud').run('getCurrentActivelistingsForMapSellerCount', params);
    },
    getCurrentInActivelistingsForMapSellerCount: function getCurrentInActivelistingsForMapSellerCount(params) {
      return this.get('parseService.cloud').run('getCurrentInActivelistingsForMapSellerCount', params);
    },
    getAllActivelistingsForMapSeller: function getAllActivelistingsForMapSeller(params) {
      return this.get('parseService.cloud').run('getAllActivelistingsForMapSeller', params);
    },
    getAllCurrentMAPProducts: function getAllCurrentMAPProducts(params) {
      return this.get('parseService.cloud').run('getAllCurrentMAPProducts', params);
    },
    updateMAPSellerEmailArrayAll: function updateMAPSellerEmailArrayAll(params) {
      return this.get('parseService.cloud').run('updateMAPSellerEmailArrayAll', params);
    },
    updateMAPSellerEmailArrayAll2Day: function updateMAPSellerEmailArrayAll2Day(params) {
      return this.get('parseService.cloud').run('updateMAPSellerEmailArrayAll2Day', params);
    },
    updateMAPSellerEmailArray4th: function updateMAPSellerEmailArray4th(params) {
      return this.get('parseService.cloud').run('updateMAPSellerEmailArray4th', params);
    },
    updateMapSeller3pStatus: function updateMapSeller3pStatus(params) {
      return this.get('parseService.cloud').run('updateMapSeller3pStatus', params);
    },
    updateMapSellerStageAndCompletedStage: function updateMapSellerStageAndCompletedStage(params) {
      return this.get('parseService.cloud').run('updateMapSellerStageAndCompletedStage', params);
    },
    updateMapSellerStageAndCompletedStageBulk: function updateMapSellerStageAndCompletedStageBulk(params) {
      return this.get('parseService.cloud').run('updateMapSellerStageAndCompletedStageBulk', params);
    }
  });
});