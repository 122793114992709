define('uabide/services/map-inbox-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    parseService: Ember.inject.service(),

    mapResultsLastCallAmount: 0,
    mapResults: [],
    fileArray: [],
    numberDownloadedHistoryFileResults: 0,
    selectedMapSeller: null,

    addMapResults: function addMapResults(newResults) {
      this.set('mapResultsLastCallAmount', newResults.length);
      this.get('mapResults').pushObjects(newResults);
    },
    clearAllNow: function clearAllNow() {
      this.set('mapResults', []);
    },
    addHistoryFileResults: function addHistoryFileResults(newResults) {
      this.set('numberDownloadedHistoryFileResults', newResults.length);
      this.get('fileArray').pushObjects(newResults);
    },
    resetFileForDownload: function resetFileForDownload(newResults) {
      this.set('numberDownloadedHistoryFileResults', 0);
      this.set('fileArray', []);
    },
    getCurrentMapEmailRecords: function getCurrentMapEmailRecords(params) {
      return this.get('parseService.cloud').run('getCurrentMapEmailRecords', params);
    }
  });
});