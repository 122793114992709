define('uabide/controllers/portal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  Parse.initialize("bzn8msayuASvFwWDJEY3DdGYDXb7eV9rweY2rr7o", "fRmOrMDotozW5iYypZ9s73yADQP3MiKmbIhQxXGw");
  Parse.serverURL = 'https://uabide.back4app.io/';
  var inject = Ember.inject;

  exports.default = Ember.Controller.extend({
    queryParams: ['sessionToken'],
    sessionToken: null,
    spinner: inject.service('spinner'),
    notificationService: Ember.inject.service(),

    actions: {
      logoutNow: function logoutNow() {
        var _this = this;

        console.log("trying to logout ");
        var self = this;
        this.get('spinner').show('indexSpinner');
        Parse.User.logOut().then(function () {
          self.get('spinner').hide('indexSpinner');
          self.get('listingservice').clearAllNow();
          self.get('vendorservice').clearAllNow();
          _this.transitionToRoute('login');
          setTimeout(function () {
            location.reload();
          }, 500);
        });
      },
      loadUser: function loadUser() {
        console.log("starting method");
        var sessionToken = this.get('sessionToken');
        //console.log("starting to load user sessionToken:" + sessionToken);
        var self = this;
        if (sessionToken != null) {
          console.log("starting parse load user:");
          this.get('spinner').show('indexSpinner');
          Parse.User.become(sessionToken).then(function (user) {
            console.log("success logging in user");
            self.get('spinner').hide('indexSpinner');
            self.transitionToRoute('index');
          }, function (error) {
            console.log("error logging in user" + error.message);
            self.get('spinner').hide('indexSpinner');
            self.get('notificationService').error("You failed to login error:" + error.message);
          });
        } else {
          console.log("session token missing");
        }
      }
    }
  });
});