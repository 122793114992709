define("uabide/helpers/background-helper", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.backgroundHelper = backgroundHelper;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function backgroundHelper(params /*, hash*/) {
    var _params = _slicedToArray(params, 1),
        status = _params[0];

    var text;
    switch (status) {
      case "kehe":
        text = "c-navbar_kehe";
        break;
      case "180commerce":
        text = "c-navbar_180";
        break;
      case "tlksourcing":
        text = "c-navbar_tlk";
        break;
      case "CrownMerchandise":
        text = "c-navbar_crown";
        break;
      case "vorys":
        text = "c-navbar_vorys";
        break;
      case "sunken_stone":
        text = "c-navbar_sunken_stone";
        break;
      case "amplio":
        text = "c-navbar_amplio";
        break;
      case "snap":
        text = "c-navbar_snap";
        break;
      case "anderson":
        text = "c-navbar_anderson";
        break;
      case "palmetto":
        text = "c-navbar_palmetto";
        break;
      case "flywheel":
        text = "c-navbar_flywheel";
        break;
      case "omniix":
        text = "c-navbar_omniix";
        break;
      case "hollow":
        text = "c-navbar_hollow";
        break;
      case "beekeeper":
        text = "c-navbar_beekeeper";
        break;
      case "quiverr":
        text = "c-navbar_quiverr";
        break;
      case "tesmo":
        text = "c-navbar_tesmo";
        break;
      case "treeoflife":
        text = "c-navbar_treeoflife";
        break;
      case "i9media":
        text = "c-navbar_i9media";
        break;
      case "fortress":
        text = "c-navbar_fortress";
        break;
      case "splendid":
        text = "c-navbar_splendid";
        break;
      case "unfi":
        text = "c-navbar_unfi";
        break;
      case "bluewheelmedia":
        text = "c-navbar_bluewheelmedia";
        break;
      case "g2":
        text = "c-navbar_g2";
        break;
      case "wingpoint":
        text = "c-navbar_wingpoint";
        break;
      case "orcapacific":
        text = "c-navbar_orcapacific";
        break;
      case "marketdefense":
        text = "c-navbar_marketdefense";
        break;
      case "kaspien":
        text = "c-navbar_kaspien";
        break;
      case "etailz":
        text = "c-navbar_kaspien";
        break;
      case "commercerev":
        text = "c-navbar_commercerev";
        break;
      case "themarsagency":
        text = "c-navbar_themarsagency";
        break;
      case "i2o":
        text = "c-navbar_i2o";
        break;
      case "blueoceanbrands":
        text = "c-navbar_blueoceanbrands";
        break;
      case "photonlegal":
        text = "c-navbar_photonlegal";
        break;
      default:
        text = "c-navbar";
    }
    //console.log("status1 is ", status);
    return text;
  }

  exports.default = Ember.Helper.helper(backgroundHelper);
});