define('uabide/services/seller-listing-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    parseconverter: Ember.inject.service('parse-converter'),
    parseService: Ember.inject.service(),

    sellerListingResults: [],
    sellerListingResultsLastCallAmount: 0,
    sellerListingsFileArray: null,
    numberDownloadedSellerListingsHistoryFileResults: 0,
    listingsVendorId: null,
    limboOn: false,
    allowedNonContributors: ['vdcx9ptmMR'],

    addSellerListingResults: function addSellerListingResults(newResults) {
      this.set('sellerListingResultsLastCallAmount', newResults.length);
      this.get('sellerListingResults').pushObjects(newResults);
      var firstElement = newResults[0];
      this.set('limboOn', firstElement.status == 'Limbo' || firstElement.status == 'Archive');
    },
    clearSellerListingsAllNow: function clearSellerListingsAllNow() {
      this.set('sellerListingResultsLastCallAmount', 0);
      this.set('sellerListingResults', []);
    },
    resetSellerListingsFileForDownload: function resetSellerListingsFileForDownload() {
      this.set('sellerListingsFileArray', []);
      this.set('numberDownloadedSellerListingsHistoryFileResults', 0);
    },
    addSellerListingsHistoryFileResults: function addSellerListingsHistoryFileResults(myResults) {
      this.get('sellerListingsFileArray').pushObjects(myResults);
      this.set('numberDownloadedSellerListingsHistoryFileResults', myResults.length);
    }
  });
});