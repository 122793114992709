define('uabide/components/modals/edit-stage-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    stage: 0,
    completedStage: 0,

    didUpdateAttrs: function didUpdateAttrs() {
      if (this.currentInput) {
        var _currentInput = this.currentInput,
            stage = _currentInput.stage,
            completedStage = _currentInput.completedStage;

        this.setProperties({
          stage: stage,
          completedStage: completedStage
        });
      } else {
        this.setProperties({
          stage: 0,
          completedStage: 0
        });
      }
    },


    actions: {
      update: function update() {
        var stage = this.get('stage');
        var completedStage = this.get('completedStage');
        this.update({
          stage: parseInt(stage),
          completedStage: parseInt(completedStage)
        });
      }
    }
  });
});