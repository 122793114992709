define("uabide/helpers/set-style-type", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.setStyleType = setStyleType;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function setStyleType(params /*, hash*/) {
    var _params = _slicedToArray(params, 2),
        mode = _params[0],
        type = _params[1];

    var text;
    switch (mode) {
      case "dateDesc":
        if (type == "dateDesc") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;
      case "name":
        if (type == "name") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "sellerName":
        if (type == "sellerName") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "itemNumber":
        if (type == "itemNumber") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "priceNumber":
        if (type == "priceNumber") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "shoppingPlatform":
        if (type == "shoppingPlatform") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "numberOfListings":
        if (type == "numberOfListings") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "type":
        if (type == "type") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "title":
        if (type == "title") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "ASIN":
        if (type == "ASIN") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "model_number":
        if (type == "model_number") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "upc_number":
        if (type == "upc_number") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "map_price":
        if (type == "map_price") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "msrp_price":
        if (type == "msrp_price") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "status":
        if (type == "status") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "numberOfInfractions":
        if (type == "numberOfInfractions") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "currentPrice":
        if (type == "currentPrice") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "updatedAt":
        if (type == "updatedAt") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "currentItemURL":
        if (type == "currentItemURL") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "condition":
        if (type == "condition") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "seller":
        if (type == "seller") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "brand":
        if (type == "brand") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "isBuyboxWinner":
        if (type == "isBuyboxWinner") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "markSellerAsPriority":
        if (type == "markSellerAsPriority") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "numberOfPriorityListings":
        if (type == "numberOfPriorityListings") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "matchCertainty":
        if (type == "matchCertainty") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "ratingPercentage":
        if (type == "ratingPercentage") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "ratingCount":
        if (type == "ratingCount") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      default:
        text = "c-table__cell c-table__cell--head";
        break;
    }
    return text;
  }

  exports.default = Ember.Helper.helper(setStyleType);
});