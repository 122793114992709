define('uabide/routes/map/account-products', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    mapSellersService: Ember.inject.service(),
    parentModel: null,

    afterModel: function afterModel(params, transition) {
      if (!Ember.isPresent(params.isActiveSku)) {
        var isActiveSku = this.get('mapSellersService.isActiveSku');
        if (!Ember.isPresent(isActiveSku)) {
          this.replaceWith('map.accounts');
        }
      } else {
        this.get('mapSellersService').set('isActiveSku', params.isActiveSku);
        this.get('mapSellersService').set('currentProductsSellerId', params.sellerId);
      }
    },
    setupController: function setupController(controller, model) {
      if (model.account) {
        var parentModel = this.modelFor('map');
        this.set('parentModel', parentModel);
        parentModel.set('title', model.account.sellerName);
        parentModel.set('image', model.account.shoppingPlatform);
        parentModel.set('previousRoute', 'map.accounts');
      }
    }
  });
});