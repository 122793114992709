define('uabide/components/modals/copy-audit-report-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    downloadByListing: 'Download by listing',
    downloadByAccount: 'Download entire account',
    downloadType: null,
    platformFilterValue: Ember.computed('currentPlatform', function () {
      var currentPlatform = this.currentPlatform;
      if (currentPlatform && !currentPlatform.length) {
        return 'Filter By Platform';
      } else if (currentPlatform == 'showAll') {
        return 'All';
      } else {
        return currentPlatform;
      }
    }),

    actions: {
      setDownloadType: function setDownloadType(type) {
        this.set('downloadType', type);
      },
      filterPlatform: function filterPlatform(platform, close) {
        this.filterByPlatform(platform);
        close();
      },
      download: function download() {
        this.downloadCopyAuditByAccount();
        // const downloadType = this.get('downloadType').split(' ');
        // if (downloadType.includes('listing')) {
        //   this.downloadCopyAuditByListing();
        // } else if (downloadType.includes('account')) {
        //   this.downloadCopyAuditByAccount();
        // }
      }
    }
  });
});