define('uabide/services/vendor-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    parseService: Ember.inject.service(),
    parseconverter: Ember.inject.service('parse-converter'),
    vendorResults: [],
    vendorResultsLastCallAmount: 0,
    selectedVendors: [],
    vendorStatus: null,
    vendorPlatform: "showAll",
    numberOfListings: "showAll",
    daysOld: "showAll",
    nameChanged: "showAll",
    fileArray: [],
    fileIdArray: [],
    numberDownloadedHistoryFileResults: 0,
    clearAllFilters: false,
    hasPriorityFilterOn: null,
    hasRepliedFilterOn: null,
    hasDoNotContactFilterOn: null,
    hasRemovedFilterOn: null,
    init: function init() {
      this._super();
    },

    addVendorResults: function addVendorResults(newResults) {
      this.set('vendorResultsLastCallAmount', newResults.length);
      this.get('vendorResults').pushObjects(newResults);
    },
    addOrRemoveItemFromSelectedItem: function addOrRemoveItemFromSelectedItem(item) {
      var indexSkuResults = this.get('selectedVendors');
      var a = indexSkuResults.indexOf(item);
      if (a == -1) {
        this.get('selectedVendors').pushObject(item);
      } else {
        this.get('selectedVendors').removeObject(item);
      }
      console.log(indexSkuResults.length);
    },
    clearAllNow: function clearAllNow() {
      this.set('listingResultsLastCallAmount', 0);
      this.set('vendorResults', []);
      this.set('selectedVendors', []);
    },

    addAllListingsToSelectedNow: function addAllListingsToSelectedNow() {
      var listingResults = this.get('vendorResults');
      this.set('selectedVendors', []);
      for (var i = 0; i < listingResults.length; i++) {
        var item = listingResults[i];
        this.get('selectedVendors').pushObject(item);
      }
    },
    removeAllSelectedListingsNow: function removeAllSelectedListingsNow() {
      this.set('selectedVendors', []);
    },
    addAllListingsToSelectedNowReview: function addAllListingsToSelectedNowReview() {
      var listingResults = this.get('vendorResults');
      this.set('selectedVendors', []);
      for (var i = 0; i < listingResults.length; i++) {
        var item = listingResults[i];
        if (item.status == "Review") {
          this.get('selectedVendors').pushObject(item);
        }
      }
    },
    removeAllSelectedListingsNowReview: function removeAllSelectedListingsNowReview() {
      this.set('selectedVendors', []);
    },

    updateItemInSelectedArray: function updateItemInSelectedArray(newItem) {
      var vendorResults = this.get("vendorResults");
      for (var i = 0; i < vendorResults.length; i++) {
        var item = vendorResults[i];
        if (newItem.objectId == item.objectId) {
          vendorResults[i] = newItem;
          this.set('vendorResults', vendorResults);
          this.get('selectedVendors').removeObject(item);
          console.log("updating vendor lists now with new item");
        }
      }
    },
    setVendorStatus: function setVendorStatus(status) {
      this.set('vendorStatus', status);
    },

    resetFileForDownload: function resetFileForDownload() {
      var emptyArray = [];
      var emptyArray2 = [];
      this.set("fileArray", emptyArray);
      this.set("fileIdArray", emptyArray2);

      this.set('numberDownloadedHistoryFileResults', 0);
    },
    addHistoryFileResults: function addHistoryFileResults(myResults) {
      var fileIdArray = this.get("fileIdArray");
      var fileArray = this.get("fileArray");
      for (var i = 0; i < myResults.length; i++) {
        var item = myResults[i];
        if (!fileIdArray.includes(item.objectId)) {
          fileIdArray.pushObject(item.objectId);
          fileArray.pushObject(item);
        }
      }
      this.set("fileArray", fileArray);
      this.set("fileIdArray", fileIdArray);
      this.set('numberDownloadedHistoryFileResults', myResults.length);
    },
    updateItemInVendorArray: function updateItemInVendorArray(newItem) {
      var vendorResults = this.get("vendorResults");
      for (var i = 0; i < vendorResults.length; i++) {
        var item = vendorResults[i];
        if (newItem.objectId == item.objectId) {
          vendorResults[i] = newItem;
          this.set('vendorResults', []);
          this.set('vendorResults', vendorResults);
        }
      }
    },
    getCurrentVendors: function getCurrentVendors(params) {
      return this.get('parseService.cloud').run('getCurrentVendors', params);
    },
    searchVendorsAcrossAccountsByStatusAndSubStatus: function searchVendorsAcrossAccountsByStatusAndSubStatus(params) {
      return this.get('parseService.cloud').run('searchVendorsAcrossAccountsByStatusAndSubStatus', params);
    },
    setPriorityVendor: function setPriorityVendor(params) {
      return this.get('parseService.cloud').run('setPriorityVendor', params);
    },
    setIsRepliedForVendor: function setIsRepliedForVendor(params) {
      return this.get('parseService.cloud').run('setIsRepliedForVendor', params);
    },
    setIsDoNotContactForVendor: function setIsDoNotContactForVendor(params) {
      return this.get('parseService.cloud').run('setIsDoNotContactForVendor', params);
    },
    createSellerStatusReason: function createSellerStatusReason(params) {
      return this.get('parseService.cloud').run('createSellerStatusReason', params);
    },
    updateSellersNotes: function updateSellersNotes(params) {
      return this.get('parseService.cloud').run('updateSellersNotes', params);
    },
    getSellerStatusReasons: function getSellerStatusReasons(params) {
      return this.get('parseService.cloud').run('getSellerStatusReasons', params);
    },
    updateVendorStatusNow: function updateVendorStatusNow(params) {
      return this.get('parseService.cloud').run('updateVendorStatusNow', params);
    },
    updateMasterVendorEmailArray: function updateMasterVendorEmailArray(params) {
      return this.get('parseService.cloud').run('updateMasterVendorEmailArray', params);
    },
    toggleDeadEmail: function toggleDeadEmail(params) {
      return this.get('parseService.cloud').run('toggleDeadEmail', params);
    },
    toggleRespondedEmail: function toggleRespondedEmail(params) {
      return this.get('parseService.cloud').run('toggleRespondedEmail', params);
    },
    updateMasterVendorSingleEmailArray: function updateMasterVendorSingleEmailArray(params) {
      return this.get('parseService.cloud').run('updateMasterVendorSingleEmailArray', params);
    },
    getSellersChangedByTimeAndStatus: function getSellersChangedByTimeAndStatus(params) {
      return this.get('parseService.cloud').run('getSellersChangedByTimeAndStatus', params);
    },
    getCurrentSellerCountIsRemoved: function getCurrentSellerCountIsRemoved(params) {
      return this.get('parseService.cloud').run('getCurrentSellerCountIsRemoved', params);
    },
    getSellerStatusRecordsForVendor: function getSellerStatusRecordsForVendor(params) {
      return this.get('parseService.cloud').run('getSellerStatusRecordsForVendor', params);
    },
    getCurrentSellerCount: function getCurrentSellerCount(params) {
      return this.get('parseService.cloud').run('getCurrentSellerCount', params);
    }
  });
});