define('uabide/services/numbers-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var inject = Ember.inject;

  exports.default = Ember.Service.extend({
    parseconverter: inject.service('parse-converter'),
    vendorResults: [],
    vendorResultsLastCallAmount: 0,
    selectedVendors: [],
    vendorStatus: "active",
    vendorPlatform: "showAll",
    condition: "new",
    fileArray: null,
    numberDownloadedHistoryFileResults: 0,
    clearAllFilters: false,
    init: function init() {
      this._super();
    },

    addVendorResults: function addVendorResults(newResults) {
      this.set('vendorResultsLastCallAmount', newResults.length);
      this.get('vendorResults').pushObjects(newResults);
    },
    addOrRemoveItemFromSelectedItem: function addOrRemoveItemFromSelectedItem(item) {
      var indexSkuResults = this.get('selectedVendors');
      var a = indexSkuResults.indexOf(item);
      if (a == -1) {
        this.get('selectedVendors').pushObject(item);
      } else {
        this.get('selectedVendors').removeObject(item);
      }
      console.log(indexSkuResults.length);
    },
    clearAllNow: function clearAllNow() {
      this.set('listingResultsLastCallAmount', 0);
      this.set('vendorResults', []);
      this.set('selectedVendors', []);
    },

    addAllListingsToSelectedNow: function addAllListingsToSelectedNow() {
      var listingResults = this.get('vendorResults');
      this.set('selectedVendors', []);
      for (var i = 0; i < listingResults.length; i++) {
        var item = listingResults[i];
        this.get('selectedVendors').pushObject(item);
      }
    },
    removeAllSelectedListingsNow: function removeAllSelectedListingsNow() {
      this.set('selectedVendors', []);
    },
    addAllListingsToSelectedNowReview: function addAllListingsToSelectedNowReview() {
      var listingResults = this.get('vendorResults');
      this.set('selectedVendors', []);
      for (var i = 0; i < listingResults.length; i++) {
        var item = listingResults[i];
        if (item.status == "Review") {
          this.get('selectedVendors').pushObject(item);
        }
      }
    },
    removeAllSelectedListingsNowReview: function removeAllSelectedListingsNowReview() {
      this.set('selectedVendors', []);
    },

    updateItemInSelectedArray: function updateItemInSelectedArray(newItem) {
      var vendorResults = this.get("vendorResults");
      for (var i = 0; i < vendorResults.length; i++) {
        var item = vendorResults[i];
        if (newItem.objectId == item.objectId) {
          vendorResults[i] = newItem;
          this.set('vendorResults', vendorResults);
          this.get('selectedVendors').removeObject(item);
          console.log("updating lists now with new item");
        }
      }
    },
    setVendorStatus: function setVendorStatus(status) {
      this.set('vendorStatus', status);
    },

    resetFileForDownload: function resetFileForDownload() {
      var emptyArray = [];
      this.set("fileArray", emptyArray);
      this.set('numberDownloadedHistoryFileResults', 0);
    },
    addHistoryFileResults: function addHistoryFileResults(myResults) {
      this.get('fileArray').pushObjects(myResults);
      this.set('numberDownloadedHistoryFileResults', myResults.length);
    },
    updateItemInVendorArray: function updateItemInVendorArray(newItem) {
      var vendorResults = this.get("vendorResults");
      for (var i = 0; i < vendorResults.length; i++) {
        var item = vendorResults[i];
        if (newItem.objectId == item.objectId) {
          vendorResults[i] = newItem;
          this.set('vendorResults', []);
          this.set('vendorResults', vendorResults);
        }
      }
    }
  });
});