define('uabide/components/sub-statuses-cell', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isPriority: Ember.computed.or('vendor.priorityVendor', 'listing.markSellerAsPriority'),
    isReplied: Ember.computed.alias('vendor.isReplied'),
    isDoNotContact: Ember.computed.alias('vendor.isDoNotContact'),
    hasEmail: Ember.computed.alias('vendor.hasMasterEmail'),
    deadEmailsOnly: Ember.computed.alias('vendor.deadEmailsOnly'),
    priorityValue: 'priority',
    repliedValue: 'replied',
    emaiValue: 'email',
    deadEmail: 'deadEmail',
    doNotContactValue: 'doNotContact'
  });
});