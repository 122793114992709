define('uabide/components/modals/add-user-accounts-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    searchValue: null,
    currentEditUserAccountsArray: [],
    currentMultiAccountsArray: [],
    accountsSorting: ['isAdded:desc'],
    sortedMultiAccountsArray: Ember.computed.sort('currentMultiAccountsArray', 'accountsSorting'),
    accounts: Ember.computed('searchValue', 'sortedMultiAccountsArray', function () {
      var searchValueTrim = this.get('searchValue');
      var sortedMultiAccountsArray = this.get('sortedMultiAccountsArray');
      if (searchValueTrim && searchValueTrim.length) {
        return sortedMultiAccountsArray.filter(function (el) {
          if (el.accountName) {
            return el.accountName.toUpperCase().startsWith(searchValueTrim.toUpperCase());
          }
        });
      } else {
        return sortedMultiAccountsArray;
      }
    }),

    actions: {
      addAccount: function addAccount(item) {
        item.set('isAdded', true);
        this.get('currentEditUserAccountsArray').pushObject(item.objectId);
      },
      removeAccount: function removeAccount(item) {
        item.set('isAdded', false);
        this.get('currentEditUserAccountsArray').removeObject(item.objectId);
      },
      confirmUpdate: function confirmUpdate() {
        var accounts = this.get('currentEditUserAccountsArray');
        this.updateUserMultiAccount(accounts);
        this.toggleModal();
      }
    }
  });
});