define('uabide/helpers/map-stage-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.mapStageTypeAccount = mapStageTypeAccount;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function mapStageTypeAccount(params) {
    var _params = _slicedToArray(params, 2),
        status = _params[0],
        type = _params[1];

    var style = status ? status : 0;

    switch (style) {
      case 1:
        style = 'warning';
        break;
      case 2:
        style = 'orange';
        break;
      case 3:
        style = 'danger';
        break;
      case 4:
        style = 'danger-2';
        break;
      default:
        style = 'danger-3';
        break;
    }
    return (type == 'btn' ? 'c-btn' : 'c-badge') + '--' + style;
  }

  exports.default = Ember.Helper.helper(mapStageTypeAccount);
});