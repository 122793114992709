define('uabide/components/modals/map-listings-more-filters-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentAmazonType: null,
    currentSellerType: null,
    allValue: 'All',

    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.set('currentAmazonType', null);
      this.set('currentSellerType', null);
    },


    actions: {
      setAmazonType: function setAmazonType(type) {
        this.set('currentAmazonType', type);
      },
      setSellerType: function setSellerType(type) {
        this.set('currentSellerType', type);
      },
      filter: function filter() {
        var currentAmazonType = this.get('currentAmazonType');
        var currentSellerType = this.get('currentSellerType');
        if (currentAmazonType) {
          this.filterStatusByAmazonType(currentAmazonType);
        }
        if (currentSellerType) {
          this.filterStatusByType(currentSellerType);
        }
        this.toggleModal();
      }
    }
  });
});