define('uabide/routes/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  Parse.initialize("bzn8msayuASvFwWDJEY3DdGYDXb7eV9rweY2rr7o", "fRmOrMDotozW5iYypZ9s73yADQP3MiKmbIhQxXGw");
  Parse.serverURL = 'https://uabide.back4app.io/';

  exports.default = Ember.Route.extend({
    sessionService: Ember.inject.service(),
    accountcontroller: Ember.inject.service('account-controller'),
    vendorservice: Ember.inject.service('vendor-service'),
    listingservice: Ember.inject.service('listing-service'),

    beforeModel: function beforeModel() {
      var user = this.get('sessionService.currentUser');
      if (user && this.get('sessionService.isMapProtection') && this.get('sessionService.isAdmin') === false) {
        this.replaceWith('/map/overview');
      }
      var currentUser = Parse.User.current();
      if (currentUser) {
        console.log('you are logged in');
        var myself = Parse.User.current().fetch();
        this.get('accountcontroller').setIsAdmin();
      } else {
        console.log('not logged in');
        this.transitionTo('login');
      }
    },
    model: function model() {
      if (this.get('accountcontroller.currentAccount')) {
        return Ember.RSVP.hash({
          currentNewSellerPriorityVendor: this.get('vendorservice').getCurrentVendors({ isCount: true, priorityVendor: true, status: 'New Seller', skip: 0 }),
          currentNotifiedPriorityVendor: this.get('vendorservice').getCurrentVendors({ isCount: true, priorityVendor: true, status: 'Notified', skip: 0 }),
          currentNotifiedRepliedVendor: this.get('vendorservice').getCurrentVendors({ isCount: true, isReplied: true, status: 'Notified', skip: 0 }),
          currentUnauthorizedPriorityVendor: this.get('vendorservice').getCurrentVendors({ isCount: true, priorityVendor: true, status: 'Unauthorized', skip: 0 }),
          currentUnauthorizedRepliedVendor: this.get('vendorservice').getCurrentVendors({ isCount: true, isReplied: true, status: 'Unauthorized', skip: 0 }),
          currentReviewPriorityVendor: this.get('vendorservice').getCurrentVendors({ isCount: true, priorityVendor: true, status: 'Review', skip: 0 }),
          currentReviewRepliedVendor: this.get('vendorservice').getCurrentVendors({ isCount: true, isReplied: true, status: 'Review', skip: 0 }),
          currentRemovedSellerCount: this.get('vendorservice').getCurrentSellerCountIsRemoved(),
          currentReviewSellerCount: this.get('vendorservice').getCurrentSellerCount({ status: 'Review' }),
          currentReviewListingCount: this.get('listingservice').getCurrentListingCount({ status: 'Review' }),
          currentVerifiedSellerCount: this.get('vendorservice').getCurrentSellerCount({ status: 'Verified' }),
          currentVerifiedListingCount: this.get('listingservice').getCurrentListingCount({ status: 'Verified' })
        });
      }
    },


    actions: {
      refreshThisRoute: function refreshThisRoute() {
        console.log("refreshing this route now");
        this.refresh();
      }
    }
  });
});