define('uabide/components/modals/update-account-state-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    selectedAccountState: null,

    selectedAccountStateIsBrandProtection: Ember.computed.equal('selectedAccountState', 1),
    selectedAccountStateIsMAPProtection: Ember.computed.equal('selectedAccountState', 2),
    selectedAccountStateIsBoth: Ember.computed.equal('selectedAccountState', 3),

    actions: {
      setAccountState: function setAccountState(accountState) {
        this.set('selectedAccountState', accountState);
      }
    }
  });
});