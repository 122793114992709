define('uabide/components/modals/confirm-run-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      confirm: function confirm() {
        this.get('confirm')();
        this.get('toggleModal')();
      },
      toggleModal: function toggleModal() {
        this.get('toggleModal')();
      }
    }
  });
});