define('uabide/services/map-products-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    parseService: Ember.inject.service(),

    vendorSideLoad: 'showAll',
    mapResultsLastCallAmount: 0,
    mapResults: [],
    fileArray: [],
    numberDownloadedHistoryFileResults: 0,
    umap: null,
    selectedListings: [],
    addMapResults: function addMapResults(newResults) {
      this.set('mapResultsLastCallAmount', newResults.length);
      this.get('mapResults').pushObjects(newResults);
    },
    addHistoryFileResults: function addHistoryFileResults(newResults) {
      this.set('numberDownloadedHistoryFileResults', newResults.length);
      this.get('fileArray').pushObjects(newResults);
    },
    resetFileForDownload: function resetFileForDownload(newResults) {
      this.set('numberDownloadedHistoryFileResults', 0);
      this.set('fileArray', []);
    },
    clearAllNow: function clearAllNow() {
      this.set('mapResults', []);
    },
    getCurrentMAPProducts: function getCurrentMAPProducts(params) {
      return this.get('parseService.cloud').run('getCurrentMAPProducts', params);
    },
    getMapInfringementsForMapProduct: function getMapInfringementsForMapProduct(params) {
      return this.get('parseService.cloud').run('getMapInfringementsForMapProduct', params);
    },
    updateMapProduct: function updateMapProduct(params) {
      return this.get('parseService.cloud').run('updateMapProduct', params);
    },
    uploadAccountMAPProductList: function uploadAccountMAPProductList(params) {
      return this.get('parseService.cloud').run('uploadAccountMAPProductList', params);
    },
    enableOrDisableMapProduct: function enableOrDisableMapProduct(params) {
      return this.get('parseService.cloud').run('enableOrDisableMapProduct', params);
    },
    addAllListingsToSelectedNow: function addAllListingsToSelectedNow() {
      var mapResults = this.get('mapResults');
      this.set('selectedListings', []);
      for (var i = 0; i < mapResults.length; i++) {
        var item = mapResults[i];
        this.get('selectedListings').pushObject(item);
      }
    },
    addOrRemoveItemFromSelectedItem: function addOrRemoveItemFromSelectedItem(item) {
      var indexSkuResults = this.get('selectedListings');
      var a = indexSkuResults.indexOf(item);
      if (a == -1) {
        this.get('selectedListings').pushObject(item);
      } else {
        this.get('selectedListings').removeObject(item);
      }
    },
    updateItemInSelectedArray: function updateItemInSelectedArray(newItem) {
      var mapResults = this.get("mapResults");
      for (var i = 0; i < mapResults.length; i++) {
        var item = mapResults[i];
        if (newItem.objectId == item.objectId) {
          mapResults[i] = newItem;
          this.set('mapResults', mapResults);
          this.get('selectedListings').removeObject(item);
        }
      }
    },
    removeAllSelectedListingsNow: function removeAllSelectedListingsNow() {
      this.set('selectedListings', []);
    }
  });
});