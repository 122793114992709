define('uabide/services/listing-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var inject = Ember.inject;

  Parse.initialize("bzn8msayuASvFwWDJEY3DdGYDXb7eV9rweY2rr7o", "fRmOrMDotozW5iYypZ9s73yADQP3MiKmbIhQxXGw");
  Parse.serverURL = 'https://uabide.back4app.io/';

  exports.default = Ember.Service.extend({
    parseconverter: Ember.inject.service('parse-converter'),
    parseService: Ember.inject.service(),

    listingResults: [],
    listingResultsLastCallAmount: 0,
    temporaryListingResults: [],
    temporaryListingResultsLastCallAmount: 0,
    selectedListings: [],
    listingStatus: "showAll",
    listingPlatform: "showAll",
    fileArray: [],
    fileIdArray: [],
    numberDownloadedHistoryFileResults: 0,
    turboOn: false,
    brandArray: [],
    brandCountArray: [],
    selectedBrands: [],
    globalBrandArray: [],
    globalBrandNumberArray: [],
    selectedGlobalBrands: [],
    brandFilterOn: false,
    globalBrandFilterOn: false,
    limboOn: false,
    clearAllFilters: false,
    hasPriorityFilterOn: null,
    hasRepliedFilterOn: null,
    listingProductState: null,
    hasBuyBoxWinnerFilterOn: null,
    listingSubStatus: null,
    vendorStatus: null,
    isCounterfeit: null,

    addListingResults: function addListingResults(newResults) {
      if (newResults.length > 0) {
        var newResult = newResults[0];
        if (newResult.status == "Limbo" || newResult.status == "Archive") {
          this.set('limboOn', true);
        } else {
          this.set('limboOn', false);
        }
      }
      this.set('listingResultsLastCallAmount', newResults.length);
      this.get('listingResults').pushObjects(newResults);
    },
    addOrRemoveItemFromSelectedItem: function addOrRemoveItemFromSelectedItem(item) {
      var indexSkuResults = this.get('selectedListings');
      var a = indexSkuResults.indexOf(item);
      if (a == -1) {
        this.get('selectedListings').pushObject(item);
      } else {
        this.get('selectedListings').removeObject(item);
      }
    },
    addOrRemoveItemFromSelectedBrand: function addOrRemoveItemFromSelectedBrand(item) {
      var indexSkuResults = this.get('selectedBrands');
      var a = indexSkuResults.indexOf(item);
      if (a == -1) {
        this.get('selectedBrands').pushObject(item);
      } else {
        this.get('selectedBrands').removeObject(item);
      }
    },
    addOrRemoveItemFromSelectedGlobalBrand: function addOrRemoveItemFromSelectedGlobalBrand(item) {
      var indexSkuResults = this.get('selectedGlobalBrands');
      var a = indexSkuResults.indexOf(item);
      if (a == -1) {
        this.get('selectedGlobalBrands').pushObject(item);
      } else {
        this.get('selectedGlobalBrands').removeObject(item);
      }
    },
    clearAllNow: function clearAllNow() {
      this.set('listingResultsLastCallAmount', 0);
      this.set('listingResults', []);
      this.set('selectedListings', []);
    },
    addAllListingsToSelectedNow: function addAllListingsToSelectedNow() {
      var listingResults = this.get('listingResults');
      this.set('selectedListings', []);
      for (var i = 0; i < listingResults.length; i++) {
        var item = listingResults[i];
        this.get('selectedListings').pushObject(item);
      }
    },
    removeAllSelectedListingsNow: function removeAllSelectedListingsNow() {
      this.set('selectedListings', []);
    },
    addAllBrandsToSelectedNow: function addAllBrandsToSelectedNow() {
      var brandArray = this.get('brandArray');
      this.set('selectedBrands', []);
      for (var i = 0; i < brandArray.length; i++) {
        var item = brandArray[i];
        this.get('selectedBrands').pushObject(item);
      }
    },
    removeAllSelectedBrandsNow: function removeAllSelectedBrandsNow() {
      this.set('selectedBrands', []);
    },
    addAllGlobalBrandsToSelectedNow: function addAllGlobalBrandsToSelectedNow() {
      var brandArray = this.get('globalBrandArray');
      this.set('selectedGlobalBrands', []);
      for (var i = 0; i < brandArray.length; i++) {
        var item = brandArray[i];
        this.get('selectedGlobalBrands').pushObject(item);
      }
    },
    removeAllGlobalSelectedBrandsNow: function removeAllGlobalSelectedBrandsNow() {
      this.set('selectedGlobalBrands', []);
    },
    updateItemInSelectedArray: function updateItemInSelectedArray(newItem) {
      var listingResults = this.get("listingResults");
      for (var i = 0; i < listingResults.length; i++) {
        var item = listingResults[i];
        if (newItem.objectId == item.objectId) {
          listingResults[i] = newItem;
          this.set('listingResults', listingResults);
          this.get('selectedListings').removeObject(item);
        }
      }
      var brandFilterOn = this.get('brandFilterOn');
      if (brandFilterOn) {
        var temporaryListingResults = this.get('temporaryListingResults');
        if (temporaryListingResults.length > 0) {
          for (var x = 0; x < temporaryListingResults.length; x++) {
            var item = temporaryListingResults[x];
            if (newItem.objectId == item.objectId) {
              temporaryListingResults[x] = newItem;
              this.set('temporaryListingResults', temporaryListingResults);
            }
          }
        }
      }
    },
    setListingStatus: function setListingStatus(status) {
      this.set('listingStatus', status);
    },
    updateTurboSettings: function updateTurboSettings(turboOn) {
      this.set('turboOn', turboOn);
      var params = { turboOn: turboOn };
      Parse.Cloud.run("updateUsersTurboSettings", params).then(function (result) {
        var myself = Parse.User.current().fetch();
        //console.log(result);
      }, function (error) {
        console.log("updateUsersTurboSettings -  " + error.message);
      });
    },
    updateTurbo: function updateTurbo(turboOn) {
      this.set('turboOn', turboOn);
    },
    resetFileForDownload: function resetFileForDownload() {
      var emptyArray = [];
      var emptyArray2 = [];
      this.set("fileArray", emptyArray);
      this.set("fileIdArray", emptyArray2);

      this.set('numberDownloadedHistoryFileResults', 0);
    },
    addHistoryFileResults: function addHistoryFileResults(myResults) {
      var fileIdArray = this.get("fileIdArray");
      var fileArray = this.get("fileArray");
      for (var i = 0; i < myResults.length; i++) {
        var item = myResults[i];
        if (!fileIdArray.includes(item.objectId)) {
          fileIdArray.pushObject(item.objectId);
          fileArray.pushObject(item);
        }
      }
      this.set("fileArray", fileArray);
      this.set("fileIdArray", fileIdArray);
      this.set('numberDownloadedHistoryFileResults', myResults.length);
    },
    updateItemInListingArray: function updateItemInListingArray(newItem) {
      var listingResults = this.get("listingResults");
      for (var i = 0; i < listingResults.length; i++) {
        var item = listingResults[i];
        if (newItem.objectId == item.objectId) {
          listingResults[i] = newItem;
          this.set('listingResults', []);
          this.set('listingResults', listingResults);
        }
      }
      var brandFilterOn = this.get('brandFilterOn');
      if (brandFilterOn) {
        var temporaryListingResults = this.get('temporaryListingResults');
        if (temporaryListingResults.length > 0) {
          for (var x = 0; x < temporaryListingResults.length; x++) {
            var item = temporaryListingResults[x];
            if (newItem.objectId == item.objectId) {
              temporaryListingResults[x] = newItem;
              this.set('temporaryListingResults', temporaryListingResults);
            }
          }
        }
      }
    },
    filterByBrand: function filterByBrand() {
      var brandFilterOn = this.get('brandFilterOn');
      var listingResults = this.get("listingResults");
      var brandArray = [];
      var brandCountArray = [];
      for (var i = 0; i < listingResults.length; i++) {
        var item = listingResults[i];
        var brand = item.brand;
        if (brand != "") {
          var a = brandArray.indexOf(brand);
          if (a == -1) {
            brandArray.pushObject(brand);
            var brandCount = {};
            brandCount.brand = brand;
            brandCount.count = 1;
            brandCountArray.pushObject(brandCount);
          } else {
            for (var x = 0; x < brandCountArray.length; x++) {
              var brandObject = brandCountArray[x];
              if (brandObject.brand == brand) {
                var newcount = brandObject.count + 1;
                brandObject.count = newcount;
                brandCountArray[x] = brandObject;
              }
            }
          }
        }
      }
      this.set("brandCountArray", brandCountArray);
      this.set("brandArray", brandArray);
    },
    applyBrandFilters: function applyBrandFilters() {
      this.resetGlobalBrandFilters();
      var brandFilterOn = this.get('brandFilterOn');
      var listingResults = this.get('listingResults');
      var selectedBrands = this.get('selectedBrands');
      if (selectedBrands.length == 0) {
        this.resetBrandFilters();
        return;
      }
      if (brandFilterOn) {
        var temporaryListingResults = this.get('temporaryListingResults');
        listingResults = temporaryListingResults;
      } else {
        this.set("temporaryListingResults", listingResults);
        this.set("brandFilterOn", true);
      }
      var filteredBrandListingArray = [];
      for (var i = 0; i < listingResults.length; i++) {
        var item = listingResults[i];
        var a = selectedBrands.indexOf(item.brand);
        if (a != -1) {
          filteredBrandListingArray.pushObject(item);
        }
      }
      this.set("listingResults", filteredBrandListingArray);
    },
    resetBrandFilters: function resetBrandFilters() {
      var brandFilterOn = this.get('brandFilterOn');
      this.set("selectedBrands", []);
      if (brandFilterOn) {
        this.set("brandFilterOn", false);
        var temporaryListingResults = this.get('temporaryListingResults');
        this.set("brandArray", []);
        this.set("brandCountArray", []);
        if (temporaryListingResults.length > 0) {
          this.set("listingResults", temporaryListingResults);
          this.set("temporaryListingResults", []);
        }
      }
    },
    applyGlobalBrandFilters: function applyGlobalBrandFilters() {
      this.resetBrandFilters();
      this.set("globalBrandFilterOn", true);
      var selectedGlobalBrands = this.get('selectedGlobalBrands');

      if (selectedGlobalBrands.length == 0) {
        this.resetGlobalBrandFilters();
        return;
      }
      this.clearAllNow();
    },
    resetGlobalBrandFilters: function resetGlobalBrandFilters() {
      var globalBrandFilterOn = this.get('globalBrandFilterOn');
      if (globalBrandFilterOn) {
        this.set("globalBrandFilterOn", false);
        this.set("globalBrandArray", []);
        this.set("globalBrandNumberArray", []);
        this.clearAllNow();
      }
      this.set("selectedGlobalBrands", []);
    },
    removeBrandsFromBlockedList: function removeBrandsFromBlockedList(params) {
      return Parse.Cloud.run('removeBrandsFromBlockedList', params);
    },
    getCurrentListings: function getCurrentListings(params) {
      return this.get('parseService.cloud').run('getCurrentListings', params);
    },
    getFilteredListingStatusCount: function getFilteredListingStatusCount(params) {
      return this.get('parseService.cloud').run('getFilteredListingStatusCount', params);
    },
    getFilteredSellersRemovedCount: function getFilteredSellersRemovedCount(params) {
      return this.get('parseService.cloud').run('getFilteredSellersRemovedCount', params);
    },
    setPriorityListing: function setPriorityListing(params) {
      return this.get('parseService.cloud').run('setPriorityListing', params);
    },
    setIsRepliedForListing: function setIsRepliedForListing(params) {
      return this.get('parseService.cloud').run('setIsRepliedForListing', params);
    },
    updateMultipleListingStatusNow: function updateMultipleListingStatusNow(params) {
      return this.get('parseService.cloud').run('updateMultipleListingStatusNow', params);
    },
    importSelectedListings: function importSelectedListings(params) {
      return this.get('parseService.cloud').run('importSelectedListings', params);
    },
    updateMultipleListingStatusNowLimboArchive: function updateMultipleListingStatusNowLimboArchive(params) {
      return this.get('parseService.cloud').run('updateMultipleListingStatusNowLimboArchive', params);
    },
    getListingsChangedByTimeAndStatus: function getListingsChangedByTimeAndStatus(params) {
      return this.get('parseService.cloud').run('getListingsChangedByTimeAndStatus', params);
    },
    getCurrentListingCount: function getCurrentListingCount(params) {
      return this.get('parseService.cloud').run('getCurrentListingCount', params);
    }
  });
});