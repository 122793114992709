define('uabide/components/modals/use-modals', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var UseModalsComponent = Ember.Component.extend({
    modalService: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.get('modalService').add([].concat(_toConsumableArray(this.params)));
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.get('modalService').remove([].concat(_toConsumableArray(this.params)));
    }
  });

  UseModalsComponent.reopenClass({
    positionalParams: 'params'
  });

  exports.default = UseModalsComponent;
});