define('uabide/components/dropdown-menu/dropdown-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    anyImage: true,

    actions: {
      toggleAnyImage: function toggleAnyImage() {
        this.toggleProperty('anyImage');
      }
    }
  });
});