define('uabide/routes/map/overview', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    accountController: Ember.inject.service('account-controller'),
    mapService: Ember.inject.service(),

    model: function model() {
      return Ember.RSVP.hash({
        accountController: this.get('accountController'),
        currentMapRetailersCountRetailers: this.get('mapService').getCurrentMapRetailersCountRetailers(),
        currentMapCountRetailers3p: this.get('mapService').getCurrentMapCountRetailers3p(),
        currentMapCountNonCompliantRetailers: this.get('mapService').getCurrentMapCountNonCompliantRetailers(),
        currentMapCountNonCompliantRetailers3p: this.get('mapService').getCurrentMapCountNonCompliantRetailers3p(),
        currentMapCountActiveProducts: this.get('mapService').getCurrentMapCountActiveProducts(),
        currentMapCountInActiveProducts: this.get('mapService').getCurrentMapCountInActiveProducts(),
        totalListings: this.get('mapService').getTotalListings(),
        totalNonCompliantListings: this.get('mapService').getTotalNonCompliantListings(),
        stage1Accounts: this.get('mapService').getStage1Accounts(),
        stage2Accounts: this.get('mapService').getStage2Accounts(),
        stage3Accounts: this.get('mapService').getStage3Accounts(),
        stage5Accounts: this.get('mapService').getStage5Accounts(),
        inspectAccounts: this.get('mapService').getInspectAccounts()
      });
    }
  });
});