define('uabide/services/parse-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  Parse.initialize("bzn8msayuASvFwWDJEY3DdGYDXb7eV9rweY2rr7o", "fRmOrMDotozW5iYypZ9s73yADQP3MiKmbIhQxXGw");
  Parse.serverURL = 'https://uabide.back4app.io/';

  exports.default = Ember.Service.extend({
    cloud: Ember.computed.alias('parse.Cloud'),
    user: Ember.computed.alias('parse.User'),

    init: function init() {
      this.set('parse', Parse);
    }
  });
});