define('uabide/components/chart-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentCanvasId: null,
    currentChart: null,

    didInsertElement: function didInsertElement() {
      var ctx = this.element.firstChild;
      var labels = this.labels ? this.labels : this.data.map(function (el) {
        return '';
      });
      var myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [{
            label: '',
            data: this.data,
            backgroundColor: this.colors.backgroundColors,
            borderColor: this.colors.borderColors,
            borderWidth: 1
          }]
        },
        options: {
          barPercentage: 1.2,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          },
          legend: {
            labels: {
              boxWidth: 0
            }
          }
        }
      });
      this.set('currentChart', myChart);
      this._super.apply(this, arguments);
    }
  });
});