define('uabide/components/modals/new-reporting-system-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentDate: null,
    leftMonthDate: null,
    rightMonthDate: null,
    selectedDate: null,
    selectedLeftDate: null,
    selectedRightDate: null,
    isSelectingStartDate: true,
    startEndDate: null,
    customUrl: '',
    accountservice: Ember.inject.service('account-service'),

    init: function init() {
      this._super.apply(this, arguments);
      // Set the current date to today by default
      var currentDate = new Date();
      this.set('leftMonthDate', new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
      this.set('rightMonthDate', new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
    },


    // Computed property to get the month and year name for the right month

    leftMonthName: Ember.computed('leftMonthDate', function () {
      var leftMonthDate = this.get('leftMonthDate');
      var month = leftMonthDate.getMonth();
      var year = leftMonthDate.getFullYear();
      var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      return months[month] + ' ' + year;
    }),
    rightMonthName: Ember.computed('rightMonthDate', function () {
      var rightMonthDate = this.get('rightMonthDate');
      var month = rightMonthDate.getMonth();
      var year = rightMonthDate.getFullYear();
      var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      return months[month] + ' ' + year;
    }),

    // Computed property to get the first day of the current month

    firstDayOfLeftMonth: Ember.computed('leftMonthDate', function () {
      return new Date(this.get('leftMonthDate').getFullYear(), this.get('leftMonthDate').getMonth(), 1).getDay();
    }),

    firstDayOfRightMonth: Ember.computed('rightMonthDate', function () {
      return new Date(this.get('rightMonthDate').getFullYear(), this.get('rightMonthDate').getMonth(), 1).getDay();
    }),

    // Computed property to get the number of days in the current left month
    numberOfDaysInLeftMonth: Ember.computed('leftMonthDate', function () {
      var year = this.get('leftMonthDate').getFullYear();
      var month = this.get('leftMonthDate').getMonth() + 1;
      return new Date(year, month, 0).getDate();
    }),

    // Computed property to get the number of days in the right month
    numberOfDaysInRightMonth: Ember.computed('rightMonthDate', function () {
      var year = this.get('rightMonthDate').getFullYear();
      var month = this.get('rightMonthDate').getMonth() + 1;
      return new Date(year, month, 0).getDate();
    }),

    // Function to generate an array of numbers representing the days of the left and right month
    generateLeftDaysArray: Ember.computed('firstDayOfLeftMonth', 'numberOfDaysInLeftMonth', function () {
      var firstDayOfLeftMonth = this.get('firstDayOfLeftMonth');
      var numberOfDaysInLeftMonth = this.get('numberOfDaysInLeftMonth');

      var leftDaysArray = [];
      for (var i = 1; i <= numberOfDaysInLeftMonth; i++) {
        leftDaysArray.push(i);
      }

      for (var _i = 0; _i < firstDayOfLeftMonth; _i++) {
        leftDaysArray.unshift(null);
      }

      return leftDaysArray;
    }),

    generateRightDaysArray: Ember.computed('firstDayOfRightMonth', 'numberOfDaysInRightMonth', function () {
      var firstDayOfRightMonth = this.get('firstDayOfRightMonth');
      var numberOfDaysInRightMonth = this.get('numberOfDaysInRightMonth');

      var rightDaysArray = [];
      for (var i = 1; i <= numberOfDaysInRightMonth; i++) {
        rightDaysArray.push(i);
      }

      for (var _i2 = 0; _i2 < firstDayOfRightMonth; _i2++) {
        rightDaysArray.unshift(null);
      }

      return rightDaysArray;
    }),

    daysOfWeek: Ember.computed(function () {
      return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    }),

    isDateInRangeLeft: Ember.computed('startEndDate', 'leftMonthDate', function () {
      var _getProperties = this.getProperties('startEndDate', 'leftMonthDate'),
          startEndDate = _getProperties.startEndDate,
          leftMonthDate = _getProperties.leftMonthDate;

      if (startEndDate && startEndDate.start && startEndDate.end) {
        var startDate = startEndDate.start.getDate();
        var endDate = startEndDate.end.getDate();
        var currentDay = leftMonthDate.getDate();
        return currentDay === startDate || currentDay > startDate && currentDay < endDate || currentDay === endDate;
      }
      return false;
    }),

    isDateInRangeRight: Ember.computed('startEndDate', 'rightMonthDate', function () {
      var _getProperties2 = this.getProperties('startEndDate', 'rightMonthDate'),
          startEndDate = _getProperties2.startEndDate,
          rightMonthDate = _getProperties2.rightMonthDate;

      if (startEndDate && startEndDate.start && startEndDate.end) {
        var startDate = startEndDate.start.getDate();
        var endDate = startEndDate.end.getDate();
        var currentDay = rightMonthDate.getDate();
        return currentDay === startDate || currentDay > startDate && currentDay < endDate || currentDay === endDate;
      }
      return false;
    }),

    actions: {

      // Action to move to the previous month
      prevLeftMonth: function prevLeftMonth() {
        var leftMonthDate = this.get('leftMonthDate');
        leftMonthDate.setMonth(leftMonthDate.getMonth() - 1);
        this.set('leftMonthDate', new Date(leftMonthDate));
      },


      // Action to move to the next month for the left month
      nextLeftMonth: function nextLeftMonth() {
        var leftMonthDate = this.get('leftMonthDate');
        leftMonthDate.setMonth(leftMonthDate.getMonth() + 1);
        this.set('leftMonthDate', new Date(leftMonthDate));
      },


      // Action to move to the previous month for the right month
      prevRightMonth: function prevRightMonth() {
        var rightMonthDate = this.get('rightMonthDate');
        rightMonthDate.setMonth(rightMonthDate.getMonth() - 1);
        this.set('rightMonthDate', new Date(rightMonthDate));
      },


      // Action to move to the next month for the right month
      nextRightMonth: function nextRightMonth() {
        var rightMonthDate = this.get('rightMonthDate');
        rightMonthDate.setMonth(rightMonthDate.getMonth() + 1);
        this.set('rightMonthDate', new Date(rightMonthDate));
      },
      dateClicked: function dateClicked(day, isLeftCalendar) {
        var clickedDate = isLeftCalendar ? this.get('leftMonthDate') : this.get('rightMonthDate');
        var month = ('0' + (clickedDate.getMonth() + 1)).slice(-2); // Added leading zero if necessary
        var dayOfMonth = ('0' + day).slice(-2); // Added leading zero if necessary
        var year = clickedDate.getFullYear();
        var formattedDate = month + '/' + dayOfMonth + '/' + year;

        // Determine if the start or end date is already set
        var startEndDate = this.get('startEndDate');

        // If neither start nor end date is set, set the clicked date as the start date
        if (!startEndDate || startEndDate.start && startEndDate.end) {
          this.set('startEndDate', { start: clickedDate, end: null });
          console.log('Start date set:', formattedDate);
          this.set('startDateSet', formattedDate);
        } else if (startEndDate.start && !startEndDate.end) {
          // Start date is set but end date is not set, set the clicked date as the end date
          var startDate = startEndDate.start;
          var endDate = clickedDate;
          this.set('startEndDate', { start: startDate, end: endDate });
          console.log('End date set:', formattedDate);
          this.set('endDateSet', formattedDate);
        } else {
          // End date is set but start date is not set, set the clicked date as the start date
          var _startDate = clickedDate;
          var _endDate = startEndDate.end;
          this.set('startEndDate', { start: _startDate, end: _endDate });
          console.log('Start date set:', formattedDate);
          this.set('startDateSet', formattedDate);
        }
      },
      applyDates: function applyDates(startDate, endDate, objectId) {
        // Construct the custom URL with the provided parameters
        var customUrl = '/account-report-bulk/' + objectId + '/report?daterange=11&startDate=' + startDate + '&endDate=' + endDate;
        console.log(customUrl);
        console.log(startDate);
        console.log(endDate);
        // Open the custom URL in a new tab/window
        window.open(customUrl, '_blank');
      }
    }
  });
});