define('uabide/routes/application', ['exports', 'uabide/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    sessionService: Ember.inject.service(),
    accountcontroller: Ember.inject.service('account-controller'),
    beforeModel: function beforeModel(transition) {
      var currentUser = this.get('sessionService.currentUser');
      var token = transition.queryParams.token;
      if (!token) {
        if (currentUser) {
          console.log('you are logged in');
          this.get('accountcontroller').setIsAdmin();
        } else {
          console.log('not logged in');
          this.transitionTo('login');
        }
      }
    },
    setupController: function setupController(controller) {
      controller.set('task', this.validateEnvironment());
    },


    actions: {
      refreshThisRoute: function refreshThisRoute() {
        console.log('refreshing this route now');
        this.refresh();
      }
    },

    validateEnvironment: function validateEnvironment() {
      var isStaging = _environment.default.environment === 'production';
      var id = _environment.default.TEST_ENVIRONMENT_TASK_ID;
      var title = '"' + _environment.default.TEST_ENVIRONMENT_TASK_TITLE + '"';
      var url = _environment.default.TEST_ENVIRONMENT_TASK_LINK;
      if (isStaging && id && title && url) {
        return {
          id: id,
          title: title,
          url: url
        };
      } else {
        return null;
      }
    }
  });
});