define('uabide/utils/string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.capitalizeFirstLetter = capitalizeFirstLetter;
  function capitalizeFirstLetter(string) {
    if (string === 'amazon' || string === 'Amazon') {
      return 'Amazon US';
    }
    return string.replace(/(^\w{1})|(\s{1}\w{1})/g, function (match) {
      return match.toUpperCase();
    });
  }
});