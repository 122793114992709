define('uabide/components/modals/first-seen-date-range-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    firstSeenStart: null,
    firstSeenEnd: null,

    didUpdateAttrs: function didUpdateAttrs() {
      this.set('firstSeenStart', this.start);
      this.set('firstSeenEnd', this.end);
    },


    actions: {
      setFirstSeenStart: function setFirstSeenStart(date) {
        this.set('firstSeenStart', date);
      },
      setFirstSeenEnd: function setFirstSeenEnd(date) {
        this.set('firstSeenEnd', date);
      },
      setDates: function setDates() {
        this.setDateParams(this.get('firstSeenStart'), this.get('firstSeenEnd'));
      }
    }
  });
});