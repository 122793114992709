define('uabide/components/search-filter-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    searchInputStyle: Ember.computed('multiField', 'isBroadSelectAvailable', function () {
      var multiField = this.get('multiField');
      var isBroadSelectAvailable = this.get('isBroadSelectAvailable');

      if (multiField) {
        return 'has-multi-field';
      } else if (isBroadSelectAvailable) {
        return 'has-addon-right';
      } else {
        return '';
      }
    })
  });
});