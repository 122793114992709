define('uabide/services/vendor-white-list-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    parseService: Ember.inject.service(),
    vendorResults: [],
    vendorResultsLastCallAmount: 0,
    fileArray: null,
    numberDownloadedHistoryFileResults: 0,

    addVendorResults: function addVendorResults(newResults) {
      this.set('vendorResultsLastCallAmount', newResults.length);
      this.get('vendorResults').pushObjects(newResults);
    },
    clearAllNow: function clearAllNow() {
      this.set('vendorResultsLastCallAmount', 0);
      this.set('vendorResults', []);
    },
    resetFileForDownload: function resetFileForDownload() {
      this.set('fileArray', []);
      this.set('numberDownloadedHistoryFileResults', 0);
    },
    addHistoryFileResults: function addHistoryFileResults(myResults) {
      this.get('fileArray').pushObjects(myResults);
      this.set('numberDownloadedHistoryFileResults', myResults.length);
    },
    getVendorWhiteList: function getVendorWhiteList(params) {
      return this.get('parseService.cloud').run('getVendorWhiteList', params);
    },
    checkIfVendorisOnWhiteList: function checkIfVendorisOnWhiteList(params) {
      return this.get('parseService.cloud').run('checkIfVendorisOnWhiteList', params);
    },
    setVendorToWhiteList: function setVendorToWhiteList(params) {
      return this.get('parseService.cloud').run('setVendorToWhiteList', params);
    },
    removeVendorFromWhiteList: function removeVendorFromWhiteList(params) {
      return this.get('parseService.cloud').run('removeVendorFromWhiteList', params);
    },
    updateAccountOverrideArray: function updateAccountOverrideArray(params) {
      return this.get('parseService.cloud').run('updateAccountOverrideArray', params);
    }
  });
});