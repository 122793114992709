define("uabide/helpers/platform-image", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.platformImage = platformImage;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function platformImage(params /*, hash*/) {
    var _params = _slicedToArray(params, 1),
        status = _params[0];

    var text;
    if (status == undefined) {
      return "";
    }
    var newStatus = status.toLowerCase();
    switch (newStatus) {
      case "amazon mx":
        text = "/img/Amazon_Mexico.png";
        break;
      case "amazon es":
        text = "/img/Amazon_Spain.png";
        break;
      case "amazon it":
        text = "/img/Amazon_Italy.png";
        break;
      case "amazon in":
        text = "/img/Amazon_India.png";
        break;
      case "amazon cn":
        text = "/img/Amazon_China.png";
        break;
      case "amazon ch":
        text = "/img/Amazon_China.png";
        break;
      case "amazon br":
        text = "/img/Amazon_Brazil.png";
        break;
      case "amazon":
        text = "/img/Amazon_United States.png";
        break;
      case "amazon us":
        text = "/img/Amazon_United States.png";
        break;
      case "amazon au":
        text = "/img/Amazon_Australia.png";
        break;
      case "amazon uk":
        text = "/img/Amazon_United Kingdom.png";
        break;
      case "amazon jp":
        text = "/img/Amazon_Japan.png";
        break;
      case "amazon ca":
        text = "/img/Amazon_Canada.png";
        break;
      case "amazon de":
        text = "/img/Amazon_Germany.png";
        break;
      case "amazon fr":
        text = "/img/Amazon_France.png";
        break;
      case "ali-express":
        text = "/img/ali_express.png";
        break;
      case "ebay":
        text = "/img/ebay.png";
        break;
      case "walmart":
        text = "/img/Walmart_Logos_United States.png";
        break;
      case "walmart ca":
        text = "/img/Walmart_Logos_Canada.png";
        break;
      case "rakuten fr":
        text = "/img/rakuten-fr.png";
        break;
      case "rakuten":
        text = "/img/rakuten.png";
        break;
      case "rueducommerce":
        text = "/img/rueducommerce.png";
        break;
      case "bol":
        text = "/img/bol.png";
        break;
      case "tmall":
        text = "/img/Tmall.png";
        break;
      case "jd":
        text = "/img/JD.png";
        break;
      case "allegro":
        text = "/img/allegro-logo.png";
        break;
      case "1688":
        text = "/img/Marketplace Dashboard Logos_1688.png";
        break;
      case "alibaba":
        text = "/img/Marketplace Dashboard Logos_Alibaba.png";
        break;
      case "bukalapak":
        text = "/img/Marketplace Dashboard Logos_bukalapak.png";
        break;
      case "dhgate":
        text = "/img/Marketplace Dashboard Logos_DHGate.png";
        break;
      case "made-in-china":
        text = "/img/Marketplace Dashboard Logos_Made-in-china.png";
        break;
      case "wish":
        text = "/img/Marketplace Dashboard Logos_Wish.png";
        break;
      case "taobao":
        text = "/img/Marketplace Dashboard Logos_Taobao.png";
        break;
      case "everychina":
        text = "/img/EveryChina.png";
        break;
      case "redbubble":
        text = "/img/redbubble.jpg";
        break;
      case "red bubble":
        text = "/img/redbubble.jpg";
        break;

      case "letgo":
        text = "/img/Marketplace_Letgo.png";
        break;
      case "ioffer":
        text = "/img/Marketplace_iOffer.png";
        break;
      case "offerup":
        text = "/img/Marketplace_OfferUp.png";
        break;
      case "c2coffer":
        text = "/img/Marketplace_C2Coffer.png";
        break;
      case "poshmark":
        text = "/img/poshmark.png";
        break;
      case "ebay ca":
        text = "/img/ebay_ca.png";
        break;
      case "ebay au":
        text = "/img/ebay_au.jpg";
        break;
      case "ebay uk":
        text = "/img/ebay_uk.jpg";
        break;
      case "mercado libre ar":
        text = "/img/ML2.png";
        break;
      case "mercado libre br":
        text = "/img/ML2.png";
        break;
      case "mercado libre co":
        text = "/img/ML2.png";
        break;
      case "mercado libre mx":
        text = "/img/ML2.png";
        break;
      case "mercado libre uy":
        text = "/img/ML2.png";
        break;
      case "mercado libre cl":
        text = "/img/ML2.png";
        break;
      case "mercado libre do":
        text = "/img/ML2.png";
        break;
      case "mercado libre ec":
        text = "/img/ML2.png";
        break;
      case "mercado libre pe":
        text = "/img/ML2.png";
        break;
      case "mercado libre ve":
        text = "/img/ML2.png";
        break;
      case "lazada":
        text = "/img/Lazada-Thailand.png";
        break;
      case "lazada th":
        text = "/img/Lazada-Thailand.png";
        break;
      case "lazada (thailand)":
        text = "/img/Lazada-Thailand.png";
        break;
      case "lazada vn":
        text = "/img/lazada_vn.png";
        break;
      case "lazada sg":
        text = "/img/lazada_sg.png";
        break;
      case "lazada ph":
        text = "/img/lazada_ph.png";
        break;
      case "lazada my":
        text = "/img/lazada_my.png";
        break;
      case "lazada id":
        text = "/img/lazada_id.png";
        break;
      case "coupang":
        text = "/img/coupang.jpg";
        break;
      case "mercari":
        text = "/img/mercari.png";
        break;
      case "website":
        text = "/img/website.png";
        break;
      case "sears":
        text = "/img/sears.png";
        break;
      case "weedmaps":
        text = "/img/weedmaps-raw-garden.jpg";
        break;
      case "instagram":
        text = "/img/instagram.png";
        break;
      case "newegg ca":
        text = "/img/newegg_ca_logo.png";
        break;
      case "newegg":
        text = "/img/newegg_icon.png";
        break;
      case "bestbuy ca":
        text = "/img/bestbuy_CA.png";
        break;
      case "google-shopping":
        text = "/img/google-shopping.png";
        break;
      case "google":
        text = "/img/google-shopping.png";
        break;
      case "wheresweed":
        text = "/img/where-s-weed-logo.jpg";
        break;
      case "cdiscount":
        text = "/img/cdiscount_banner-1.png";
        break;
      case "facebook":
        text = "/img/fb-logo.png";
        break;
      case "aliexpress":
        text = "/img/aliexpress-logo.png";
        break;
      case "twitter":
        text = "/img/Twitter-logo-2011.png";
        break;
      case "bonanza":
        text = "/img/bonanza_hi_res_logo_green.png";
        break;
      case "desertcart":
        text = "/img/DesertCart-logo.png";
        break;
      case "etsy":
        text = "/img/Etsy-logo.png";
        break;
      case "gmarket":
        text = "/img/Gmarket-logo.gif";
        break;
      case "ruten":
        text = "/img/Ruten-logo.png";
        break;
      case "wemakeprice":
        text = "/img/Wemakeprice-logo.png";
        break;
      case "abt":
        text = "/img/ABT.png";
        break;
      case "bedbathandbeyond":
        text = "/img/bedbathandbeyond-logo.jpg";
        break;
      case "bloomingdales":
        text = "/img/bloomingdales-logo.png";
        break;
      case "crate and barrel":
        text = "/img/crateandbarrel-logo.png";
        break;
      case "macys":
        text = "/img/macys-logo.png";
        break;
      case "sur la table":
        text = "/img/surlatable-logo.png";
        break;
      case "wayfair":
        text = "/img/wayfair-logo.png";
        break;
      case "williams sonoma":
        text = "/img/williamssonoma-logo.png";
        break;
      case "americanas":
        text = "/img/americanas-logo.png";
        break;
      case "shoptime":
        text = "/img/shoptime-logo.png";
        break;
      case "submarino":
        text = "/img/submarino-logo.png";
        break;

      case "shopee id":
        text = "/img/shopee-logo-id.png";
        break;
      case "shopee my":
        text = "/img/shopee-logo-my.png";
        break;
      case "shopee ph":
        text = "/img/shopee-logo-ph.png";
        break;
      case "shopee sg":
        text = "/img/shopee-logo-sg.png";
        break;
      case "shopee th":
        text = "/img/shopee-logo-th.png";
        break;
      case "shopee vn":
        text = "/img/shopee-logo-vn.png";
        break;
      case "overstock":
        text = "/img/overstock.png";
        break;
      case "tokopedia":
        text = "/img/tokopedia_logo.png";
        break;
      case "jd.com":
        text = "/img/jd_logo.png";
        break;
      case "flipkart":
        text = "/img/flipkart_logo.png";
        break;
      case "noon":
        text = "/img/noon_logo.png";
        break;
      case "souq":
        text = "/img/souq_logo.png";
        break;
      case "amazon sg":
        text = "/img/Amazon_SE-02.png";
        break;
      case "ebay pl":
        text = "/img/eBay_Amazon_Logos-03.png";
        break;
      case "ebay hk":
        text = "/img/eBay_Amazon_Logos_United States.png";
        break;
      case "amazon se":
        text = "/img/Amazon_SE-02.png";
        break;
      case "vinted es":
        text = "/img/vinted.png";
        break;
      case " catch au":
        text = "/img/catch_logo.png";
        break;
      case "shutterstock":
        text = "/img/shutterstock_logo.png";
        break;
      case "bestbuy":
        text = "/img/bestbuy-logo1.png";
        break;
      case "- bhphotovideo":
        text = "/img/bhphotovideo-logo.png";
        break;
      case "officedepot":
        text = "/img/officedepot-logo.png";
        break;
      case "staples":
        text = "/img/staples-logo.png";
        break;
      case "target":
        text = "/img/target-logo.jpeg";
        break;
      case "drschollsshoes":
        text = "/img/drschollsshoes-logo.png";
        break;

      case "belk":
        text = "/img/belk-logo.png";
        break;
      case "dillards":
        text = "/img/dillards-logo.png";
        break;
      case "dsw":
        text = "/img/dsw-logo.png";
        break;
      case "famousfootwear":
        text = "/img/famousfootwear-logo.png";
        break;
      case "hsn":
        text = "/img/hsn-logo.png";
        break;
      case "kohls":
        text = "/img/kohls-logo.png";
        break;
      case "marshalls":
        text = "/img/marshalls-logo.png";
        break;
      case "naturalizer":
        text = "/img/naturalizer-logo.png";
        break;
      case "nordstrom":
        text = "/img/nordstrom-logo.png";
        break;
      case "nordstromrack":
        text = "/img/nordstromrack-logo.png";
        break;
      case "qvc":
        text = "/img/qvc-logo.png";
        break;
      case "qvcuk":
        text = "/img/qvcuk-logo.png";
        break;
      case "rackroomshoes":
        text = "/img/rackroomshoes-logo.png";
        break;
      case "shoecarnival":
        text = "/img/shoecarnival-logo.png";
        break;
      case "shoemall":
        text = "/img/shoemall-logo.png";
        break;
      case "sierra":
        text = "/img/sierra-logo.png";
        break;
      case "stitchfix":
        text = "/img/drschollsshoes-logo.png";
        break;
      case "tjmaxx":
        text = "/img/tjmaxx-logo.png";
        break;
      case "zappos":
        text = "/img/zappos-logo.png";
        break;

      case "basspro":
        text = "/img/basspro-logo.png";
        break;
      case "cabelas":
        text = "/img/cabelas-logo.png";
        break;
      case "cabelas ca":
        text = "/img/cabelasca-logo.png";
        break;
      case "canadiantire ca":
        text = "/img/canadiantireca-logo.png";
        break;
      case "groupethomasmarine":
        text = "/img/groupethomasmarine-logo.png";
        break;
      case "pricegrabber":
        text = "/img/pricegrabber-logo.png";
        break;
      case "radioworld ca":
        text = "/img/radioworldca-logo.png";
        break;
      case "sail ca":
        text = "/img/sailca-logo.png";
        break;
      case "thefishinhole":
        text = "/img/thefishinhole-logo.png";
        break;
      case "masoneasypay":
        text = "/img/masoneasypay-logo.png";
        break;
      case "houzz":
        text = "/img/houzz-logo.png";
        break;
      case "kogan":
        text = "/img/kogan-logo.png";
        break;

      case "ryka":
        text = "/img/ryka-logo.png";
        break;

      case "veronicabeard":
        text = "/img/veronicabeard-logo.png";
        break;

      case "vince":
        text = "/img/vince-logo.png";
        break;

      case "bzees":
        text = "/img/bzees-logo.png";
        break;

      case "lifestride":
        text = "/img/lifestride-logo.png";
        break;

      case "ibeautistore":
        text = "/img/ibeautistore-logo.png";
        break;

      case "ubuyeg":
        text = "/img/ubuyeg-logo.png";
        break;

      case "globalsources":
        text = "/img/globalsources-logo.png";
        break;

      case "desert-cart":
        text = "/img/desert-cart.png";
        break;

      case "bigamart":
        text = "/img/bigamart-logo.png";
        break;

      case "shopee in":
        text = "/img/shopee-logo-in.png";
        break;

      case "allenedmonds.com":
        text = "/img/allenedmonds-logo.png";
        break;

      case "francosarto":
        text = "/img/francosarto-logo.png";
        break;

      case "ubuy za":
        text = "/img/ubuyza-logo.png";
        break;

      case "wantitall za":
        text = "/img/wantitallza-logo.png";
        break;

      case "shopee br":
        text = "/img/shopeebr-logo.png";
        break;

      case "shopee co":
        text = "/img/shopeeco-logo.png";
        break;
      case "shopee mx":
        text = "/img/shopeemx-logo.png";
        break;
      case "shopee tw":
        text = "/img/shopeetw-logo.png";
        break;
      case "opensea":
        text = "/img/opensea-logo.png";
        break;
      case "nisuta":
        text = "/img/nisuta-logo.png";
        break;
      case "ruten tw":
        text = "/img/rutentw-logo.png";
        break;
      case "wirepc":
        text = "/img/wirepc-logo.png";
        break;
      case "zodiacshoes":
        text = "/img/zodiacshoes-logo.png";
        break;
      case "samedelman":
        text = "/img/samedelman-logo.png";
        break;
      case "circusny":
        text = "/img/circusny_logo.png";
        break;
      case "vionicshoes":
        text = "/img/vionic-logo.png";
        break;
      case "temu":
        text = "/img/temu-logo.png";
        break;
      case "bhphotovideo":
        text = "/img/bhphotovideo-logo.png";
        break;
      case "ebayde":
        text = "/img/ebayDE.jpeg";
        break;
      case "ebay de":
        text = "/img/ebayDE.jpeg";
        break;
      case "kroger":
        text = "/img/kroger.png";
        break;
      case "mxlocker":
        text = "/img/mxlocker.png";
        break;
      case "auctionkr":
        text = "/img/auctionkr-logo.png";
        break;
      case "neiman marcus":
        text = "/img/neimanmarcus-logo.png";
        break;

      case "fruugo":
        text = "/img/fruugo-logo.png";
        break;

      case "fruugo ch":
        text = "/img/fruugo-logo.png";
        break;

      case "jcpenney":
        text = "/img/jcpenney-logo.png";
        break;

      case "catch au":
        text = "/img/catchau-logo.png";
        break;

      case "tiktok":
        text = "/img/tiktok-logo.png";
        break;

      case "teepublic":
        text = "/img/teepublic-logo.png";
        break;

      case "shein":
        text = "/img/shein-logo.png";
        break;

      case "homedepot":
        text = "/img/homedepot-logo.png";
        break;

      case "lowes":
        text = "/img/lowes-logo.png";
        break;

      case "googleshopping":
        text = "/img/googleshopping-logo.png";
        break;

      case "zoro":
        text = "/img/zoro-logo.png";
        break;

      case "google shopping":
        text = "/img/googleshopping-logo.png";
        break;

      case "amazon ae":
        text = "/img/Amazon_AE.png";
        break;

      case "amazon be":
        text = "/img/Amazon_BE.png";
        break;

      case "amazon eg":
        text = "/img/Amazon_EG.png";
        break;

      case "amazon nl":
        text = "/img/Amazon_NL.png";
        break;

      case "amazon sa":
        text = "/img/Amazon_SA.png";
        break;

      case "fruugo uk":
        text = "/img/fruugo-logo.png";
        break;

      case "wallmart mx":
        text = "/img/Wallmart-MX-logo.png";
        break;

      case "marktplaats":
        text = "/img/martplaats-logo.png";
        break;

      case "bergdorfgoodman":
        text = "/img/bergdorfgoodman-logo.png";
        break;

      case "saksfifthavenue":
        text = "/img/saksfifthavenue-logo.png";
        break;

      case "iherb":
        text = "/img/iherb-logo.png";
        break;

      case "palmers":
        text = "/img/palmers-logo.png";
        break;

      case "riteaid":
        text = "/img/riteaid-logo.png";
        break;

      case "vitacost":
        text = "/img/vitacost-logo.png";
        break;

      case "walgreens":
        text = "/img/walgreens-logo.png";
        break;

      default:
        text = "/img/default_platform_image.png";
    }
    return text;
  }

  exports.default = Ember.Helper.helper(platformImage);
});