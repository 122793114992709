define('uabide/components/modals/sellers-more-filters-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentDoNotContact: null,
    currentSellerHasEmail: null,
    currentSellerPriority: null,
    currentSellerReplied: null,
    currentRemoved: null,

    calculateDiscovered: null,
    calculatePending: null,
    calculateClosed: null,
    calculateVerifying: null,
    calculateFlagged: null,
    calculateVerified: null,
    calculateAuthorized: null,
    calculateReview: null,

    currentDiscovered: null,
    currentPending: null,
    currentClosed: null,
    currentVerifying: null,
    currentFlagged: null,
    currentVerified: null,
    currentAuthorized: null,
    currentReview: null,

    allValue: 'All',
    doNotContactValue: 'Do Not Contact',
    hasPriorityValue: 'Has Priority',
    hasRepliedValue: 'Has Replied',
    hasEmailValue: 'Has Email',
    hasNoEmailValue: 'Has No Email',
    inactiveValue: 'Is Inactive',
    activeValue: 'Is Active',

    calculateDiscoveredValue: 'Discovered',
    calculatePendingValue: 'Pending',
    calculateClosedValue: 'Closed',
    calculateVerifyingValue: 'Verifying',
    calculateFlaggedValue: 'Flagged',
    calculateVerifiedValue: 'Verified',
    calculateAuthorizedValue: 'Authorized',
    calculateReviewValue: 'Review',

    discoveredValue: 'Discovered',
    pendingValue: 'Pending',
    closedValue: 'Closed',
    verifyingValue: 'Verifying',
    flaggedValue: 'Flagged',
    verifieValue: 'Verified',
    authorizedValue: 'Authorized',
    reviewValue: 'Review',

    didUpdateAttrs: function didUpdateAttrs() {
      this.setProperties({
        currentDoNotContact: null,
        currentSellerPriority: null,
        currentSellerReplied: null,
        currentSellerHasEmail: null,
        currentRemoved: null,

        calculateDiscovered: null,
        calculatePending: null,
        calculateClosed: null,
        calculateVerifying: null,
        calculateFlagged: null,
        calculateVerified: null,
        calculateAuthorized: null,
        calculateReview: null,

        currentDiscovered: null,
        currentPending: null,
        currentClosed: null,
        currentVerifying: null,
        currentFlagged: null,
        currentVerified: null,
        currentAuthorized: null,
        currentReview: null

      });
    },


    actions: {
      setDoNotContact: function setDoNotContact(value) {
        this.set('currentDoNotContact', value);
      },
      setPriority: function setPriority(value) {
        this.set('currentSellerPriority', value);
      },
      setReplied: function setReplied(value) {
        this.set('currentSellerReplied', value);
      },
      setHasEmail: function setHasEmail(value) {
        this.set('currentSellerHasEmail', value);
      },
      setRemoved: function setRemoved(value) {
        this.set('currentRemoved', value);
      },
      setCalculateDiscovered: function setCalculateDiscovered(value) {
        this.set('calculateSelected', value);
      },
      setCalculatePending: function setCalculatePending(value) {
        this.set('calculateSelected', value);
      },
      setCalculateClosed: function setCalculateClosed(value) {
        this.set('calculateSelected', value);
      },
      setCalculateVerifying: function setCalculateVerifying(value) {
        this.set('calculateSelected', value);
      },
      setCalculateFlagged: function setCalculateFlagged(value) {
        this.set('calculateSelected', value);
      },
      setCalculateVerified: function setCalculateVerified(value) {
        this.set('calculateSelected', value);
      },
      setCalculateAuthorized: function setCalculateAuthorized(value) {
        this.set('calculateSelected', value);
      },
      setCalculateReview: function setCalculateReview(value) {
        this.set('calculateSelected', value);
      },
      setDiscovered: function setDiscovered(value) {
        this.set('currentColumn', value);
      },
      setPending: function setPending(value) {
        this.set('currentColumn', value);
      },
      setClosed: function setClosed(value) {
        this.set('currentColumn', value);
      },
      setVerifying: function setVerifying(value) {
        this.set('currentColumn', value);
      },
      setFlagged: function setFlagged(value) {
        this.set('currentColumn', value);
      },
      setVerified: function setVerified(value) {
        this.set('currentColumn', value);
      },
      setAuthorized: function setAuthorized(value) {
        this.set('currentColumn', value);
      },
      setReview: function setReview(value) {
        this.set('currentColumn', value);
      },
      filter: function filter() {
        var currentDoNotContact = this.get('currentDoNotContact');
        var currentSellerPriority = this.get('currentSellerPriority');
        var currentSellerReplied = this.get('currentSellerReplied');
        var currentSellerHasEmail = this.get('currentSellerHasEmail');
        var currentRemoved = this.get('currentRemoved');
        var currentColumn = this.get('currentColumn');
        var calculateSelected = this.get('calculateSelected');

        if (currentDoNotContact) {
          this.filterByDoNotContact(currentDoNotContact);
        }
        if (currentSellerPriority) {
          this.filterByHasPriority(currentSellerPriority);
        }
        if (currentSellerReplied) {
          this.filterByHasReplied(currentSellerReplied);
        }
        if (currentSellerHasEmail) {
          this.filterByHasEmail(currentSellerHasEmail);
        }
        if (currentRemoved) {
          var removed = void 0;

          if (currentRemoved == this.get('inactiveValue')) {
            removed = true;
          } else if (currentRemoved == this.get('activeValue')) {
            removed = false;
          }

          this.filterByRemoved(removed);
        }
        if (currentColumn) {
          this.showExtraColumn(currentColumn);
        }
        if (calculateSelected) {
          this.calculateListingsPerStatusPerSeller(calculateSelected);
        }
        this.toggleModal();
      }
    }
  });
});