define('uabide/components/start-loading', ['exports', 'ember-in-viewport'], function (exports, _emberInViewport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberInViewport.default, {
    didEnterViewport: function didEnterViewport() {
      this.sendAction('startLoading');
      console.log('entered first load component');
    },
    didExitViewport: function didExitViewport() {
      console.log('exited component');
    },
    didScroll: function didScroll(direction) {
      console.log(direction); // 'up' || 'down' || 'left' || 'right'
    },

    viewportOptionsOverride: Ember.on('didInsertElement', function () {
      Ember.setProperties(this, {
        viewportEnabled: true,
        viewportUseRAF: true,
        viewportSpy: true,
        viewportScrollSensitivity: 1,
        viewportRefreshRate: 150,
        viewportTolerance: {
          top: 50,
          bottom: 50,
          left: 20,
          right: 20
        }
      });
    })
  });
});