define('uabide/components/modals/map-product-display-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    keyFieldValue: Ember.computed('currentMapKeyField', function () {
      if (this.currentMapKeyField === this.mapValueModelNumber) {
        return 'Model Number';
      } else if (this.currentMapKeyField === this.mapValueUpcNumber) {
        return 'UPC Number';
      } else {
        return 'ASIN';
      }
    }),

    actions: {
      upload: function upload() {
        this.uploadMAPItemsToAccount();
        this.toggleModal();
      }
    }
  });
});