define('uabide/routes/map', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    sessionService: Ember.inject.service(),
    accountController: Ember.inject.service('account-controller'),
    beforeModel: function beforeModel() {
      var user = this.get('sessionService.currentUser');
      if (user && this.get('sessionService.isBrandProtection') && this.get('sessionService.isAdmin') === false) {
        //this.replaceWith('index');
      }
    },
    model: function model() {
      return this.get('accountController');
    }
  });
});