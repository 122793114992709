define("uabide/utils/split-array", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = splitArray;
  function splitArray(array, length) {
    var splittedArray = [];
    if (array.length > length) {
      while (array.length > 0) {
        splittedArray.push(array.splice(0, length));
      }
    } else {
      splittedArray.push(array);
    }

    return splittedArray;
  }
});