define('uabide/components/modals/seller-leave-note-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    notificationService: Ember.inject.service(),
    currentReason: null,
    currentNotes: null,

    didUpdateAttrs: function didUpdateAttrs() {
      if (this.isOpen && this.currentSellerNoteReasons && this.currentSellerNoteReasons.length) {
        this.set('currentReason', this.currentSellerNoteReasons.pop());
        this.set('currentNotes', null);
      }
      this._super.apply(this, arguments);
    },


    actions: {
      submit: function submit() {
        var currentNotes = this.get('currentNotes');
        var currentReason = this.get('currentReason');
        if (currentNotes && currentReason) {
          this.addNotesToVendor({
            text: this.get('currentNotes'),
            reason: this.get('currentReason')
          });
        } else {
          this.get('notificationService').error('Notes is required');
        }
      },
      selectReason: function selectReason(reason) {
        this.set('currentReason', reason);
      }
    }
  });
});