define('uabide/components/display-image', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'img',
    attributeBindings: ['src', 'errorSrc'],
    src: null,

    didInsertElement: function didInsertElement() {
      this.$().on('error', function () {
        if (this.get('errorSrc')) {
          this.set('src', this.get('errorSrc'));
        } else {
          this.element.remove();
        }
      }.bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      this.$().off();
    }
  });
});