define('uabide/components/modals/add-new-product-display-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentAsin: null,
    currentModelNumber: null,
    currentUpcNumber: null,
    currentTitle: null,
    currentMapPrice: null,
    currentMsrpPrice: null,
    currentSku: null,
    currentDiscontinued: '',
    currentUpcArray: '',
    currentAsinArray: '',
    currentImage: '',
    keyFieldValue: Ember.computed('currentMapKeyField', function () {
      if (this.currentMapKeyField === this.mapValueModelNumber) {
        return 'Model Number';
      } else if (this.currentMapKeyField === this.mapValueUpcNumber) {
        return 'UPC Number';
      } else {
        return 'ASIN';
      }
    }),

    actions: {
      upload: function upload(e) {
        e.preventDefault();
        var map_price = this.get('currentMapPrice');
        var msrp_price = this.get('currentMsrpPrice');
        var discontinued = this.get('currentDiscontinued');
        var itemNumber = void 0;
        if (this.currentMapKeyField === this.mapValueModelNumber) {
          itemNumber = this.get('currentModelNumber');
        } else if (this.currentMapKeyField === this.mapValueUpcNumber) {
          itemNumber = this.get('currentUpcNumber');
        } else {
          itemNumber = this.get('currentAsin');
        }

        var mapUploadArray = [{
          ASIN: this.get('currentAsin'),
          model_number: this.get('currentModelNumber'),
          upc_number: this.get('currentUpcNumber'),
          SKU: this.get('currentSku'),
          title: this.get('currentTitle'),
          map_price: Number(map_price.replace(/[^0-9\.]+/g, '')),
          msrp_price: Number(msrp_price.replace(/[^0-9\.]+/g, '')),
          itemNumber: itemNumber,
          discontinued: discontinued.replace(/[^0-9a-zA-Z]/g, ''),
          UPC_ARRAY: JSON.parse(this.get('currentUpcArray').replace(/'/g, '"')),
          ASIN_ARRAY: JSON.parse(this.get('currentAsinArray').replace(/'/g, '"')),
          image: this.get('currentImage')
        }];

        this.setManualAddProductParams(mapUploadArray);
      }
    }
  });
});