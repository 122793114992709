define('uabide/components/modals/manage-account-override-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    notificationService: Ember.inject.service(),
    currentSelectedAccountsArray: [],
    currentSearchValue: '',
    isSearchFocused: false,
    currentAccounts: Ember.computed('currentSearchValue', 'accounts', function () {
      var currentSearchValue = this.get('currentSearchValue');
      if (currentSearchValue && currentSearchValue.length) {
        return this.accounts.filter(function (el) {
          if (el.accountName) {
            return el.accountName.toUpperCase().includes(currentSearchValue.toUpperCase());
          }
        });
      } else {
        return this.accounts;
      }
    }),

    didUpdateAttrs: function didUpdateAttrs() {
      var _this = this;

      if (this.currentVendor && this.currentVendor.accountOverrideArray.length) {
        var currentSelectedAccounts = this.currentVendor.accountOverrideArray.map(function (accountId) {
          return _this.accounts.find(function (account) {
            return account.objectId === accountId;
          });
        });
        this.set('currentSelectedAccountsArray', currentSelectedAccounts);
      }

      if (!this.isOpen) {
        this.set('currentSelectedAccountsArray', []);
        this.set('currentSearchValue', '');
      }
    },


    actions: {
      addNewAccount: function addNewAccount(account) {
        var currentSelectedAccountsArray = this.get('currentSelectedAccountsArray');
        if (currentSelectedAccountsArray.some(function (el) {
          return el.objectId === account.objectId;
        })) {
          this.get('notificationService').error('This account is already present on the account override list');
        } else {
          currentSelectedAccountsArray.pushObject(account);
          this.set('currentSelectedAccountsArray', currentSelectedAccountsArray);
          this.set('currentSearchValue', '');
          this.set('isSearchFocused', false);
        }
      },
      removeAccount: function removeAccount(account) {
        var currentSelectedAccountsArray = this.get('currentSelectedAccountsArray');
        currentSelectedAccountsArray.removeObject(account);
        this.get('currentSelectedAccountsArray', currentSelectedAccountsArray);
      },
      setFocusIn: function setFocusIn() {
        this.set('isSearchFocused', true);
      },
      setFocusOut: function setFocusOut() {
        var _this2 = this;

        Ember.run.later(function () {
          _this2.set('isSearchFocused', false);
        }, 100);
      },
      save: function save() {
        var accountsArray = this.get('currentSelectedAccountsArray').map(function (el) {
          return el.objectId;
        });
        this.updateAccountOverride(accountsArray);
      }
    }
  });
});