define('uabide/services/map-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    parseconverter: Ember.inject.service('parse-converter'),
    parseService: Ember.inject.service(),

    mapResults: [],
    selectedMap: [],
    mapStatus: null,
    mapPlatform: 'showAll',
    clearAllFilters: false,
    mapResultsLastCallAmount: 0,
    fileArray: null,
    numberDownloadedHistoryFileResults: 0,

    addMapResults: function addMapResults(newResults) {
      this.set('mapResultsLastCallAmount', newResults.length);
      this.get('mapResults').pushObjects(newResults);
    },
    addOrRemoveItemFromSelectedItem: function addOrRemoveItemFromSelectedItem(item) {
      var indexSkuResults = this.get('selectedMap');
      var a = indexSkuResults.indexOf(item);

      if (a == -1) {
        this.get('selectedMap').pushObject(item);
      } else {
        this.get('selectedMap').removeObject(item);
      }
    },
    clearAllNow: function clearAllNow() {
      this.set('mapResults', []);
      this.set('selectedMap', []);
    },
    addAllListingsToSelectedNow: function addAllListingsToSelectedNow() {
      var listingResults = this.get('mapResults');

      this.set('selectedMap', []);

      for (var i = 0; i < listingResults.length; i++) {
        var item = listingResults[i];
        this.get('selectedMap').pushObject(item);
      }
    },
    removeAllSelectedListingsNow: function removeAllSelectedListingsNow() {
      this.set('selectedMap', []);
    },
    resetFileForDownload: function resetFileForDownload() {
      var emptyArray = [];
      this.set("fileArray", emptyArray);
      this.set('numberDownloadedHistoryFileResults', 0);
    },
    addHistoryFileResults: function addHistoryFileResults(myResults) {
      this.get('fileArray').pushObjects(myResults);
      this.set('numberDownloadedHistoryFileResults', myResults.length);
    },
    getCurrentMapRetailersCountRetailers: function getCurrentMapRetailersCountRetailers(params) {
      return this.get('parseService.cloud').run('getCurrentMapRetailersCountRetailers', params);
    },
    getCurrentMapCountRetailers3p: function getCurrentMapCountRetailers3p(params) {
      return this.get('parseService.cloud').run('getCurrentMapCountRetailers3p', params);
    },
    getCurrentMapCountNonCompliantRetailers: function getCurrentMapCountNonCompliantRetailers(params) {
      return this.get('parseService.cloud').run('getCurrentMapCountNonCompliantRetailers', params);
    },
    getCurrentMapCountNonCompliantRetailers3p: function getCurrentMapCountNonCompliantRetailers3p(params) {
      return this.get('parseService.cloud').run('getCurrentMapCountNonCompliantRetailers3p', params);
    },
    getCurrentMapCountActiveProducts: function getCurrentMapCountActiveProducts(params) {
      return this.get('parseService.cloud').run('getCurrentMapCountActiveProducts', params);
    },
    getCurrentMapCountInActiveProducts: function getCurrentMapCountInActiveProducts(params) {
      return this.get('parseService.cloud').run('getCurrentMapCountInActiveProducts', params);
    },
    getTotalListings: function getTotalListings(params) {
      return this.get('parseService.cloud').run('getTotalListings', params);
    },
    getTotalNonCompliantListings: function getTotalNonCompliantListings(params) {
      return this.get('parseService.cloud').run('getTotalNonCompliantListings', params);
    },
    getStage1Accounts: function getStage1Accounts(params) {
      return this.get('parseService.cloud').run('getStage1Accounts', params);
    },
    getStage2Accounts: function getStage2Accounts(params) {
      return this.get('parseService.cloud').run('getStage2Accounts', params);
    },
    getStage3Accounts: function getStage3Accounts(params) {
      return this.get('parseService.cloud').run('getStage3Accounts', params);
    },
    getStage5Accounts: function getStage5Accounts(params) {
      return this.get('parseService.cloud').run('getStage5Accounts', params);
    },
    getInspectAccounts: function getInspectAccounts(params) {
      return this.get('parseService.cloud').run('getInspectAccounts', params);
    },
    getMapViolationsForReports: function getMapViolationsForReports(params) {
      return this.get('parseService.cloud').run('getMapViolationsForReports', params);
    },
    getMAPPricingChangeForMAPListing: function getMAPPricingChangeForMAPListing(params) {
      return this.get('parseService.cloud').run('getMAPPricingChangeForMAPListing', params);
    }
  });
});