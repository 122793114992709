define('uabide/components/navbar-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    accountcontroller: Ember.inject.service('account-controller'),
    listingservice: Ember.inject.service('listing-service'),
    sessionService: Ember.inject.service(),
    isAdmin: Ember.computed.and('accountcontroller.isAdmin', 'accountcontroller.showAdminView'),
    refresh: true,
    actions: {
      logoutNow: function logoutNow() {
        this.sendAction('logoutNow');
      },
      turnOnTurbo: function turnOnTurbo() {
        //this.set("refresh", false);
        this.get("listingservice").updateTurboSettings(true);
      },
      turnOffTurbo: function turnOffTurbo() {
        //this.set("refresh", false);
        this.get("listingservice").updateTurboSettings(false);
      },
      toggleAdminDisplay: function toggleAdminDisplay() {
        console.log("toggleAdminDisplay");
        this.get("accountcontroller").toggleAdminView();
      }
    }
  });
});