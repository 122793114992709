define('uabide/services/browser-vendor', ['exports', 'bowser'], function (exports, _bowser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    currentOSName: null,

    getOSName: function getOSName() {
      if (!this.get('currentOSName')) {
        var userAgent = window.navigator.userAgent;
        if (userAgent) {
          var browser = _bowser.default.getParser(userAgent);
          var name = browser.parsedResult.os.name;
          if (name) {
            switch (name) {
              case 'Windows':
                {
                  this.set('currentOSName', 'windows');
                }
            }
          }
        }
      }
    }
  });
});