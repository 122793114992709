define('uabide/components/modals/last-seen-date-range-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    lastSeenStart: null,
    lastSeenEnd: null,

    didUpdateAttrs: function didUpdateAttrs() {
      this.set('lastSeenStart', this.start);
      this.set('lastSeenSEnd', this.end);
    },


    actions: {
      setLastSeenStart: function setLastSeenStart(date) {
        this.set('lastSeenStart', date);
      },
      setLastSeenEnd: function setLastSeenEnd(date) {
        this.set('lastSeenEnd', date);
      },
      setDates: function setDates() {
        this.setDateParams(this.get('lastSeenStart'), this.get('lastSeenEnd'));
      }
    }
  });
});