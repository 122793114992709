define('uabide/components/search-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      search: function search(e) {
        this.set('searchValue', e.target.value);
      },
      clearSearch: function clearSearch() {
        this.set('searchValue', null);
      }
    }
  });
});