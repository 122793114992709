define('uabide/components/modals/show-image-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    panzoomInstance: null,

    didUpdate: function didUpdate() {
      if (this.image) {
        var imageDOM = document.getElementById('panzoom');
        var panzoomInstance = panzoom(imageDOM, {
          transformOrigin: { x: 0.5, y: 0.5 },
          bounds: true,
          zoomDoubleClickSpeed: 1
        });
        this.set('panzoomInstance', panzoomInstance);
      }
    },
    willDestroyElement: function willDestroyElement() {
      var panzoomInstance = this.get('panzoomInstance');
      if (panzoomInstance) {
        this.get('panzoomInstance').dispose();
      }
      this._super.apply(this, arguments);
    },


    actions: {
      zoomIn: function zoomIn() {
        var container = document.querySelector('#panzoom');
        var cx = container.offsetWidth / 2;
        var cy = container.offsetHeight / 2;
        var zoomBy = 1.4;
        var pz = this.get('panzoomInstance');
        pz.smoothZoom(cx, cy, zoomBy);
      },
      zoomOut: function zoomOut() {
        var container = document.querySelector('#panzoom');
        var cx = container.offsetWidth / 2;
        var cy = container.offsetHeight / 2;
        var zoomBy = 0.7;
        var pz = this.get('panzoomInstance');
        pz.smoothZoom(cx, cy, zoomBy);
      }
    }
  });
});