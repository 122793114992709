define('uabide/services/assets-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var inject = Ember.inject;

  exports.default = Ember.Service.extend({
    parseconverter: inject.service('parse-converter'),
    assetResults: [],
    assetResultsLastCallAmount: 0,
    selectedAssets: [],
    assetStatus: "showAll",
    init: function init() {
      this._super();
    },

    addAssetResults: function addAssetResults(newResults) {
      this.set('assetResultsLastCallAmount', newResults.length);
      this.get('assetResults').pushObjects(newResults);
    },
    addOrRemoveItemFromSelectedItem: function addOrRemoveItemFromSelectedItem(item) {
      var indexSkuResults = this.get('selectedAssets');
      var a = indexSkuResults.indexOf(item);
      if (a == -1) {
        this.get('selectedAssets').pushObject(item);
      } else {
        this.get('selectedAssets').removeObject(item);
      }
      console.log(indexSkuResults.length);
    },
    addAllAccountsToSelectedNow: function addAllAccountsToSelectedNow() {
      var accountResults = this.get('assetResults');
      this.set('selectedAssets', []);
      for (var i = 0; i < accountResults.length; i++) {
        var item = accountResults[i];
        this.get('selectedAssets').pushObject(item);
      }
    },
    clearAllNow: function clearAllNow() {
      this.set('assetResultsLastCallAmount', 0);
      this.set('assetResults', []);
      this.set('selectedAssets', []);
    },
    clearAllSelected: function clearAllSelected() {
      this.set('selectedAssets', []);
    },

    addNewAsset: function addNewAsset(newAsset) {
      var assetArray = [newAsset];
      var assetResults = this.get('assetResults');
      var newAmount = assetResults.length + 1;
      this.set('assetResultsLastCallAmount', newAmount);
      this.get('assetResults').pushObjects(assetArray);
    },
    updateItemInAssetArray: function updateItemInAssetArray(newItem) {
      var assetResults = this.get("assetResults");
      for (var i = 0; i < assetResults.length; i++) {
        var item = assetResults[i];
        if (newItem.objectId == item.objectId) {
          assetResults[i] = newItem;
          this.set('assetResults', []);
          this.set('assetResults', assetResults);
        }
      }
    }
  });
});