define('uabide/utils/format-download-date', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = formatDownloadDate;
  function formatDownloadDate(date) {
    var currentDate = date ? date : new Date();
    return [padTo2Digits(currentDate.getMonth() + 1), padTo2Digits(currentDate.getDate()), currentDate.getFullYear(), padTo2Digits(currentDate.getHours() % 12), padTo2Digits(currentDate.getMinutes()), padTo2Digits(currentDate.getSeconds())].join('-') + (currentDate.getHours() >= 12 ? 'PM' : 'AM');
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
});