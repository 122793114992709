define('uabide/controllers/mapview', ['exports', 'uabide/utils/format-download-date'], function (exports, _formatDownloadDate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  Parse.initialize("bzn8msayuASvFwWDJEY3DdGYDXb7eV9rweY2rr7o", "fRmOrMDotozW5iYypZ9s73yADQP3MiKmbIhQxXGw");
  Parse.serverURL = 'https://uabide.back4app.io/';
  var inject = Ember.inject;

  exports.default = Ember.Controller.extend({
    spinner: inject.service('spinner'),
    listingservice: inject.service('listing-service'),
    vendorservice: inject.service('vendor-service'),
    parseconverter: inject.service('parse-converter'),
    mapservice: inject.service('map-service'),
    accountcontroller: inject.service('account-controller'),
    notificationService: Ember.inject.service(),
    numberOfListings: 0,
    numberOfMAPProducts: 0,
    showListings: false,
    checkAll: false,
    checkAllVendor: false,
    showAllContent: true,
    accountName: "",
    value_discovered: "Discovered",
    value_enforce: "Enforce",
    value_need_info: "Need Info",
    value_pending: "Pending",
    value_closed: "Closed",
    value_authorized: "Authorized",
    value_verified: "Verified",
    value_review: "Review",
    value_flagged: "Flagged",
    value_price_flagged: "Price Flag",
    value_resubmit: "Resubmit",
    value_rejected: "Rejected",
    value_consumer: "Consumer",
    value_takedown: "Takedown",
    value_new_seller: "New Seller",
    value_infringer: "Infringer",
    value_notified: "Notified",
    value_unauthorized: "Unauthorized",
    searchMode: "numberOfListings",
    direction: "down",
    isShowingFilterListingsModal: false,
    isShowingFilterVendorsModal: false,
    isShowingVendorDetailModal: false,
    listingStatus: null,
    mySearchText: null,
    myAccount: null,
    currentVendor: null,
    currentSellerUrl: null,
    currentSellerInvoice: null,
    currentSellerCompanyContact: null,
    currentSellerCompanyAddress: null,
    currentSellerSupplierAddress: null,
    currentSellerSupplierContact: null,
    currentVendorSubStatus: null,
    currentCompany: null,
    currentSupplier: null,
    currentNotes: null,
    currentSellerStatus: null,
    currentButtonText: null,
    currentInputType: null,
    currentInputTypeEditor: null,
    currentMasterVendor: null,
    currentMasterVendorSellerURLArray: null,
    currentMasterVendorInvoice: null,
    currentMasterVendorSubStatus: null,
    currentMasterVendorCompany: null,
    currentMasterVendorSupplier: null,
    currentMasterVendorNotes: null,
    currentMasterVendorCompanyContact: null,
    currentMasterVendorCompanyAddress: null,
    currentMasterVendorSupplierAddress: null,
    currentMasterVendorSupplierContact: null,
    currentMasterVendorEmail: null,
    currentMasterVendorPhoneNumber: null,
    sellerDetailPopUpFrame: "sellerDetailPopUp",
    currentVendorEmail: null,
    currentVendorPhoneNumber: null,
    display_text: "",

    isShowingMailModal: false,
    currentVendorMail: null,
    letterHistoryList: [],
    currentLetterOrder: null,
    currentLetterImageUrl: null,
    isShowingMailFormModal: false,
    addressName: null,
    addressLine1: null,
    addressLine2: null,
    addressCity: null,
    addressState: null,
    addressPostalCode: null,
    addressCountry: null,
    returnAddress: null,
    displayTextObserver: Ember.observer('display_text', function () {
      this.get('notificationService').showDisplayTextNotification(this.get('display_text'));
    }),

    actions: {
      logoutNow: function logoutNow() {
        var _this = this;

        console.log("trying to logout ");
        var self = this;
        this.get('spinner').show('discoverySpinner');
        Parse.User.logOut().then(function () {
          self.get('spinner').hide('discoverySpinner');
          self.get('listingservice').clearAllNow();
          self.get('vendorservice').clearAllNow();
          self.get('mapservice').clearAllNow();

          _this.transitionToRoute('login');
          setTimeout(function () {
            location.reload();
          }, 500);
        });
      },
      startLoading: function startLoading() {

        var self = this;
        this.get('spinner').show('discoverySpinner');
        var today = new Date();

        var listingservice = this.get("listingservice");
        var turboOn = listingservice.get("turboOn");
        var searchMode = this.get("searchMode");
        var params = {};
        var mySearchText = this.get("mySearchText");
        var direction = this.get('direction');

        if (mySearchText == null || mySearchText == "") {
          params = {
            lastPostDate: today,
            mode: searchMode,
            skip: 0,
            direction: direction,
            download: turboOn,
            local: true
          };
        } else {
          params = {
            lastPostDate: today,
            mode: searchMode,
            searchText: mySearchText,
            skip: 0,
            direction: direction,
            download: turboOn,
            local: true
          };
        }
        console.log("params are ", params);

        Parse.Cloud.run("getCurrentMAPProducts", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          console.log("startLoading1", result.length);
          var resultsArray = [];
          for (var i = 0; i < result.length; i++) {
            var item = result[i];
            var myMapProduct = self.get('parseconverter').convertToMAPProduct(item);
            self.get('accountcontroller').setCurrentAccount(myMapProduct.account);
            self.set("myAccount", myMapProduct.account);
            resultsArray.pushObject(myMapProduct);
          }
          self.get('accountcontroller').setIsAdmin();
          self.get('accountcontroller').addPlatformArray();
          self.get('mapservice').addMapResults(resultsArray);
          console.log("startLoading2");
          self.send("refreshThisRoute");
          if (turboOn) {
            self.send("loadMoreItems");
          }
          self.send("recountMAPProducts");
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("Loading Listing error -  " + error.message);
        });
      },
      recountMAPProducts: function recountMAPProducts() {
        var self = this;
        var params = {};
        Parse.Cloud.run("getCurrentMAPProductCount", params).then(function (statusCount) {
          self.set('numberOfMAPProducts', statusCount);
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          console.log("getCurrentSellerCount -  " + error.message);
        });
      },
      loadMoreItems: function loadMoreItems() {
        var searchMode = this.get("searchMode");
        var mapservice = this.get("mapservice");
        var mapResults = mapservice.get("mapResults");
        var numberDownloadedActivityResults = this.get('mapservice').get("mapResultsLastCallAmount");
        if (numberDownloadedActivityResults > 0) {
          var self = this;
          var historyResults = this.get('mapservice').get("mapResults");
          var lastResult = historyResults.slice(-1)[0];
          if (lastResult != undefined) {
            this.get('spinner').show('discoverySpinner');
            var currentDate = lastResult.createdAt;

            var listingservice = this.get("listingservice");
            var turboOn = listingservice.get("turboOn");
            var params = {};
            var direction = this.get('direction');
            var mySearchText = this.get("mySearchText");
            if (mySearchText == null || mySearchText == "") {
              params = {
                lastPostDate: currentDate,
                mode: searchMode,
                skip: mapResults.length,
                direction: direction,
                download: turboOn,
                local: true
              };
            } else {
              params = {
                lastPostDate: currentDate,
                mode: searchMode,
                searchText: mySearchText,
                skip: mapResults.length,
                direction: direction,
                download: turboOn,
                local: true
              };
            }

            Parse.Cloud.run("getCurrentMAPProducts", params).then(function (result) {
              self.get('spinner').hide('discoverySpinner');
              var resultsArray = [];
              for (var i = 0; i < result.length; i++) {
                var item = result[i];
                var myMapProduct = self.get('parseconverter').convertToMAPProduct(item);
                resultsArray.pushObject(myMapProduct);
              }
              self.get('mapservice').addMapResults(resultsArray);

              self.send("refreshThisRoute");
              if (turboOn) {
                self.send("loadMoreItems");
              }
            }, function (error) {
              self.get('spinner').hide('discoverySpinner');
              self.get('notificationService').error("Loading Sku error1 -  " + error.message);
            });
          }
        }
      },
      toggleCheckBoxVendor: function toggleCheckBoxVendor(item) {
        this.get('mapservice').addOrRemoveItemFromSelectedItem(item);
      },
      showFiltersNow: function showFiltersNow() {
        this.send("toggleVendorFilterModal");
      },
      updateCheckAllVendor: function updateCheckAllVendor() {
        var checkAllVendor = this.get('checkAllVendor');
        if (checkAllVendor) {
          this.set('checkAllVendor', false);
          this.get('mapservice').removeAllSelectedListingsNow();
          this.send("refreshThisRoute");
        } else {
          this.set('checkAllVendor', true);
          this.get('mapservice').addAllListingsToSelectedNow();
          this.send("refreshThisRoute");
        }
      },


      toggleVendorFilterModal: function toggleVendorFilterModal() {
        this.toggleProperty('isShowingFilterVendorsModal');
      },

      filterStatusByVendor: function filterStatusByVendor(status) {

        if (status == "ClearAll") {
          //this.send("toggleVendorFilterModal");
          this.get("vendorservice").set("vendorStatus", null);
        } else if (status == "ClearAllMain") {
          this.get("vendorservice").set("vendorStatus", null);
          this.set('mySearchText', null);
          this.get("vendorservice").set("vendorPlatform", "showAll");
        } else {
          //this.send("toggleVendorFilterModal");
          this.get("vendorservice").set("vendorStatus", status);
        }
        var mapservice = this.get('mapservice');
        if (mapservice.mapResults.length == 0) {
          this.send('startLoading');
        } else {
          this.get('mapservice').clearAllNow();
        }
      },
      searchText: function searchText(text) {
        if (text !== '') {
          this.set('mySearchText', text);
          var mapservice = this.get('mapservice');
          if (mapservice.mapResults.length == 0) {
            this.send('startLoading');
          } else {
            this.get('mapservice').clearAllNow();
          }
        }
      },
      sortBy: function sortBy(type) {
        var searchMode = this.get('searchMode');
        var direction = this.get('direction');
        if (searchMode == type) {
          if (direction == "up") {
            this.set('direction', "down");
          } else {
            this.set('direction', "up");
          }
        } else {
          this.set('direction', "up");
        }

        this.set('searchMode', type);
        var mapservice = this.get("mapservice");
        if (mapservice.vendorResults.length == 0) {
          this.send("startLoading");
        }
        this.get('mapservice').clearAllNow();
      },
      setMapAmount: function setMapAmount() {
        var myAccount = this.get('myAccount');
        this.set('numberOfMAPProducts', myAccount.numberOfMAPProducts);
      },
      exportList: function exportList() {
        this.get('mapservice').resetFileForDownload();
        this.send('startDownload');
      },
      startDownload: function startDownload() {
        var self = this;
        this.get('spinner').show('discoverySpinner');
        var today = new Date();

        var searchMode = this.get("searchMode");
        var params = {};
        var mySearchText = this.get("mySearchText");
        var direction = this.get('direction');

        if (mySearchText == null || mySearchText == "") {
          params = {
            lastPostDate: today,
            mode: searchMode,
            skip: 0,
            direction: direction,
            download: true,
            local: false
          };
        } else {
          params = {
            lastPostDate: today,
            mode: searchMode,
            searchText: mySearchText,
            skip: 0,
            direction: direction,
            download: true,
            local: false
          };
        }

        Parse.Cloud.run("getCurrentMAPProducts", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          var resultsArray = [];
          for (var i = 0; i < result.length; i++) {
            var item = result[i];
            var myVendor = self.get('parseconverter').convertToMAPProduct(item);
            resultsArray.pushObject(myVendor);
          }
          self.get('mapservice').addHistoryFileResults(resultsArray);
          if (resultsArray.length > 0) {
            var number = resultsArray.length;
            var numberString = number.toString();
            var numberText = numberString + " Map Downloaded";
            self.set('display_text', numberText);
            self.send('getMoreFileResults');
          } else {
            self.set('display_text', "");
            self.send('finishDownload');
          }
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("Loading Listing error -  " + error.message);
        });
      },
      getMoreFileResults: function getMoreFileResults() {
        var self = this;
        this.get('spinner').show('discoverySpinner');

        var mapservice = this.get("mapservice");
        var mapResults = mapservice.get("fileArray");
        var lastResult = mapResults.slice(-1)[0];
        var currentDate = lastResult.createdAt;
        var searchMode = this.get("searchMode");
        var params = {};
        var mySearchText = this.get("mySearchText");
        var direction = this.get('direction');

        if (mySearchText == null || mySearchText == "") {
          params = {
            lastPostDate: currentDate,
            mode: searchMode,
            skip: mapResults.length,
            direction: direction,
            download: true,
            local: false
          };
        } else {
          params = {
            lastPostDate: currentDate,
            mode: searchMode,
            searchText: mySearchText,
            skip: mapResults.length,
            direction: direction,
            download: true,
            local: false
          };
        }

        Parse.Cloud.run("getCurrentMAPProducts", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          var resultsArray = [];
          for (var i = 0; i < result.length; i++) {
            var item = result[i];
            var myMap = self.get('parseconverter').convertToMAPProduct(item);
            resultsArray.pushObject(myMap);
          }
          self.get('mapservice').addHistoryFileResults(resultsArray);
          if (resultsArray.length > 0) {
            var newHistoryResults = self.get('mapservice').get("fileArray");
            var number = newHistoryResults.length;
            var numberString = number.toString();
            var numberText = numberString + " Sellers Downloaded";
            self.set('display_text', numberText);
            self.send('getMoreFileResults');
          } else {
            self.set('display_text', "");
            self.send('finishDownload');
          }
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("Loading Listing error -  " + error.message);
        });
      },
      finishDownload: function finishDownload() {
        var fileArray = this.get('mapservice').get("fileArray");
        var csvContent = "";
        var row = '"' + "Created At" + '"' + ',' + '"' + "Title" + '"' + ',' + '"' + "ASIN" + '"' + ',' + '"' + "Model Number" + '"' + ',' + '"' + "UPC Number" + '"' + ',' + '"' + "MAP Price" + '"' + ',' + '"' + "MSRP Price" + '"' + ',' + '"' + "Item Number" + '"';
        csvContent += row + "\n";

        fileArray.forEach(function (rowArray) {
          var row = '"' + rowArray.shortDate.replace(/\"/g, "") + '"' + ',' + '"' + rowArray.title.replace(/\"/g, "") + '"' + ',' + '"' + rowArray.ASIN + '"' + ',' + '"' + rowArray.model_number + '"' + ',' + '"' + rowArray.upc_number + '"' + ',' + '"' + rowArray.map_price + '"' + ',' + '"' + rowArray.msrp_price + '"' + ',' + '"' + rowArray.itemNumber + '"';
          csvContent += row + "\n";
        });
        var downloadDate = (0, _formatDownloadDate.default)(new Date());
        var myAccount = this.get("myAccount");
        var accountName = myAccount.accountName.replace(/[^0-9a-zA-Z]/g, '');
        download(csvContent, downloadDate + '-' + accountName + '-map_products.csv', "text/plain;charset=utf-8");
        // var encodedUri = encodeURI(csvContent);
        // var link = document.createElement("a");
        // link.setAttribute("href", encodedUri);
        // link.setAttribute("download", "sellers.csv");
        // document.body.appendChild(link); // Required for FF
        //
        // link.click();
        //window.open(encodedUri);
      },
      showDetailScreen: function showDetailScreen(vendor) {
        var self = this;
        var params = {
          objectId: vendor.objectId
        };
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("getSingleVendor", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          if (result.length > 0) {
            var item = result[0];
            var myVendor = self.get('parseconverter').convertToVendor(item);
            self.set('currentVendor', myVendor);
            self.set('currentSellerUrl', myVendor.sellerURL);
            self.set('currentSellerInvoice', myVendor.invoice);
            self.set('currentVendorSubStatus', myVendor.subStatus);
            self.set('currentSellerCompanyContact', myVendor.companyContact);
            self.set('currentSellerCompanyAddress', myVendor.companyAddress);
            self.set('currentSellerSupplierAddress', myVendor.supplierAddress);
            self.set('currentSellerSupplierContact', myVendor.supplierContact);
            self.set('currentSellerStatus', myVendor.status);
            self.set('currentCompany', myVendor.company);
            self.set('currentSupplier', myVendor.supplier);
            self.set('currentNotes', myVendor.notes);
            self.set('currentVendorPhoneNumber', myVendor.phone);
            self.set('currentVendorEmail', myVendor.email);

            self.set('currentMasterVendor', myVendor.masterVendor);
            self.set('currentMasterVendorSellerURLArray', myVendor.masterVendor.sellerURLArray);
            self.set('currentMasterVendorInvoice', myVendor.masterVendor.invoice);
            self.set('currentMasterVendorSubStatus', myVendor.masterVendor.subStatus);
            self.set('currentMasterVendorCompany', myVendor.masterVendor.company);
            self.set('currentMasterVendorSupplier', myVendor.masterVendor.supplier);
            self.set('currentMasterVendorNotes', myVendor.masterVendor.notes);
            self.set('currentMasterVendorCompanyContact', myVendor.masterVendor.companyContact);
            self.set('currentMasterVendorCompanyAddress', myVendor.masterVendor.companyAddress);
            self.set('currentMasterVendorSupplierAddress', myVendor.masterVendor.supplierAddress);
            self.set('currentMasterVendorSupplierContact', myVendor.masterVendor.supplierContact);
            self.set('currentMasterVendorPhoneNumber', myVendor.masterVendor.phoneNumber);
            self.set('currentMasterVendorEmail', myVendor.masterVendor.email);

            var accountcontroller = self.get('accountcontroller');
            if (accountcontroller.isAdmin) {
              self.set('currentButtonText', 'Save and Close');
              self.set('currentInputType', false);
              self.set('currentInputTypeEditor', false);
              if (myVendor.hasMasterVendor && accountcontroller.showAdminView) {
                self.set('sellerDetailPopUpFrame', "sellerDetailPopUpFrame");
              } else {
                self.set('sellerDetailPopUpFrame', "sellerDetailPopUp");
              }
            } else {
              if (accountcontroller.isEditor) {
                self.set('currentButtonText', 'Save and Close');
                self.set('currentInputType', true);
                self.set('sellerDetailPopUpFrame', "sellerDetailPopUp");
                self.set('currentInputTypeEditor', false);
              } else {
                self.set('currentButtonText', 'Save Notes and Close');
                self.set('currentInputType', true);
                self.set('sellerDetailPopUpFrame', "sellerDetailPopUp");
                self.set('currentInputTypeEditor', true);
              }
            }
            self.toggleProperty('isShowingVendorDetailModal');
          } else {
            self.get('notificationService').warning("Vendor not found");
          }
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("Vendor notes error -  " + error.message);
        });
      },

      toggleVendorDetailModal: function toggleVendorDetailModal() {
        this.toggleProperty('isShowingVendorDetailModal');
      },
      setVendorToValue: function setVendorToValue(value) {
        this.set('currentSellerStatus', value);
      },
      setVendorToValueIfReview: function setVendorToValueIfReview(value) {
        var currentVendor = this.get('currentVendor');
        this.set('showAllContent', false);
        this.send("refreshThisRoute");
        var self = this;
        var params = { vendorId: currentVendor.objectId, status: value };

        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("updateVendorStatusNow", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          var mySku = self.get('parseconverter').convertToVendor(result);
          self.get('vendorservice').updateItemInSelectedArray(mySku);
          self.toggleProperty('isShowingVendorDetailModal');
          self.set('showAllContent', true);
          self.send("refreshThisRoute");
          self.send("showDetailScreen", mySku);
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("1S - Error updating vendor -  " + error.message);
          self.set('showAllContent', true);
          self.send("refreshThisRoute");
        });
      },
      updateSellerInfo: function updateSellerInfo() {
        var self = this;
        this.toggleProperty('isShowingVendorDetailModal');
        var accountcontroller = this.get('accountcontroller');
        if (accountcontroller.isEditor) {
          var currentVendor = this.get('currentVendor');
          var currentSellerUrl = this.get('currentSellerUrl');
          var currentSellerInvoice = this.get('currentSellerInvoice');
          var currentVendorSubStatus = this.get('currentVendorSubStatus');

          var currentSellerCompanyContact = this.get('currentSellerCompanyContact');
          var currentSellerCompanyAddress = this.get('currentSellerCompanyAddress');
          var currentSellerSupplierAddress = this.get('currentSellerSupplierAddress');
          var currentSellerSupplierContact = this.get('currentSellerSupplierContact');

          var currentCompany = this.get('currentCompany');
          var currentSupplier = this.get('currentSupplier');
          var currentNotes = this.get('currentNotes');
          var currentSellerStatus = this.get('currentSellerStatus');

          var currentVendorEmail = this.get('currentVendorEmail');
          var currentVendorPhoneNumber = this.get('currentVendorPhoneNumber');

          var currentMasterVendorSellerURLArray = this.get('currentMasterVendorSellerURLArray');
          var currentMasterVendorInvoice = this.get('currentMasterVendorInvoice');
          var currentMasterVendorSubStatus = this.get('currentMasterVendorSubStatus');
          var currentMasterVendorCompany = this.get('currentMasterVendorCompany');
          var currentMasterVendorSupplier = this.get('currentMasterVendorSupplier');
          var currentMasterVendorNotes = this.get('currentMasterVendorNotes');
          var currentMasterVendorCompanyContact = this.get('currentMasterVendorCompanyContact');
          var currentMasterVendorCompanyAddress = this.get('currentMasterVendorCompanyAddress');
          var currentMasterVendorSupplierAddress = this.get('currentMasterVendorSupplierAddress');
          var currentMasterVendorSupplierContact = this.get('currentMasterVendorSupplierContact');
          var currentMasterVendorPhoneNumber = this.get('currentMasterVendorPhoneNumber');
          var currentMasterVendorEmail = this.get('currentMasterVendorEmail');

          var params = {
            vendorObjectId: currentVendor.objectId,
            sellerUrl: currentSellerUrl,
            invoice: currentSellerInvoice,
            vendorSubStatus: currentVendorSubStatus,
            company: currentCompany,
            supplier: currentSupplier,
            notes: currentNotes,
            sellerStatus: currentSellerStatus,
            companyContact: currentSellerCompanyContact,
            companyAddress: currentSellerCompanyAddress,
            supplierAddress: currentSellerSupplierAddress,
            supplierContact: currentSellerSupplierContact,
            email: currentVendorEmail,
            phone: currentVendorPhoneNumber,

            currentMasterVendorSellerURLArray: currentMasterVendorSellerURLArray,
            currentMasterVendorInvoice: currentMasterVendorInvoice,
            currentMasterVendorSubStatus: currentMasterVendorSubStatus,
            currentMasterVendorCompany: currentMasterVendorCompany,
            currentMasterVendorSupplier: currentMasterVendorSupplier,
            currentMasterVendorNotes: currentMasterVendorNotes,
            currentMasterVendorCompanyContact: currentMasterVendorCompanyContact,
            currentMasterVendorCompanyAddress: currentMasterVendorCompanyAddress,
            currentMasterVendorSupplierAddress: currentMasterVendorSupplierAddress,
            currentMasterVendorSupplierContact: currentMasterVendorSupplierContact,
            currentMasterVendorPhoneNumber: currentMasterVendorPhoneNumber,
            currentMasterVendorEmail: currentMasterVendorEmail
          };
          this.get('spinner').show('discoverySpinner');
          Parse.Cloud.run("updateVendorInfo", params).then(function (result) {
            self.get('spinner').hide('discoverySpinner');
            var mySku = self.get('parseconverter').convertToVendor(result);
            self.get('vendorservice').updateItemInVendorArray(mySku);
            if (currentSellerStatus == "Authorized" || currentSellerStatus == "Notified" || currentSellerStatus == "Unauthorized" || currentSellerStatus == "Verified" || currentSellerStatus == "Review" || currentSellerStatus == "Rejected") {
              var newDate = new Date();
              self.send("setSingleVendorsListingsToValue", mySku, currentSellerStatus, newDate);
            }
            self.send("refreshThisRoute");
          }, function (error) {
            self.get('spinner').hide('discoverySpinner');
            self.get('notificationService').error("Loading Sku error2 -  " + error.message);
          });
        } else {
          var currentVendor1 = this.get('currentVendor');
          var currentNotes1 = this.get('currentNotes');
          var params1 = {
            objectId: currentVendor1.objectId,
            notes: currentNotes1
          };

          this.get('spinner').show('discoverySpinner');
          Parse.Cloud.run("updateSellersNotes", params1).then(function (result) {
            self.get('spinner').hide('discoverySpinner');
            var mySku = self.get('parseconverter').convertToVendor(result);
            self.get('vendorservice').updateItemInVendorArray(mySku);
            self.send("refreshThisRoute");
          }, function (error) {
            self.get('spinner').hide('discoverySpinner');
            self.get('notificationService').error("3 - Error updating notes -  " + error.message);
          });
        }
      },
      setSingleVendorsListingsToValue: function setSingleVendorsListingsToValue(selectedVendor, value, date) {
        var self = this;
        var params = { vendorId: selectedVendor.objectId, status: value, lastDate: date };
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("setVendorsMultipleListingsToValue", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          if (result == 0) {
            self.get('listingservice').clearAllNow();
            self.send("refreshThisRoute");
          } else {
            self.send("setSingleVendorsListingsToValue", selectedVendor, value, result);
          }
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("3 - Error setting listing values Sku -  " + error.message);
        });
      },
      checkState: function checkState(text) {
        if (text == "") {
          this.set('searchData', '');
          this.set('mySearchText', null);
          this.get("vendorservice").set("vendorStatus", "showAll");
          this.get("vendorservice").set("vendorPlatform", "showAll");
          this.send("filterStatusByVendor", "ClearAllMain");
        }
      },
      showMailPopUp: function showMailPopUp(vendor) {
        this.set('currentVendorMail', vendor);
        this.toggleProperty('isShowingMailModal');
        var self = this;
        var params = {
          vendorId: listing.vendor.objectId
        };
        //
        self.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("getSentLettersForVendor", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          console.log("getSentLettersForVendor", result);
          var resultsArray = [];
          for (var i = 0; i < result.length; i++) {
            var item = result[i];
            var mySentLetter = self.get('parseconverter').convertToSentLetters(item);
            resultsArray.pushObject(mySentLetter);
          }
          self.set("letterHistoryList", resultsArray);
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("error getting letter info -  " + error.message);
        });
      },
      toggleIsShowingMailModal: function toggleIsShowingMailModal() {
        this.toggleProperty('isShowingMailModal');
      },
      openImageLink: function openImageLink(link) {
        window.open(link);
      },
      openMailLetter: function openMailLetter(order) {
        this.toggleProperty('isShowingMailModal');
        var currentVendorMail = this.get('currentVendorMail');
        var myAccount = this.get('myAccount');
        var self = this;
        var params = {
          objectId: currentVendorMail.objectId
        };
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("getSingleVendor", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          if (result.length > 0) {
            var item = result[0];
            currentVendorMail = self.get('parseconverter').convertToVendor(item);

            self.set('currentLetterOrder', order);
            switch (order) {
              case 1:
                self.set("currentLetterImageUrl", currentVendorMail.account.letter1);
                break;
              case 2:
                self.set("currentLetterImageUrl", currentVendorMail.account.letter2);
                break;
              case 3:
                self.set("currentLetterImageUrl", currentVendorMail.account.letter3);
                break;
              default:
                self.set("currentLetterImageUrl", "/img/red_x.png");
            }
            //
            self.set('currentSellerCompanyAddress', currentVendorMail.companyAddress);

            if (currentVendorMail.addressName == "") {
              self.set('addressName', currentVendorMail.sellerName);
            } else {
              self.set('addressName', currentVendorMail.addressName);
            }
            self.set('addressLine1', currentVendorMail.addressLine1);
            self.set('addressLine2', currentVendorMail.addressLine2);
            self.set('addressCity', currentVendorMail.addressCity);
            self.set('addressState', currentVendorMail.addressState);
            self.set('addressPostalCode', currentVendorMail.addressPostalCode);
            self.set('addressCountry', currentVendorMail.addressCountry);
            self.set('returnAddress', myAccount.returnAddress);

            self.toggleProperty('isShowingMailFormModal');
            //
          } else {
            self.get('notificationService').warning("Vendor not found");
          }
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("Vendor notes error -  " + error.message);
        });
      },
      toggleIsShowingMailFormModal: function toggleIsShowingMailFormModal() {
        this.toggleProperty('isShowingMailFormModal');
      },
      sendLettertoVendor: function sendLettertoVendor() {
        var addressName = this.get('addressName');
        var addressLine1 = this.get('addressLine1');
        var addressLine2 = this.get('addressLine2');
        var addressCity = this.get('addressCity');
        var addressState = this.get('addressState');
        var addressPostalCode = this.get('addressPostalCode');
        var addressCountry = this.get('addressCountry');
        var currentVendorMail = this.get('currentVendorMail');
        var currentLetterOrder = this.get('currentLetterOrder');
        this.toggleProperty('isShowingMailFormModal');

        var params = {
          vendorId: currentVendorMail.objectId,
          letterOrder: currentLetterOrder,
          addressName: addressName,
          addressLine1: addressLine1,
          addressLine2: addressLine2,
          addressCity: addressCity,
          addressState: addressState,
          addressPostalCode: addressPostalCode,
          addressCountry: addressCountry
        };
        var self = this;
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("sendLetterToVendor", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').success(result);
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("error sending letter -  " + error.message);
        });
      },
      transitionToMAPProtection: function transitionToMAPProtection() {
        this.transitionToRoute('map');
        Ember.run.later(function () {
          location.reload();
        }, 500);
      }
    }
  });


  function download(data, filename, type) {
    var a = document.createElement("a"),
        file = new Blob([data], { type: type });
    if (window.navigator.msSaveOrOpenBlob) // IE10+
      window.navigator.msSaveOrOpenBlob(file, filename);else {
      // Others
      var url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }
});