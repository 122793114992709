define('uabide/routes/numbers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  Parse.initialize("bzn8msayuASvFwWDJEY3DdGYDXb7eV9rweY2rr7o", "fRmOrMDotozW5iYypZ9s73yADQP3MiKmbIhQxXGw");
  Parse.serverURL = 'https://uabide.back4app.io/';

  exports.default = Ember.Route.extend({
    sessionService: Ember.inject.service(),
    accountcontroller: Ember.inject.service('account-controller'),
    beforeModel: function beforeModel() {
      var user = this.get('sessionService.currentUser');
      if (user) {
        if (this.get('sessionService.isMapProtection') && !this.get('sessionService.isFullAccount') && !this.get('sessionService.isAdmin')) {
          this.replaceWith('/map/overview');
        }
      }
    },

    setupController: function setupController(controller) {
      var currentUser = Parse.User.current();
      if (currentUser) {
        console.log('you are logged in');
        var myself = Parse.User.current().fetch();
        this.get('accountcontroller').setIsAdmin();
      } else {
        console.log('not logged in');
        this.transitionTo('login');
      }
    },
    actions: {
      refreshThisRoute: function refreshThisRoute() {
        console.log("refreshing this route now");
        this.refresh();
      }
    }
  });
});