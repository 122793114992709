define('uabide/controllers/leads', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  Parse.initialize("bzn8msayuASvFwWDJEY3DdGYDXb7eV9rweY2rr7o", "fRmOrMDotozW5iYypZ9s73yADQP3MiKmbIhQxXGw");
  Parse.serverURL = 'https://uabide.back4app.io/';
  var inject = Ember.inject;

  exports.default = Ember.Controller.extend({
    spinner: inject.service('spinner'),
    leadservice: inject.service('lead-service'),
    parseconverter: inject.service('parse-converter'),
    accountcontroller: inject.service('account-controller'),
    listingservice: inject.service('listing-service'),
    vendorservice: inject.service('vendor-service'),
    notificationService: Ember.inject.service(),
    showListings: true,
    checkAll: false,
    showAllContent: true,
    accountName: "",
    isShowingFilterListingsModal: false,
    isShowingAddAccountModal: false,
    mySearchText: null,
    searchMode: "numberOfSellers",
    direction: "down",
    numberOfAccounts: 0,
    value_active: "active",
    value_inactive: "inactive",
    currentButtonText: null,
    currentInputType: null,
    newAccountName: "",
    newAccountPartner: "",
    userEmail: "",
    userPassword: "",
    keywords: "",
    shoppingPlatform: "",
    url: "",
    isLoading: false,
    isShowingNotesModal: false,
    currentLead: null,
    currentLeadName: null,
    isShowingEditUsersModal: false,
    editUserArray: [],
    editUserAccount: null,
    editUser: null,
    isShowingEditUserModal: false,
    currentUsername: "",
    newPassword: "",
    editableAccount: null,
    currentAccountName: "",
    currentAccountPartner: "",
    createUsername: "",
    createPassword: "",
    shouldBeContributor: false,
    partnerList: [],
    currentNotes: "",
    isShowingUserLeadModal: false,
    newParentCompany: "",
    isShowingAddParentCompanyModal: false,
    isShowingSetParentCompanyModal: false,
    currentParentCompany: "",
    parentCompanyList: [],
    isShowingLeadContactsModal: false,
    leadContactArray: [],
    currentLeadLinkedInPage: "",
    currentLeadWebsite: "",
    currentLeadPartner: "",
    currentLeadPlanType: "",
    currentLeadQuote: "",
    isShowingCreateContact: false,
    newLeadContactFullName: "",
    newLeadContactTitle: "",
    newLeadContactEmail: "",
    newLeadContactLinkedInProfile: "",
    newLeadContactPhone: "",
    newLeadContactMobilePhone: "",
    currentLeadContact: null,
    isShowingEditLeadContact: false,
    editLeadContactFullName: "",
    editLeadContactTitle: "",
    editLeadContactEmail: "",
    editLeadContactLinkedInProfile: "",
    editLeadContactPhone: "",
    editLeadContactPhoneMobile: "",
    isShowingUploadLeadDataFile: false,
    leadFileArrayArray: [],
    daysLater: 1,
    isShowingFollowUpLater: false,
    isShowingSetAccountType: false,
    isShowingSetLeadName: false,
    accountTypeList: [],
    currentAccountType: "",
    nlBrand: "",
    nlNumberOfSellers: "",
    nlNotes: "",
    nlShoppingPlatform: "",
    nlNumberOfListings: "",
    nlLinkedInPage: "",
    nlWebsite: "",
    nlType: "",
    isShowingAddNewLeadModal: false,
    platformList: [],
    arrayLength: 0,
    actions: {
      logoutNow: function logoutNow() {
        var self = this;
        this.get('spinner').show('discoverySpinner');
        Parse.User.logOut().then(function () {
          self.get('spinner').hide('discoverySpinner');
          self.get('listingservice').clearAllNow();
          self.get('vendorservice').clearAllNow();
          self.transitionToRoute('login');
          setTimeout(function () {
            location.reload();
          }, 500);
        });
      },
      startLoading: function startLoading() {
        var self = this;
        this.get('spinner').show('discoverySpinner');
        var today = new Date();
        var leadservice = this.get("leadservice");

        var accountResults = leadservice.get("accountResults");
        var accountStatus = leadservice.get("accountStatus");

        var mySearchText = this.get("mySearchText");
        var searchMode = this.get("searchMode");
        var direction = this.get('direction');
        var params = {};
        if (accountStatus == null) {
          if (mySearchText == null || mySearchText == "") {
            params = {
              lastPostDate: today,
              mode: searchMode,
              skip: accountResults.length,
              direction: direction
            };
          } else {
            params = {
              lastPostDate: today,
              mode: searchMode,
              searchText: mySearchText,
              skip: accountResults.length,
              direction: direction
            };
          }
        } else {
          if (mySearchText == null || mySearchText == "") {
            params = {
              lastPostDate: today,
              mode: searchMode,
              status: accountStatus,
              skip: accountResults.length,
              direction: direction
            };
          } else {
            params = {
              lastPostDate: today,
              mode: searchMode,
              status: accountStatus,
              searchText: mySearchText,
              skip: accountResults.length,
              direction: direction
            };
          }
        }

        Parse.Cloud.run("getCurrentLeads", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          var resultsArray = [];
          for (var i = 0; i < result.length; i++) {
            var item = result[i];
            var myAccount = self.get('parseconverter').convertToLead(item);
            resultsArray.pushObject(myAccount);
          }
          self.get('leadservice').addAccountResults(resultsArray);
          var leadservice = self.get('leadservice');
          self.set('numberOfAccounts', leadservice.accountResults.length);
          var status = leadservice.get("accountStatus");
          self.send("recountLeads", status);
          self.send("refreshThisRoute");
          self.send("loadAccount");
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("Loading Listing error -  " + error.message);
        });
      },
      loadMoreItems: function loadMoreItems() {
        // var isLoading = this.get("isLoading");
        // if(isLoading) {
        //   return;
        // }
        var searchMode = this.get("searchMode");
        var numberDownloadedActivityResults = this.get('leadservice').get("accountResultsLastCallAmount");
        if (numberDownloadedActivityResults > 0) {
          var self = this;
          var historyResults = this.get('leadservice').get("accountResults");
          var lastResult = historyResults.slice(-1)[0];

          if (lastResult != undefined) {
            this.get('spinner').show('discoverySpinner');
            var currentDate = lastResult.createdAt;
            var leadservice = this.get("leadservice");
            var accountResults = leadservice.get("accountResults");
            var accountStatus = leadservice.get("accountStatus");
            var params = {};
            var direction = this.get('direction');
            var mySearchText = this.get("mySearchText");
            if (accountStatus == null) {
              if (mySearchText == null || mySearchText == "") {
                params = {
                  lastPostDate: currentDate,
                  mode: searchMode,
                  skip: accountResults.length,
                  direction: direction
                };
              } else {
                params = {
                  lastPostDate: currentDate,
                  mode: searchMode,
                  searchText: mySearchText,
                  skip: accountResults.length,
                  direction: direction
                };
              }
            } else {
              if (mySearchText == null || mySearchText == "") {
                params = {
                  lastPostDate: currentDate,
                  mode: searchMode,
                  status: accountStatus,
                  skip: accountResults.length,
                  direction: direction
                };
              } else {
                params = {
                  lastPostDate: currentDate,
                  mode: searchMode,
                  status: accountStatus,
                  searchText: mySearchText,
                  skip: accountResults.length,
                  direction: direction
                };
              }
            }
            self.set("isLoading", true);
            Parse.Cloud.run("getCurrentLeads", params).then(function (result) {
              self.get('spinner').hide('discoverySpinner');
              var resultsArray = [];
              for (var i = 0; i < result.length; i++) {
                var item = result[i];
                var myAccount = self.get('parseconverter').convertToLead(item);
                resultsArray.pushObject(myAccount);
              }
              self.get('leadservice').addAccountResults(resultsArray);
              // var leadservice = self.get('leadservice');
              // self.set('numberOfAccounts', leadservice.accountResults.length);

              self.set("isLoading", false);
              self.send("refreshThisRoute");
            }, function (error) {
              self.get('spinner').hide('discoverySpinner');
              self.set("isLoading", false);
              self.get('notificationService').error("Loading Sku error -  " + error.message);
            });
          }
        }
      },
      recountLeads: function recountLeads(status) {
        var self = this;
        var params = {
          status: status
        };
        Parse.Cloud.run("getCurrentLeadsCount", params).then(function (statusCount) {
          self.set('numberOfAccounts', statusCount);
          console.log("numberOfLeads is ", statusCount);
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          console.log("numberOfLeads -  " + error.message);
        });
      },
      toggleCheckBoxListing: function toggleCheckBoxListing(item) {
        this.get('leadservice').addOrRemoveItemFromSelectedItem(item);
      },
      showFiltersNow: function showFiltersNow() {
        this.send("toggleListingFilterModal");
      },
      updateCheckAll: function updateCheckAll() {
        var checkAll = this.get('checkAll');
        if (checkAll) {
          this.set('checkAll', false);
          this.get('leadservice').removeAllSelectedAccountsNow();
          this.send("refreshThisRoute");
        } else {
          this.set('checkAll', true);
          this.get('leadservice').addAllAccountsToSelectedNow();
          this.send("refreshThisRoute");
        }
      },
      showDetailScreen: function showDetailScreen() {},
      setSelectedListingsToValue: function setSelectedListingsToValue(value) {
        this.set('showAllContent', false);
        this.send("refreshThisRoute");
        // this.get('accountcontroller').setIsAdminFalse();
        var selectedAccounts = this.get('leadservice').get('selectedAccounts');

        var self = this;
        if (selectedAccounts.length > 0) {

          var itemToHide = selectedAccounts[0];
          var params = { leadId: itemToHide.objectId, status: value };

          this.get('spinner').show('discoverySpinner');
          Parse.Cloud.run("updateLeadStatusNow", params).then(function (result) {
            self.get('spinner').hide('discoverySpinner');
            var myAccount = self.get('parseconverter').convertToLead(result);
            self.get('leadservice').updateItemInSelectedArray(myAccount);
            self.set('showAllContent', true);
            self.send("refreshThisRoute");
            self.send("setSelectedListingsToValue", value);
          }, function (error) {
            self.get('spinner').hide('discoverySpinner');
            self.get('notificationService').error("Error Hiding Sku -  " + error.message);
            // self.get('accountcontroller').setIsAdmin();

            self.set('checkAll', false);
            self.set('showAllContent', true);
            self.send("refreshThisRoute");
          });
        } else {
          // this.get('accountcontroller').setIsAdmin();
          self.set('checkAll', false);
          this.set('showAllContent', true);
          this.send("refreshThisRoute");
        }
      },


      toggleListingFilterModal: function toggleListingFilterModal() {
        this.toggleProperty('isShowingFilterListingsModal');
      },
      setPartnerToValue: function setPartnerToValue(value) {
        if (value == "No Partner") {
          this.set('newAccountPartner', "");
        } else {
          this.set('newAccountPartner', value);
        }
      },
      filterStatusBy: function filterStatusBy(status) {

        this.get("leadservice").set("accountStatus", status);

        var leadservice = this.get("leadservice");
        if (leadservice.accountResults.length == 0) {
          this.send("startLoading");
          return;
        }
        this.get('leadservice').clearAllNow();
      },
      searchText: function searchText(text) {
        this.set("mySearchText", text);
        //this.get('leadservice').clearAllNow();
        var leadservice = this.get("leadservice");
        if (leadservice.accountResults.length == 0) {
          this.send("startLoading");
        }
        this.get('leadservice').clearAllNow();
      },
      sortBy: function sortBy(type) {
        var searchMode = this.get('searchMode');
        var direction = this.get('direction');
        if (searchMode == type) {
          if (direction == "up") {
            this.set('direction', "down");
          } else {
            this.set('direction', "up");
          }
        } else {
          this.set('direction', "up");
        }
        this.set('searchMode', type);
        var leadservice = this.get("leadservice");
        if (leadservice.accountResults.length == 0) {
          this.send("startLoading");
        }
        this.get('leadservice').clearAllNow();
      },
      emptyMethod: function emptyMethod() {},

      //isShowingNotesModal
      showNotes: function showNotes(lead) {

        var self = this;
        this.get('spinner').show('discoverySpinner');
        var params = {
          leadId: lead.objectId
        };
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("getUserLead", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          var freshLead = self.get('parseconverter').convertToLead(result);
          self.set('currentLead', freshLead);
          self.set('currentNotes', freshLead.notes);
          self.toggleProperty('isShowingNotesModal');
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("error getUserLead status -  " + error.message);
        });
      },

      toggleShowingNotesModal: function toggleShowingNotesModal() {
        this.toggleProperty('isShowingNotesModal');
      },
      updateNotes: function updateNotes() {
        var currentLead = this.get("currentLead");
        var currentNotes = this.get("currentNotes");
        var self = this;
        this.get('spinner').show('discoverySpinner');
        var params = {
          leadId: currentLead.objectId,
          notes: currentNotes
        };
        self.set('showAllContent', false);
        Parse.Cloud.run("updateLeadNotes", params).then(function (result) {
          self.toggleProperty('isShowingNotesModal');
          self.get('spinner').hide('discoverySpinner');
          var lead = self.get('parseconverter').convertToLead(result);
          self.get('leadservice').updateItemInArray(lead);
          self.set('showAllContent', true);
          self.send("refreshThisRoute");
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.set('showAllContent', true);
          self.get('notificationService').error("error updateLeadNotes status -  " + error.message);
        });
      },
      assignLead: function assignLead(lead) {
        if (lead.hasUser) {
          this.set('currentLead', lead);
          this.toggleProperty('isShowingUserLeadModal');
          return;
        }
        this.send("assignLeadToUser", lead);
      },


      toggleShowingUserLeadModal: function toggleShowingUserLeadModal() {
        this.toggleProperty('isShowingUserLeadModal');
      },

      reassignLead: function reassignLead() {
        this.toggleProperty('isShowingUserLeadModal');
        var currentLead = this.get("currentLead");
        this.send("assignLeadToUser", currentLead);
      },
      assignLeadToUser: function assignLeadToUser(lead) {
        var self = this;
        var params = {
          leadId: lead.objectId
        };
        self.set('showAllContent', false);
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("assignLeadToMyself", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          var lead = self.get('parseconverter').convertToLead(result);
          self.get('leadservice').updateItemInArray(lead);
          self.set('showAllContent', true);
          self.send("refreshThisRoute");
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.set('showAllContent', true);
          self.get('notificationService').error("error getting account Users -  " + error.message);
        });
      },
      addNewParentCompany: function addNewParentCompany() {
        this.set("newParentCompany", "");
        this.toggleProperty('isShowingAddParentCompanyModal');
      },

      toggleShowingAddParentCompanyModal: function toggleShowingAddParentCompanyModal() {
        this.toggleProperty('isShowingAddParentCompanyModal');
      },

      saveParentCompany: function saveParentCompany() {
        var self = this;
        var newParentCompany = this.get("newParentCompany");
        var params = { parentCompany: newParentCompany };
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("createParentCompany", params).then(function (results) {
          self.get('spinner').hide('discoverySpinner');
          self.toggleProperty('isShowingAddParentCompanyModal');
          self.get('notificationService').success(results);
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("error saving parent company -  " + error.message);
        });
      },
      setParentCompany: function setParentCompany(lead) {
        this.set('currentLead', lead);
        this.set('currentParentCompany', "");

        var self = this;
        var params = {};
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("getLeadParentCompanyNames", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          var resultsArray = [];
          for (var i = 0; i < result.length; i++) {
            var parentCompanyName = result[i];
            resultsArray.pushObject(parentCompanyName);
          }
          self.set('parentCompanyList', resultsArray);
          self.toggleProperty('isShowingSetParentCompanyModal');
          self.send("refreshThisRoute");
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("Error getting partner names -  " + error.message);
        });
      },

      toggleShowingSetParentCompanyModal: function toggleShowingSetParentCompanyModal() {
        this.toggleProperty('isShowingSetParentCompanyModal');
      },
      setLeadParentCompanyToValue: function setLeadParentCompanyToValue(name) {
        this.set('currentParentCompany', name);
      },
      saveParentCompanyForLead: function saveParentCompanyForLead() {
        var currentLead = this.get('currentLead');
        var currentParentCompany = this.get('currentParentCompany');

        if (currentParentCompany == "") {
          this.get('notificationService').warning("Please Select A Parent Company");
          return;
        }

        var self = this;
        var params = {
          leadId: currentLead.objectId,
          parentCompany: currentParentCompany
        };
        self.set('showAllContent', false);
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("setLeadsParentCompany", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          self.toggleProperty('isShowingSetParentCompanyModal');
          var lead = self.get('parseconverter').convertToLead(result);
          self.get('leadservice').updateItemInArray(lead);
          self.set('showAllContent', true);
          self.send("refreshThisRoute");
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.set('showAllContent', true);
          self.get('notificationService').error("error getting account Users -  " + error.message);
        });
      },
      loadContacts: function loadContacts(lead) {
        this.set('currentLead', lead);
        this.set('isShowingCreateContact', false);
        this.set('isShowingEditLeadContact', false);
        this.set('currentLeadLinkedInPage', lead.linkedInPage);
        this.set('currentLeadWebsite', lead.website);
        this.set('currentLeadPartner', lead.partner);
        this.set('currentLeadPlanType', lead.planType);
        this.set('currentLeadQuote', lead.quote);
        var self = this;
        var params = {
          leadId: lead.objectId
        };
        self.set('showAllContent', false);
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("getContactsForLead", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          var resultsArray = [];
          for (var i = 0; i < result.length; i++) {
            var item = result[i];
            var leadContact = self.get('parseconverter').convertToLeadContact(item);
            resultsArray.pushObject(leadContact);
          }
          self.set('leadContactArray', resultsArray);
          self.set('arrayLength', result.length);
          self.set('showAllContent', true);
          self.send("refreshThisRoute");
          self.toggleProperty('isShowingLeadContactsModal');
        }, function (error) {
          self.set('showAllContent', true);
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("error getting account Users -  " + error.message);
        });
      },
      toggleShowingLeadContactsModal: function toggleShowingLeadContactsModal() {
        this.toggleProperty('isShowingLeadContactsModal');
      },
      removeLeadFile: function removeLeadFile(leadFile) {
        var self = this;
        var params = {
          leadFileId: leadFile.objectId
        };
        self.set('showAllContent', false);
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("removeLeadFile", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          self.set('isShowingUploadLeadDataFile', false);
          self.set('showAllContent', true);
          self.send("refreshThisRoute");
          self.send("showLeadDocuments");
        }, function (error) {
          self.set('showAllContent', true);
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("error removing lead file -  " + error.message);
        });
      },
      removeLeadContact: function removeLeadContact(leadContact) {
        var self = this;
        var params = {
          leadContactId: leadContact.objectId
        };
        var currentLead = this.get('currentLead');
        self.set('showAllContent', false);
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("removeLeadContact", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          self.toggleProperty('isShowingLeadContactsModal');
          self.set('showAllContent', true);
          self.send("refreshThisRoute");
          self.send("loadContacts", currentLead);
          self.get('notificationService').success(result);
        }, function (error) {
          self.set('showAllContent', true);
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("error removing lead file -  " + error.message);
        });
      },
      showLeadDocuments: function showLeadDocuments() {
        var currentLead = this.get('currentLead');
        this.set('leadFileArrayArray', []);
        var self = this;
        var params = {
          leadId: currentLead.objectId
        };
        self.set('showAllContent', false);
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("getFilesForLead", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          var resultsArray = [];
          for (var i = 0; i < result.length; i++) {
            var item = result[i];
            var newLeadFile = self.get('parseconverter').convertToLeadFile(item);
            resultsArray.pushObject(newLeadFile);
          }
          self.set('leadFileArrayArray', resultsArray);
          self.set('showAllContent', true);
          self.send("refreshThisRoute");
          self.set('isShowingLeadContactsModal', false);
          self.set('isShowingUploadLeadDataFile', true);
        }, function (error) {
          self.set('showAllContent', true);
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("error getting lead files -  " + error.message);
        });
      },
      closeShowingUploadLeadDataFile: function closeShowingUploadLeadDataFile() {
        this.set('isShowingUploadLeadDataFile', false);
        var currentLead = this.get('currentLead');
        this.send("loadContacts", currentLead);
      },
      uploadloadFile: function uploadloadFile(files) {
        console.log("");
        var currentLead = this.get('currentLead');
        var leadFileArrayArray = this.get('leadFileArrayArray');
        // ;      var dateString = new Date().toLocaleString();
        //       dateString = dateString.replace(/[^0-9a-zA-Z]/g, '');
        if (files.length > 0) {
          var file = files[0];
          console.log("file is ", file);
          var name = file.name;
          var parseFile = new Parse.File(name, file);

          var self = this;
          this.get('spinner').show('discoverySpinner');
          parseFile.save().then(function () {
            var params = {
              leadId: currentLead.objectId,
              file: parseFile,
              name: name
            };
            Parse.Cloud.run("addFileToLead", params).then(function (result) {
              self.get('spinner').hide('discoverySpinner');
              var newLeadFile = self.get('parseconverter').convertToLeadFile(result);
              leadFileArrayArray.pushObject(newLeadFile);
            }, function (error) {
              self.get('spinner').hide('discoverySpinner');
              self.get('notificationService').error("error uploading new file -  " + error.message);
            });
          }, function (error) {
            self.get('spinner').hide('discoverySpinner');
            self.get('notificationService').error("error saving file -  " + error.message);
          });
        }
      },
      toggleNewContact: function toggleNewContact() {

        this.set('newLeadContactFullName', "");
        this.set('newLeadContactTitle', "");
        this.set('newLeadContactEmail', "");
        this.set('newLeadContactLinkedInProfile', "");
        this.set('newLeadContactPhone', "");
        this.set('newLeadContactMobilePhone', "");
        this.toggleProperty('isShowingCreateContact');
      },
      saveLeadData: function saveLeadData() {
        var linkedInPage = this.get("currentLeadLinkedInPage");
        var website = this.get("currentLeadWebsite");
        var partner = this.get("currentLeadPartner");
        var planType = this.get("currentLeadPlanType");
        var quote = this.get("currentLeadQuote");

        var currentLead = this.get('currentLead');

        var self = this;
        var params = {
          leadId: currentLead.objectId,
          linkedInPage: linkedInPage,
          website: website,
          partner: partner,
          planType: planType,
          quote: quote
        };
        self.set('showAllContent', false);
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("saveLeadData", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          var lead = self.get('parseconverter').convertToLead(result);
          self.get('leadservice').updateItemInArray(lead);
          self.set('showAllContent', true);
          self.send("refreshThisRoute");
          self.get('notificationService').success("Lead Data Updated");
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.set('showAllContent', true);
          self.get('notificationService').error("error getting account Users -  " + error.message);
        });
      },
      createNewContact: function createNewContact() {

        var fullName = this.get("newLeadContactFullName");
        var title = this.get("newLeadContactTitle");
        var email = this.get("newLeadContactEmail");
        var linkedInProfile = this.get("newLeadContactLinkedInProfile");
        var phone = this.get("newLeadContactPhone");
        var mobilePhone = this.get("newLeadContactMobilePhone");

        if (fullName == "" || fullName == undefined) {
          this.get('notificationService').warning("full name needs to be filled out");
          return;
        }

        if (email == "" && phone == "" && mobilePhone == "" && linkedInProfile == "") {
          this.get('notificationService').warning("contact information needed - email, phone, or linkedIn profile");
          return;
        }

        var currentLead = this.get('currentLead');

        var self = this;
        var params = {
          leadId: currentLead.objectId,
          fullName: fullName,
          title: title,
          email: email,
          linkedInProfile: linkedInProfile,
          phone: phone,
          mobilePhone: mobilePhone
        };
        self.set('showAllContent', false);
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("createNewLeadContact", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          var leadContact = self.get('parseconverter').convertToLeadContact(result);
          var leadContactArray = self.get("leadContactArray");
          leadContactArray.pushObject(leadContact);
          self.set('leadContactArray', leadContactArray);
          self.set('showAllContent', true);
          self.send("refreshThisRoute");
          self.toggleProperty('isShowingCreateContact');
          self.get('notificationService').success("Lead Data Updated");
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.set('showAllContent', true);
          self.get('notificationService').error("error getting account Users -  " + error.message);
        });
      },
      leadContactContacted: function leadContactContacted(leadContact) {
        var self = this;
        var params = {
          leadContactId: leadContact.objectId
        };
        self.set('showAllContent', false);
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("markLeadContactAsContacted", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          var leadContact = self.get('parseconverter').convertToLeadContact(result);
          var leadContactArray = self.get("leadContactArray");
          for (var i = 0; i < leadContactArray.length; i++) {
            var item = leadContactArray[i];
            if (leadContact.objectId == item.objectId) {
              leadContactArray[i] = leadContact;
            }
          }
          self.get('leadservice').updateItemInArray(leadContact.lead);
          self.set('leadContactArray', leadContactArray);
          self.set('showAllContent', true);
          self.send("refreshThisRoute");
          self.get('notificationService').success("Lead Contact Updated");
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.set('showAllContent', true);
          self.get('notificationService').error(" lead Contacted error -  " + error.message);
        });
      },
      changeLeadContactStatus: function changeLeadContactStatus(leadContact, status) {
        var self = this;
        var currentLead = this.get('currentLead');
        var params = {
          leadContactId: leadContact.objectId,
          status: status
        };
        self.set('showAllContent', false);
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("changeLeadContactStatus", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          var leadContact = self.get('parseconverter').convertToLeadContact(result);
          var leadContactArray = self.get("leadContactArray");
          for (var i = 0; i < leadContactArray.length; i++) {
            var item = leadContactArray[i];
            if (leadContact.objectId == item.objectId) {
              leadContactArray[i] = leadContact;
            }
          }
          self.get('leadservice').updateItemInArray(leadContact.lead);
          self.set('leadContactArray', leadContactArray);
          self.toggleProperty('isShowingLeadContactsModal');
          self.set('showAllContent', true);
          self.send("refreshThisRoute");
          self.send("loadContacts", currentLead);
          self.get('notificationService').success("Lead Contact Updated");
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.set('showAllContent', true);
          self.get('notificationService').error(" lead Contacted error -  " + error.message);
        });
      },
      editLeadContact: function editLeadContact(leadContact) {
        this.set('currentLeadContact', leadContact);
        this.set('editLeadContactFullName', leadContact.fullName);
        this.set('editLeadContactTitle', leadContact.title);
        this.set('editLeadContactEmail', leadContact.email);
        this.set('editLeadContactLinkedInProfile', leadContact.linkedInProfile);
        this.set('editLeadContactPhone', leadContact.phone);
        this.set('editLeadContactPhoneMobile', leadContact.mobilePhone);

        this.set('isShowingEditLeadContact', true);
      },
      closeEditContact: function closeEditContact() {
        this.set('isShowingEditLeadContact', false);
      },
      updateLeadContact: function updateLeadContact() {
        var fullName = this.get("editLeadContactFullName");
        var title = this.get("editLeadContactTitle");
        var email = this.get("editLeadContactEmail");
        var linkedInProfile = this.get("editLeadContactLinkedInProfile");
        var phone = this.get("editLeadContactPhone");
        var mobilePhone = this.get("editLeadContactPhoneMobile");

        var currentLeadContact = this.get('currentLeadContact');
        var currentLead = this.get('currentLead');

        var self = this;
        var params = {
          leadContactId: currentLeadContact.objectId,
          fullName: fullName,
          title: title,
          email: email,
          linkedInProfile: linkedInProfile,
          phone: phone,
          mobilePhone: mobilePhone
        };
        self.set('showAllContent', false);
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("updateLeadContact", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          self.set('isShowingEditLeadContact', false);
          var leadContact = self.get('parseconverter').convertToLeadContact(result);
          var leadContactArray = self.get("leadContactArray");
          for (var i = 0; i < leadContactArray.length; i++) {
            var item = leadContactArray[i];
            if (leadContact.objectId == item.objectId) {
              leadContactArray[i] = leadContact;
            }
          }
          self.get('leadservice').updateItemInArray(leadContact.lead);
          self.set('leadContactArray', leadContactArray);
          self.toggleProperty('isShowingLeadContactsModal');
          self.set('showAllContent', true);
          self.send("refreshThisRoute");
          self.send("loadContacts", currentLead);
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.set('showAllContent', true);
          self.get('notificationService').error(" lead Contacted error -  " + error.message);
        });
      },
      followUpLater: function followUpLater(lead) {
        this.set('currentLead', lead);
        this.set('daysLater', 1);
        this.set('isShowingFollowUpLater', true);
      },
      toggleShowingFollowUpLater: function toggleShowingFollowUpLater() {
        this.toggleProperty('isShowingFollowUpLater');
      },
      updateFollowUpDate: function updateFollowUpDate() {
        var currentLead = this.get('currentLead');
        var daysLater = this.get('daysLater');
        if (daysLater < 1 || daysLater > 365) {
          this.get('notificationService').warning("Number of days must be between 1 and 365");
          return;
        }
        daysLater = parseInt(daysLater);
        var self = this;
        var params = {
          leadId: currentLead.objectId,
          daysLater: daysLater
        };
        self.set('showAllContent', false);
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("updateLeadFollowUpDays", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          var lead = self.get('parseconverter').convertToLead(result);
          self.get('leadservice').updateItemInArray(lead);
          self.toggleProperty('isShowingFollowUpLater');
          self.set('showAllContent', true);
          self.send("refreshThisRoute");
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.set('showAllContent', true);
          self.get('notificationService').error(" lead follow days error -  " + error.message);
        });
      },
      setAccountTypeEdit: function setAccountTypeEdit(value) {
        this.set('currentAccountType', value);
      },
      setAccountTypeNewLead: function setAccountTypeNewLead(value) {
        this.set('nlType', value);
      },
      setAccountType: function setAccountType(account) {
        this.set('currentAccount', account);
        this.toggleProperty('isShowingSetAccountType');
      },

      toggleShowingSetAccountType: function toggleShowingSetAccountType() {
        this.toggleProperty('isShowingSetAccountType');
      },
      updateAccountType: function updateAccountType() {
        var currentAccount = this.get('currentAccount');
        var currentAccountType = this.get('currentAccountType');
        if (currentAccountType == "") {
          return this.get('notificationService').warning("Account type needs to be filled out");
        }
        var params = {
          type: currentAccountType,
          objectId: currentAccount.objectId

        };
        this.set('showAllContent', false);
        var self = this;
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("updateLeadType", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          var lead = self.get('parseconverter').convertToLead(result);
          self.get('leadservice').updateItemInArray(lead);
          self.toggleProperty('isShowingSetAccountType');
          self.set('showAllContent', true);
          self.send("refreshThisRoute");
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.set('showAllContent', true);
          self.get('notificationService').error("Loading Sku error -  " + error.message);
        });
      },
      startSetLeadName: function startSetLeadName(lead) {
        this.set('currentLead', lead);
        this.set('currentLeadName', lead.brand);
        this.toggleProperty('isShowingSetLeadName');
      },
      toggleShowingSetLeadName: function toggleShowingSetLeadName() {
        this.toggleProperty('isShowingSetLeadName');
      },
      updateLeadName: function updateLeadName() {
        var currentLead = this.get("currentLead");
        var currentLeadName = this.get("currentLeadName");

        if (currentLeadName == "") {
          return this.get('notificationService').warning("lead name needs to be filled out");
        }
        var params = {
          objectId: currentLead.objectId,
          brand: currentLeadName

        };
        this.set('showAllContent', false);
        var self = this;
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("updateLeadBrand", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          var lead = self.get('parseconverter').convertToLead(result);
          self.get('leadservice').updateItemInArray(lead);
          self.toggleProperty('isShowingSetLeadName');
          self.set('showAllContent', true);
          self.send("refreshThisRoute");
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.set('showAllContent', true);
          self.get('notificationService').error("upading lead name error -  " + error.message);
        });
      },
      addNewLeadPopUp: function addNewLeadPopUp() {
        this.set("nlBrand", "");
        this.set("nlShoppingPlatform", "");
        this.set("nlType", "");
        this.set("nlNumberOfSellers", "0");
        this.set("nlNumberOfListings", "0");
        this.set("nlNotes", "");
        this.set("nlLinkedInPage", "");
        this.set("nlWebsite", "");
        var params = {};
        var self = this;
        self.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("getPlatformNames", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          var resultsArray = [];
          for (var i = 0; i < result.length; i++) {
            var item = result[i];
            var shoppingPlatform = self.get('parseconverter').convertToShoppingPlatform(item);
            resultsArray.pushObject(shoppingPlatform);
          }
          self.set('platformList', resultsArray);

          self.get('spinner').show('discoverySpinner');
          Parse.Cloud.run("getAccountTypes", params).then(function (result) {
            self.get('spinner').hide('discoverySpinner');
            var resultsArray = [];
            for (var i = 0; i < result.length; i++) {
              var item = result[i];
              resultsArray.pushObject(item);
            }
            self.set('accountTypeList', resultsArray);
            self.toggleProperty('isShowingAddNewLeadModal');
            self.send("refreshThisRoute");
          }, function (error) {
            self.get('spinner').hide('discoverySpinner');
            self.get('notificationService').error("Error getting account type names -  " + error.message);
          });
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("Error getting partner names -  " + error.message);
        });
      },

      toggleShowingAddNewLeadModal: function toggleShowingAddNewLeadModal() {
        this.toggleProperty('isShowingAddNewLeadModal');
      },
      setShoppingPlatformToValueEdit: function setShoppingPlatformToValueEdit(value) {
        this.set('nlShoppingPlatform', value);
      },
      createNewLead: function createNewLead() {
        var nlBrand = this.get('nlBrand');
        var nlShoppingPlatform = this.get('nlShoppingPlatform');
        var nlType = this.get('nlType');
        var nlNumberOfSellers = this.get('nlNumberOfSellers');
        var nlNumberOfListings = this.get('nlNumberOfListings');
        var nlNotes = this.get('nlNotes');
        var nlLinkedInPage = this.get('nlLinkedInPage');
        var nlWebsite = this.get('nlWebsite');

        if (nlBrand == "") {
          return this.get('notificationService').warning("Brand needs to be filled out");
        }
        if (nlShoppingPlatform == "") {
          return this.get('notificationService').warning("ShoppingPlatform needs to be filled out");
        }
        if (nlType == "") {
          return this.get('notificationService').warning("Type needs to be filled out");
        }
        if (nlNumberOfSellers == "" || nlNumberOfSellers == "0" || nlNumberOfSellers == 0) {
          return this.get('notificationService').warning("Number Of Sellers needs to be filled out");
        }
        if (nlNumberOfListings == "" || nlNumberOfListings == "0" || nlNumberOfListings == 0) {
          return this.get('notificationService').warning("Number Of Listings needs to be filled out");
        }

        var params = {
          brand: nlBrand,
          shoppingPlatform: nlShoppingPlatform,
          type: nlType,
          numberOfSellers: nlNumberOfSellers,
          numberOfListings: nlNumberOfListings,
          notes: nlNotes,
          linkedInPage: nlLinkedInPage,
          website: nlWebsite
        };
        var self = this;
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("createManualLead", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          self.set('isShowingAddNewLeadModal', false);
          self.set("nlBrand", "");
          self.set("nlShoppingPlatform", "");
          self.set("nlType", "");
          self.set("nlNumberOfSellers", "0");
          self.set("nlNumberOfListings", "0");
          self.set("nlNotes", "");
          self.set("nlLinkedInPage", "");
          self.set("nlWebsite", "");
          self.send("refreshThisRoute");
          var newLead = self.get('parseconverter').convertToLead(result);
          self.get('leadservice').addNewAccount(newLead);
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("Creating Lead error -  " + error.message);
        });
      },
      loadAccount: function loadAccount() {
        var self = this;
        var params = {};
        Parse.Cloud.run("getCurrentAccount", params).then(function (results) {
          if (results.length > 0) {
            var item = results[0];
            var myAccount = self.get('parseconverter').convertToAccount(item);
            self.get('accountcontroller').setCurrentAccount(myAccount);
            self.send("refreshThisRoute");
          }
        }, function (error) {
          if (error.code == 209) {
            //self.send("logoutNow");
            self.get('notificationService').error("Please logout and logback in - token expired");
          } else {
            self.get('notificationService').error("Loading Listing error -  " + error.message);
          }
        });
      },
      transitionToMAPProtection: function transitionToMAPProtection() {
        this.transitionToRoute('map');
        Ember.run.later(function () {
          location.reload();
        }, 500);
      }
    }
  });
});