define("uabide/routes/login", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  Parse.initialize("bzn8msayuASvFwWDJEY3DdGYDXb7eV9rweY2rr7o", "fRmOrMDotozW5iYypZ9s73yADQP3MiKmbIhQxXGw");
  Parse.serverURL = 'https://uabide.back4app.io/';

  exports.default = Ember.Route.extend({
    setupController: function setupController(controller) {
      console.log('checking login status');
      var currentUser = Parse.User.current();
      if (currentUser) {
        var myself = Parse.User.current().fetch();
        this.transitionTo('index');
      } else {
        console.log('not logged in');
      }
    }
  });
});