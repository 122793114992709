define('uabide/router', ['exports', 'uabide/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('login');
    this.route('discovery');
    this.route('report');
    this.route('account-details');
    this.route('account-settings');
    this.route('listings');
    this.route('sellers');
    this.route('seller-listings', { path: 'sellers/:sellerId/listings' });
    this.route('account');
    this.route('account-report', { path: 'account/:accountId/report' });
    this.route('account-report-bulk', { path: 'account-report-bulk/:accountId/report' });
    this.route('assets', { path: '/crawls' });
    this.route('portal');
    this.route('leads');
    this.route('mapview');
    this.route('maplistings');
    this.route('numbers');
    this.route('reports');
    this.route('reports-loading');
    this.route('seller-white-list');
    this.route('seller-search');
    this.route('map-clickup-dashboard');
    this.route('referral-page');
    this.route('map', function () {
      this.route('overview');
      this.route('accounts');
      this.route('products');
      this.route('listings');
      this.route('reports');
      this.route('inbox');
      this.route('settings');
      this.route('account-products', { path: 'accounts/:sellerId/products' });
      this.route('product-active-violations', { path: 'products/:productId/active-violations' });
    });
    this.route('not-found', { path: '/*path' });
  });

  exports.default = Router;
});