define('uabide/components/modals/download-vendor-status-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    status: null,
    currentSubStatus: null,

    didUpdateAttrs: function didUpdateAttrs() {
      this.set('status', null);
      this.set('currentSubStatus', null);
    },


    actions: {
      setSearchStatus: function setSearchStatus(status) {
        this.set('status', status);
      },
      submit: function submit() {
        this.searchAllStatuses(this.get('status'), this.get('currentSubStatus'));
      }
    }
  });
});