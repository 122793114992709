define("uabide/helpers/status-type-leads", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.statusTypeLeads = statusTypeLeads;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function statusTypeLeads(params /*, hash*/) {
    var _params = _slicedToArray(params, 1),
        status = _params[0];

    var text;
    switch (status) {
      case "new":
        text = "c-badge--danger";
        break;
      case "lead":
        text = "c-btn--yellow";
        break;
      case "contacted":
        text = "c-btn--purple";
        break;
      case "inProgress":
        text = "c-btn--green";
        break;
      case "sowSent":
        text = "c-btn--warning";
        break;
      case "won":
        text = "c-btn--info";
        break;
      case "lost":
        text = "c-btn--orange";
        break;
      case "revisit":
        text = "c-btn--red";
        break;
      case "reject":
        text = "c-btn--pink";
        break;
      case "showAllNew":
        text = "c-badge--danger";
        break;

      default:
        text = "c-btn--primary";
    }
    return text;
  }

  exports.default = Ember.Helper.helper(statusTypeLeads);
});