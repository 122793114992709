define('uabide/services/account-controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var inject = Ember.inject;

  Parse.initialize("bzn8msayuASvFwWDJEY3DdGYDXb7eV9rweY2rr7o", "fRmOrMDotozW5iYypZ9s73yADQP3MiKmbIhQxXGw");
  Parse.serverURL = 'https://uabide.back4app.io/';

  exports.default = Ember.Service.extend({
    parseconverter: inject.service('parse-converter'),
    listingservice: inject.service('listing-service'),
    currentAccount: null,
    isAdmin: false,
    isSuperAdmin: false,
    showAdminView: false,
    isEditor: false,
    isAccountAdmin: false,
    currentAccounts: [],
    accountConditionArray: [],
    conditionArray: [],
    isMe: false,
    multiAccount: false,
    isMap: false,
    showEmailFilter: true,
    currentUserId: null,
    favoriteAccounts: [],
    naturalizerAccountID: '1e5GOtGX3N',
    drSchoolsAccountID: 'UTZ4he6szh',
    isCaleres: false,
    isMapOnboarding: false,
    partner: "",
    setCurrentAccount: function setCurrentAccount(account) {
      var accountConditionArray = account.accountConditionArray;
      var partner = account.partner;
      if (partner == "caleres") {
        this.set('isCaleres', true);
      } else {
        this.set('isCaleres', false);
      }
      var taskId = account.taskId;
      if (taskId == "") {
        this.set('isMapOnboarding', false);
      } else {
        this.set('isMapOnboarding', true);
      }
      this.set('accountConditionArray', accountConditionArray);
      this.set('currentAccount', account);
      this.set('isMap', account.isMap);
      this.set('partner', partner);
      this.setUMAPFilterAuthorization(account.partner);
    },
    setIsAdmin: function setIsAdmin() {
      var myself = Parse.User.current().fetch();
      var currentUser = Parse.User.current();
      if (currentUser) {
        var user = this.get('parseconverter').convertToUser(currentUser);
        if (!user.isAdmin) {
          Parse.User.logOut().then(function () {
            setTimeout(function () {
              location.reload();
            }, 500);
          });
        }
        console.log("is admin:" + user.isAdmin);
        this.set('isAdmin', user.isAdmin);
        this.set('isSuperAdmin', user.isSuperAdmin);
        this.set('isEditor', user.isEditor);
        this.set('multiAccount', user.multiAccount);
        this.set('isAccountAdmin', user.isAccountAdmin);
        this.set('showEmailFilter', user.showEmailFilter);
        this.set('currentUserId', user.objectId);
        if (user.objectId == "2ryRDMG693") {
          this.set('isMe', true);
        }
        this.get("listingservice").updateTurbo(user.turboOn);
      }
      if (user.isAdmin) {
        this.set('favoriteAccounts', user.favoriteAccounts);

        var self = this;
        currentUser.fetch().then(function (fetchedUser) {
          var refreshedUser = self.get('parseconverter').convertToUser(fetchedUser);
          self.set('showAdminView', refreshedUser.showAdminView);
        });
      }
    },
    toggleAdminView: function toggleAdminView() {
      var currentUser = Parse.User.current();
      if (currentUser) {
        var user = this.get('parseconverter').convertToUser(currentUser);
        if (!user.isAdmin) {
          return;
        }
        this.toggleProperty('showAdminView');
        var showAdminView = this.get("showAdminView");
        // if (showAdminView) {
        //   showAdminView = false;
        // } else {
        //   showAdminView = true;
        // }
        var params = { showAdminView: showAdminView };
        Parse.Cloud.run("updateAdminView", params).then(function (results) {
          console.log("toggleAdminView updated");
        }, function (error) {
          console.log("updateAdminView error -  " + error.message);
        });
      }
    },

    setIsAdminFalse: function setIsAdminFalse() {
      this.set('isAdmin', false);
      this.set('isSuperAdmin', false);
      this.set('isEditor', false);
    },
    clearAccounts: function clearAccounts() {
      this.set('currentAccounts', []);
    },
    addAccountResults: function addAccountResults(results) {
      //console.log("results length "+results.length);
      this.get('currentAccounts').pushObjects(results);
    },
    addPlatformArray: function addPlatformArray() {
      //console.log("addPlatformArray1");
      this.set('platformArray', []);
      var params = {};
      var self = this;
      return Parse.Cloud.run("getCurrentPlatformNames", params).then(function (results) {
        //console.log("addPlatformArray2");
        var platformArray = [];
        for (var i = 0; i < results.length; i++) {
          var item = results[i];
          var cleanName = capitalizeFirstLetter(item);
          var platform = new Ember.Object();
          platform.set('name', item);
          platform.set('cleanName', cleanName);
          platformArray.push(platform);
        }

        platformArray.sort(function (a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        //console.log("addPlatformArray3");
        self.get('platformArray').pushObjects(platformArray);
        //console.log("addPlatformArray4 - " + platformArray);
      }, function (error) {
        console.log("platform array error -  " + error.message);
      });
    },
    setUMAPFilterAuthorization: function setUMAPFilterAuthorization(partner) {
      if (partner === "caleres") {
        this.set('isUMAPFilterEnabled', true);
      } else {
        this.set('isUMAPFilterEnabled', false);
      }
    }
  });


  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
});