define('uabide/services/seller-search-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    parseService: Ember.inject.service(),
    sellerResults: [],
    sellerResultsLastCallAmount: 0,
    fileArray: null,
    numberDownloadedHistoryFileResults: 0,

    addSellerResults: function addSellerResults(newResults) {
      this.set('sellerResultsLastCallAmount', newResults.length);
      this.get('sellerResults').pushObjects(newResults);
    },
    clearAllNow: function clearAllNow() {
      this.set('sellerResultsLastCallAmount', 0);
      this.set('sellerResults', []);
    },
    resetFileForDownload: function resetFileForDownload() {
      this.set('fileArray', []);
      this.set('numberDownloadedHistoryFileResults', 0);
    },
    addHistoryFileResults: function addHistoryFileResults(myResults) {
      this.get('fileArray').pushObjects(myResults);
      this.set('numberDownloadedHistoryFileResults', myResults.length);
    },
    crossAccountVendorSearch: function crossAccountVendorSearch(params) {
      return this.get('parseService.cloud').run('crossAccountVendorSearch', params);
    }
  });
});