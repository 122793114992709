define('uabide/controllers/discovery', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  Parse.initialize("bzn8msayuASvFwWDJEY3DdGYDXb7eV9rweY2rr7o", "fRmOrMDotozW5iYypZ9s73yADQP3MiKmbIhQxXGw");
  Parse.serverURL = 'https://uabide.back4app.io/';
  var inject = Ember.inject;

  exports.default = Ember.Controller.extend({
    spinner: inject.service('spinner'),
    listingservice: inject.service('listing-service'),
    vendorservice: inject.service('vendor-service'),
    parseconverter: inject.service('parse-converter'),
    accountcontroller: inject.service('account-controller'),
    notificationService: Ember.inject.service(),
    numberOfListings: 0,
    numberOfVendors: 0,
    showListings: true,
    checkAll: false,
    checkAllVendor: false,
    showAllContent: true,
    accountName: "",
    value_discovered: "Discovered",
    value_enforce: "Enforce",
    value_pending: "Pending",
    value_closed: "Closed",
    value_authorized: "Authorized",
    value_flagged: "Flagged",
    value_price_flagged: "Price Flag",
    value_resubmit: "Resubmit",
    value_rejected: "Rejected",
    value_consumer: "Consumer",
    value_takedown: "Takedown",
    value_new_seller: "New Seller",
    value_infringer: "Infringer",
    isShowingFilterListingsModal: false,
    isShowingFilterVendorsModal: false,
    listingStatus: null,
    vendorStatus: null,
    actions: {
      logoutNow: function logoutNow() {
        var _this = this;

        console.log("trying to logout ");
        var self = this;
        this.get('spinner').show('discoverySpinner');
        Parse.User.logOut().then(function () {
          self.get('spinner').hide('discoverySpinner');
          self.get('listingservice').clearAllNow();
          self.get('vendorservice').clearAllNow();
          _this.transitionToRoute('login');
          setTimeout(function () {
            location.reload();
          }, 500);
        });
      },
      startLoading: function startLoading() {

        var showListings = this.get("showListings");
        if (showListings) {
          console.log('started loading listings');
          var self = this;
          this.get('spinner').show('discoverySpinner');
          var today = new Date();

          var listingStatus = this.get("listingStatus");
          var params = {};
          if (listingStatus == null) {
            params = { lastPostDate: today, mode: "dateDesc" };
          } else {
            params = { lastPostDate: today, mode: "dateDesc", status: listingStatus };
          }

          Parse.Cloud.run("getCurrentListings", params).then(function (result) {
            console.log('finished downloading listings');
            self.get('spinner').hide('discoverySpinner');
            var resultsArray = [];
            for (var i = 0; i < result.length; i++) {
              var item = result[i];
              var myListing = self.get('parseconverter').convertToListing(item);
              self.set('numberOfListings', myListing.account.numberOfListings);
              self.set('numberOfVendors', myListing.account.numberOfVendors);
              self.set("accountName", myListing.account.accountName);
              self.get('accountcontroller').setCurrentAccount(myListing.account);
              resultsArray.pushObject(myListing);
            }
            self.get('listingservice').addListingResults(resultsArray);
            console.log('number of listings ' + resultsArray.length);
            console.log('finished loading listings');
            self.send("refreshThisRoute");
          }, function (error) {
            self.get('spinner').hide('discoverySpinner');
            self.get('notificationService').error("Loading Listing error -  " + error.message);
          });
        } else {
          console.log('started loading listings');
          var self = this;
          this.get('spinner').show('discoverySpinner');
          var today = new Date();
          var vendorStatus = this.get("vendorStatus");
          var params = {};
          if (vendorStatus == null) {
            params = { lastPostDate: today, mode: "dateDesc" };
          } else {
            params = { lastPostDate: today, mode: "dateDesc", status: vendorStatus };
          }

          Parse.Cloud.run("getCurrentVendors", params).then(function (result) {
            console.log('finished downloading vendors');
            self.get('spinner').hide('discoverySpinner');
            var resultsArray = [];
            for (var i = 0; i < result.length; i++) {
              var item = result[i];
              var myVendor = self.get('parseconverter').convertToVendor(item);
              resultsArray.pushObject(myVendor);
            }
            self.get('vendorservice').addVendorResults(resultsArray);
            console.log('number of vendors ' + resultsArray.length);
            console.log('finished vendor listings');
            self.send("refreshThisRoute");
          }, function (error) {
            self.get('spinner').hide('discoverySpinner');
            self.get('notificationService').error("Loading Listing error -  " + error.message);
          });
        }
      },
      loadMoreItems: function loadMoreItems() {

        var showListings = this.get("showListings");
        if (showListings) {
          var numberDownloadedActivityResults = this.get('listingservice').get("listingResultsLastCallAmount");
          if (numberDownloadedActivityResults > 0) {
            var self = this;
            var historyResults = this.get('listingservice').get("listingResults");
            var lastResult = historyResults.slice(-1)[0];
            if (lastResult != undefined) {
              this.get('spinner').show('discoverySpinner');
              var currentDate = lastResult.createdAt;
              var listingStatus = this.get("listingStatus");
              var params = {};
              if (listingStatus == null) {
                params = { lastPostDate: currentDate, mode: "dateDesc" };
              } else {
                params = { lastPostDate: currentDate, mode: "dateDesc", status: listingStatus };
              }

              Parse.Cloud.run("getCurrentListings", params).then(function (result) {
                self.get('spinner').hide('discoverySpinner');
                var resultsArray = [];
                for (var i = 0; i < result.length; i++) {
                  var item = result[i];
                  var mySku = self.get('parseconverter').convertToListing(item);
                  resultsArray.pushObject(mySku);
                }
                self.get('listingservice').addListingResults(resultsArray);
                self.send("refreshThisRoute");
              }, function (error) {
                self.get('spinner').hide('discoverySpinner');
                self.get('notificationService').error("Loading Sku error -  " + error.message);
              });
            }
          }
        } else {
          var numberDownloadedActivityResults = this.get('vendorservice').get("vendorResultsLastCallAmount");
          if (numberDownloadedActivityResults > 0) {
            var self = this;
            var historyResults = this.get('vendorservice').get("vendorResults");
            var lastResult = historyResults.slice(-1)[0];
            if (lastResult != undefined) {
              this.get('spinner').show('discoverySpinner');
              var currentDate = lastResult.createdAt;
              var vendorStatus = this.get("vendorStatus");
              var params = {};
              if (vendorStatus == null) {
                params = { lastPostDate: currentDate, mode: "dateDesc" };
              } else {
                params = { lastPostDate: currentDate, mode: "dateDesc", status: vendorStatus };
              }

              Parse.Cloud.run("getCurrentVendors", params).then(function (result) {
                self.get('spinner').hide('discoverySpinner');
                var resultsArray = [];
                for (var i = 0; i < result.length; i++) {
                  var item = result[i];
                  var myVendor = self.get('parseconverter').convertToVendor(item);
                  resultsArray.pushObject(myVendor);
                }
                self.get('vendorservice').addVendorResults(resultsArray);
                self.send("refreshThisRoute");
              }, function (error) {
                self.get('spinner').hide('discoverySpinner');
                self.get('notificationService').error("Loading Sku error -  " + error.message);
              });
            }
          }
        }
      },
      showListingsNow: function showListingsNow() {
        this.set('showListings', true);
      },
      showVendorsNow: function showVendorsNow() {
        this.set('showListings', false);
      },
      toggleCheckBoxListing: function toggleCheckBoxListing(item) {
        //console.log(item);
        this.get('listingservice').addOrRemoveItemFromSelectedItem(item);
        //this.controller.set('toggleChecked', !this.controller.get('toggleChecked'));
      },
      toggleCheckBoxVendor: function toggleCheckBoxVendor(item) {
        //console.log(item);
        this.get('vendorservice').addOrRemoveItemFromSelectedItem(item);
        //this.controller.set('toggleChecked', !this.controller.get('toggleChecked'));
      },
      showFiltersNow: function showFiltersNow() {
        var showListings = this.get("showListings");
        if (showListings) {
          this.send("toggleListingFilterModal");
        } else {
          this.send("toggleVendorFilterModal");
        }
      },
      updateCheckAll: function updateCheckAll() {
        var checkAll = this.get('checkAll');
        if (checkAll) {
          this.set('checkAll', false);
          this.get('listingservice').removeAllSelectedListingsNow();
          this.send("refreshThisRoute");
        } else {
          this.set('checkAll', true);
          this.get('listingservice').addAllListingsToSelectedNow();
          this.send("refreshThisRoute");
        }
      },
      updateCheckAllVendor: function updateCheckAllVendor() {
        var checkAllVendor = this.get('checkAllVendor');
        if (checkAllVendor) {
          this.set('checkAllVendor', false);
          this.get('vendorservice').removeAllSelectedListingsNow();
          this.send("refreshThisRoute");
        } else {
          this.set('checkAllVendor', true);
          this.get('vendorservice').addAllListingsToSelectedNow();
          this.send("refreshThisRoute");
        }
      },
      setSelectedListingsToValue: function setSelectedListingsToValue(value) {
        this.set('showAllContent', false);
        this.send("refreshThisRoute");
        // this.get('accountcontroller').setIsAdminFalse();
        var selectedListings = this.get('listingservice').get('selectedListings');
        console.log("setSelectedListingsToValue " + value);
        console.log("setSelectedListings length " + selectedListings.length);

        var self = this;
        if (selectedListings.length > 0) {

          var itemToHide = selectedListings[0];
          var params = { listingId: itemToHide.objectId, status: value };

          this.get('spinner').show('discoverySpinner');
          Parse.Cloud.run("updateListingStatusNow", params).then(function (result) {
            self.get('spinner').hide('discoverySpinner');
            var mySku = self.get('parseconverter').convertToListing(result);
            self.get('listingservice').updateItemInSelectedArray(mySku);
            self.set('showAllContent', true);
            self.send("refreshThisRoute");
            self.send("setSelectedListingsToValue", value);
          }, function (error) {
            self.get('spinner').hide('discoverySpinner');
            self.get('notificationService').error("Error Hiding Sku -  " + error.message);
            // self.get('accountcontroller').setIsAdmin();

            self.set('checkAll', false);
            self.set('showAllContent', true);
            self.send("refreshThisRoute");
          });
        } else {
          // this.get('accountcontroller').setIsAdmin();
          self.set('checkAll', false);
          this.set('showAllContent', true);
          this.send("refreshThisRoute");
          console.log("finished updating all items ");
        }
      },
      setSelectedVendorsToValue: function setSelectedVendorsToValue(value) {
        this.set('showAllContent', false);
        this.send("refreshThisRoute");
        // this.get('accountcontroller').setIsAdminFalse();
        var selectedVendors = this.get('vendorservice').get('selectedVendors');
        console.log("setSelectedListingsToValue " + value);
        console.log("setSelectedListings length " + selectedVendors.length);

        var self = this;
        if (selectedVendors.length > 0) {

          var itemToHide = selectedVendors[0];
          var params = { vendorId: itemToHide.objectId, status: value };

          this.get('spinner').show('discoverySpinner');
          Parse.Cloud.run("updateVendorStatusNow", params).then(function (result) {
            self.get('spinner').hide('discoverySpinner');
            var mySku = self.get('parseconverter').convertToVendor(result);
            self.get('vendorservice').updateItemInSelectedArray(mySku);
            self.set('showAllContent', true);
            self.send("refreshThisRoute");
            self.send("setSelectedVendorsToValue", value);
          }, function (error) {
            self.get('spinner').hide('discoverySpinner');
            self.get('notificationService').error("Error Hiding Sku -  " + error.message);
            // self.get('accountcontroller').setIsAdmin();

            self.set('checkAllVendor', false);
            self.set('showAllContent', true);
            self.send("refreshThisRoute");
          });
        } else {
          // this.get('accountcontroller').setIsAdmin();
          self.set('checkAllVendor', false);
          this.set('showAllContent', true);
          this.send("refreshThisRoute");
          console.log("finished updating all items ");
        }
      },

      toggleListingFilterModal: function toggleListingFilterModal() {
        this.toggleProperty('isShowingFilterListingsModal');
      },
      toggleVendorFilterModal: function toggleVendorFilterModal() {
        this.toggleProperty('isShowingFilterVendorsModal');
      },
      filterStatusBy: function filterStatusBy(status) {
        this.send("toggleListingFilterModal");
        this.get('listingservice').clearAllNow();
        if (status == "ClearAll") {
          this.set("listingStatus", null);
        } else {
          this.set("listingStatus", status);
        }
      },
      filterStatusByVendor: function filterStatusByVendor(status) {
        this.send("toggleVendorFilterModal");
        this.get('vendorservice').clearAllNow();
        if (status == "ClearAll") {
          this.set("vendorStatus", null);
        } else {
          this.set("vendorStatus", status);
        }
      }
    }
  });
});