define('uabide/components/modals/send-notice-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    account: Ember.computed.alias('accountNotice.account.sellerName'),
    subject: Ember.computed.alias('accountNotice.template.subject'),
    currentText: null,
    currentEmails: null,

    didUpdateAttrs: function didUpdateAttrs() {
      if (this.accountNotice) {
        this.set('currentEmails', this.accountNotice.emails.join(', '));
        this.set('currentText', this.accountNotice.template.message);
      }
    },


    actions: {
      submit: function submit() {
        var completeEmailBody = this.accountNotice.template.head + this.get('currentText').replace(/(\r\n|\n|\r)/gm, '');
        var emails = this.get('currentEmails').split(',').map(function (el) {
          return el.trim();
        }).filter(function (el) {
          return el;
        });
        this.sendWarningNotice({
          emails: emails,
          text: completeEmailBody
        });
      }
    }
  });
});