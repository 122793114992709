define('uabide/components/modals/manage-user-accounts-policy-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    accountservice: Ember.inject.service('account-service'),
    notificationService: Ember.inject.service(),
    searchValue: null,
    currentEditUserAccountsArray: [],
    accountsSorting: ['isAdded:desc'],
    sortedMultiAccountsArray: Ember.computed.sort('currentMultiAccountsArray', 'accountsSorting'),
    accounts: Ember.computed('searchValue', 'sortedMultiAccountsArray', function () {
      var searchValueTrim = this.get('searchValue');
      var sortedMultiAccountsArray = this.get('sortedMultiAccountsArray');
      if (searchValueTrim && searchValueTrim.length) {
        return sortedMultiAccountsArray.filter(function (el) {
          if (el.accountName) {
            return el.accountName.toUpperCase().startsWith(searchValueTrim.toUpperCase());
          }
        });
      } else {
        return sortedMultiAccountsArray;
      }
    }),

    actions: {
      selectAccount: function selectAccount(item) {
        if (!item.users) {
          this.getAccountUsers(item);
        }
        item.toggleProperty('isSelected');
      },
      updateAccountPolicy: function updateAccountPolicy(account, files) {
        this.updateAccountMapPolicy(files, account);
        this._closeAllAccounts();
      },
      showCreateNewUser: function showCreateNewUser(item) {
        item.toggleProperty('isUserFormShowing');
      },
      createUser: function createUser(account) {
        var createUsername = account.createUsername,
            createPassword = account.createPassword,
            objectId = account.objectId;

        try {
          if (createPassword == '') {
            throw new Error('Password needs to be filled out');
          }
          if (createUsername == '') {
            throw new Error('Email needs to be filled out');
          }

          if (!this.get('accountservice').validateEmail(createUsername)) {
            throw new Error('Email needs to be a valid email!');
          }
          if (createPassword.length < 7) {
            throw new Error('Password must be at least 7 characters');
          }

          this.createNewUser({
            accountId: objectId,
            password: createPassword,
            username: createUsername,
            isContributor: false
          }, account);

          this._closeAllAccounts();
        } catch (error) {
          this.get('notificationService').warning(error);
        }
      },
      toggleAddAccountsModal: function toggleAddAccountsModal(user) {
        this._closeAllAccounts();
        this.manageAccount(user);
      }
    },

    _closeAllAccounts: function _closeAllAccounts() {
      this.get('currentMultiAccountsArray').setEach('isSelected', false);
      this.get('currentMultiAccountsArray').setEach('isUserFormShowing', false);
    }
  });
});