define('uabide/services/notification-service', ['exports', 'ember-cli-notifications/services/notification-messages-service'], function (exports, _notificationMessagesService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _notificationMessagesService.default.extend({
    defaultClearDuration: 3000,
    defaultAutoClear: true,
    notificationBody: null,

    getNotificationBody: function getNotificationBody() {
      var notification = document.getElementById('displayTextNotification');
      if (notification) {
        this.set('notificationBody', notification);
      }
    },
    updateNotificationBodyText: function updateNotificationBodyText(text) {
      var notificationBody = this.get('notificationBody');
      notificationBody.textContent = text;
    },
    showDisplayTextNotification: function showDisplayTextNotification(text) {
      if (text) {
        this.getNotificationBody();
        if (this.get('notificationBody')) {
          this.updateNotificationBodyText(text);
        } else {
          this.displayTextSuccess(text);
        }
      } else {
        this.set('notificationBody', null);
      }
    },
    error: function error(message, op) {
      var options = getOptions(op);
      options.htmlContent = true;
      options.autoClear = Ember.isPresent(options.autoClear) ? options.autoClear : this.get('defaultAutoClear');
      options.cssClasses = 'c-alert error-notification notification';
      options.message = '<i class="c-alert__icon fa fa-times-circle"></i><div class="notificationMessage">' + message + '</div>';
      options.type = 'error';
      return this.addNotification(options);
    },
    success: function success(message, op) {
      var options = getOptions(op);
      options.htmlContent = true;
      options.autoClear = Ember.isPresent(options.autoClear) ? options.autoClear : this.get('defaultAutoClear');
      options.cssClasses = 'c-alert success-notification notification';
      options.message = '<i class="c-alert__icon fa fa-check-circle"></i><div class="notificationMessage">' + message + '</div>';
      options.type = 'success';
      return this.addNotification(options);
    },
    displayTextSuccess: function displayTextSuccess(message, op) {
      var options = getOptions(op);
      options.htmlContent = true;
      options.autoClear = false;
      options.cssClasses = 'c-alert success-notification notification';
      options.message = '<i class="c-alert__icon fa fa-check-circle"></i><div id="displayTextNotification" class="notificationMessage"">' + message + '</div>';
      options.type = 'success';
      return this.addNotification(options);
    },
    info: function info(message, op) {
      var options = getOptions(op);
      options.htmlContent = true;
      options.autoClear = Ember.isPresent(options.autoClear) ? options.autoClear : this.get('defaultAutoClear');
      options.cssClasses = 'c-alert info-notification notification';
      options.message = '<i class="c-alert__icon fa fa-info-circle"></i><div class="notificationMessage">' + message + '</div>';
      options.type = 'info';
      return this.addNotification(options);
    },
    warning: function warning(message, op) {
      var options = getOptions(op);
      options.htmlContent = true;
      options.autoClear = Ember.isPresent(options.autoClear) ? options.autoClear : this.get('defaultAutoClear');
      options.cssClasses = 'c-alert warning-notification notification';
      options.message = '<i class="c-alert__icon fa fa-exclamation-circle"></i><div class="notificationMessage">' + message + '</div>';
      options.type = 'warning';
      return this.addNotification(options);
    }
  });


  function getOptions(option) {
    return option ? option : {};
  }
});