define("uabide/helpers/set-active-type", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.setActiveType = setActiveType;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function setActiveType(params /*, hash*/) {
    var _params = _slicedToArray(params, 2),
        mode = _params[0],
        type = _params[1];

    var text;
    switch (mode) {
      case "dateDesc":
        if (type == "dateDesc") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;
      case "name":
        if (type == "name") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "sellerName":
        if (type == "sellerName") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "itemNumber":
        if (type == "itemNumber") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "priceNumber":
        if (type == "priceNumber") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "shoppingPlatform":
        if (type == "shoppingPlatform") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "numberOfListings":
        if (type == "numberOfListings") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "brand":
        if (type == "brand") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "numberOfSellers":
        if (type == "numberOfSellers") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "totalListings":
        if (type == "totalListings") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "userName":
        if (type == "userName") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "parentCompany":
        if (type == "parentCompany") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "status":
        if (type == "status") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "subStatus":
        if (type == "subStatus") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "lowerCaseShoppingPlatform":
        if (type == "lowerCaseShoppingPlatform") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "followUpDate":
        if (type == "followUpDate") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "lastContacted":
        if (type == "lastContacted") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "followUpEmailSent":
        if (type == "followUpEmailSent") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      case "type":
        if (type == "type") {
          text = "c-table__cell underlineClass c-table__cell--head";
        } else {
          text = "c-table__cell c-table__cell--head";
        }
        break;

      default:
        text = "c-table__cell c-table__cell--head";
        break;
    }
    return text;
  }

  exports.default = Ember.Helper.helper(setActiveType);
});