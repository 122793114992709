define('uabide/routes/map-clickup-dashboard', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        afterModel: function afterModel() {
            Ember.run.scheduleOnce('afterRender', this, this.setupChart);
        },
        setupChart: function setupChart() {

            var countDownToLaunch = document.getElementById('countDownToLaunch').getContext('2d');
            var countDownToLaunchChart = new Chart(countDownToLaunch, {
                type: 'doughnut',
                data: {
                    labels: ['Underway', 'Complete', 'To Do'],
                    datasets: [{
                        data: [3, 2, 6],
                        backgroundColor: ['#FF5722', '#51D34F', '#7C9DAF']
                    }]
                },
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            enabled: false
                        }
                    }
                }
            });

            var chatInput = document.querySelector(".chat-input textarea");
            var sendChatBtn = document.querySelector(".chat-input i");
            var chatBox = document.querySelector(".chatbox");

            var userMessage = void 0;

            var createChatLi = function createChatLi(message, className) {
                var chatLi = document.createElement("li");
                chatLi.classList.add("li", className);
                var chatContent = void 0;

                if (className === "chat-outgoing") {
                    chatContent = '<p>' + message + '</p>';
                } else {
                    chatContent = '<i class="fa fa-user-o"></i> <p>' + message + '</p>';
                }

                chatLi.innerHTML = chatContent;
                return chatLi;
            };

            var handleChat = function handleChat() {
                userMessage = chatInput.value.trim();
                if (!userMessage) return;

                chatBox.appendChild(createChatLi(userMessage, "chat-outgoing"));
                chatInput.value = "";

                setTimeout(function () {
                    chatBox.appendChild(createChatLi("Thinking...", "chat-incoming"));
                    chatBox.scrollTop = chatBox.scrollHeight;
                }, 600);
            };

            // sendChatBtn.addEventListener("click", handleChat);
            //
            // chatInput.addEventListener("keydown", (event) => {
            //   if (event.key === "Enter") {
            //     handleChat();
            //   }
            // });


            // const statusCells = document.querySelectorAll(".task-table .status-data");
            //
            // statusCells.forEach((cell) => {
            //
            //   const status = cell.textContent.trim();
            //
            //   switch (status) {
            //     case "TO DO":
            //       cell.style.backgroundColor = "#7C9DAF";
            //       break;
            //     case "UNDERWAY":
            //       cell.style.backgroundColor = "#FF5722";
            //       break;
            //     case "COMPLETE":
            //       cell.style.backgroundColor = "#51D34F";
            //       break;
            //     default:
            //       cell.style.backgroundColor = "RED";
            //       break;
            //   }
            // });

        }
    });
});