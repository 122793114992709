define('uabide/services/modal-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    modals: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('modals', []);
    },
    add: function add(modals) {
      this.get('modals').addObjects(modals);
    },
    remove: function remove(modals) {
      this.get('modals').removeObjects(modals);
    }
  });
});