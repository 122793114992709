define('uabide/routes/map/product-active-violations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    mapListingsService: Ember.inject.service(),

    afterModel: function afterModel(model, transition) {
      var mapProduct = this.get('mapListingsService.mapProductListing');
      if (!mapProduct) {
        this.replaceWith('map.products');
      }
    }
  });
});