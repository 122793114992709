define('uabide/controllers/assets', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  Parse.initialize("bzn8msayuASvFwWDJEY3DdGYDXb7eV9rweY2rr7o", "fRmOrMDotozW5iYypZ9s73yADQP3MiKmbIhQxXGw");
  Parse.serverURL = 'https://uabide.back4app.io/';
  var inject = Ember.inject;

  exports.default = Ember.Controller.extend({
    spinner: inject.service('spinner'),
    leadservice: inject.service('account-service'),
    parseconverter: inject.service('parse-converter'),
    accountcontroller: inject.service('account-controller'),
    listingservice: inject.service('listing-service'),
    vendorservice: inject.service('vendor-service'),
    assetservice: inject.service('assets-service'),
    notificationService: Ember.inject.service(),
    showListings: true,
    checkAll: false,
    showAllContent: true,
    accountName: "",
    isShowingFilterListingsModal: false,
    isShowingAddAssetModal: false,
    mySearchText: null,
    searchMode: "dateDesc",
    direction: "up",
    numberOfAssets: 0,
    value_active: "active",
    value_inactive: "inactive",
    currentButtonText: null,
    currentInputType: null,
    newAccountName: "",
    newAccountPartner: "",
    userEmail: "",
    userPassword: "",
    currentAccount: null,
    isShowingAddStartingUrlModal: false,
    keywords: "",
    shoppingPlatform: "",
    url: "",
    currentAsset: null,
    isShowingEditAssetModal: false,
    currentAssetKeywords: "",
    currentAssetShoppingPlatform: "",
    currentAssetUrl: "",
    platformList: [],
    isShowingAddShoppingPlatformModal: false,
    newShoppingPlatform: "",
    isShowingAddNewListingModal: false,
    nlStartingURLId: "",
    nlName: "",
    nlItemURL: "",
    nlItemNumber: "",
    nlImgURL: "",
    nlPrice: "",
    nlSellerName: "",
    nlSellerURL: "",
    nlShippingData: "",
    nlModel_number: "",
    nlBrand: "",
    nlCompany: "",
    nlEmail: "",
    nlPhone: "",
    nlAccountName: "",
    nlShoppingPlatform: "",
    actions: {
      logoutNow: function logoutNow() {
        var _this = this;

        var self = this;
        this.get('spinner').show('discoverySpinner');
        Parse.User.logOut().then(function () {
          self.get('spinner').hide('discoverySpinner');
          self.get('listingservice').clearAllNow();
          self.get('vendorservice').clearAllNow();
          _this.transitionToRoute('login');
          setTimeout(function () {
            location.reload();
          }, 500);
        });
      },
      startLoading: function startLoading() {
        var self = this;
        this.get('spinner').show('discoverySpinner');
        var today = new Date();
        var assetservice = this.get("assetservice");

        var assetResults = assetservice.get("assetResults");
        var assetStatus = assetservice.get("assetStatus");

        var mySearchText = this.get("mySearchText");
        var searchMode = this.get("searchMode");
        var direction = this.get('direction');
        var params = {};
        if (assetStatus == null) {
          if (mySearchText == null || mySearchText == "") {
            params = {
              lastPostDate: today,
              mode: searchMode,
              skip: assetResults.length,
              direction: direction
            };
          } else {
            params = {
              lastPostDate: today,
              mode: searchMode,
              searchText: mySearchText,
              skip: assetResults.length,
              direction: direction
            };
          }
        } else {
          if (mySearchText == null || mySearchText == "") {
            params = {
              lastPostDate: today,
              mode: searchMode,
              status: assetStatus,
              skip: assetResults.length,
              direction: direction
            };
          } else {
            params = {
              lastPostDate: today,
              mode: searchMode,
              status: assetStatus,
              searchText: mySearchText,
              skip: assetResults.length,
              direction: direction
            };
          }
        }

        Parse.Cloud.run("getCurrentAssets", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          var resultsArray = [];
          for (var i = 0; i < result.length; i++) {
            var item = result[i];
            var myAsset = self.get('parseconverter').convertToStartingURL(item);
            resultsArray.pushObject(myAsset);
          }
          self.get('assetservice').addAssetResults(resultsArray);

          var assetservice = self.get('assetservice');
          self.set('numberOfAssets', assetservice.assetResults.length);
          self.send("refreshThisRoute");
          self.send("loadAccount");
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("Loading Listing error -  " + error.message);
        });
      },
      loadMoreItems: function loadMoreItems() {
        var searchMode = this.get("searchMode");
        var numberDownloadedActivityResults = this.get('assetservice').get("assetResultsLastCallAmount");
        if (numberDownloadedActivityResults > 0) {
          var self = this;
          var historyResults = this.get('assetservice').get("assetResults");
          var lastResult = historyResults.slice(-1)[0];
          if (lastResult != undefined) {
            this.get('spinner').show('discoverySpinner');
            var currentDate = lastResult.createdAt;
            var assetservice = this.get("assetservice");
            var assetResults = assetservice.get("assetResults");
            var assetStatus = assetservice.get("assetStatus");
            var params = {};
            var direction = this.get('direction');
            var mySearchText = this.get("mySearchText");
            if (assetStatus == null) {
              if (mySearchText == null || mySearchText == "") {
                params = {
                  lastPostDate: currentDate,
                  mode: searchMode,
                  skip: assetResults.length,
                  direction: direction
                };
              } else {
                params = {
                  lastPostDate: currentDate,
                  mode: searchMode,
                  searchText: mySearchText,
                  skip: assetResults.length,
                  direction: direction
                };
              }
            } else {
              if (mySearchText == null || mySearchText == "") {
                params = {
                  lastPostDate: currentDate,
                  mode: searchMode,
                  status: assetStatus,
                  skip: assetResults.length,
                  direction: direction
                };
              } else {
                params = {
                  lastPostDate: currentDate,
                  mode: searchMode,
                  status: assetStatus,
                  searchText: mySearchText,
                  skip: assetResults.length,
                  direction: direction
                };
              }
            }

            Parse.Cloud.run("getCurrentAssets", params).then(function (result) {
              self.get('spinner').hide('discoverySpinner');
              var resultsArray = [];
              for (var i = 0; i < result.length; i++) {
                var item = result[i];
                var myAsset = self.get('parseconverter').convertToStartingURL(item);
                resultsArray.pushObject(myAsset);
              }
              self.get('assetservice').addAssetResults(resultsArray);
              var assetservice = self.get('assetservice');
              self.set('numberOfAssets', assetservice.assetResults.length);
              self.send("refreshThisRoute");
            }, function (error) {
              self.get('spinner').hide('discoverySpinner');
              self.get('notificationService').error("Loading Sku error -  " + error.message);
            });
          }
        }
      },
      toggleCheckBoxListing: function toggleCheckBoxListing(item) {
        this.get('assetservice').addOrRemoveItemFromSelectedItem(item);
      },
      showFiltersNow: function showFiltersNow() {
        this.send("toggleListingFilterModal");
      },
      updateCheckAll: function updateCheckAll() {
        var checkAll = this.get('checkAll');
        if (checkAll) {
          this.set('checkAll', false);
          this.get('assetservice').removeAllSelectedAccountsNow();
          this.send("refreshThisRoute");
        } else {
          this.set('checkAll', true);
          this.get('assetservice').addAllAccountsToSelectedNow();
          this.send("refreshThisRoute");
        }
      },
      showDetailScreen: function showDetailScreen() {},
      runScansForAssets: function runScansForAssets() {
        this.set('showAllContent', false);
        // this.send("refreshThisRoute");
        // // this.get('accountcontroller').setIsAdminFalse();
        // var selectedAccounts = this.get('leadservice').get('selectedAccounts');
        // console.log("setSelectedListingsToValue " + value);
        // console.log("setSelectedListings length " + selectedAccounts.length);
        //
        // var self = this;
        // if (selectedAccounts.length > 0) {
        //
        //   var itemToHide = selectedAccounts[0];
        //   var params = {accountId: itemToHide.objectId, status: value};
        //
        //   this.get('spinner').show('discoverySpinner');
        //   Parse.Cloud.run("updateAccountStatusNow", params).then(function (result) {
        //     self.get('spinner').hide('discoverySpinner');
        //     var myAccount = self.get('parseconverter').convertToAccount(result);
        //     self.get('leadservice').updateItemInSelectedArray(myAccount);
        //     self.set('showAllContent', true);
        //     self.send("refreshThisRoute");
        //     self.send("setSelectedListingsToValue", value);
        //   }, function (error) {
        //     self.get('spinner').hide('discoverySpinner');
        //     self.get('notificationService').error("Error Hiding Sku -  " + error.message);
        //     // self.get('accountcontroller').setIsAdmin();
        //
        //     self.set('checkAll', false);
        //     self.set('showAllContent', true);
        //     self.send("refreshThisRoute");
        //   });
        //
        // } else {
        //   // this.get('accountcontroller').setIsAdmin();
        //   self.set('checkAll', false);
        //   this.set('showAllContent', true);
        //   this.send("refreshThisRoute");
        //   console.log("finished updating all items ");
        // }
        this.get('assetservice').clearAllSelected();
        var self = this;
        setTimeout(function () {
          self.set('showAllContent', true);
        }, 500);
      },


      toggleListingFilterModal: function toggleListingFilterModal() {
        this.toggleProperty('isShowingFilterListingsModal');
      },
      toggleAddAccount: function toggleAddAccount() {
        this.toggleProperty('isShowingAddAssetModal');
      },
      getAccountsForAdmin: function getAccountsForAdmin() {

        this.toggleProperty('isShowingAddAssetModal');
        this.get('accountcontroller').clearAccounts();
        var currentAccounts = this.get('accountcontroller').currentAccounts;
        var self = this;
        var params = { skip: currentAccounts.length };
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("getAccountsForAdmin", params).then(function (results) {
          self.get('spinner').hide('discoverySpinner');
          if (results.length > 0) {
            var resultsArray = [];
            for (var i = 0; i < results.length; i++) {
              var item = results[i];
              var account = self.get('parseconverter').convertToAccount(item);
              resultsArray.pushObject(account);
            }
            self.get('accountcontroller').addAccountResults(resultsArray);
            self.send("getMoreAccountsForAdmin");
          } else {
            self.send("refreshThisRoute");
          }
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("error getting accounts -  " + error.message);
        });
      },
      refreshAssets: function refreshAssets() {
        var assetservice = this.get("assetservice");
        if (assetservice.assetResults.length == 0) {
          this.send("startLoading");
        }
        this.get('assetservice').clearAllNow();
      },
      addNewShoppingPlatform: function addNewShoppingPlatform() {
        this.set("newShoppingPlatform", "");
        this.toggleProperty('isShowingAddShoppingPlatformModal');
      },
      saveShoppingPlatform: function saveShoppingPlatform() {
        var self = this;
        var newShoppingPlatform = this.get("newShoppingPlatform");
        var params = { shoppingPlatform: newShoppingPlatform };
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("createNewshoppingPlatform", params).then(function (results) {
          self.get('spinner').hide('discoverySpinner');
          self.toggleProperty('isShowingAddShoppingPlatformModal');
          self.get('notificationService').success(results);
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("error gsaving shopping platform -  " + error.message);
        });
      },
      addNewListingPopUp: function addNewListingPopUp(startingURLId, nlAccountName, nlShoppingPlatform) {
        this.set("nlStartingURLId", startingURLId);
        this.set("nlAccountName", nlAccountName);
        this.set("nlShoppingPlatform", nlShoppingPlatform);

        this.set("nlName", "");
        this.set("nlItemURL", "");
        this.set("nlItemNumber", "");
        this.set("nlImgURL", "");
        this.set("nlPrice", "");
        this.set("nlSellerName", "");
        this.set("nlSellerURL", "");
        this.set("nlShippingData", "");
        this.set("nlModel_number", "");
        this.set("nlBrand", "");
        this.set("nlCompany", "");
        this.set("nlEmail", "");
        this.set("nlPhone", "");
        this.toggleProperty('isShowingAddNewListingModal');
      },

      toggleShowingAddNewListingModal: function toggleShowingAddNewListingModal() {
        this.toggleProperty('isShowingAddNewListingModal');
      },
      cancelNewListing: function cancelNewListing() {
        this.toggleProperty('isShowingAddNewListingModal');
        this.set("nlStartingURLId", "");
        this.set("nlName", "");
        this.set("nlItemURL", "");
        this.set("nlItemNumber", "");
        this.set("nlImgURL", "");
        this.set("nlPrice", "");
        this.set("nlSellerName", "");
        this.set("nlSellerURL", "");
        this.set("nlShippingData", "");
        this.set("nlModel_number", "");
        this.set("nlBrand", "");
        this.set("nlCompany", "");
        this.set("nlEmail", "");
        this.set("nlPhone", "");
        this.set("nlAccountName", "");
        this.set("nlShoppingPlatform", "");
      },
      createNewListing: function createNewListing() {
        var self = this;
        var nlStartingURLId = this.get("nlStartingURLId");
        var nlName = this.get("nlName");
        var nlItemURL = this.get("nlItemURL");
        var nlItemNumber = this.get("nlItemNumber");
        var nlImgURL = this.get("nlImgURL");
        var nlPrice = this.get("nlPrice");
        var nlSellerName = this.get("nlSellerName");
        var nlSellerURL = this.get("nlSellerURL");
        var nlShippingData = this.get("nlShippingData");
        var nlModel_number = this.get("nlModel_number");
        var nlBrand = this.get("nlBrand");
        var nlCompany = this.get("nlCompany");
        var nlEmail = this.get("nlEmail");
        var nlPhone = this.get("nlPhone");

        if (nlStartingURLId == undefined || nlStartingURLId == "" || nlStartingURLId == null) {
          this.get('notificationService').warning("Missing Asset ObjectId ");
          return;
        }
        if (nlName == undefined || nlName == "" || nlName == null) {
          this.get('notificationService').warning("Missing Name ");
          return;
        }
        if (nlItemURL == undefined || nlItemURL == "" || nlItemURL == null) {
          this.get('notificationService').warning("Missing Item URL ");
          return;
        }
        if (nlItemNumber == undefined || nlItemNumber == "" || nlItemNumber == null) {
          this.get('notificationService').warning("Missing Item Number ");
          return;
        }
        if (nlSellerName == undefined || nlSellerName == "" || nlSellerName == null) {
          this.get('notificationService').warning("Missing Seller Name ");
          return;
        }
        if (nlSellerURL == undefined || nlSellerURL == "" || nlSellerURL == null) {
          this.get('notificationService').warning("Missing Seller URL ");
          return;
        }
        if (nlImgURL == undefined || nlImgURL == "" || nlSellerURL == null) {
          this.get('notificationService').warning("Missing Image URL ");
          return;
        }
        if (nlPrice == undefined || nlPrice == "" || nlPrice == null) {
          this.get('notificationService').warning("Missing Price ");
          return;
        }
        if (nlBrand == undefined || nlBrand == "" || nlBrand == null) {
          this.get('notificationService').warning("Missing Brand ");
          return;
        }
        if (nlImgURL == undefined || nlImgURL == null) {
          nlImgURL = "";
        }
        if (nlPrice == undefined || nlPrice == null) {
          nlPrice = "";
        }
        if (nlShippingData == undefined || nlShippingData == null) {
          nlShippingData = "";
        }
        if (nlModel_number == undefined || nlModel_number == null) {
          nlModel_number = "";
        }
        if (nlCompany == undefined || nlCompany == null) {
          nlCompany = "";
        }
        if (nlEmail == undefined || nlEmail == null) {
          nlEmail = "";
        }
        if (nlPhone == undefined || nlPhone == null) {
          nlPhone = "";
        }

        nlStartingURLId = nlStartingURLId.replace(/(\r\n|\n|\r)/gm, "");
        nlName = nlName.replace(/(\r\n|\n|\r)/gm, "");
        nlItemURL = nlItemURL.replace(/(\r\n|\n|\r)/gm, "");
        nlItemNumber = nlItemNumber.replace(/(\r\n|\n|\r)/gm, "");
        nlImgURL = nlImgURL.replace(/(\r\n|\n|\r)/gm, "");
        nlPrice = nlPrice.replace(/(\r\n|\n|\r)/gm, "");
        nlSellerName = nlSellerName.replace(/(\r\n|\n|\r)/gm, "");
        nlSellerURL = nlSellerURL.replace(/(\r\n|\n|\r)/gm, "");
        nlShippingData = nlShippingData.replace(/(\r\n|\n|\r)/gm, "");
        nlModel_number = nlModel_number.replace(/(\r\n|\n|\r)/gm, "");
        nlBrand = nlBrand.replace(/(\r\n|\n|\r)/gm, "");
        nlCompany = nlCompany.replace(/(\r\n|\n|\r)/gm, "");
        nlEmail = nlEmail.replace(/(\r\n|\n|\r)/gm, "");
        nlPhone = nlPhone.replace(/(\r\n|\n|\r)/gm, "");

        var params = {
          startingURLId: nlStartingURLId,
          name: nlName,
          itemURL: nlItemURL,
          itemNumber: nlItemNumber,
          imgURL: nlImgURL,
          price: nlPrice,
          sellerName: nlSellerName,
          sellerURL: nlSellerURL,
          shippingData: nlShippingData,
          model_number: nlModel_number,
          brand: nlBrand,
          company: nlCompany,
          email: nlEmail,
          phone: nlPhone
        };

        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("importListingDataWithLogicV3", params).then(function (results) {
          self.get('spinner').hide('discoverySpinner');
          self.set("nlName", "");
          self.set("nlItemURL", "");
          self.set("nlItemNumber", "");
          self.set("nlImgURL", "");
          self.set("nlPrice", "");
          self.set("nlSellerName", "");
          self.set("nlSellerURL", "");
          self.set("nlShippingData", "");
          self.set("nlModel_number", "");
          self.set("nlBrand", "");
          self.set("nlCompany", "");
          self.set("nlEmail", "");
          self.set("nlPhone", "");
          self.get('notificationService').success(results);
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("listing upload error -  " + error.message);
        });
      },


      //importListingDataWithLogicV3
      getMoreAccountsForAdmin: function getMoreAccountsForAdmin() {
        var currentAccounts = this.get('accountcontroller').currentAccounts;
        var self = this;
        var params = { skip: currentAccounts.length };
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("getAccountsForAdmin", params).then(function (results) {
          self.get('spinner').hide('discoverySpinner');
          if (results.length > 0) {
            var resultsArray = [];
            for (var i = 0; i < results.length; i++) {
              var item = results[i];
              var account = self.get('parseconverter').convertToAccount(item);
              resultsArray.pushObject(account);
            }
            self.get('accountcontroller').addAccountResults(resultsArray);
            self.send("getMoreAccountsForAdmin");
          } else {
            self.send("refreshThisRoute");
          }
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("error getting vendors -  " + error.message);
        });
      },
      addAssetNow: function addAssetNow() {
        this.toggleProperty('isShowingAddAssetModal');
        // var newAccountName = this.get('newAccountName');
        // var newAccountPartner = this.get('newAccountPartner');
        // var userEmail = this.get('userEmail');
        // var userPassword = this.get('userPassword');
        // if (userPassword == "") {
        //   return this.get('notificationService').warning("Password needs to be filled out");
        // }
        // if (userEmail == "") {
        //   return this.get('notificationService').warning("Email needs to be filled out");
        // }
        // if (newAccountName == "") {
        //   return this.get('notificationService').warning("Account name needs to be filled out");
        // }
        // if (!this.get('leadservice').validateEmail(userEmail)) {
        //   return this.get('notificationService').warning("Email needs to be a valid email!");
        // }
        // var params = {
        //   newAccountName: newAccountName,
        //   newAccountPartner: newAccountPartner,
        //   userEmail: userEmail,
        //   userPassword: userPassword
        // };
        // var self = this;
        // this.get('spinner').show('discoverySpinner');
        // Parse.Cloud.run("createAccountAndUser", params).then(function (result) {
        //   self.get('spinner').hide('discoverySpinner');
        //   var newAccount = self.get('parseconverter').convertToAccount(result);
        //   self.get('leadservice').addNewAccount(newAccount);
        //   self.set('newAccountName', "");
        //   self.set('newAccountPartner', "");
        //   self.set('userEmail', "");
        //   self.set('userPassword', "");
        //   self.send("refreshThisRoute");
        // }, function (error) {
        //   self.get('spinner').hide('discoverySpinner');
        //   self.get('notificationService').error("Loading Sku error -  " + error.message);
        // });
      },
      updateListingSellerInfo: function updateListingSellerInfo() {},
      filterStatusBy: function filterStatusBy(status) {

        // this.get("leadservice").set("accountStatus", status);
        //
        // var leadservice = this.get("leadservice");
        // if (leadservice.accountResults.length == 0) {
        //   this.send("startLoading");
        // }
        // this.get('leadservice').clearAllNow();

      },
      searchText: function searchText(text) {
        this.set("mySearchText", text);
        var assetservice = this.get("assetservice");
        if (assetservice.assetResults.length == 0) {
          this.send("startLoading");
        }
        this.get('assetservice').clearAllNow();
      },
      sortBy: function sortBy(type) {
        var searchMode = this.get('searchMode');
        var direction = this.get('direction');
        if (searchMode == type) {
          if (direction == "up") {
            this.set('direction', "down");
          } else {
            this.set('direction', "up");
          }
        } else {
          this.set('direction', "up");
        }
        this.set('searchMode', type);
        var leadservice = this.get("assetservice");
        if (leadservice.assetResults.length == 0) {
          this.send("startLoading");
        }
        this.get('assetservice').clearAllNow();
      },
      emptyMethod: function emptyMethod() {},
      updateCrawlers: function updateCrawlers(skip) {
        if (skip == "done") {
          return;
        }
        var self = this;
        this.get('spinner').show('discoverySpinner');
        var params = {
          skip: skip
        };
        Parse.Cloud.run("updateCrawlerDatabase", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          if (result == undefined) {
            return;
          }
          self.send('updateCrawlers', result);
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("Loading crawler error -  " + error.message);
        });
      },
      createStartingUrlForAccount: function createStartingUrlForAccount(account) {
        this.set('currentAccount', account);
        var self = this;
        var params = {};
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("getPlatformNames", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          var resultsArray = [];
          for (var i = 0; i < result.length; i++) {
            var item = result[i];
            var shoppingPlatform = self.get('parseconverter').convertToShoppingPlatform(item);
            resultsArray.pushObject(shoppingPlatform);
          }
          self.set('platformList', resultsArray);
          self.toggleProperty('isShowingAddAssetModal');
          self.toggleProperty('isShowingAddStartingUrlModal');
          self.send("refreshThisRoute");
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("Error getting partner names -  " + error.message);
        });
      },
      toggleAddStartingUrl: function toggleAddStartingUrl() {
        this.toggleProperty('isShowingAddStartingUrlModal');
      },
      setShoppingPlatformToValue: function setShoppingPlatformToValue(value) {
        this.set('shoppingPlatform', value);
      },
      setShoppingPlatformToValueEdit: function setShoppingPlatformToValueEdit(value) {
        this.set('currentAssetShoppingPlatform', value);
      },
      addNewStartingUrl: function addNewStartingUrl() {
        this.toggleProperty('isShowingAddStartingUrlModal');
        var keywords = this.get('keywords');
        var shoppingPlatform = this.get('shoppingPlatform');
        var url = this.get('url');
        var currentAccount = this.get('currentAccount');

        if (keywords == "") {
          return this.get('notificationService').warning("keywords needs to be filled out");
        }
        if (shoppingPlatform == "") {
          return this.get('notificationService').warning("shoppingPlatform needs to be filled out");
        }
        if (url == "") {
          return this.get('notificationService').warning("url needs to be filled out");
        }

        var params = {
          keywords: keywords,
          shoppingPlatform: shoppingPlatform,
          url: url,
          accountId: currentAccount.objectId
        };
        var self = this;
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("createStartingUrlForSite", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          self.set('keywords', "");
          self.set('shoppingPlatform', "");
          self.set('url', "");
          self.get('assetservice').clearAllNow();
          self.send("refreshThisRoute");
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("Loading Sku error -  " + error.message);
        });
      },
      editAsset: function editAsset(asset) {

        var self = this;
        var params = {};
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("getPlatformNames", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          var resultsArray = [];
          for (var i = 0; i < result.length; i++) {
            var item = result[i];
            var shoppingPlatform = self.get('parseconverter').convertToShoppingPlatform(item);
            resultsArray.pushObject(shoppingPlatform);
          }
          self.set('platformList', resultsArray);
          self.set('currentAsset', asset);
          self.set('currentAssetKeywords', asset.keywords);
          self.set('currentAssetShoppingPlatform', asset.shoppingPlatform);
          self.set('currentAssetUrl', asset.url);
          self.toggleProperty('isShowingEditAssetModal');
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("Error getting partner names -  " + error.message);
        });
      },

      toggleShowingEditAssetModal: function toggleShowingEditAssetModal() {
        this.toggleProperty('isShowingEditAssetModal');
      },
      toggleShowingAddShoppingPlatformModal: function toggleShowingAddShoppingPlatformModal() {
        this.toggleProperty('isShowingAddShoppingPlatformModal');
      },
      updateAssetInfo: function updateAssetInfo() {
        var asset = this.get('currentAsset');
        var keywords = this.get('currentAssetKeywords');
        var shoppingPlatform = this.get('currentAssetShoppingPlatform');
        var url = this.get('currentAssetUrl');

        var params = {
          startingURLObjectId: asset.objectId,
          shoppingPlatform: shoppingPlatform,
          url: url,
          keywords: keywords
        };
        var self = this;
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("uopdateStartingUrl", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          self.toggleProperty('isShowingEditAssetModal');
          self.set('currentAsset', null);
          self.set('currentAssetKeywords', "");
          self.set('currentAssetShoppingPlatform', "");
          self.set('currentAssetUrl', "");
          var myAsset = self.get('parseconverter').convertToStartingURL(result);
          self.get('assetservice').updateItemInAssetArray(myAsset);
          self.send("refreshThisRoute");
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("uopdateStartingUrl error -  " + error.message);
        });
      },
      loadAccount: function loadAccount() {
        var self = this;
        var params = {};
        Parse.Cloud.run("getCurrentAccount", params).then(function (results) {
          if (results.length > 0) {
            var item = results[0];
            var myAccount = self.get('parseconverter').convertToAccount(item);
            self.get('accountcontroller').setCurrentAccount(myAccount);
            self.send("refreshThisRoute");
          }
        }, function (error) {
          if (error.code == 209) {
            //self.send("logoutNow");
            self.get('notificationService').error("Please logout and logback in - token expired");
          } else {
            self.get('notificationService').error("Loading Listing error -  " + error.message);
          }
        });
      },

      //isShowingEditAssetModal
      transitionToMAPProtection: function transitionToMAPProtection() {
        this.transitionToRoute('map');
        Ember.run.later(function () {
          location.reload();
        }, 500);
      }
    }
  });
});