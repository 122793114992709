define('uabide/services/lead-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var inject = Ember.inject;

  exports.default = Ember.Service.extend({
    parseconverter: inject.service('parse-converter'),
    accountResults: [],
    accountResultsLastCallAmount: 0,
    selectedAccounts: [],
    accountStatus: "showAll",
    init: function init() {
      this._super();
    },

    addAccountResults: function addAccountResults(newResults) {
      this.set('accountResultsLastCallAmount', newResults.length);
      this.get('accountResults').pushObjects(newResults);
    },
    addOrRemoveItemFromSelectedItem: function addOrRemoveItemFromSelectedItem(item) {
      var indexSkuResults = this.get('selectedAccounts');
      var a = indexSkuResults.indexOf(item);
      if (a == -1) {
        this.get('selectedAccounts').pushObject(item);
      } else {
        this.get('selectedAccounts').removeObject(item);
      }
      console.log(indexSkuResults.length);
    },
    removeAllSelectedAccountsNow: function removeAllSelectedAccountsNow() {
      this.set('selectedAccounts', []);
    },
    addAllAccountsToSelectedNow: function addAllAccountsToSelectedNow() {
      var accountResults = this.get('accountResults');
      this.set('selectedAccounts', []);
      for (var i = 0; i < accountResults.length; i++) {
        var item = accountResults[i];
        this.get('selectedAccounts').pushObject(item);
      }
    },
    updateItemInSelectedArray: function updateItemInSelectedArray(newItem) {
      var accountResults = this.get("accountResults");
      for (var i = 0; i < accountResults.length; i++) {
        var item = accountResults[i];
        if (newItem.objectId == item.objectId) {
          accountResults[i] = newItem;
          this.set('accountResults', accountResults);
          this.get('selectedAccounts').removeObject(item);
          console.log("updating accounts now with new item");
        }
      }
    },
    updateItemInArray: function updateItemInArray(newItem) {
      var accountResults = this.get("accountResults");
      for (var i = 0; i < accountResults.length; i++) {
        var item = accountResults[i];
        if (newItem.objectId == item.objectId) {
          accountResults[i] = newItem;
          this.set('accountResults', accountResults);
        }
      }
    },
    clearAllNow: function clearAllNow() {
      this.set('accountResultsLastCallAmount', 0);
      this.set('accountResults', []);
      this.set('selectedAccounts', []);
    },

    addNewAccount: function addNewAccount(newAccount) {
      var accountArray = [newAccount];
      this.get('accountResults').pushObjects(accountArray);
    },
    validateEmail: function validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  });
});