define('uabide/helpers/set-filter-name-text', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.setFilterNameText = setFilterNameText;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function setFilterNameText(params) {
    var _params = _slicedToArray(params, 1),
        filter = _params[0];

    var text = filter ? filter.toLowerCase() : '';

    switch (text) {
      case 'searchtext':
        text = 'Search';
        break;
      case 'mySearchtext':
        text = 'Search';
        break;
      case 'umap':
        text = 'Filter By UMAP';
        break;
      case 'amazontype':
        text = 'Filter By Amazon Type';
        break;
      case 'mapproductid':
        text = 'Filter By Product Id';
        break;
      case 'mapsellerid':
        text = 'Filter By Account Id';
        break;
      case 'iscurrent':
        text = 'Filter By IsCurrent';
        break;
      case 'sideload':
        text = 'Filter By Side Load';
        break;
      case 'global':
        text = 'Filter By Brand Globally';
        break;
      case 'local':
        text = 'Filter By Brand Locally';
        break;
      case 'numberoflistings':
        text = 'Filter By Number Of Listings';
        break;
      case 'daysold':
        text = 'Filter By Days Old';
        break;
      case 'hasemail':
        text = 'Filter By Has Email';
        break;
      case 'haspriority':
        text = 'Filter By Has Priority';
        break;
      case 'hasreplied':
        text = 'Filter By Has Replied';
        break;
      case 'hasdonotcontact':
        text = 'Filter By Has Do Not Contact';
        break;
      case 'productstate':
        text = 'Filter By Product State';
        break;
      case 'unknown':
        text = 'Filter By Unknown';
        break;
      case 'closeddate':
        text = 'Filter By Closed Date';
        break;
      case 'isbuyboxwinner':
        text = 'Filter By BuyBox Winner';
        break;
      default:
        text = 'Filter By ' + text;
    }
    return text;
  }

  exports.default = Ember.Helper.helper(setFilterNameText);
});