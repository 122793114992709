define('uabide/components/modals/turn-on-off-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentRadioValue: null,

    didUpdateAttrs: function didUpdateAttrs() {
      if (Ember.isPresent(this.value) && this.get('currentRadioValue') === null) {
        this.set('currentRadioValue', this.value);
      }
      if (!this.isOpen) {
        this.set('currentRadioValue', null);
      }
    },


    actions: {
      confirm: function confirm() {
        var currentRadioValue = this.get('currentRadioValue');
        if (currentRadioValue !== this.value) {
          this.confirm(currentRadioValue);
        }
        this.toggleModal();
      },
      toggleRadio: function toggleRadio(value) {
        this.set('currentRadioValue', value);
      }
    }
  });
});