define('uabide/services/parse-converter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  Parse.initialize("bzn8msayuASvFwWDJEY3DdGYDXb7eV9rweY2rr7o", "fRmOrMDotozW5iYypZ9s73yADQP3MiKmbIhQxXGw");
  Parse.serverURL = 'https://uabide.back4app.io/';

  exports.default = Ember.Service.extend({
    parseService: Ember.inject.service(),
    accountcontroller: Ember.inject.service('account-controller'),
    convertToListing: function convertToListing(item) {
      // var str = JSON.stringify(item);
      // console.log("converting " + str);

      var myListing = {};
      var thisListing = item;

      var account = thisListing.get("account");
      if (account == undefined) {
        myListing.account = {};
      } else {
        myListing.account = this.convertToAccount(account);
      }
      var name = thisListing.get("name");
      if (name == undefined) {
        myListing.name = "";
      } else {
        myListing.name = name;
      }

      var description = thisListing.get("description");
      if (description == undefined) {
        myListing.description = "";
      } else {
        myListing.description = description;
      }

      var itemURL = thisListing.get("itemURL");
      if (itemURL == undefined) {
        myListing.itemURL = "";
      } else {
        if (myListing.account.disableListingLinks) {
          myListing.itemURL = "donotclick-" + fixBrokenUrls(itemURL);
        } else {
          myListing.itemURL = fixBrokenUrls(itemURL);
        }
      }

      var price = thisListing.get("price");
      if (price == undefined || price == null) {
        myListing.price = "0";
      } else {
        myListing.price = price;
      }

      var sellerName = thisListing.get("sellerName");
      if (sellerName == undefined) {
        myListing.sellerName = "";
      } else {
        myListing.sellerName = sellerName;
      }

      var sellerURL = thisListing.get("sellerURL");
      if (sellerURL == undefined) {
        myListing.sellerURL = "";
      } else {
        if (myListing.account.disableListingLinks) {
          myListing.sellerURL = "donotclick-" + fixBrokenUrls(sellerURL);
        } else {
          myListing.sellerURL = fixBrokenUrls(sellerURL);
        }
      }

      var accountId = thisListing.get("accountId");
      if (accountId == undefined) {
        myListing.accountId = "";
      } else {
        myListing.accountId = accountId;
      }

      var createdAt = thisListing.createdAt;
      var firstSeen = thisListing.get("firstSeen");
      //console.log("firstSeen is ", firstSeen);
      //console.log("accountId is ", myListing.accountId);
      if (createdAt == undefined) {
        myListing.createdAt = "";
        myListing.shortDate = '';
      } else {
        if (myListing.accountId == "WjkRlgyytL" && firstSeen != undefined) {
          myListing.createdAt = firstSeen;
          var shortDate = myListing.createdAt.toLocaleDateString();
          myListing.shortDate = shortDate;
        } else {
          myListing.createdAt = createdAt;
          var shortDate = thisListing.createdAt.toLocaleDateString();
          myListing.shortDate = shortDate;
        }
      }

      var updatedAt = thisListing.updatedAt;
      if (updatedAt == undefined) {
        myListing.updatedAt = "";
        myListing.shortDateUpdatedAt = '';
      } else {
        myListing.updatedAt = updatedAt;
        var shortDateUpdatedAt = thisListing.updatedAt.toLocaleDateString();
        myListing.shortDateUpdatedAt = shortDate;
      }

      var objectId = thisListing.id;
      if (objectId == undefined) {
        myListing.objectId = "";
      } else {
        myListing.objectId = objectId;
      }

      var location = thisListing.get("location");
      if (location == undefined) {
        myListing.location = "";
      } else {
        myListing.location = location;
      }

      var itemNumber = thisListing.get("itemNumber");
      if (itemNumber == undefined) {
        myListing.itemNumber = false;
      } else {
        myListing.itemNumber = itemNumber;
      }

      var imgURL = thisListing.get("imgURL");
      if (imgURL == undefined) {
        myListing.imgURL = "";
      } else {

        if (myListing.account.disableListingLinks) {
          myListing.imgURL = "donotclick-" + imgURL;
        } else {
          myListing.imgURL = imgURL;
        }
      }

      var quantityAvailable = thisListing.get("quantityAvailable");
      if (quantityAvailable == undefined) {
        myListing.quantityAvailable = 0;
      } else {
        myListing.quantityAvailable = quantityAvailable;
      }

      var quantitySold = thisListing.get("quantitySold");
      if (quantitySold == undefined) {
        myListing.quantitySold = 0;
      } else {
        myListing.quantitySold = quantitySold;
      }

      var productId = thisListing.get("productId");
      if (productId == undefined) {
        myListing.productId = "";
      } else {
        myListing.productId = productId;
      }

      var accountName = thisListing.get("accountName");
      if (accountName == undefined) {
        myListing.accountName = "";
      } else {
        myListing.accountName = accountName;
      }

      var asset = thisListing.get("asset");
      if (asset == undefined) {
        myListing.asset = "";
      } else {
        myListing.asset = asset;
      }

      var rawPrice = thisListing.get("rawPrice");
      if (rawPrice == undefined) {
        myListing.rawPrice = 0;
      } else {
        myListing.rawPrice = rawPrice;
      }

      var Unenforceable = thisListing.get("Unenforceable");
      if (Unenforceable == undefined) {
        myListing.Unenforceable = false;
      } else {
        myListing.Unenforceable = true;
      }

      var priceNumber = thisListing.get("priceNumber");
      if (priceNumber == undefined || priceNumber == null || isNaN(priceNumber)) {
        myListing.priceNumber = 0;
      } else {
        myListing.priceNumber = priceNumber;
      }
      var shoppingPlatform = thisListing.get("shoppingPlatform");
      if (shoppingPlatform == undefined) {
        myListing.shoppingPlatform = "";
      } else {
        myListing.shoppingPlatform = shoppingPlatform;
      }

      var keyword = thisListing.get("keyword");
      if (keyword == undefined) {
        myListing.keyword = "";
      } else {
        myListing.keyword = keyword;
      }

      var status = thisListing.get("status");
      if (status == undefined) {
        myListing.status = "";
      } else {
        myListing.status = status;
      }

      var condition = thisListing.get("condition");
      if (condition == undefined) {
        myListing.condition = "";
      } else {
        myListing.condition = condition;
      }

      var subStatus = thisListing.get("subStatus");
      if (subStatus == undefined) {
        myListing.subStatus = "";
      } else {
        myListing.subStatus = subStatus;
      }

      var usdPrice = thisListing.get("usdPrice");
      if (usdPrice == undefined) {
        myListing.usdPrice = 0;
      } else {
        myListing.usdPrice = usdPrice;
      }

      var accountcontroller = this.get("accountcontroller");

      if (Unenforceable && accountcontroller.isAdmin) {
        myListing.subStatus = myListing.subStatus + " Unenforceable";
      }

      var shippingData = thisListing.get("shippingData");
      if (shippingData == undefined) {
        myListing.shippingData = "";
      } else {
        myListing.shippingData = shippingData;
      }

      var shippingPrice = thisListing.get("shippingPrice");
      if (shippingPrice == undefined) {
        myListing.shippingPrice = "";
      } else {
        myListing.shippingPrice = shippingPrice;
      }

      var isBuyboxWinner = thisListing.get("isBuyboxWinner");
      if (isBuyboxWinner == undefined) {
        myListing.isBuyboxWinner = "";
      } else {
        if (isBuyboxWinner) {
          myListing.isBuyboxWinner = "True";
        } else {
          myListing.isBuyboxWinner = "";
        }
      }

      var buyBoxDate = thisListing.get("buyBoxDate");
      if (buyBoxDate == undefined) {
        myListing.buyBoxDate = "";
        myListing.buyBoxDateShort = "";
      } else {
        myListing.buyBoxDate = buyBoxDate;
        myListing.buyBoxDateShort = buyBoxDate.toLocaleDateString();
      }

      var lastSeen = thisListing.get("lastSeen");
      if (lastSeen == undefined) {
        myListing.lastSeen = "";
        myListing.lastSeenShort = "";
      } else {
        myListing.lastSeen = lastSeen;
        myListing.lastSeenShort = lastSeen.toLocaleDateString();
      }

      if (lastSeen == undefined) {
        myListing.time = "";
      } else {
        var hours = lastSeen.getHours();
        var minutes = lastSeen.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        hours = hours <= 9 ? '0' + hours : '' + hours;
        minutes = minutes <= 9 ? '0' + minutes : '' + minutes;
        myListing.time = hours + ':' + minutes + ampm;
      }

      var vendor = thisListing.get("vendor");
      if (vendor == undefined) {
        myListing.vendor = {};
      } else {
        myListing.vendor = this.convertToVendor(vendor);
      }

      var matchCertainty = thisListing.get("matchCertainty");
      if (matchCertainty == undefined) {
        myListing.matchCertainty = "0";
      } else {
        myListing.matchCertainty = matchCertainty;
      }

      var matchImageUrl = thisListing.get("matchImageUrl");
      if (matchImageUrl == undefined) {
        myListing.matchImageUrl = "";
      } else {
        myListing.matchImageUrl = matchImageUrl;
      }

      var matchProductUrl = thisListing.get("matchProductUrl");
      if (matchProductUrl == undefined) {
        myListing.matchProductUrl = "";
      } else {
        myListing.matchProductUrl = matchProductUrl;
      }

      var matchProductSku = thisListing.get("matchProductSku");
      if (matchProductSku == undefined) {
        myListing.matchProductSku = "";
      } else {
        myListing.matchProductSku = matchProductSku;
      }

      var matchProductName = thisListing.get("matchProductName");
      if (matchProductName == undefined) {
        myListing.matchProductName = "";
      } else {
        myListing.matchProductName = matchProductName;
      }

      var isCounterfeit = thisListing.get("isCounterfeit");
      if (isCounterfeit == undefined) {
        myListing.isCounterfeit = false;
      } else {
        myListing.isCounterfeit = isCounterfeit;
      }

      var vendorId = thisListing.get("vendorId");
      if (vendorId == undefined) {
        myListing.vendorId = "";
      } else {
        myListing.vendorId = vendorId;
      }

      var model_number = thisListing.get("model_number");
      if (model_number == undefined) {
        myListing.model_number = "";
      } else {
        myListing.model_number = model_number;
      }

      var brand = thisListing.get("brand");
      if (brand == undefined) {
        myListing.brand = "";
      } else {
        myListing.brand = brand;
      }

      var imageArray = thisListing.get("imageArray");
      if (imageArray == undefined) {
        myListing.imageArray = [];
      } else {
        myListing.imageArray = imageArray;
      }

      var priorityListing = thisListing.get("priorityListing");
      if (priorityListing == undefined) {
        myListing.priorityListing = false;
      } else {
        myListing.priorityListing = priorityListing;
      }

      var isReplied = thisListing.get("isReplied");
      if (isReplied == undefined) {
        myListing.isReplied = false;
      } else {
        myListing.isReplied = isReplied;
      }

      var closedDate = thisListing.get("closedDate");
      if (closedDate == undefined) {
        myListing.closedDate = "";
        myListing.closedDateShort = "";
      } else {
        myListing.closedDate = closedDate;
        myListing.closedDateShort = closedDate.toLocaleDateString();
      }

      var listingClosedDate = thisListing.get("listingClosedDate");
      if (listingClosedDate == undefined) {
        myListing.listingClosedDate = "";
        myListing.listingClosedDateShort = "";
      } else {
        myListing.listingClosedDate = listingClosedDate;
        myListing.listingClosedDateShort = listingClosedDate.toLocaleDateString();
      }

      var lastNotified = thisListing.get("lastNotified");
      if (lastNotified == undefined) {
        myListing.lastNotified = "";
        myListing.lastNotifiedShort = "";
      } else {
        myListing.lastNotified = lastNotified;
        myListing.lastNotifiedShort = lastNotified.toLocaleDateString();
      }

      var markSellerAsPriority = thisListing.get("markSellerAsPriority");
      if (markSellerAsPriority == undefined) {
        myListing.markSellerAsPriority = false;
      } else {
        myListing.markSellerAsPriority = markSellerAsPriority;
      }

      return myListing;
    },

    convertToVendor: function convertToVendor(item) {
      // var str = JSON.stringify(item);
      // console.log("converting " + str);

      var myVendor = {};
      var thisVendor = item;

      var account = thisVendor.get("account");
      if (account == undefined) {
        myVendor.account = "";
      } else {
        myVendor.account = this.convertToAccount(account);
      }

      var sellerName = thisVendor.get("sellerName");
      if (sellerName == undefined) {
        myVendor.sellerName = "";
      } else {
        myVendor.sellerName = sellerName;
      }

      var sellerURL = thisVendor.get("sellerURL");
      if (sellerURL == undefined) {
        myVendor.sellerURL = "";
      } else {

        if (myVendor.account.disableListingLinks) {
          myVendor.sellerURL = "donotclick-" + fixBrokenUrls(sellerURL);
        } else {
          myVendor.sellerURL = fixBrokenUrls(sellerURL);
        }
      }

      var invoice = thisVendor.get("invoice");
      if (invoice == undefined || invoice == "") {
        myVendor.invoice = "";
        myVendor.isInvoice = false;
      } else {
        myVendor.invoice = invoice;
        myVendor.isInvoice = true;
      }

      var shoppingPlatform = thisVendor.get("shoppingPlatform");
      if (shoppingPlatform == undefined) {
        myVendor.shoppingPlatform = "";
      } else {
        myVendor.shoppingPlatform = shoppingPlatform;
      }

      var accountId = thisVendor.get("accountId");
      if (accountId == undefined) {
        myVendor.accountId = "";
      } else {
        myVendor.accountId = accountId;
      }

      var createdAt = thisVendor.createdAt;
      var firstSeen = thisVendor.get("firstSeen");

      if (createdAt == undefined) {
        myVendor.createdAt = "";
        myVendor.shortDate = '';
      } else {

        if (myVendor.accountId == "WjkRlgyytL" && firstSeen != undefined) {
          myVendor.createdAt = firstSeen;
          var shortDate = myVendor.createdAt.toLocaleDateString();
          myVendor.shortDate = shortDate;
        } else {
          myVendor.createdAt = createdAt;
          var shortDate = thisVendor.createdAt.toLocaleDateString();
          myVendor.shortDate = shortDate;
        }
      }

      var objectId = thisVendor.id;
      if (objectId == undefined) {
        myVendor.objectId = "";
      } else {
        myVendor.objectId = objectId;
      }

      var status = thisVendor.get("status");
      if (status == undefined) {
        myVendor.status = "";
        myVendor.isEditable = false;
      } else {
        myVendor.status = status;
        if (status == "Review") {
          myVendor.isEditable = true;
        } else {
          myVendor.isEditable = true;
        }
      }

      var alias = thisVendor.get("alias");
      if (alias == undefined) {
        myVendor.alias = "";
        myVendor.aliasString = "";
      } else {
        myVendor.alias = alias;
        var aliasString = alias.toString();
        //myVendor.aliasString = aliasString.replace(/,/g, "*");
        myVendor.aliasString = aliasString;
      }

      //
      var companyContact = thisVendor.get("companyContact");
      if (companyContact == undefined) {
        myVendor.companyContact = "";
      } else {
        myVendor.companyContact = companyContact;
      }

      var companyAddress = thisVendor.get("companyAddress");
      if (companyAddress == undefined) {
        myVendor.companyAddress = "";
      } else {
        myVendor.companyAddress = companyAddress;
      }

      var numDiscoveredListings = thisVendor.get("numPendingListings");
      if (numDiscoveredListings == undefined) {
        myVendor.numDiscoveredListings = "";
      } else {
        myVendor.numDiscoveredListings = numDiscoveredListings;
      }

      var numPendingListings = thisVendor.get("numPendingListings");
      if (numPendingListings == undefined) {
        myVendor.numPendingListings = "";
      } else {
        myVendor.numPendingListings = numPendingListings;
      }

      var numClosedListings = thisVendor.get("numClosedListings");
      if (numClosedListings == undefined) {
        myVendor.numClosedListings = "";
      } else {
        myVendor.numClosedListings = numClosedListings;
      }

      var numVerifyingListings = thisVendor.get("numVerifyingListings");
      if (numVerifyingListings == undefined) {
        myVendor.numVerifyingListings = "";
      } else {
        myVendor.numVerifyingListings = numVerifyingListings;
      }

      var numFlaggedListings = thisVendor.get("numFlaggedListings");
      if (numFlaggedListings == undefined) {
        myVendor.numFlaggedListings = "";
      } else {
        myVendor.numFlaggedListings = numFlaggedListings;
      }

      var numVerifiedListings = thisVendor.get("numVerifiedListings");
      if (numVerifiedListings == undefined) {
        myVendor.numVerifiedListings = "";
      } else {
        myVendor.numVerifiedListings = numVerifiedListings;
      }

      var numAuthorizedListings = thisVendor.get("numAuthorizedListings");
      if (numAuthorizedListings == undefined) {
        myVendor.numAuthorizedListings = "";
      } else {
        myVendor.numAuthorizedListings = numAuthorizedListings;
      }

      var numReviewListings = thisVendor.get("numReviewListings");
      if (numReviewListings == undefined) {
        myVendor.numReviewListings = "";
      } else {
        myVendor.numReviewListings = numReviewListings;
      }

      var supplierAddress = thisVendor.get("supplierAddress");
      if (supplierAddress == undefined) {
        myVendor.supplierAddress = "";
      } else {
        myVendor.supplierAddress = supplierAddress;
      }

      var supplierContact = thisVendor.get("supplierContact");
      if (supplierContact == undefined) {
        myVendor.supplierContact = "";
      } else {
        myVendor.supplierContact = supplierContact;
      }

      var email = thisVendor.get("email");
      if (email == undefined) {
        myVendor.email = "";
      } else {
        myVendor.email = email;
      }

      var hasEmail = thisVendor.get("hasEmail");
      if (hasEmail == undefined) {
        myVendor.hasEmail = false;
      } else {
        myVendor.hasEmail = hasEmail;
      }

      var phone = thisVendor.get("phone");
      if (phone == undefined) {
        myVendor.phone = "";
      } else {
        myVendor.phone = phone;
      }

      var numberOfPriorityListings = thisVendor.get("numberOfPriorityListings");
      if (numberOfPriorityListings == undefined) {
        myVendor.numberOfPriorityListings = 0;
      } else {
        myVendor.numberOfPriorityListings = numberOfPriorityListings;
      }

      var masterVendor = thisVendor.get("masterVendor");
      if (masterVendor == undefined) {
        myVendor.masterVendor = {};
        myVendor.hasMasterVendor = false;
      } else {
        myVendor.masterVendor = this.convertToMasterVendor(masterVendor);
        myVendor.hasMasterVendor = true;
      }

      var masterVendorId = thisVendor.get("masterVendorId");
      if (masterVendorId == undefined) {
        myVendor.masterVendorId = "";
      } else {
        myVendor.masterVendorId = masterVendorId;
      }

      var ratingPercentage = thisVendor.get("ratingPercentage");
      if (ratingPercentage == undefined) {
        myVendor.ratingPercentage = "";
      } else {
        myVendor.ratingPercentage = ratingPercentage;
      }

      var ratingCount = thisVendor.get("ratingCount");
      if (ratingCount == undefined) {
        myVendor.ratingCount = "";
      } else {
        myVendor.ratingCount = ratingCount;
      }

      var shippingLocation = thisVendor.get("shippingLocation");
      if (shippingLocation == undefined) {
        myVendor.shippingLocation = "";
      } else {
        myVendor.shippingLocation = shippingLocation;
      }

      var statusUpdated = thisVendor.get("statusUpdated");
      if (statusUpdated == undefined) {
        myVendor.statusUpdated = "";
        myVendor.statusUpdatedClean = "";
      } else {
        myVendor.statusUpdated = statusUpdated.toLocaleDateString();
        myVendor.statusUpdatedClean = getFormatDate(statusUpdated);
      }

      var stageUpdated = thisVendor.get("stageUpdated");
      if (stageUpdated == undefined) {
        myVendor.stageUpdated = "";
        myVendor.stageUpdatedClean = "";
      } else {
        myVendor.stageUpdated = stageUpdated.toLocaleDateString();
        myVendor.stageUpdatedClean = getFormatDate(stageUpdated);
      }

      var company = thisVendor.get("company");
      if (company == undefined) {
        myVendor.company = "";
      } else {
        myVendor.company = company;
      }

      var stage = thisVendor.get("stage");
      if (stage == undefined) {
        myVendor.stage = "";
        myVendor.stageText = "";
      } else {
        myVendor.stage = stage;
        switch (stage) {
          case "notice 1":
            myVendor.stageText = "Verification Request";
            break;
          case "notice 2":
            myVendor.stageText = "Verification Request 2";
            break;
          case "notice 3":
            myVendor.stageText = "Final Warning";
            break;
          // case "re-notification":
          //   myVendor.stageText = "Policy Violation";
          //   break;
          default:
            myVendor.stageText = stage;
            break;
        }
      }

      var scheduled = thisVendor.get("scheduled");
      if (scheduled == undefined) {
        myVendor.scheduled = false;
        myVendor.scheduledText = "Manual";
      } else {
        myVendor.scheduled = scheduled;
        if (scheduled) {
          myVendor.scheduledText = "Automated";
        } else {
          myVendor.scheduledText = "Manual";
        }
      }

      var supplier = thisVendor.get("supplier");
      if (supplier == undefined) {
        myVendor.supplier = "";
      } else {
        myVendor.supplier = supplier;
      }

      var vatNumber = thisVendor.get("vatNumber");
      if (vatNumber == undefined) {
        myVendor.vatNumber = "";
      } else {
        myVendor.vatNumber = vatNumber;
      }

      var notes = thisVendor.get("notes");
      if (notes == undefined) {
        myVendor.notes = "";
      } else {
        myVendor.notes = notes;
      }

      var isPriority = thisVendor.get("isPriority");
      if (isPriority == undefined) {
        myVendor.isPriority = false;
      } else {
        myVendor.isPriority = isPriority;
      }

      var subStatus = thisVendor.get("subStatus");
      if (subStatus == undefined) {
        myVendor.subStatus = "";
      } else {

        var accountcontroller = this.get("accountcontroller");
        if (accountcontroller.isAdmin) {
          myVendor.subStatus = subStatus;
        } else {
          subStatus = subStatus.replace("Unenforceable", "");
          myVendor.subStatus = subStatus;
        }
      }

      var addressName = thisVendor.get("addressName");
      if (addressName == undefined) {
        myVendor.addressName = "";
      } else {
        myVendor.addressName = addressName;
      }

      var addressLine1 = thisVendor.get("addressLine1");
      if (addressLine1 == undefined) {
        myVendor.addressLine1 = "";
      } else {
        myVendor.addressLine1 = addressLine1;
      }

      var addressLine2 = thisVendor.get("addressLine2");
      if (addressLine2 == undefined) {
        myVendor.addressLine2 = "";
      } else {
        myVendor.addressLine2 = addressLine2;
      }

      var addressCity = thisVendor.get("addressCity");
      if (addressCity == undefined) {
        myVendor.addressCity = "";
      } else {
        myVendor.addressCity = addressCity;
      }

      var addressState = thisVendor.get("addressState");
      if (addressState == undefined) {
        myVendor.addressState = "";
      } else {
        myVendor.addressState = addressState;
      }

      var addressPostalCode = thisVendor.get("addressPostalCode");
      if (addressPostalCode == undefined) {
        myVendor.addressPostalCode = "";
      } else {
        myVendor.addressPostalCode = addressPostalCode;
      }

      var addressCountry = thisVendor.get("addressCountry");
      if (addressCountry == undefined) {
        myVendor.addressCountry = "";
      } else {
        myVendor.addressCountry = addressCountry;
      }

      var currentUser = Parse.User.current();
      myVendor.clientLink = "https://contactsystem.vantagebp.com/email.php?acct=" + myVendor.accountId + "&vend=" + myVendor.objectId + "&userid=" + currentUser.id;

      var numberOfListings = thisVendor.get("numberOfListings");
      if (numberOfListings == undefined) {
        myVendor.numberOfListings = 0;
      } else {
        if (numberOfListings < 0) {
          numberOfListings = 0;
          fixNumberOfSellerListings(objectId, accountId);
        }
        myVendor.numberOfListings = numberOfListings;
      }

      var active = thisVendor.get("numberOfListings") - thisVendor.get("numClosedListings");
      if (isNaN(active) || active === undefined) {
        myVendor.active = "0";
      } else {
        myVendor.active = active.toString();
      }

      var inActive = thisVendor.get("numClosedListings");
      if (isNaN(inActive) || inActive === undefined) {
        myVendor.inActive = "0";
      } else {
        myVendor.inActive = inActive.toString();
      }

      var priorityVendor = thisVendor.get("priorityVendor");
      if (priorityVendor == undefined) {
        myVendor.priorityVendor = false;
      } else {
        myVendor.priorityVendor = priorityVendor;
      }

      var isReplied = thisVendor.get("isReplied");
      if (isReplied == undefined) {
        myVendor.isReplied = false;
      } else {
        myVendor.isReplied = isReplied;
      }

      var isDoNotContact = thisVendor.get("isDoNotContact");
      if (isDoNotContact == undefined) {
        myVendor.isDoNotContact = false;
      } else {
        myVendor.isDoNotContact = isDoNotContact;
      }
      myVendor.deadEmailsOnly = false;
      if (myVendor.masterVendor.email == "" && myVendor.masterVendor.emailArray.length == 0) {
        myVendor.hasMasterEmail = false;
      } else if (myVendor.masterVendor.emailArray) {
        if (myVendor.masterVendor.emailArray.length > myVendor.masterVendor.deadEmailArray.length) {
          myVendor.hasMasterEmail = true;
        } else if (myVendor.masterVendor.email != "") {
          myVendor.hasMasterEmail = true;
        } else {
          myVendor.hasMasterEmail = false;
        }
      } else if (myVendor.masterVendor.email != "") {
        myVendor.hasMasterEmail = true;
      } else {
        myVendor.hasMasterEmail = false;
      }

      if (myVendor.masterVendor.emailArray != undefined && myVendor.masterVendor.emailArray != undefined) {
        if (myVendor.masterVendor.emailArray.length == myVendor.masterVendor.deadEmailArray.length && myVendor.masterVendor.emailArray.length > 0 && myVendor.masterVendor.deadEmailArray.length > 0) {
          var deadEmailsOnly = true;
          for (var i = 0; i < myVendor.masterVendor.deadEmailArray; i++) {
            var deadEmail = myVendor.masterVendor.deadEmailArray[i];
            if (!myVendor.masterVendor.emailArray.includes(deadEmail)) {
              deadEmailsOnly = false;
            }
          }
          myVendor.deadEmailsOnly = deadEmailsOnly;
        }
      }

      var isRemoved = thisVendor.get("isRemoved");
      if (isRemoved == undefined) {
        myVendor.isRemoved = false;
      } else {
        myVendor.isRemoved = isRemoved;
      }

      var nameChanged = thisVendor.get("nameChanged");
      if (nameChanged == undefined) {
        myVendor.nameChanged = false;
      } else {
        myVendor.nameChanged = nameChanged;
      }

      return myVendor;
    },

    convertToAccount: function convertToAccount(item) {
      // var str = JSON.stringify(item);
      // console.log("converting " + str);

      var myAccount = {};
      var thisAccount = item;

      var accountName = thisAccount.get("accountName");
      if (accountName == undefined) {
        myAccount.accountName = "";
        myAccount.encodedName = "";
      } else {
        myAccount.accountName = accountName;
        accountName = accountName.replace("&", "And");
        myAccount.encodedName = encodeURI(accountName);
      }

      var status = thisAccount.get("status");
      if (status == undefined) {
        myAccount.status = "inactive";
      } else {
        myAccount.status = status;
      }

      var sumClosedListings = thisAccount.get("sumClosedListings");
      if (sumClosedListings == undefined) {
        myAccount.sumClosedListings = -1;
      } else {
        myAccount.sumClosedListings = sumClosedListings;
      }

      var mapVariance = thisAccount.get("mapVariance");
      if (mapVariance == undefined) {
        myAccount.mapVariance = 0;
      } else {
        myAccount.mapVariance = mapVariance;
      }

      var mapDaysToExpire = thisAccount.get("mapDaysToExpire");
      if (mapDaysToExpire == undefined) {
        myAccount.mapDaysToExpire = 0;
      } else {
        myAccount.mapDaysToExpire = mapDaysToExpire;
      }

      var mapPolicy = thisAccount.get("mapPolicy");
      if (mapPolicy == undefined) {
        myAccount.mapPolicy = "";
      } else {
        myAccount.mapPolicy = mapPolicy;
      }

      var accountState = thisAccount.get("accountState");
      if (accountState == undefined) {
        myAccount.accountState = 1;
        myAccount.isMapAccount = false;
      } else {
        myAccount.accountState = accountState;
        if (accountState > 1) {
          myAccount.isMapAccount = true;
        } else {
          myAccount.isMapAccount = false;
        }
      }

      var continuous_map_seller_enforcement = thisAccount.get("continuous_map_seller_enforcement");
      if (continuous_map_seller_enforcement == undefined) {
        myAccount.continuous_map_seller_enforcement = false;
      } else {
        myAccount.continuous_map_seller_enforcement = continuous_map_seller_enforcement;
      }

      var accountConditionArray = thisAccount.get("accountConditionArray");
      if (accountConditionArray == undefined) {
        myAccount.accountConditionArray = [];
      } else {
        myAccount.accountConditionArray = accountConditionArray;
      }

      var runStatus = thisAccount.get("runStatus");

      if (runStatus == undefined) {
        myAccount.runStatus = "";
        myAccount.runStatusText = "Run";
      } else {
        runStatus = runStatus.replace(/[0-9]/g, '');
        myAccount.runStatus = runStatus;
        if (runStatus == "complete") {
          myAccount.runStatusText = "Run";
        } else {
          var nameCapitalized = runStatus.charAt(0).toUpperCase() + runStatus.slice(1);
          myAccount.runStatusText = nameCapitalized;
        }
      }

      var runStatusDate = thisAccount.get("runStatusDate");
      if (runStatusDate == undefined) {
        myAccount.runStatusDate = "";
      } else {
        myAccount.runStatusDate = runStatusDate.toLocaleDateString();
      }

      var runStatusClosed = thisAccount.get("runStatusClosed");
      if (runStatusClosed == undefined) {
        myAccount.runStatusClosed = "";
        myAccount.runStatusClosedText = "Run";
      } else {
        runStatusClosed = runStatusClosed.replace(/[0-9]/g, '');
        myAccount.runStatusClosed = runStatusClosed;
        if (runStatusClosed == "complete") {
          myAccount.runStatusClosedText = "Run";
        } else {
          var _nameCapitalized = runStatusClosed.charAt(0).toUpperCase() + runStatusClosed.slice(1);
          myAccount.runStatusClosedText = _nameCapitalized;
        }
      }

      var runStatusClosedDate = thisAccount.get("runStatusClosedDate");
      if (runStatusClosedDate == undefined) {
        myAccount.runStatusClosedDate = "";
      } else {
        myAccount.runStatusClosedDate = runStatusClosedDate.toLocaleDateString();
      }

      var inactive = thisAccount.get("inactive");
      if (inactive == undefined) {
        myAccount.inactive = false;
      } else {
        myAccount.inactive = inactive;
      }

      var isMap = thisAccount.get("isMap");
      if (isMap == undefined) {
        myAccount.isMap = false;
      } else {
        myAccount.isMap = isMap;
      }

      var isPriority = thisAccount.get("isPriority");
      if (isPriority == undefined) {
        myAccount.isPriority = false;
      } else {
        myAccount.isPriority = isPriority;
      }

      var searchedForLeads = thisAccount.get("searchedForLeads");
      if (searchedForLeads == undefined) {
        myAccount.searchedForLeads = false;
      } else {
        myAccount.searchedForLeads = searchedForLeads;
      }

      var hasBeenItemNumberRecounted = thisAccount.get("hasBeenItemNumberRecounted");
      if (hasBeenItemNumberRecounted == undefined) {
        myAccount.hasBeenItemNumberRecounted = false;
      } else {
        myAccount.hasBeenItemNumberRecounted = hasBeenItemNumberRecounted;
      }

      var numAssets = thisAccount.get("numAssets");
      if (numAssets == undefined) {
        myAccount.numAssets = 0;
      } else {
        myAccount.numAssets = numAssets;
      }

      var lastScan = thisAccount.get("lastScan");
      if (lastScan == undefined) {
        myAccount.lastScan = "";
      } else {
        myAccount.lastScan = lastScan.toLocaleDateString();
      }

      var activity = thisAccount.get("activity");
      if (activity == undefined) {
        myAccount.activity = "";
      } else {
        myAccount.activity = activity;
      }

      var businessName = thisAccount.get("businessName");
      if (businessName == undefined) {
        myAccount.businessName = "";
      } else {
        myAccount.businessName = businessName;
      }

      var brandName = thisAccount.get("brandName");
      if (brandName == undefined) {
        myAccount.brandName = "";
      } else {
        myAccount.brandName = brandName;
      }

      var type = thisAccount.get("type");
      if (type == undefined) {
        myAccount.type = "";
      } else {
        myAccount.type = type;
      }
      var searchArray = thisAccount.get("searchArray");
      if (searchArray == undefined) {
        myAccount.searchArray = [];
      } else {
        myAccount.searchArray = searchArray;
      }

      var numberOfListings = thisAccount.get("numberOfListings");
      if (numberOfListings == undefined) {
        myAccount.numberOfListings = 0;
      } else {
        if (numberOfListings < 0) {
          numberOfListings = 0;
        }
        myAccount.numberOfListings = numberOfListings;
      }

      var createdAt = thisAccount.createdAt;
      if (createdAt == undefined) {
        myAccount.createdAt = "";
        myAccount.shortDate = "";
      } else {
        myAccount.createdAt = createdAt;
        var shortDate = thisAccount.createdAt.toLocaleDateString();
        myAccount.shortDate = shortDate;
      }

      var objectId = thisAccount.id;
      if (objectId == undefined) {
        myAccount.objectId = "";
      } else {
        myAccount.objectId = objectId;
      }

      var numberOfVendors = thisAccount.get("numberOfVendors");
      if (numberOfVendors == undefined) {
        myAccount.numberOfVendors = 0;
      } else {
        myAccount.numberOfVendors = numberOfVendors;
      }

      var totalMapSkus = thisAccount.get("totalMapSkus");
      if (totalMapSkus == undefined) {
        myAccount.totalMapSkus = 0;
      } else {
        myAccount.totalMapSkus = totalMapSkus;
      }

      var isBeingRecounted = thisAccount.get("isBeingRecounted");
      if (isBeingRecounted == undefined) {
        myAccount.isBeingRecounted = false;
      } else {
        myAccount.isBeingRecounted = isBeingRecounted;
      }

      var numberRejectedVendor = thisAccount.get("numberRejectedVendor");
      if (numberRejectedVendor == undefined) {
        myAccount.numberRejectedVendor = 0;
      } else {
        myAccount.numberRejectedVendor = numberRejectedVendor;
      }

      var numberReviewed = thisAccount.get("numberReviewed");
      if (numberReviewed == undefined) {
        myAccount.numberReviewed = 0;
      } else {
        myAccount.numberReviewed = numberReviewed;
      }

      var numberRejected = thisAccount.get("numberRejected");
      if (numberRejected == undefined) {
        myAccount.numberRejected = 0;
      } else {
        myAccount.numberRejected = numberRejected;
      }

      var numberEnforced = thisAccount.get("numberEnforced");
      if (numberEnforced == undefined) {
        myAccount.numberEnforced = 0;
      } else {
        myAccount.numberEnforced = numberEnforced;
      }

      var numberPending = thisAccount.get("numberPending");
      if (numberPending == undefined) {
        myAccount.numberPending = 0;
      } else {
        myAccount.numberPending = numberPending;
      }

      var alternatePrice = thisAccount.get("alternatePrice");
      if (alternatePrice == undefined) {
        myAccount.alternatePrice = false;
      } else {
        myAccount.alternatePrice = alternatePrice;
      }

      var canMarkPriority = thisAccount.get("canMarkPriority");
      if (canMarkPriority == undefined) {
        myAccount.canMarkPriority = false;
      } else {
        myAccount.canMarkPriority = canMarkPriority;
      }
      var isImageMatching = thisAccount.get("isImageMatching");
      if (isImageMatching == undefined) {
        myAccount.isImageMatching = false;
      } else {
        myAccount.isImageMatching = isImageMatching;
      }

      var priceType = thisAccount.get("priceType");
      if (priceType == undefined) {
        myAccount.priceType = "";
      } else {
        myAccount.priceType = priceType;
      }

      var numberRemoved = thisAccount.get("numberRemoved");
      if (numberRemoved == undefined) {
        myAccount.numberRemoved = 0;
      } else {
        myAccount.numberRemoved = numberRemoved;
      }

      var numberInspected = thisAccount.get("numberInspected");
      if (numberInspected == undefined) {
        myAccount.numberInspected = 0;
      } else {
        myAccount.numberInspected = numberInspected;
      }

      var numberLimbo = thisAccount.get("numberLimbo");
      if (numberLimbo == undefined) {
        myAccount.numberLimbo = 0;
      } else {
        myAccount.numberLimbo = numberLimbo;
      }

      var numberFlagged = thisAccount.get("numberFlagged");
      if (numberFlagged == undefined) {
        myAccount.numberFlagged = 0;
      } else {
        myAccount.numberFlagged = numberFlagged;
      }

      var numberVerifying = thisAccount.get("numberVerifying");
      if (numberVerifying == undefined) {
        myAccount.numberVerifying = 0;
      } else {
        myAccount.numberVerifying = numberVerifying;
      }

      var numberVerified = thisAccount.get("numberVerified");
      if (numberVerified == undefined) {
        myAccount.numberVerified = 0;
      } else {
        myAccount.numberVerified = numberVerified;
      }

      var numberNewSellers = thisAccount.get("numberNewSellers");
      if (numberNewSellers == undefined) {
        myAccount.numberNewSellers = 0;
      } else {
        myAccount.numberNewSellers = numberNewSellers;
      }

      var numberInfringers = thisAccount.get("numberInfringers");
      if (numberInfringers == undefined) {
        myAccount.numberInfringers = 0;
      } else {
        myAccount.numberInfringers = numberInfringers;
      }

      // var numberNoResponse = thisAccount.get("numberNoResponse");
      // if(numberNoResponse == undefined){
      //   myAccount.numberNoResponse = 0;
      // }else{
      //   myAccount.numberNoResponse = numberNoResponse;
      // }

      var numberNotified = thisAccount.get("numberNotified");
      if (numberNotified == undefined) {
        myAccount.numberNotified = 0;
      } else {
        myAccount.numberNotified = numberNotified;
      }

      var numberAuthorized = thisAccount.get("numberAuthorized");
      if (numberAuthorized == undefined) {
        myAccount.numberAuthorized = 0;
      } else {
        myAccount.numberAuthorized = numberAuthorized;
      }

      var numberClosed = thisAccount.get("numberClosed");
      if (numberClosed == undefined) {
        myAccount.numberClosed = 0;
      } else {
        myAccount.numberClosed = numberClosed;
      }

      var totalASIN = thisAccount.get("totalASIN");
      if (totalASIN == undefined) {
        myAccount.totalASIN = 0;
      } else {
        myAccount.totalASIN = totalASIN;
      }

      var totalListings = thisAccount.get("totalListings");
      if (totalListings == undefined) {
        myAccount.totalListings = 0;
      } else {
        myAccount.totalListings = totalListings;
      }

      var totalSellers = thisAccount.get("totalSellers");
      if (totalSellers == undefined) {
        myAccount.totalSellers = 0;
      } else {
        myAccount.totalSellers = totalSellers;
      }

      var numberInfringerVendor = thisAccount.get("numberInfringerVendor");
      if (numberInfringerVendor == undefined) {
        myAccount.numberInfringerVendor = 0;
      } else {
        myAccount.numberInfringerVendor = numberInfringerVendor;
      }

      var numberAuthorizedVendor = thisAccount.get("numberAuthorizedVendor");
      if (numberAuthorizedVendor == undefined) {
        myAccount.numberAuthorizedVendor = 0;
      } else {
        myAccount.numberAuthorizedVendor = numberAuthorizedVendor;
      }

      var numberUnauthorized = thisAccount.get("numberUnauthorized");
      if (numberUnauthorized == undefined) {
        myAccount.numberUnauthorized = 0;
      } else {
        myAccount.numberUnauthorized = numberUnauthorized;
      }

      var onlineReport = thisAccount.get("onlineReport");
      if (onlineReport != undefined) {
        myAccount.onlineReport = onlineReport.url();
        //console.log("has image - " +image.url());
      } else {
        myAccount.onlineReport = "";
      }

      var onlineSummary = thisAccount.get("onlineSummary");
      if (onlineSummary != undefined) {
        myAccount.onlineSummary = onlineSummary.url();
        //console.log("has image - " +image.url());
      } else {
        myAccount.onlineSummary = "";
      }

      var sageTreeCQRScore = thisAccount.get("sageTreeCQRScore");
      if (sageTreeCQRScore != undefined) {
        myAccount.sageTreeCQRScore = sageTreeCQRScore.url();
        //console.log("has image - " +image.url());
      } else {
        myAccount.sageTreeCQRScore = "";
      }

      var image = thisAccount.get("image");
      if (image != undefined) {
        myAccount.image = image.url();
        // console.log("has image - " +image.url());
      } else {
        myAccount.image = "";
      }

      var disableListingLinks = thisAccount.get("disableListingLinks");
      if (disableListingLinks == undefined) {
        myAccount.disableListingLinks = false;
      } else {
        myAccount.disableListingLinks = disableListingLinks;
      }

      var largeLogo = thisAccount.get("largeLogo");
      if (largeLogo != undefined) {
        myAccount.largeLogo = largeLogo.url();
      } else {
        myAccount.largeLogo = "";
      }

      var partner = thisAccount.get("partner");
      if (partner == undefined) {
        myAccount.partner = "";
      } else {
        myAccount.partner = partner;
      }

      var taskId = thisAccount.get("taskId");
      if (taskId == undefined) {
        myAccount.taskId = "";
      } else {
        myAccount.taskId = taskId;
      }

      var accountManager = thisAccount.get("accountManager");
      if (accountManager != undefined) {
        myAccount.accountManager = this.convertToUser(accountManager);
      } else {
        myAccount.accountManager = {};
      }

      var accountManagerId = thisAccount.get("accountManagerId");
      if (accountManagerId != undefined) {
        myAccount.accountManagerId = accountManagerId;
      } else {
        myAccount.accountManagerId = "";
      }

      if (myAccount.partner == "caleres") {
        myAccount.isCaleres = true;
      } else {
        myAccount.isCaleres = false;
      }

      if (partner != undefined) {
        if (partner == "") {
          myAccount.returnAddress = "Vantage BP";
        } else {
          myAccount.returnAddress = returnAddress(partner);
        }
      } else {
        myAccount.returnAddress = "Vantage BP";
      }

      var numberReviewListings = thisAccount.get("numberReviewListings");
      if (numberReviewListings != undefined) {
        myAccount.numberReviewListings = numberReviewListings;
      } else {
        myAccount.numberReviewListings = 0;
      }

      var numberAuthorizedListings = thisAccount.get("numberAuthorizedListings");
      if (numberAuthorizedListings != undefined) {
        myAccount.numberAuthorizedListings = numberAuthorizedListings;
      } else {
        myAccount.numberAuthorizedListings = 0;
      }

      var numberOfMAPListings = thisAccount.get("numberOfMAPListings");
      if (numberOfMAPListings != undefined) {
        myAccount.numberOfMAPListings = numberOfMAPListings;
      } else {
        myAccount.numberOfMAPListings = 0;
      }

      var numberOfMAPProducts = thisAccount.get("numberOfMAPProducts");
      if (numberOfMAPProducts != undefined) {
        myAccount.numberOfMAPProducts = numberOfMAPProducts;
      } else {
        myAccount.numberOfMAPProducts = 0;
      }

      var blockedBrands = thisAccount.get("blockedBrands");
      if (blockedBrands != undefined) {
        myAccount.blockedBrands = blockedBrands;
      } else {
        myAccount.blockedBrands = [];
      }

      var letter1 = thisAccount.get("letter1");
      if (letter1 != undefined) {
        myAccount.letter1 = letter1.url();
        myAccount.hasLetter1 = true;
      } else {
        myAccount.letter1 = "/img/red_x.png";
        myAccount.hasLetter1 = false;
      }

      var letter2 = thisAccount.get("letter2");
      if (letter2 != undefined) {
        myAccount.letter2 = letter2.url();
        myAccount.hasLetter2 = true;
      } else {
        myAccount.letter2 = "/img/red_x.png";
        myAccount.hasLetter2 = false;
      }

      var letter3 = thisAccount.get("letter3");
      if (letter3 != undefined) {
        myAccount.letter3 = letter3.url();
        myAccount.hasLetter3 = true;
      } else {
        myAccount.letter3 = "/img/red_x.png";
        myAccount.hasLetter3 = false;
      }

      var sizeArray = thisAccount.get("sizeArray");
      if (sizeArray !== undefined) {
        myAccount.hasSizeArray = true;
        myAccount.sizeArray = sizeArray;
      } else {
        myAccount.hasSizeArray = false;
        myAccount.sizeArray = [];
      }

      var lastScanDate = thisAccount.get("lastScanDate");
      if (lastScanDate == undefined) {
        myAccount.lastScanDate = "";
        myAccount.lastScanShortDate = "";
        myAccount.time = "";
      } else {
        myAccount.lastScanDate = lastScanDate;
        var lastScanShortDate = myAccount.lastScanDate.toLocaleDateString();
        myAccount.lastScanShortDate = lastScanShortDate;
        myAccount.time = convertTimeOfDate(lastScanDate);
      }

      var reportEmails = thisAccount.get("reportEmails");
      if (reportEmails == undefined) {
        myAccount.reportEmails = [];
      } else {
        myAccount.reportEmails = reportEmails;
      }

      var lastSeen = thisAccount.get("lastSeen");
      if (lastSeen == undefined) {
        myAccount.lastSeen = "";
        myAccount.lastSeenShort = "";
      } else {
        myAccount.lastSeen = lastSeen;
        myAccount.lastSeenShort = lastSeen.toLocaleDateString();
      }

      var lastClosedCrawlerDate = thisAccount.get("lastClosedCrawlerDate");
      if (lastClosedCrawlerDate == undefined) {
        myAccount.lastClosedCrawlerDate = "";
        myAccount.lastClosedCrawlerDateShort = "";
        myAccount.lastClosedCrawlerTime = "";
      } else {
        myAccount.lastClosedCrawlerDate = lastClosedCrawlerDate;
        myAccount.lastClosedCrawlerDateShort = lastClosedCrawlerDate.toLocaleDateString();
        myAccount.lastClosedCrawlerTime = convertTimeOfDate(lastClosedCrawlerDate);
      }

      var lastProductCrawlerDate = thisAccount.get("lastProductCrawlerDate");
      if (lastProductCrawlerDate == undefined) {
        myAccount.lastProductCrawlerDate = "";
        myAccount.lastProductCrawlerDateShort = "";
        myAccount.lastProductCrawlerTime = "";
      } else {
        myAccount.lastProductCrawlerDate = lastProductCrawlerDate;
        myAccount.lastProductCrawlerDateShort = lastProductCrawlerDate.toLocaleDateString();
        myAccount.lastProductCrawlerTime = convertTimeOfDate(lastProductCrawlerDate);
      }

      myAccount.numberOther = Number(myAccount.numberAuthorizedListings) + Number(myAccount.numberReviewListings) + Number(myAccount.numberVerified);

      var skipForceNote = thisAccount.get("skipForceNote");
      if (skipForceNote == undefined) {
        myAccount.skipForceNote = false;
      } else {
        myAccount.skipForceNote = skipForceNote;
      }

      var monthlyReportEmails = thisAccount.get("monthlyReportEmails");
      if (monthlyReportEmails == undefined) {
        myAccount.monthlyReportEmails = [];
      } else {
        myAccount.monthlyReportEmails = monthlyReportEmails.filter(function (email) {
          return email !== '';
        });
      }
      var currentdate6Months = new Date();
      var last6months = new Date(currentdate6Months.setMonth(currentdate6Months.getMonth() - 3));
      if (myAccount.status != "active" && myAccount.createdAt < last6months) {
        myAccount.canArchive = true;
      } else {
        myAccount.canArchive = false;
      }

      return myAccount;
    },

    convertToUser: function convertToUser(item) {
      // var str = JSON.stringify(item);
      // console.log("converting " + str);

      var myUser = {};
      var thisUser = item;

      var username = thisUser.get("username");
      if (username == undefined) {
        myUser.username = "";
      } else {
        myUser.username = username.toLowerCase();
      }

      var email = thisUser.get("email");
      if (email == undefined) {
        myUser.email = "";
      } else {
        myUser.email = email.toLowerCase();
      }

      var currentPhoneNumber = thisUser.get("currentPhoneNumber");
      if (currentPhoneNumber == undefined) {
        myUser.currentPhoneNumber = "";
      } else {
        myUser.currentPhoneNumber = currentPhoneNumber;
      }

      var should2fa = thisUser.get("should2fa");
      if (should2fa == undefined) {
        myUser.should2fa = false;
      } else {
        myUser.should2fa = should2fa;
      }

      var showEmailFilter = thisUser.get("showEmailFilter");
      if (showEmailFilter == undefined) {
        myUser.showEmailFilter = true;
      } else {
        myUser.showEmailFilter = showEmailFilter;
      }

      var shouldText = thisUser.get("shouldText");
      if (shouldText == undefined) {
        myUser.shouldText = false;
      } else {
        myUser.shouldText = shouldText;
      }

      var isAdmin = thisUser.get("isAdmin");
      if (isAdmin == undefined) {
        myUser.isAdmin = false;
      } else {
        myUser.isAdmin = isAdmin;
      }

      var showAdminView = thisUser.get("showAdminView");
      if (showAdminView == undefined) {
        if (myUser.isAdmin) {
          myUser.showAdminView = true;
        } else {
          myUser.showAdminView = false;
        }
      } else {
        myUser.showAdminView = showAdminView;
      }

      var isSuperAdmin = thisUser.get("isSuperAdmin");
      if (isSuperAdmin == undefined) {
        myUser.isSuperAdmin = false;
      } else {
        myUser.isSuperAdmin = isSuperAdmin;
      }

      var multiAccount = thisUser.get("multiAccount");
      if (multiAccount == undefined) {
        myUser.multiAccount = false;
      } else {
        myUser.multiAccount = multiAccount;
      }

      var createdAt = thisUser.createdAt;
      if (createdAt == undefined) {
        myUser.createdAt = "";
      } else {
        myUser.createdAt = createdAt;
      }

      var objectId = thisUser.id;
      if (objectId == undefined) {
        myUser.objectId = "";
      } else {
        myUser.objectId = objectId;
      }

      var active = thisUser.get("active");
      if (active == undefined) {
        myUser.active = false;
      } else {
        myUser.active = active;
      }

      var turboOn = thisUser.get("turboOn");
      if (turboOn == undefined) {
        myUser.turboOn = false;
      } else {
        myUser.turboOn = turboOn;
      }

      var isContributor = thisUser.get("isContributor");
      if (isContributor == undefined) {
        myUser.isContributor = false;
      } else {
        myUser.isContributor = isContributor;
      }

      if (myUser.isContributor == true || myUser.isAdmin == true) {
        myUser.isEditor = true;
      } else {
        myUser.isEditor = false;
      }

      var isAccountAdmin = thisUser.get("isAccountAdmin");
      if (isAccountAdmin == undefined) {
        myUser.isAccountAdmin = false;
      } else {
        myUser.isAccountAdmin = isAccountAdmin;
      }

      var accounts = thisUser.get("accounts");
      if (accounts == undefined) {
        myUser.accounts = [];
      } else {
        myUser.accounts = accounts;
      }

      var favoriteAccounts = thisUser.get("favoriteAccounts");
      if (favoriteAccounts == undefined) {
        myUser.favoriteAccounts = [];
      } else {
        myUser.favoriteAccounts = favoriteAccounts;
      }

      return myUser;
    },

    convertToStartingURL: function convertToStartingURL(item) {
      //var str = JSON.stringify(item);
      //console.log("converting " + str);
      //console.log("converting 1" );
      var myStartingURL = {};
      var thisStartingURL = item;
      //console.log("converting 2" );
      var keywords = thisStartingURL.get("keywords");
      if (keywords == undefined) {
        myStartingURL.keywords = "";
      } else {
        myStartingURL.keywords = keywords;
      }
      //console.log("converting 3" );
      var url = thisStartingURL.get("url");
      if (url == undefined) {
        myStartingURL.url = "";
      } else {
        myStartingURL.url = url;
      }
      //console.log("converting 4" );
      var shoppingPlatform = thisStartingURL.get("shoppingPlatform");
      //console.log("converting 4A" );
      if (shoppingPlatform == undefined) {
        //console.log("converting 4B" );
        myStartingURL.shoppingPlatform = "";
      } else {
        //console.log("converting 4C ",  );
        myStartingURL.shoppingPlatform = shoppingPlatform;
        //console.log("converting 4D" );
      }
      //console.log("converting 5" );
      var accountName = thisStartingURL.get("accountName");
      if (accountName == undefined) {
        myStartingURL.accountName = "";
      } else {
        myStartingURL.accountName = accountName;
      }
      //console.log("converting 6" );
      var crawlerName = thisStartingURL.get("crawlerName");
      if (crawlerName == undefined) {
        myStartingURL.crawlerName = "";
      } else {
        myStartingURL.crawlerName = crawlerName;
      }
      //console.log("converting 7" );
      var lastScan = thisStartingURL.get("lastScan");
      if (lastScan == undefined) {
        myStartingURL.lastScan = "";
      } else {
        myStartingURL.lastScan = lastScan.toLocaleDateString();
      }
      //console.log("converting 8" );
      var activity = thisStartingURL.get("activity");
      if (activity == undefined) {
        myStartingURL.activity = "";
      } else {
        myStartingURL.activity = activity;
      }
      //console.log("converting 9" );
      var searchArray = thisStartingURL.get("searchArray");
      if (searchArray == undefined) {
        myStartingURL.searchArray = [];
      } else {
        myStartingURL.searchArray = searchArray;
      }
      //console.log("converting 10" );
      var createdAt = thisStartingURL.createdAt;
      if (createdAt == undefined) {
        myStartingURL.createdAt = "";
        myStartingURL.shortDate = "";
      } else {
        myStartingURL.createdAt = createdAt;
        var shortDate = thisStartingURL.createdAt.toLocaleDateString();
        myStartingURL.shortDate = shortDate;
      }
      //console.log("converting 11" );
      var updatedAt = thisStartingURL.updatedAt;
      myStartingURL.updatedAt = updatedAt;

      //console.log("converting 13" );
      var account = thisStartingURL.get("account");
      if (account == undefined) {
        myStartingURL.account = {};
      } else {
        myStartingURL.account = this.convertToAccount(account);
      }

      var objectId = thisStartingURL.id;
      if (objectId == undefined) {
        myStartingURL.objectId = "";
      } else {
        myStartingURL.objectId = objectId;
      }
      //console.log("converting 14" );
      return myStartingURL;
    },

    convertToMasterVendor: function convertToMasterVendor(item) {
      // var str = JSON.stringify(item);
      // console.log("converting " + str);

      var myMasterVendor = {};
      var thisMasterVendor = item;

      var sellerName = thisMasterVendor.get("sellerName");
      if (sellerName == undefined) {
        myMasterVendor.sellerName = "no name";
      } else {
        myMasterVendor.sellerName = sellerName;
      }

      var sellerURLArray = thisMasterVendor.get("sellerURLArray");
      if (sellerURLArray == undefined) {
        myMasterVendor.sellerURLArray = "";
      } else {
        var sellerURLArrayText = "";
        for (var i = 0; i < sellerURLArray.length; i++) {
          var text = sellerURLArray[i];
          sellerURLArrayText = sellerURLArrayText + " " + text;
        }
        myMasterVendor.sellerURLArray = sellerURLArrayText;
      }

      var invoice = thisMasterVendor.get("invoice");
      if (invoice == undefined || invoice == "") {
        myMasterVendor.invoice = "";
        myMasterVendor.isInvoice = false;
      } else {
        myMasterVendor.invoice = invoice;
        myMasterVendor.isInvoice = true;
      }

      var createdAt = thisMasterVendor.createdAt;
      if (createdAt == undefined) {
        myMasterVendor.createdAt = "";
      } else {
        myMasterVendor.createdAt = createdAt;
      }

      var objectId = thisMasterVendor.id;
      if (objectId == undefined) {
        myMasterVendor.objectId = "";
      } else {
        myMasterVendor.objectId = objectId;
      }

      //
      var companyContact = thisMasterVendor.get("companyContact");
      if (companyContact == undefined) {
        myMasterVendor.companyContact = "";
      } else {
        myMasterVendor.companyContact = companyContact;
      }

      var phoneNumber = thisMasterVendor.get("phoneNumber");
      if (phoneNumber == undefined) {
        myMasterVendor.phoneNumber = "";
      } else {
        myMasterVendor.phoneNumber = phoneNumber;
      }

      var email = thisMasterVendor.get("email");
      if (email == undefined) {
        myMasterVendor.email = "";
      } else {
        myMasterVendor.email = email;
      }

      var companyAddress = thisMasterVendor.get("companyAddress");
      if (companyAddress == undefined) {
        myMasterVendor.companyAddress = "";
      } else {
        myMasterVendor.companyAddress = companyAddress;
      }

      var supplierAddress = thisMasterVendor.get("supplierAddress");
      if (supplierAddress == undefined) {
        myMasterVendor.supplierAddress = "";
      } else {
        myMasterVendor.supplierAddress = supplierAddress;
      }

      var supplierContact = thisMasterVendor.get("supplierContact");
      if (supplierContact == undefined) {
        myMasterVendor.supplierContact = "";
      } else {
        myMasterVendor.supplierContact = supplierContact;
      }
      //
      var company = thisMasterVendor.get("company");
      if (company == undefined) {
        myMasterVendor.company = "";
      } else {
        myMasterVendor.company = company;
      }

      var supplier = thisMasterVendor.get("supplier");
      if (supplier == undefined) {
        myMasterVendor.supplier = "";
      } else {
        myMasterVendor.supplier = supplier;
      }

      var notes = thisMasterVendor.get("notes");
      if (notes == undefined) {
        myMasterVendor.notes = "";
      } else {
        myMasterVendor.notes = notes;
      }

      var subStatus = thisMasterVendor.get("subStatus");
      if (subStatus == undefined) {
        myMasterVendor.subStatus = "";
      } else {
        myMasterVendor.subStatus = subStatus;
      }

      var emailArray = thisMasterVendor.get("emailArray");
      if (emailArray == undefined) {
        myMasterVendor.emailArray = [];
      } else {
        myMasterVendor.emailArray = emailArray.filter(function (email) {
          return email !== '';
        });
      }

      var respondedEmailArray = thisMasterVendor.get("respondedEmailArray");
      if (respondedEmailArray == undefined) {
        myMasterVendor.respondedEmailArray = [];
      } else {
        myMasterVendor.respondedEmailArray = respondedEmailArray.filter(function (email) {
          return email !== '';
        });
      }

      var deadEmailArray = thisMasterVendor.get("deadEmailArray");
      if (deadEmailArray == undefined) {
        myMasterVendor.deadEmailArray = [];
      } else {
        myMasterVendor.deadEmailArray = deadEmailArray.filter(function (email) {
          return email !== '';
        });
      }

      return myMasterVendor;
    },

    convertToBrand: function convertToBrand(item) {
      var myBrand = {};
      var thisBrand = item;

      var createdAt = thisBrand.createdAt;
      if (createdAt == undefined) {
        myBrand.createdAt = "";
        myBrand.shortDate = "";
      } else {
        myBrand.createdAt = createdAt;
        var shortDate = thisBrand.createdAt.toLocaleDateString();
        myBrand.shortDate = shortDate;
      }
      var updatedAt = thisBrand.updatedAt;
      myBrand.updatedAt = updatedAt;

      var objectId = thisBrand.id;
      if (objectId == undefined) {
        myBrand.objectId = "";
      } else {
        myBrand.objectId = objectId;
      }
      var brand = thisBrand.get("brand");
      if (brand == undefined) {
        myBrand.brand = "";
      } else {
        myBrand.brand = brand;
      }
      var count = thisBrand.get("count");
      if (count == undefined) {
        myBrand.count = 0;
      } else {
        myBrand.count = count;
      }
      return myBrand;
    },

    convertToListingStatusRecord: function convertToListingStatusRecord(item) {
      //var str = JSON.stringify(item);
      //console.log("converting " + str);
      //console.log("converting 1" );
      var myListingStatusRecord = {};
      var thisListingStatusRecord = item;

      var status = thisListingStatusRecord.get("status");
      if (status == undefined) {
        myListingStatusRecord.status = "";
      } else {

        switch (status) {
          case "notice 1":
            myListingStatusRecord.stageText = "Verification Request";
            break;
          case "notice 2":
            myListingStatusRecord.stageText = "Verification Request 2";
            break;
          case "notice 3":
            myListingStatusRecord.stageText = "Final Warning";
            break;
          // case "re-notification":
          //   myListingStatusRecord.stageText = "Policy Violation";
          //   break;
          default:
            myListingStatusRecord.stageText = status;
            break;
        }
      }
      var previousStatus = thisListingStatusRecord.get("previousStatus");
      if (previousStatus == undefined) {
        myListingStatusRecord.previousStatus = "";
      } else {
        myListingStatusRecord.previousStatus = previousStatus;
      }
      var listingId = thisListingStatusRecord.get("listingId");
      if (listingId == undefined) {
        myListingStatusRecord.listingId = "";
      } else {
        myListingStatusRecord.listingId = listingId;
      }
      var user = thisListingStatusRecord.get("user");
      if (user == undefined) {
        myListingStatusRecord.user = {};
      } else {
        myListingStatusRecord.user = this.convertToUser(user);
      }
      var userId = thisListingStatusRecord.get("userId");
      if (userId == undefined) {
        myListingStatusRecord.userId = "";
      } else {
        myListingStatusRecord.userId = userId;
      }
      var createdAt = thisListingStatusRecord.createdAt;
      if (createdAt == undefined) {
        myListingStatusRecord.createdAt = "";
        myListingStatusRecord.shortDate = "";
      } else {
        myListingStatusRecord.createdAt = createdAt;
        var shortDate = thisListingStatusRecord.createdAt.toLocaleDateString();
        myListingStatusRecord.shortDate = shortDate;
      }
      var updatedAt = thisListingStatusRecord.updatedAt;
      myListingStatusRecord.updatedAt = updatedAt;

      var objectId = thisListingStatusRecord.id;
      if (objectId == undefined) {
        myListingStatusRecord.objectId = "";
      } else {
        myListingStatusRecord.objectId = objectId;
      }
      return myListingStatusRecord;
    },

    convertToSellerStatusRecord: function convertToSellerStatusRecord(item) {
      //var str = JSON.stringify(item);
      //console.log("converting " + str);
      //console.log("converting 1" );
      var mySellerStatusRecord = {};
      var thisSellerStatusRecord = item;

      var status = thisSellerStatusRecord.get("status");
      if (status == undefined) {
        mySellerStatusRecord.status = "";
      } else {
        mySellerStatusRecord.status = status;
      }

      var vendor = thisSellerStatusRecord.get("vendor");
      if (vendor == undefined) {
        mySellerStatusRecord.vendor = {};
      } else {
        mySellerStatusRecord.vendor = this.convertToVendor(vendor);
      }

      var vendorId = thisSellerStatusRecord.get("vendorId");
      if (vendorId == undefined) {
        mySellerStatusRecord.vendorId = "";
      } else {
        mySellerStatusRecord.vendorId = vendorId;
      }
      var user = thisSellerStatusRecord.get("user");
      if (user == undefined) {
        mySellerStatusRecord.user = {};
      } else {
        mySellerStatusRecord.user = this.convertToUser(user);
      }
      var userId = thisSellerStatusRecord.get("userId");
      if (userId == undefined) {
        mySellerStatusRecord.userId = "";
      } else {
        mySellerStatusRecord.userId = userId;
      }
      var createdAt = thisSellerStatusRecord.createdAt;
      if (createdAt == undefined) {
        mySellerStatusRecord.createdAt = "";
        mySellerStatusRecord.shortDate = "";
      } else {
        mySellerStatusRecord.createdAt = createdAt;
        var shortDate = thisSellerStatusRecord.createdAt.toLocaleDateString();
        mySellerStatusRecord.shortDate = shortDate;
      }
      var updatedAt = thisSellerStatusRecord.updatedAt;
      mySellerStatusRecord.updatedAt = updatedAt;

      var objectId = thisSellerStatusRecord.id;
      if (objectId == undefined) {
        mySellerStatusRecord.objectId = "";
      } else {
        mySellerStatusRecord.objectId = objectId;
      }
      return mySellerStatusRecord;
    },

    convertToSentLetters: function convertToSentLetters(item) {
      //var str = JSON.stringify(item);
      //console.log("converting " + str);
      //console.log("converting 1" );
      var mySentLetter = {};
      var thisSentLetter = item;

      var user = thisSentLetter.get("user");
      if (user == undefined) {
        mySentLetter.user = {};
      } else {
        mySentLetter.user = this.convertToUser(user);
      }
      var userId = thisSentLetter.get("userId");
      if (userId == undefined) {
        mySentLetter.userId = "";
      } else {
        mySentLetter.userId = userId;
      }

      var vendor = thisSentLetter.get("vendor");
      if (vendor == undefined) {
        mySentLetter.vendor = {};
      } else {
        mySentLetter.vendor = this.convertToVendor(vendor);
      }
      var vendorId = thisSentLetter.get("vendorId");
      if (vendorId == undefined) {
        mySentLetter.vendorId = "";
      } else {
        mySentLetter.vendorId = vendorId;
      }

      var account = thisSentLetter.get("account");
      if (account == undefined) {
        mySentLetter.account = {};
      } else {
        mySentLetter.account = this.convertToAccount(account);
      }
      var accountId = thisSentLetter.get("accountId");
      if (accountId == undefined) {
        mySentLetter.accountId = "";
      } else {
        mySentLetter.accountId = accountId;
      }

      var createdAt = thisSentLetter.createdAt;
      if (createdAt == undefined) {
        mySentLetter.createdAt = "";
        mySentLetter.shortDate = "";
      } else {
        mySentLetter.createdAt = createdAt;
        var shortDate = thisSentLetter.createdAt.toLocaleDateString();
        mySentLetter.shortDate = shortDate;
      }

      var objectId = thisSentLetter.id;
      if (objectId == undefined) {
        mySentLetter.objectId = "";
      } else {
        mySentLetter.objectId = objectId;
      }

      var letterImage = thisSentLetter.get("letterImage");
      if (letterImage == undefined) {
        mySentLetter.letterImage = "";
      } else {
        mySentLetter.letterImage = letterImage.url();
      }

      var pdfImage = thisSentLetter.get("pdfImage");
      if (pdfImage == undefined) {
        mySentLetter.pdfImage = "";
      } else {
        mySentLetter.pdfImage = pdfImage.url();
      }

      var letterOrder = thisSentLetter.get("letterOrder");
      if (letterOrder == undefined) {
        mySentLetter.letterOrder = 0;
      } else {
        mySentLetter.letterOrder = letterOrder;
      }

      return mySentLetter;
    },

    convertToPartnerList: function convertToPartnerList(item) {
      //var str = JSON.stringify(item);
      //console.log("converting " + str);
      //console.log("converting 1" );
      var myPartnerList = {};
      var thisPartnerList = item;

      var name = thisPartnerList.get("name");
      if (name == undefined) {
        myPartnerList.name = "";
      } else {
        myPartnerList.name = name;
      }

      var createdAt = thisPartnerList.createdAt;
      if (createdAt == undefined) {
        myPartnerList.createdAt = "";
        myPartnerList.shortDate = "";
      } else {
        myPartnerList.createdAt = createdAt;
        var shortDate = thisPartnerList.createdAt.toLocaleDateString();
        myPartnerList.shortDate = shortDate;
      }

      var objectId = thisPartnerList.id;
      if (objectId == undefined) {
        myPartnerList.objectId = "";
      } else {
        myPartnerList.objectId = objectId;
      }

      return myPartnerList;
    },
    convertToShoppingPlatform: function convertToShoppingPlatform(item) {
      //var str = JSON.stringify(item);
      //console.log("converting " + str);
      //console.log("converting 1" );
      var myShoppingPlatform = {};
      var thisShoppingPlatform = item;

      var shoppingPlatform = thisShoppingPlatform.get("shoppingPlatform");
      if (shoppingPlatform == undefined) {
        myShoppingPlatform.shoppingPlatform = "";
      } else {
        myShoppingPlatform.shoppingPlatform = shoppingPlatform;
      }

      var lowercase = thisShoppingPlatform.get("lowercase");
      if (lowercase == undefined) {
        myShoppingPlatform.lowercase = "";
      } else {
        myShoppingPlatform.lowercase = lowercase;
      }

      var createdAt = thisShoppingPlatform.createdAt;
      if (createdAt == undefined) {
        myShoppingPlatform.createdAt = "";
        myShoppingPlatform.shortDate = "";
      } else {
        myShoppingPlatform.createdAt = createdAt;
        var shortDate = thisShoppingPlatform.createdAt.toLocaleDateString();
        myShoppingPlatform.shortDate = shortDate;
      }

      var objectId = thisShoppingPlatform.id;
      if (objectId == undefined) {
        myShoppingPlatform.objectId = "";
      } else {
        myShoppingPlatform.objectId = objectId;
      }

      return myShoppingPlatform;
    },

    convertToLead: function convertToLead(item) {
      //var str = JSON.stringify(item);
      //console.log("converting " + str);
      //console.log("converting 1" );
      var myLead = {};
      var thisLead = item;

      var brand = thisLead.get("brand");
      if (brand == undefined) {
        myLead.brand = "";
      } else {
        myLead.brand = brand;
      }

      var numberOfSellers = thisLead.get("numberOfSellers");
      if (numberOfSellers == undefined) {
        myLead.numberOfSellers = 0;
      } else {
        myLead.numberOfSellers = numberOfSellers;
      }

      var totalListings = thisLead.get("totalListings");
      if (totalListings == undefined) {
        myLead.totalListings = 0;
      } else {
        myLead.totalListings = totalListings;
      }

      var status = thisLead.get("status");
      if (status == undefined) {
        myLead.status = "unknown";
      } else {
        myLead.status = status;
      }

      var linkedInPage = thisLead.get("linkedInPage");
      if (linkedInPage == undefined) {
        myLead.linkedInPage = "";
      } else {
        myLead.linkedInPage = linkedInPage;
      }

      var website = thisLead.get("website");
      if (website == undefined) {
        myLead.website = "";
      } else {
        myLead.website = website;
      }

      var partner = thisLead.get("partner");
      if (partner == undefined) {
        myLead.partner = "";
      } else {
        myLead.partner = partner;
      }

      var planType = thisLead.get("planType");
      if (planType == undefined) {
        myLead.planType = "";
      } else {
        myLead.planType = planType;
      }

      var quote = thisLead.get("quote");
      if (quote == undefined) {
        myLead.quote = "";
      } else {
        myLead.quote = quote;
      }

      var userId = thisLead.get("userId");
      if (userId == undefined) {
        myLead.userId = "";
      } else {
        myLead.userId = userId;
      }

      var userName = thisLead.get("userName");
      if (userName == undefined) {
        myLead.userName = "";
        myLead.hasUser = false;
      } else {
        myLead.userName = userName;
        myLead.hasUser = true;
      }

      var subStatus = thisLead.get("subStatus");
      if (subStatus == undefined) {
        myLead.subStatus = "none";
        myLead.hasSubStatus = false;
      } else {
        myLead.subStatus = subStatus;
        if (subStatus == "") {
          myLead.hasSubStatus = false;
        } else {
          myLead.hasSubStatus = true;
        }
      }

      var parentCompany = thisLead.get("parentCompany");
      if (parentCompany == undefined) {
        myLead.parentCompany = "";
      } else {
        myLead.parentCompany = parentCompany;
      }
      var notes = thisLead.get("notes");
      if (notes == undefined) {
        myLead.notes = "";
        myLead.shortNotes = "";
      } else {
        myLead.notes = notes;
        if (myLead.notes.length > 200) {
          var shortNotes = notes.substring(0, 200);
          myLead.shortNotes = shortNotes + "...";
        } else {
          myLead.shortNotes = notes;
        }
      }

      var lowercaseShoppingPlatform = thisLead.get("lowercaseShoppingPlatform");
      if (lowercaseShoppingPlatform == undefined) {
        myLead.lowercaseShoppingPlatform = "";
      } else {
        myLead.lowercaseShoppingPlatform = lowercaseShoppingPlatform;
      }

      var type = thisLead.get("type");
      if (type == undefined) {
        myLead.type = "";
      } else {
        myLead.type = type;
      }

      var followUpEmailSent = thisLead.get("followUpEmailSent");
      if (followUpEmailSent == undefined) {
        myLead.followUpEmailSent = false;
      } else {
        myLead.followUpEmailSent = followUpEmailSent;
      }

      var followUpDate = thisLead.get("followUpDate");
      if (followUpDate == undefined) {
        myLead.followUpDate = "";
      } else {
        myLead.followUpDate = followUpDate.toLocaleDateString();
      }

      var lastContacted = thisLead.get("lastContacted");
      if (lastContacted == undefined) {
        myLead.lastContacted = "";
      } else {
        myLead.lastContacted = lastContacted.toLocaleDateString();
      }

      var createdAt = thisLead.createdAt;
      if (createdAt == undefined) {
        myLead.createdAt = "";
        myLead.shortDate = "";
      } else {
        myLead.createdAt = createdAt;
        var shortDate = thisLead.createdAt.toLocaleDateString();
        myLead.shortDate = shortDate;
      }

      var objectId = thisLead.id;
      if (objectId == undefined) {
        myLead.objectId = "";
      } else {
        myLead.objectId = objectId;
      }

      return myLead;
    },

    convertToLeadContact: function convertToLeadContact(item) {
      //var str = JSON.stringify(item);
      //console.log("converting " + str);
      //console.log("converting 1" );
      var myLeadContact = {};
      var thisLeadContact = item;

      var fullName = thisLeadContact.get("fullName");
      if (fullName == undefined) {
        myLeadContact.fullName = "";
      } else {
        myLeadContact.fullName = fullName;
      }

      var title = thisLeadContact.get("title");
      if (title == undefined) {
        myLeadContact.title = "";
      } else {
        myLeadContact.title = title;
      }

      var email = thisLeadContact.get("email");
      if (email == undefined) {
        myLeadContact.email = "";
      } else {
        myLeadContact.email = email;
      }

      var linkedInProfile = thisLeadContact.get("linkedInProfile");
      if (linkedInProfile == undefined) {
        myLeadContact.linkedInProfile = "";
      } else {
        myLeadContact.linkedInProfile = linkedInProfile;
      }

      var phone = thisLeadContact.get("phone");
      if (phone == undefined) {
        myLeadContact.phone = "";
      } else {
        myLeadContact.phone = phone;
      }

      var mobilePhone = thisLeadContact.get("mobilePhone");
      if (mobilePhone == undefined) {
        myLeadContact.mobilePhone = "";
      } else {
        myLeadContact.mobilePhone = mobilePhone;
      }

      var status = thisLeadContact.get("status");
      if (status == undefined) {
        myLeadContact.status = "";
      } else {
        myLeadContact.status = status;
      }

      var timesContacted = thisLeadContact.get("timesContacted");
      if (timesContacted == undefined) {
        myLeadContact.timesContacted = 0;
      } else {
        myLeadContact.timesContacted = timesContacted;
      }

      var lastContacted = thisLeadContact.get("lastContacted");
      if (lastContacted == undefined) {
        myLeadContact.lastContacted = "";
      } else {
        myLeadContact.lastContacted = lastContacted.toLocaleDateString();
      }

      var lead = thisLeadContact.get("lead");
      if (lead == undefined) {
        myLeadContact.lead = {};
      } else {
        myLeadContact.lead = this.convertToLead(lead);
      }

      var isContacted = thisLeadContact.get("isContacted");
      if (isContacted == undefined) {
        myLeadContact.isContacted = false;
      } else {
        myLeadContact.isContacted = isContacted;
      }

      var createdAt = thisLeadContact.createdAt;
      if (createdAt == undefined) {
        myLeadContact.createdAt = "";
        myLeadContact.shortDate = "";
      } else {
        myLeadContact.createdAt = createdAt;
        var shortDate = createdAt.toLocaleDateString();
        myLeadContact.shortDate = shortDate;
      }

      var objectId = thisLeadContact.id;
      if (objectId == undefined) {
        myLeadContact.objectId = "";
      } else {
        myLeadContact.objectId = objectId;
      }

      return myLeadContact;
    },

    convertToLeadFile: function convertToLeadFile(item) {
      //var str = JSON.stringify(item);
      //console.log("converting " + str);
      //console.log("converting 1" );
      var myLeadFile = {};
      var thisLeadFile = item;

      var name = thisLeadFile.get("name");
      if (name == undefined) {
        myLeadFile.name = "";
      } else {
        myLeadFile.name = name;
      }

      var file = thisLeadFile.get("file");
      if (file == undefined) {
        myLeadFile.file = "";
      } else {
        myLeadFile.file = file.url();
      }

      var createdAt = thisLeadFile.createdAt;
      if (createdAt == undefined) {
        myLeadFile.createdAt = "";
        myLeadFile.shortDate = "";
      } else {
        myLeadFile.createdAt = createdAt;
        var shortDate = createdAt.toLocaleDateString();
        myLeadFile.shortDate = shortDate;
      }

      var objectId = thisLeadFile.id;
      if (objectId == undefined) {
        myLeadFile.objectId = "";
      } else {
        myLeadFile.objectId = objectId;
      }

      return myLeadFile;
    },
    convertToSafeSeller: function convertToSafeSeller(item) {
      var mySafeSeller = {};
      var thisSafeSeller = item;

      var name = thisSafeSeller.get("name");
      if (name == undefined) {
        mySafeSeller.name = "";
      } else {
        mySafeSeller.name = name;
      }

      var file = thisSafeSeller.get("file");
      if (file == undefined) {
        mySafeSeller.file = "";
      } else {
        mySafeSeller.file = file.url();
      }

      var createdAt = thisSafeSeller.createdAt;
      if (createdAt == undefined) {
        mySafeSeller.createdAt = "";
        mySafeSeller.shortDate = "";
      } else {
        mySafeSeller.createdAt = createdAt;
        var shortDate = createdAt.toLocaleDateString();
        mySafeSeller.shortDate = shortDate;
      }

      var objectId = thisSafeSeller.id;
      if (objectId == undefined) {
        mySafeSeller.objectId = "";
      } else {
        mySafeSeller.objectId = objectId;
      }

      return mySafeSeller;
    },
    convertToMAPProduct: function convertToMAPProduct(item) {
      var myMAPProduct = {};
      var thisMAPProduct = item;

      var account = thisMAPProduct.get("account");
      if (account == undefined) {
        myMAPProduct.account = "";
      } else {
        myMAPProduct.account = this.convertToAccount(account);
      }

      var accountId = thisMAPProduct.get("accountId");
      if (accountId == undefined) {
        myMAPProduct.accountId = "";
      } else {
        myMAPProduct.accountId = accountId;
      }

      var SKU = thisMAPProduct.get("SKU");
      if (SKU == undefined) {
        myMAPProduct.SKU = "";
      } else {
        myMAPProduct.SKU = SKU.toUpperCase();
      }

      var ASIN = thisMAPProduct.get("ASIN");
      if (ASIN == undefined) {
        myMAPProduct.ASIN = "";
      } else {
        myMAPProduct.ASIN = ASIN.toUpperCase();
      }

      var model_number = thisMAPProduct.get("model_number");
      if (model_number == undefined) {
        myMAPProduct.model_number = "";
      } else {
        myMAPProduct.model_number = model_number.toUpperCase();;
      }

      var upc_number = thisMAPProduct.get("upc_number");
      if (upc_number == undefined) {
        myMAPProduct.upc_number = "";
      } else {
        myMAPProduct.upc_number = upc_number.toUpperCase();
      }

      var itemNumber = thisMAPProduct.get("itemNumber");
      if (itemNumber == undefined) {
        myMAPProduct.itemNumber = "";
      } else {
        myMAPProduct.itemNumber = itemNumber;
      }

      var map_price = thisMAPProduct.get("map_price");
      if (map_price == undefined) {
        myMAPProduct.map_price = 0;
      } else {
        myMAPProduct.map_price = map_price;
      }

      var nonCompliant = thisMAPProduct.get("nonCompliant");
      if (nonCompliant == undefined) {
        myMAPProduct.nonCompliant = 0;
        myMAPProduct.nonCompliantCaps = "Compliant";
        myMAPProduct.nonCompliantLower = "compliant";
      } else {
        myMAPProduct.nonCompliant = nonCompliant;
        if (nonCompliant > 0) {
          myMAPProduct.nonCompliantCaps = "Non-Compliant";
          myMAPProduct.nonCompliantLower = "non-compliant";
        } else {
          myMAPProduct.nonCompliantCaps = "Compliant";
          myMAPProduct.nonCompliantLower = "compliant";
        }
      }

      var numTotalViolations = thisMAPProduct.get("numTotalViolations");
      if (numTotalViolations == undefined) {
        myMAPProduct.numTotalViolations = 0;
      } else {
        myMAPProduct.numTotalViolations = numTotalViolations;
      }

      var discontinued = thisMAPProduct.get("discontinued");
      if (discontinued == undefined) {
        myMAPProduct.discontinued = false;
      } else {
        myMAPProduct.discontinued = discontinued;
      }

      var msrp_price = thisMAPProduct.get("msrp_price");
      if (msrp_price == undefined) {
        myMAPProduct.msrp_price = 0;
      } else {
        myMAPProduct.msrp_price = msrp_price;
      }

      var keyType = thisMAPProduct.get("keyType");
      if (keyType == undefined) {
        myMAPProduct.keyType = "";
      } else {
        myMAPProduct.keyType = keyType;
      }

      var title = thisMAPProduct.get("title");
      if (title == undefined) {
        myMAPProduct.title = "";
      } else {
        myMAPProduct.title = title;
      }

      var createdAt = thisMAPProduct.createdAt;
      if (createdAt == undefined) {
        myMAPProduct.createdAt = "";
        myMAPProduct.shortDate = "";
      } else {
        myMAPProduct.createdAt = createdAt;
        var shortDate = createdAt.toLocaleDateString();
        myMAPProduct.shortDate = shortDate;
      }

      var updatedAt = thisMAPProduct.updatedAt;
      if (updatedAt == undefined) {
        myMAPProduct.updatedAt = "";
        myMAPProduct.shortUpdatedAtDate = "";
      } else {
        myMAPProduct.updatedAt = updatedAt;
        var shortUpdatedAtDate = updatedAt.toLocaleDateString();
        myMAPProduct.shortUpdatedAtDate = shortUpdatedAtDate;
      }

      var objectId = thisMAPProduct.id;
      if (objectId == undefined) {
        myMAPProduct.objectId = "";
      } else {
        myMAPProduct.objectId = objectId;
      }

      var currencyType = myMAPProduct.account.alternatePrice ? myMAPProduct.account.priceType : '$';
      if (map_price === undefined) {
        myMAPProduct.mapPriceFormatted = currencyType + '0.00';
      } else {
        myMAPProduct.mapPriceFormatted = currencyType + ('' + map_price.toFixed(2));
      }

      if (msrp_price === undefined) {
        myMAPProduct.msrpPriceFormatted = currencyType + '0.00';
      } else {
        myMAPProduct.msrpPriceFormatted = currencyType + ('' + msrp_price.toFixed(2));
      }

      if (nonCompliant === undefined) {
        myMAPProduct.nonCompliantLabel = '0';
      } else {
        if (nonCompliant > 0) {
          myMAPProduct.nonCompliantLabel = '' + nonCompliant;
        } else {
          myMAPProduct.nonCompliantLabel = '0';
        }
      }

      var image = thisMAPProduct.get("image");
      if (image == undefined) {
        myMAPProduct.image = "";
      } else {
        myMAPProduct.image = image;
      }

      var size = thisMAPProduct.get("size");
      if (size == undefined) {
        myMAPProduct.size = "";
      } else {
        myMAPProduct.size = size;
      }

      var priceVariance = thisMAPProduct.get("priceVariance");
      if (priceVariance == undefined) {
        myMAPProduct.priceVariance = 0;
      } else {
        myMAPProduct.priceVariance = priceVariance;
      }

      var productDescription = thisMAPProduct.get("productDescription");
      if (productDescription == undefined) {
        myMAPProduct.productDescription = "";
      } else {
        myMAPProduct.productDescription = productDescription;
      }

      var searchArray = thisMAPProduct.get("searchArray");
      if (searchArray == undefined) {
        myMAPProduct.searchArray = [];
      } else {
        myMAPProduct.searchArray = searchArray;
      }

      var SIZE_ARRAY = thisMAPProduct.get("SIZE_ARRAY");
      if (SIZE_ARRAY == undefined) {
        myMAPProduct.SIZE_ARRAY = [];
      } else {
        myMAPProduct.SIZE_ARRAY = SIZE_ARRAY;
      }

      var UPC_ARRAY = thisMAPProduct.get("UPC_ARRAY");
      if (UPC_ARRAY == undefined) {
        myMAPProduct.UPC_ARRAY = [];
      } else {
        myMAPProduct.UPC_ARRAY = UPC_ARRAY;
      }

      var ASIN_ARRAY = thisMAPProduct.get("ASIN_ARRAY");
      if (ASIN_ARRAY == undefined) {
        myMAPProduct.ASIN_ARRAY = [];
      } else {
        myMAPProduct.ASIN_ARRAY = ASIN_ARRAY;
      }

      var futureMapPrice = thisMAPProduct.get("futureMapPrice");
      if (futureMapPrice == undefined) {
        myMAPProduct.futureMapPrice = 0;
        myMAPProduct.futureMapPriceFormatted = currencyType + '0.00';
      } else {
        myMAPProduct.futureMapPrice = futureMapPrice;
        myMAPProduct.futureMapPriceFormatted = currencyType + ('' + futureMapPrice.toFixed(2));
      }

      var futureMapPriceDate = thisMAPProduct.get("futureMapPriceDate");
      if (futureMapPriceDate == undefined) {
        myMAPProduct.futureMapPriceDate = "";
        myMAPProduct.futureMapPriceDateShort = "";
      } else {
        myMAPProduct.futureMapPriceDate = futureMapPriceDate;
        myMAPProduct.futureMapPriceDateShort = futureMapPriceDate.toLocaleDateString();
      }

      return myMAPProduct;
    },
    convertToMAPListing: function convertToMAPListing(item) {
      var myMAPListing = {};
      var thisMAPListing = item;

      var account = thisMAPListing.get("account");
      var partner = "";
      if (account == undefined) {
        myMAPListing.account = {};
      } else {
        myMAPListing.account = this.convertToAccount(account);
        partner = myMAPListing.account.partner;
      }

      var listing = thisMAPListing.get("listing");
      if (listing == undefined) {
        myMAPListing.listing = "";
      } else {
        myMAPListing.listing = this.convertToListing(listing);
      }

      var mapProduct = thisMAPListing.get("mapProduct");
      var mapProductTitle = "";
      if (mapProduct == undefined) {
        myMAPListing.mapProduct = {};
      } else {
        myMAPListing.mapProduct = this.convertToMAPProduct(mapProduct);
        mapProductTitle = myMAPListing.mapProduct.title;
      }

      var mapSeller = thisMAPListing.get("mapSeller");
      if (mapSeller == undefined) {
        myMAPListing.mapSeller = {};
      } else {
        myMAPListing.mapSeller = this.convertToMAPSeller(mapSeller);
      }

      var mapSellerId = thisMAPListing.get("mapSellerId");
      if (mapSellerId == undefined) {
        myMAPListing.mapSellerId = "";
      } else {
        myMAPListing.mapSellerId = mapSellerId;
      }

      var model_number = thisMAPListing.get("model_number");
      if (model_number == undefined) {
        myMAPListing.model_number = "";
      } else {
        myMAPListing.model_number = model_number;
      }

      var accountId = thisMAPListing.get("accountId");
      if (accountId == undefined) {
        myMAPListing.accountId = "";
      } else {
        myMAPListing.accountId = accountId;
      }

      var title = thisMAPListing.get("title");
      if (title == undefined) {
        myMAPListing.title = "";
      } else {
        myMAPListing.title = title;
      }
      if (partner == "caleres" && mapProductTitle != undefined && mapProductTitle != "") {
        myMAPListing.title = mapProductTitle;
      }

      var status = thisMAPListing.get("status");
      if (status == undefined) {
        myMAPListing.status = "";
        myMAPListing.statusNumber = 0;
      } else {
        myMAPListing.status = status;
        if (status == "compliant") {
          myMAPListing.statusNumber = 0;
        } else {
          myMAPListing.statusNumber = 1;
        }
      }

      var currentItemURL = thisMAPListing.get("currentItemURL");
      if (currentItemURL == undefined) {
        myMAPListing.currentItemURL = "";
      } else {
        myMAPListing.currentItemURL = currentItemURL;
      }

      var shoppingPlatform = thisMAPListing.get("shoppingPlatform");
      if (shoppingPlatform == undefined) {
        myMAPListing.shoppingPlatform = "";
      } else {
        myMAPListing.shoppingPlatform = shoppingPlatform;
      }

      var condition = thisMAPListing.get("condition");
      if (condition == undefined) {
        myMAPListing.condition = "";
      } else {
        myMAPListing.condition = condition;
      }

      var lastViolationDate = thisMAPListing.get("lastViolationDate");
      if (lastViolationDate == undefined) {
        myMAPListing.lastViolationDate = "";
        myMAPListing.lastViolationTime = "";
      } else {
        myMAPListing.lastViolationDate = lastViolationDate.toLocaleDateString();
        myMAPListing.lastViolationTime = convertTimeOfDate(lastViolationDate);
      }

      var lastSeen = thisMAPListing.get("lastSeen");
      if (lastSeen == undefined) {
        myMAPListing.lastSeen = "";
        myMAPListing.lastSeenTime = "";
      } else {
        myMAPListing.lastSeen = lastSeen.toLocaleDateString();
        myMAPListing.lastSeenTime = convertTimeOfDate(lastSeen);
      }

      var numberOfInfractions = thisMAPListing.get("numberOfInfractions");
      if (numberOfInfractions == undefined) {
        myMAPListing.numberOfInfractions = 0;
      } else {
        myMAPListing.numberOfInfractions = numberOfInfractions;
      }

      var currentPrice = thisMAPListing.get("currentPrice");
      if (currentPrice == undefined) {
        myMAPListing.currentPrice = 0;
      } else {
        myMAPListing.currentPrice = currentPrice;
      }

      var map_price = thisMAPListing.get("map_price");
      if (map_price == undefined) {
        myMAPListing.map_price = 0;
      } else {
        myMAPListing.map_price = map_price;
      }

      var myMAPListingdifference = myMAPListing.map_price - myMAPListing.currentPrice;
      if (myMAPListing.map_price <= 0 && Object.keys(myMAPListing.mapProduct).length !== 0) {
        myMAPListingdifference = myMAPListing.mapProduct.msrp_price - myMAPListing.currentPrice;
      }
      myMAPListing.difference = -myMAPListingdifference.toFixed(2);
      var fixedMapVariance = 0;
      if (myMAPListing.account != {}) {
        var thisMapVariance = myMAPListing.account.mapVariance;
        if (thisMapVariance != undefined) {
          fixedMapVariance = thisMapVariance;
        }
      }

      var myMAPListingAdjustedDifference = myMAPListing.map_price - fixedMapVariance;
      myMAPListingAdjustedDifference = myMAPListingAdjustedDifference - myMAPListing.currentPrice;
      myMAPListing.adjustedDifference = -myMAPListingAdjustedDifference.toFixed(2);

      var createdAt = thisMAPListing.createdAt;
      if (createdAt == undefined) {
        myMAPListing.createdAt = "";
        myMAPListing.shortDate = "";
      } else {
        myMAPListing.createdAt = createdAt;
        var shortDate = createdAt.toLocaleDateString();
        myMAPListing.shortDate = shortDate;
      }

      if (createdAt == undefined) {
        myMAPListing.time = "";
      } else {
        var hours = createdAt.getHours();
        var minutes = createdAt.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        hours = hours <= 9 ? '0' + hours : '' + hours;
        minutes = minutes <= 9 ? '0' + minutes : '' + minutes;
        myMAPListing.time = hours + ':' + minutes + ampm;
      }

      var updatedAt = thisMAPListing.updatedAt;
      if (updatedAt == undefined) {
        myMAPListing.updatedAt = "";
        myMAPListing.shortUpdatedAt = "";
      } else {
        myMAPListing.updatedAt = updatedAt;
        var shortUpdatedAt = updatedAt.toLocaleDateString();
        myMAPListing.shortUpdatedAt = shortUpdatedAt;
      }

      var descriptionNumber = thisMAPListing.get("descriptionNumber");
      if (descriptionNumber == undefined || descriptionNumber == 0) {
        myMAPListing.descriptionNumber = "";
      } else {
        myMAPListing.descriptionNumber = descriptionNumber + "%";
      }

      var description = thisMAPListing.get("description");
      if (description == undefined || description == 0) {
        myMAPListing.description = "";
      } else {
        myMAPListing.description = description;
      }

      //

      var objectId = thisMAPListing.id;
      if (objectId == undefined) {
        myMAPListing.objectId = "";
      } else {
        myMAPListing.objectId = objectId;
      }

      var currencyType = myMAPListing.account.alternatePrice ? myMAPListing.account.priceType : '$';
      if (currentPrice == undefined) {
        myMAPListing.currentPriceFormatted = currencyType + '0.00';
      } else {
        myMAPListing.currentPriceFormatted = currencyType + ('' + currentPrice.toFixed(2));
      }

      var mapPrice = thisMAPListing.get("map_price");
      if (mapPrice == undefined) {
        myMAPListing.mapPriceFormatted = currencyType + '0.00';
      } else {
        myMAPListing.mapPriceFormatted = currencyType + ('' + map_price.toFixed(2));
      }
      //var myMAPChange = myMAPListing.map - myMAPListing.price;
      myMAPListing.change = myMAPListingdifference > 0 ? '-' + currencyType + myMAPListingdifference.toFixed(2) : '' + currencyType + (-myMAPListingdifference).toFixed(2);

      if (lastViolationDate === undefined) {
        myMAPListing.trailing = "";
      } else {
        var todayDate = new Date();
        var diffInDays = getNumberOfDays(todayDate, lastViolationDate);

        if (diffInDays) {
          myMAPListing.trailing = diffInDays + ' ' + (diffInDays > 1 ? 'days' : 'day');
        } else {
          myMAPListing.trailing = "";
        }
      }

      var SIZE_ARRAY = thisMAPListing.get("SIZE_ARRAY");
      if (SIZE_ARRAY == undefined) {
        myMAPListing.SIZE_ARRAY = "";
      } else {
        myMAPListing.SIZE_ARRAY = SIZE_ARRAY;
      }

      var size = thisMAPListing.get("size");
      if (size == undefined) {
        myMAPListing.size = "";
      } else {
        myMAPListing.size = size;
      }

      var stage = thisMAPListing.get("stage");
      if (stage == undefined) {
        myMAPListing.stage = 0;
      } else {
        myMAPListing.stage = stage;
      }

      var completedStage = thisMAPListing.get("completedStage");
      if (completedStage == undefined) {
        myMAPListing.completedStage = 0;
      } else {
        myMAPListing.completedStage = completedStage;
      }

      return myMAPListing;
    },
    convertToMAPSeller: function convertToMAPSeller(item) {
      var myMAPSeller = {};
      var thisMAPSeller = item;

      var account = thisMAPSeller.get("account");
      if (account == undefined) {
        myMAPSeller.account = "";
      } else {
        myMAPSeller.account = this.convertToAccount(account);
      }

      var accountId = thisMAPSeller.get("accountId");
      if (accountId == undefined) {
        myMAPSeller.accountId = "";
      } else {
        myMAPSeller.accountId = accountId;
      }

      var stage = thisMAPSeller.get("stage");
      if (stage == undefined) {
        myMAPSeller.stage = 0;
      } else {
        myMAPSeller.stage = stage;
      }

      var lastViolation = thisMAPSeller.get("lastViolation");
      if (lastViolation == undefined) {
        myMAPSeller.lastViolation = "";
        myMAPSeller.lastViolationShortDate = "";
      } else {
        myMAPSeller.lastViolation = lastViolation;
        var lastViolationShortDate = lastViolation.toLocaleDateString();
        myMAPSeller.lastViolationShortDate = lastViolationShortDate;
      }

      var nonCompliant = thisMAPSeller.get("nonCompliant");
      if (nonCompliant == undefined) {
        myMAPSeller.nonCompliant = 0;
        myMAPSeller.nonCompliantCaps = "Compliant";
        myMAPSeller.nonCompliantLower = "compliant";
      } else {
        myMAPSeller.nonCompliant = nonCompliant;
        if (nonCompliant > 0) {
          myMAPSeller.nonCompliantCaps = "Non-Compliant";
          myMAPSeller.nonCompliantLower = "non-compliant";
        } else {
          myMAPSeller.nonCompliantCaps = "Compliant";
          myMAPSeller.nonCompliantLower = "compliant";
        }
      }

      var numberOfListings = thisMAPSeller.get("numberOfListings");
      if (numberOfListings == undefined) {
        myMAPSeller.numberOfListings = 0;
      } else {
        myMAPSeller.numberOfListings = numberOfListings;
      }

      var sellerName = thisMAPSeller.get("sellerName");
      if (sellerName == undefined) {
        myMAPSeller.sellerName = "";
      } else {
        myMAPSeller.sellerName = sellerName;
      }

      var currentSellerURL = thisMAPSeller.get("currentSellerURL");
      if (currentSellerURL == undefined) {
        myMAPSeller.currentSellerURL = "";
      } else {
        myMAPSeller.currentSellerURL = currentSellerURL;
      }

      var shoppingPlatform = thisMAPSeller.get("shoppingPlatform");
      if (shoppingPlatform == undefined) {
        myMAPSeller.shoppingPlatform = "";
      } else {
        myMAPSeller.shoppingPlatform = shoppingPlatform;
      }

      var numberOfInfractions = thisMAPSeller.get("numberOfInfractions");
      if (numberOfInfractions == undefined) {
        myMAPSeller.numberOfInfractions = 0;
      } else {
        myMAPSeller.numberOfInfractions = numberOfInfractions;
      }

      var createdAt = thisMAPSeller.createdAt;
      if (createdAt == undefined) {
        myMAPSeller.createdAt = "";
        myMAPSeller.shortDate = "";
      } else {
        myMAPSeller.createdAt = createdAt;
        var shortDate = createdAt.toLocaleDateString();
        myMAPSeller.shortDate = shortDate;
      }

      var updatedAt = thisMAPSeller.updatedAt;
      if (createdAt == updatedAt) {
        myMAPSeller.updatedAt = "";
        myMAPSeller.shortUpdatedAt = "";
      } else {
        myMAPSeller.updatedAt = createdAt;
        var shortUpdatedAt = updatedAt.toLocaleDateString();
        myMAPSeller.shortUpdatedAt = shortUpdatedAt;
      }

      var nonCompliantLabel = thisMAPSeller.get("nonCompliant");
      if (nonCompliantLabel == undefined) {
        myMAPSeller.nonCompliantLabel = 0;
      } else {
        if (nonCompliantLabel <= 0) {
          myMAPSeller.nonCompliantLabel = 0;
        } else {
          myMAPSeller.nonCompliantLabel = nonCompliantLabel;
        }
      }

      var completedStage = thisMAPSeller.get("completedStage");
      if (completedStage == undefined) {
        myMAPSeller.completedStage = 0;
      } else {
        myMAPSeller.completedStage = completedStage;
      }

      var activeSkus = thisMAPSeller.get("activeSkus");
      if (activeSkus == undefined) {
        myMAPSeller.activeSkus = 0;
      } else {
        myMAPSeller.activeSkus = activeSkus;
      }

      var inActiveSkus = thisMAPSeller.get("inActiveSkus");
      if (inActiveSkus == undefined) {
        myMAPSeller.inActiveSkus = 0;
      } else {
        if (myMAPSeller.account != undefined) {
          if (myMAPSeller.account.totalMapSkus != 0) {
            myMAPSeller.inActiveSkus = myMAPSeller.account.totalMapSkus - myMAPSeller.activeSkus;
          } else {
            myMAPSeller.inActiveSkus = inActiveSkus;
          }
        } else {
          myMAPSeller.inActiveSkus = inActiveSkus;
        }
      }

      var is3p = thisMAPSeller.get("is3p");
      if (is3p == undefined) {
        myMAPSeller.is3p = false;
      } else {
        myMAPSeller.is3p = is3p;
      }

      var email = thisMAPSeller.get("email");
      if (email == undefined) {
        myMAPSeller.email = "";
      } else {
        myMAPSeller.email = email;
      }

      var emailArrayAll = thisMAPSeller.get("emailArrayAll");
      if (emailArrayAll == undefined) {
        myMAPSeller.emailArrayAll = [];
      } else {
        myMAPSeller.emailArrayAll = emailArrayAll;
      }

      var emailArray2Notices = thisMAPSeller.get("emailArray2Notices");
      if (emailArray2Notices == undefined) {
        myMAPSeller.emailArray2Notices = [];
      } else {
        myMAPSeller.emailArray2Notices = emailArray2Notices;
      }

      var emailArray4th = thisMAPSeller.get("emailArray4th");
      if (emailArray4th == undefined) {
        myMAPSeller.emailArray4th = [];
      } else {
        myMAPSeller.emailArray4th = emailArray4th;
      }

      if (myMAPSeller.emailArray4th.length > 0 || myMAPSeller.emailArray2Notices.length > 0 || myMAPSeller.emailArrayAll.length > 0) {
        myMAPSeller.hasEmail = true;
      } else {
        myMAPSeller.hasEmail = false;
      }

      var lastSeen = thisMAPSeller.get("lastSeen");
      if (lastSeen == undefined) {
        myMAPSeller.lastSeen = "";
        myMAPSeller.lastSeenShort = "";
      } else {
        myMAPSeller.lastSeen = lastSeen;
        myMAPSeller.lastSeenShort = lastSeen.toLocaleDateString();
      }

      var objectId = thisMAPSeller.id;
      if (objectId == undefined) {
        myMAPSeller.objectId = "";
      } else {
        myMAPSeller.objectId = objectId;
      }

      return myMAPSeller;
    },
    convertToMAPInfringement: function convertToMAPInfringement(item) {
      var myMAPInfringement = {};
      var thisMAPInfringement = item;

      var account = thisMAPInfringement.get("account");
      if (account == undefined) {
        myMAPInfringement.account = "";
      } else {
        myMAPInfringement.account = this.convertToAccount(account);
      }

      var mapProduct = thisMAPInfringement.get("mapProduct");
      if (mapProduct == undefined) {
        myMAPInfringement.mapProduct = {};
      } else {
        myMAPInfringement.mapProduct = this.convertToMAPProduct(mapProduct);
      }

      var mapSeller = thisMAPInfringement.get("mapSeller");
      if (mapSeller == undefined) {
        myMAPInfringement.mapSeller = {};
      } else {
        myMAPInfringement.mapSeller = this.convertToMAPSeller(mapSeller);
      }

      var mapListing = thisMAPInfringement.get("mapListing");
      if (mapListing == undefined) {
        myMAPInfringement.mapListing = {};
      } else {
        myMAPInfringement.mapListing = this.convertToMAPListing(mapListing);
      }

      var listing = thisMAPInfringement.get("listing");
      if (listing == undefined) {
        myMAPInfringement.listing = {};
      } else {
        myMAPInfringement.listing = this.convertToListing(listing);
      }

      var accountId = thisMAPInfringement.get("accountId");
      if (accountId == undefined) {
        myMAPInfringement.accountId = "";
      } else {
        myMAPInfringement.accountId = accountId;
      }

      var title = thisMAPInfringement.get("title");
      if (title == undefined) {
        myMAPInfringement.title = "";
      } else {
        myMAPInfringement.title = title;
      }

      var itemURL = thisMAPInfringement.get("itemURL");
      if (itemURL == undefined) {
        myMAPInfringement.itemURL = "";
      } else {
        myMAPInfringement.itemURL = itemURL;
      }

      var shoppingPlatform = thisMAPInfringement.get("shoppingPlatform");
      if (shoppingPlatform == undefined) {
        myMAPInfringement.shoppingPlatform = "";
      } else {
        myMAPInfringement.shoppingPlatform = shoppingPlatform;
      }

      var price = thisMAPInfringement.get("price");
      if (price == undefined) {
        myMAPInfringement.price = 0;
      } else {
        myMAPInfringement.price = price;
      }

      var map_price = thisMAPInfringement.get("map_price");
      if (map_price == undefined) {
        myMAPInfringement.map_price = 0;
      } else {
        myMAPInfringement.map_price = map_price;
      }

      var myMAPListingdifference = myMAPInfringement.price - myMAPInfringement.map_price;
      myMAPInfringement.difference = myMAPListingdifference.toFixed(2);

      var currencyType = myMAPInfringement.account.alternatePrice ? myMAPInfringement.account.priceType : '$';
      if (map_price === undefined) {
        myMAPInfringement.mapPriceFormatted = currencyType + '0.00';
      } else {
        myMAPInfringement.mapPriceFormatted = currencyType + ('' + map_price.toFixed(2));
      }

      if (price === undefined) {
        myMAPInfringement.priceFormatted = currencyType + '0.00';
      } else {
        myMAPInfringement.priceFormatted = currencyType + ('' + price.toFixed(2));
      }

      if (myMAPInfringement.difference === undefined) {
        myMAPInfringement.differenceFormatted = currencyType + '0.00';
      } else {
        myMAPInfringement.differenceFormatted = myMAPInfringement.difference > 0 ? '' + currencyType + myMAPInfringement.difference : '-' + currencyType + -myMAPInfringement.difference;
      }

      var createdAt = thisMAPInfringement.createdAt;
      if (createdAt == undefined) {
        myMAPInfringement.createdAt = "";
        myMAPInfringement.shortDate = "";
      } else {
        myMAPInfringement.createdAt = createdAt;
        var shortDate = createdAt.toLocaleDateString();
        myMAPInfringement.shortDate = shortDate;
      }

      var updatedAt = thisMAPInfringement.updatedAt;
      if (createdAt == updatedAt) {
        myMAPInfringement.updatedAt = "";
        myMAPInfringement.shortUpdatedAt = "";
      } else {
        myMAPInfringement.updatedAt = createdAt;
        var shortUpdatedAt = updatedAt.toLocaleDateString();
        myMAPInfringement.shortUpdatedAt = shortUpdatedAt;
      }

      var objectId = thisMAPInfringement.id;
      if (objectId == undefined) {
        myMAPInfringement.objectId = "";
      } else {
        myMAPInfringement.objectId = objectId;
      }

      return myMAPInfringement;
    },
    convertToMAPInbox: function convertToMAPInbox(item) {
      var myMAPInbox = {};

      var account = item.get("account");
      if (account == undefined) {
        myMAPInbox.account = "";
      } else {
        myMAPInbox.account = this.convertToAccount(account);
      }

      var mapSeller = item.get("mapSeller");
      if (mapSeller == undefined) {
        myMAPInbox.mapSeller = {};
      } else {
        myMAPInbox.mapSeller = this.convertToMAPSeller(mapSeller);
      }

      var numberOfViolations = item.get("numberOfViolations");
      if (numberOfViolations == undefined) {
        myMAPInbox.numberOfViolations = 0;
      } else {
        myMAPInbox.numberOfViolations = numberOfViolations;
      }

      var status = item.get("status");
      if (status == undefined) {
        myMAPInbox.status = "";
      } else {
        myMAPInbox.status = status;
      }

      var shoppingPlatform = item.get("shoppingPlatform");
      if (shoppingPlatform == undefined) {
        myMAPInbox.shoppingPlatform = "";
      } else {
        myMAPInbox.shoppingPlatform = shoppingPlatform;
      }

      var messageType = item.get("messageType");
      if (messageType == undefined) {
        myMAPInbox.messageType = "";
      } else {
        myMAPInbox.messageType = messageType;
      }

      var sendTo = item.get("sendTo");
      if (sendTo == undefined) {
        myMAPInbox.sendTo = "";
      } else {
        myMAPInbox.sendTo = sendTo;
      }

      var stage = item.get("stage");
      if (stage == undefined) {
        myMAPInbox.stage = 0;
      } else {
        myMAPInbox.stage = stage;
      }

      var nonCompliant = item.get("nonCompliant");
      if (nonCompliant == undefined) {
        myMAPInbox.nonCompliant = 0;
      } else {
        myMAPInbox.nonCompliant = nonCompliant;
      }

      var text = item.get("text");
      if (text == undefined) {
        myMAPInbox.text = "";
      } else {
        myMAPInbox.text = text;
      }

      var createdAt = item.createdAt;
      if (createdAt == undefined) {
        myMAPInbox.createdAt = "";
        myMAPInbox.shortDate = "";
      } else {
        myMAPInbox.createdAt = createdAt;
        var shortDate = createdAt.toLocaleDateString();
        myMAPInbox.shortDate = shortDate;
      }

      if (createdAt == undefined) {
        myMAPInbox.time = "";
      } else {
        var hours = createdAt.getHours();
        var minutes = createdAt.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        hours = hours <= 9 ? '0' + hours : '' + hours;
        minutes = minutes <= 9 ? '0' + minutes : '' + minutes;
        myMAPInbox.time = hours + ':' + minutes + ampm;
      }

      var updatedAt = item.updatedAt;
      if (createdAt == updatedAt) {
        myMAPInbox.updatedAt = "";
        myMAPInbox.shortUpdatedAt = "";
      } else {
        myMAPInbox.updatedAt = createdAt;
        var shortUpdatedAt = updatedAt.toLocaleDateString();
        myMAPInbox.shortUpdatedAt = shortUpdatedAt;
      }

      var objectId = item.id;
      if (objectId == undefined) {
        myMAPInbox.objectId = "";
      } else {
        myMAPInbox.objectId = objectId;
      }

      return myMAPInbox;
    },
    convertToActiveMapSku: function convertToActiveMapSku(item) {
      var myActiveMapSku = {};

      var account = item.get("account");
      if (account == undefined) {
        myActiveMapSku.account = "";
      } else {
        myActiveMapSku.account = account;
      }

      var mapProduct = item.get("mapProduct");
      if (mapProduct == undefined) {
        myActiveMapSku.mapProduct = {};
      } else {
        myActiveMapSku.mapProduct = this.convertToMAPProduct(mapProduct);
      }

      var mapSeller = item.get("mapSeller");
      if (mapSeller == undefined) {
        myActiveMapSku.mapSeller = {};
      } else {
        myActiveMapSku.mapSeller = this.convertToMAPSeller(mapSeller);
      }

      var mapListing = item.get("mapListing");
      if (mapListing == undefined) {
        myActiveMapSku.mapListing = {};
      } else {
        myActiveMapSku.mapListing = this.convertToMAPListing(mapListing);
      }

      var accountId = item.get("accountId");
      if (accountId == undefined) {
        myActiveMapSku.accountId = "";
      } else {
        myActiveMapSku.accountId = accountId;
      }

      var mapProductId = item.get("mapProductId");
      if (mapProductId == undefined) {
        myActiveMapSku.mapProductId = "";
      } else {
        myActiveMapSku.mapProductId = mapProductId;
      }

      var mapListingId = item.get("mapListingId");
      if (mapListingId == undefined) {
        myActiveMapSku.mapListingId = "";
      } else {
        myActiveMapSku.mapListingId = mapListingId;
      }

      var mapSellerId = item.get("mapSellerId");
      if (mapSellerId == undefined) {
        myActiveMapSku.mapSellerId = "";
      } else {
        myActiveMapSku.mapSellerId = mapSellerId;
      }

      var createdAt = item.createdAt;
      if (createdAt == undefined) {
        myActiveMapSku.createdAt = "";
        myActiveMapSku.shortDate = "";
      } else {
        myActiveMapSku.createdAt = createdAt;
        var shortDate = createdAt.toLocaleDateString();
        myActiveMapSku.shortDate = shortDate;
      }

      if (createdAt == undefined) {
        myActiveMapSku.time = "";
      } else {
        var hours = createdAt.getHours();
        var minutes = createdAt.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        hours = hours <= 9 ? '0' + hours : '' + hours;
        minutes = minutes <= 9 ? '0' + minutes : '' + minutes;
        myActiveMapSku.time = hours + ':' + minutes + ampm;
      }

      var updatedAt = item.updatedAt;
      if (createdAt == updatedAt) {
        myActiveMapSku.updatedAt = "";
        myActiveMapSku.shortUpdatedAt = "";
      } else {
        myActiveMapSku.updatedAt = createdAt;
        var shortUpdatedAt = updatedAt.toLocaleDateString();
        myActiveMapSku.shortUpdatedAt = shortUpdatedAt;
      }

      var objectId = item.id;
      if (objectId == undefined) {
        myActiveMapSku.objectId = "";
      } else {
        myActiveMapSku.objectId = objectId;
      }

      return myActiveMapSku;
    },
    convertToVendorWhiteList: function convertToVendorWhiteList(item) {
      var myVendorWhiteList = {};

      var name = item.get("name");
      if (name == undefined) {
        myVendorWhiteList.name = "";
      } else {
        myVendorWhiteList.name = name;
      }

      var sellerId = item.get("sellerId");
      if (sellerId == undefined) {
        myVendorWhiteList.sellerId = "";
      } else {
        myVendorWhiteList.sellerId = sellerId;
      }

      var originalVendorId = item.get("originalVendorId");
      if (originalVendorId == undefined) {
        myVendorWhiteList.originalVendorId = "";
      } else {
        myVendorWhiteList.originalVendorId = originalVendorId;
      }

      var objectId = item.id;
      if (objectId == undefined) {
        myVendorWhiteList.objectId = "";
      } else {
        myVendorWhiteList.objectId = objectId;
      }

      var accountOverrideArray = item.get("accountOverrideArray");
      if (accountOverrideArray == undefined) {
        myVendorWhiteList.accountOverrideArray = [];
      } else {
        myVendorWhiteList.accountOverrideArray = accountOverrideArray;
      }

      return myVendorWhiteList;
    },
    convertToSellerStatusReason: function convertToSellerStatusReason(item) {
      var mySellerStatusReason = {};

      var reason = item.get("reason");
      if (reason == undefined) {
        mySellerStatusReason.reason = "";
      } else {
        mySellerStatusReason.reason = reason;
      }

      var status = item.get("status");
      if (status == undefined) {
        mySellerStatusReason.status = "";
      } else {
        mySellerStatusReason.status = status;
      }

      var status = item.get("status");
      if (status == undefined) {
        mySellerStatusReason.status = "";
      } else {
        mySellerStatusReason.status = status;
      }

      var notes = item.get("notes");
      if (notes == undefined) {
        mySellerStatusReason.notes = "";
      } else {
        mySellerStatusReason.notes = notes;
      }

      var createdAt = item.createdAt;
      if (createdAt == undefined) {
        mySellerStatusReason.createdAt = "";
        mySellerStatusReason.shortDate = "";
      } else {
        mySellerStatusReason.createdAt = createdAt;
        var shortDate = createdAt.toLocaleDateString();
        mySellerStatusReason.shortDate = shortDate;
      }

      if (createdAt == undefined) {
        mySellerStatusReason.time = "";
      } else {
        var hours = createdAt.getHours();
        var minutes = createdAt.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        hours = hours <= 9 ? '0' + hours : '' + hours;
        minutes = minutes <= 9 ? '0' + minutes : '' + minutes;
        mySellerStatusReason.time = hours + ':' + minutes + ampm;
      }

      var updatedAt = item.updatedAt;
      if (createdAt == updatedAt) {
        mySellerStatusReason.updatedAt = "";
        mySellerStatusReason.shortUpdatedAt = "";
      } else {
        mySellerStatusReason.updatedAt = createdAt;
        var shortUpdatedAt = updatedAt.toLocaleDateString();
        mySellerStatusReason.shortUpdatedAt = shortUpdatedAt;
      }

      var objectId = item.id;
      if (objectId == undefined) {
        mySellerStatusReason.objectId = "";
      } else {
        mySellerStatusReason.objectId = objectId;
      }

      return mySellerStatusReason;
    },
    convertToTwilioNumber: function convertToTwilioNumber(item) {
      var myTwilioNumber = {};
      var thisTwilioNumber = item;

      var number = thisTwilioNumber.get("number");
      if (number == undefined) {
        myTwilioNumber.number = "";
      } else {
        myTwilioNumber.number = number;
      }

      var active = thisTwilioNumber.get("active");
      if (active == undefined) {
        myTwilioNumber.active = true;
        myTwilioNumber.activeName = "Active";
      } else {
        myTwilioNumber.active = active;
        if (active) {
          myTwilioNumber.activeName = "Active";
        } else {
          myTwilioNumber.activeName = "Not Active";
        }
      }

      var emailArray = thisTwilioNumber.get("emailArray");
      if (emailArray == undefined) {
        myTwilioNumber.emailArray = [];
        myTwilioNumber.emailList = "";
      } else {
        var emailList = "";
        var i;
        for (i = 0; i < emailArray.length; i++) {
          emailList += emailArray[i] + ", ";
        }
        myTwilioNumber.emailList = emailList;
        myTwilioNumber.emailArray = emailArray;
      }

      var createdAt = thisTwilioNumber.createdAt;
      if (createdAt == undefined) {
        myTwilioNumber.createdAt = "";
        myTwilioNumber.shortDate = "";
      } else {
        myTwilioNumber.createdAt = createdAt;
        var shortDate = createdAt.toLocaleDateString();
        myTwilioNumber.shortDate = shortDate;
      }

      var objectId = thisTwilioNumber.id;
      if (objectId == undefined) {
        myTwilioNumber.objectId = "";
      } else {
        myTwilioNumber.objectId = objectId;
      }

      return myTwilioNumber;
    },
    convertToTwilioMessage: function convertToTwilioMessage(item) {
      var myTwilioMessage = {};
      var thisTwilioMessage = item;

      var To = thisTwilioMessage.get("To");
      if (To == undefined) {
        myTwilioMessage.To = "";
      } else {
        myTwilioMessage.To = To;
      }

      var From = thisTwilioMessage.get("From");
      if (From == undefined) {
        myTwilioMessage.From = "";
      } else {
        myTwilioMessage.From = From;
      }

      var Body = thisTwilioMessage.get("Body");
      if (Body == undefined) {
        myTwilioMessage.Body = "";
      } else {
        myTwilioMessage.Body = Body;
      }

      var FromCountry = thisTwilioMessage.get("FromCountry");
      if (FromCountry == undefined) {
        myTwilioMessage.FromCountry = "";
      } else {
        myTwilioMessage.FromCountry = FromCountry;
      }

      var FromCity = thisTwilioMessage.get("FromCity");
      if (FromCity == undefined) {
        myTwilioMessage.FromCity = "";
      } else {
        myTwilioMessage.FromCity = FromCity;
      }

      var createdAt = thisTwilioMessage.createdAt;
      if (createdAt == undefined) {
        myTwilioMessage.createdAt = "";
        myTwilioMessage.shortDate = "";
      } else {
        myTwilioMessage.createdAt = createdAt;
        var shortDate = createdAt.toLocaleString();
        myTwilioMessage.shortDate = shortDate;
      }

      var objectId = thisTwilioMessage.id;
      if (objectId == undefined) {
        myTwilioMessage.objectId = "";
      } else {
        myTwilioMessage.objectId = objectId;
      }

      return myTwilioMessage;
    },
    convertToSellerDetail: function convertToSellerDetail(item) {
      var mySellerDetail = {};
      var thisSellerDetail = item;

      var type = thisSellerDetail.get("type");
      if (type == undefined) {
        mySellerDetail.type = "";
      } else {
        mySellerDetail.type = type;
      }

      var name = thisSellerDetail.get("name");
      if (name == undefined) {
        mySellerDetail.name = "";
      } else {
        mySellerDetail.name = name;
      }

      var address = thisSellerDetail.get("address");
      if (address == undefined) {
        mySellerDetail.address = "";
      } else {
        mySellerDetail.address = address;
      }

      var website = thisSellerDetail.get("website");
      if (website == undefined) {
        mySellerDetail.website = "";
      } else {
        mySellerDetail.website = website;
      }

      var phone = thisSellerDetail.get("phone");
      if (phone == undefined) {
        mySellerDetail.phone = "";
      } else {
        mySellerDetail.phone = phone;
      }

      var email = thisSellerDetail.get("email");
      if (email == undefined) {
        mySellerDetail.email = "";
      } else {
        mySellerDetail.email = email;
      }

      var contact = thisSellerDetail.get("contact");
      if (contact == undefined) {
        mySellerDetail.contact = "";
      } else {
        mySellerDetail.contact = contact;
      }

      var vendor = thisSellerDetail.get("vendor");
      if (vendor == undefined) {
        mySellerDetail.vendor = {};
      } else {
        mySellerDetail.vendor = this.convertToVendor(vendor);
      }

      var vendorId = thisSellerDetail.get("vendorId");
      if (vendorId == undefined) {
        mySellerDetail.vendorId = "";
      } else {
        mySellerDetail.vendorId = vendorId;
      }

      var masterVendorId = thisSellerDetail.get("masterVendorId");
      if (masterVendorId == undefined) {
        mySellerDetail.masterVendorId = "";
      } else {
        mySellerDetail.masterVendorId = masterVendorId;
      }

      var createdAt = thisSellerDetail.createdAt;
      if (createdAt == undefined) {
        mySellerDetail.createdAt = "";
        mySellerDetail.shortDate = "";
      } else {
        mySellerDetail.createdAt = createdAt;
        var shortDate = createdAt.toLocaleString();
        mySellerDetail.shortDate = shortDate;
      }

      var objectId = thisSellerDetail.id;
      if (objectId == undefined) {
        mySellerDetail.objectId = "";
      } else {
        mySellerDetail.objectId = objectId;
      }

      return mySellerDetail;
    },
    convertToReport: function convertToReport(item) {
      var myReport = {};

      var sentTo = item.get("sentTo");
      if (sentTo == undefined) {
        myReport.sentTo = [];
      } else {
        myReport.sentTo = sentTo;
      }

      var reportFile = item.get("reportFile");
      if (reportFile == undefined) {
        myReport.fileName = "";
        myReport.fileURL = "";
      } else {
        myReport.fileName = reportFile.name();
        myReport.fileURL = reportFile.url();
      }

      var createdAt = item.createdAt;
      if (createdAt == undefined) {
        myReport.createdAt = "";
        myReport.shortDate = "";
      } else {
        myReport.createdAt = createdAt;
        var shortDate = createdAt.toLocaleDateString();
        myReport.shortDate = shortDate;
      }

      var updatedAt = item.updatedAt;
      if (createdAt == updatedAt) {
        myReport.updatedAt = "";
        myReport.shortUpdatedAt = "";
      } else {
        myReport.updatedAt = createdAt;
        var shortUpdatedAt = updatedAt.toLocaleDateString();
        myReport.shortUpdatedAt = shortUpdatedAt;
      }

      var objectId = item.id;
      if (objectId == undefined) {
        myReport.objectId = "";
      } else {
        myReport.objectId = objectId;
      }

      return myReport;
    }
  });


  function fixBrokenUrls(url) {
    var myUrl = url;
    myUrl = myUrl.toLowerCase();
    if (!myUrl.includes("http")) {
      var newMyUrl = "https://" + myUrl;
      return newMyUrl;
    } else {
      return url;
    }
  }

  function returnAddress(partner) {
    switch (partner) {
      case "egrowth":
        return "Egrowth";
      case "lifesorganic":
        return "Lifesorganic";
      case "g2":
        return "G2";
      case "I2o":
        return "i2o";
      case "wingpoint":
        return "Wingpoint";
      case "marketdefense":
        return "Marketdefense";
      default:
        return "Vantage BP";
    }
  }

  function fixNumberOfSellerListings(vendorId, accountId) {
    var params = { vendorId: vendorId, accountId: accountId };
    Parse.Cloud.run("fixVendorNumberOfListingsCount", params).then(function (result) {
      console.log("fixVendorNumberOfListingsCount - result is - ", result);
    }, function (error) {
      console.log("fixVendorNumberOfListingsCount - error is - ", error.message);
    });
  }

  function getNumberOfDays(startDate, endDate) {
    // One day in milliseconds
    var byDay = 1000 * 60 * 60 * 24;
    // Calculating the time difference between two dates
    var diffInTime = startDate.getTime() - endDate.getTime();
    // Calculating the no. of days between two dates
    var diffInDays = Math.round(diffInTime / byDay);
    return diffInDays;
  }

  function convertTimeOfDate(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours <= 9 ? '0' + hours : '' + hours;
    minutes = minutes <= 9 ? '0' + minutes : '' + minutes;
    return hours + ':' + minutes + ampm;
  }

  function getFormatDate(pDate) {
    var day = pDate.getDate();
    var month = pDate.getMonth() + 1;
    var year = pDate.getFullYear();

    if (month < 10) month = "0" + month;

    if (day < 10) day = "0" + day;

    return month + "/" + day + "/" + year;
  }
});