define('uabide/services/accounts-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    parseService: Ember.inject.service(),
    parseconverter: Ember.inject.service('parse-converter'),

    getAccountsForAdmin: function getAccountsForAdmin(params) {
      return this.get('parseService.cloud').run('getAccountsForAdmin', params);
    },
    getAccountsForMultiUser: function getAccountsForMultiUser(params) {
      return this.get('parseService.cloud').run('getAccountsForMultiUser', params);
    },
    setAccountForAdmin: function setAccountForAdmin(params) {
      return this.get('parseService.cloud').run('setAccountForAdmin', params);
    },
    updateUsersMultiAccount: function updateUsersMultiAccount(params) {
      return this.get('parseService.cloud').run('updateUsersMultiAccount', params);
    },
    findUserByEmailNow: function findUserByEmailNow(params) {
      return this.get('parseService.cloud').run('findUserByEmailNow', params);
    },
    startOutDatedSellerCleaningForNotified: function startOutDatedSellerCleaningForNotified(params) {
      return this.get('parseService.cloud').run('startOutDatedSellerCleaningForNotified', params);
    },
    updateUsersFavoriteAccounts: function updateUsersFavoriteAccounts(params) {
      return this.get('parseService.cloud').run('updateUsersFavoriteAccounts', params);
    },
    updateUsersIsAccountAdmin: function updateUsersIsAccountAdmin(params) {
      return this.get('parseService.cloud').run('updateUsersIsAccountAdmin', params);
    },
    getAccountUsers: function getAccountUsers(params) {
      return this.get('parseService.cloud').run('getAccountUsers', params);
    },
    createUserForAccount: function createUserForAccount(params) {
      return this.get('parseService.cloud').run('createUserForAccount', params);
    },
    updateAccountInfo: function updateAccountInfo(params) {
      return this.get('parseService.cloud').run('updateAccountInfo', params);
    },
    updateAccountsReportEmails: function updateAccountsReportEmails(params) {
      return this.get('parseService.cloud').run('updateAccountsReportEmails', params);
    },
    getSingleClosedStatsAccountForAdmin: function getSingleClosedStatsAccountForAdmin(params) {
      return this.get('parseService.cloud').run('getSingleClosedStatsAccountForAdmin', params);
    },
    checkIfSellerExistsByName: function checkIfSellerExistsByName(params) {
      return this.get('parseService.cloud').run('checkIfSellerExistsByName', params);
    },
    createReportForAccount: function createReportForAccount(params) {
      return this.get('parseService.cloud').run('createReportForAccount', params);
    },
    createReportForAccountV2: function createReportForAccountV2(params) {
      return this.get('parseService.cloud').run('createReportForAccountV2', params);
    },
    updateAccountsMonthlyReportEmails: function updateAccountsMonthlyReportEmails(params) {
      return this.get('parseService.cloud').run('updateAccountsMonthlyReportEmails', params);
    },
    getMonthlyReportsForAccount: function getMonthlyReportsForAccount(params) {
      return this.get('parseService.cloud').run('getMonthlyReportsForAccount', params);
    },
    addOrRemoveListedAlertForUserAndAccount: function addOrRemoveListedAlertForUserAndAccount(params) {
      return this.get('parseService.cloud').run('addOrRemoveListedAlertForUserAndAccount', params);
    },
    getPartnerNames: function getPartnerNames(params) {
      return this.get('parseService.cloud').run('getPartnerNames', params);
    }
  });
});