define('uabide/components/dark-mode', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        isInvertedTextColor: false,
        isInvertedNoDesign: false,
        isInitialized: false,
        isInvertedBackgroundColor: false,
        isInvertedTableHeaderBackgroundText: false,
        isInvertedImageBackgroundColor: false,

        init: function init() {
            this._super.apply(this, arguments);

            if (!this.isInitialized) {
                // Retrieve isInverted state from localStorage on component initialization
                this.set('isInvertedTextColor', localStorage.getItem('isInvertedTextColor') === 'true');
                this.set('isInvertedNoDesign', localStorage.getItem('isInvertedNoDesign') === 'true');
                this.set('isInvertedBackgroundColor', localStorage.getItem('isInvertedBackgroundColor') === 'true');
                this.set('isInvertedTableHeaderBackgroundText', localStorage.getItem('isInvertedTableHeaderBackgroundText') === 'true');
                this.set('isInvertedImageBackgroundColor', localStorage.getItem('isInvertedImageBackgroundColor') === 'true');
                this.set('isInitialized', true);
            }

            // Apply the inverted class and other styles based on the initial state
            this.applyStyles();
        },


        applyStyles: Ember.on('didRender', function () {
            var _this = this;

            document.querySelectorAll('.invertedTextColor').forEach(function (result) {
                result.classList.toggle('inverted-text-color', _this.get('isInvertedTextColor'));
            });

            document.querySelectorAll('.invertedNoDesign').forEach(function (result) {
                result.classList.toggle('inverted-no-design', _this.get('isInvertedNoDesign'));
            });

            document.querySelectorAll('.invertedBackgroundColor').forEach(function (result) {
                result.classList.toggle('inverted-background-color', _this.get('isInvertedBackgroundColor'));
            });

            document.querySelectorAll('.invertedTableHeaderBackgroundText').forEach(function (result) {
                result.classList.toggle('inverted-table-header-background-text', _this.get('isInvertedTableHeaderBackgroundText'));
            });

            document.querySelectorAll('.invertedImageBackgroundColor').forEach(function (result) {
                result.classList.toggle('inverted-image-background-color', _this.get('isInvertedImageBackgroundColor'));
            });
        }),

        actions: {
            toggleDarkMode: function toggleDarkMode() {
                // Toggle the isInverted property and store it in localStorage
                this.toggleProperty('isInvertedTextColor');
                localStorage.setItem('isInvertedTextColor', this.get('isInvertedTextColor'));

                this.toggleProperty('isInvertedNoDesign');
                localStorage.setItem('isInvertedNoDesign', this.get('isInvertedNoDesign'));

                this.toggleProperty('isInvertedBackgroundColor');
                localStorage.setItem('isInvertedBackgroundColor', this.get('isInvertedBackgroundColor'));

                this.toggleProperty('isInvertedTableHeaderBackgroundText');
                localStorage.setItem('isInvertedTableHeaderBackgroundText', this.get('isInvertedTableHeaderBackgroundText'));

                this.toggleProperty('isInvertedImageBackgroundColor');
                localStorage.setItem('isInvertedImageBackgroundColor', this.get('isInvertedImageBackgroundColor'));

                // Apply the inverted class and other styles after toggling
                this.applyStyles();

                console.log('Dark mode toggled!');
            }
        }
    });
});