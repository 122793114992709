define('uabide/components/modals/show-filters-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      resetFilter: function resetFilter(reset) {
        this.get(reset.function)(reset.value);
      }
    }
  });
});