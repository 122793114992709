define('uabide/utils/filter-array-by-object-id', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = filterArrayByObjectId;
  function filterArrayByObjectId(array) {
    var attribute = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'objectId';

    var uniqueObjectIds = new Set();
    return array.filter(function (el) {
      var duplicate = uniqueObjectIds.has(el[attribute]);
      if (!duplicate) {
        uniqueObjectIds.add(el[attribute]);
      }
      return !duplicate;
    });
  }
});