define('uabide/components/modals/product-detail-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('currentVendor', this.mapProduct);
    },


    currentVendor: null,
    currentVendorTitle: Ember.computed.alias('currentVendor.title'),
    currentVendorSKU: Ember.computed.alias('currentVendor.SKU'),
    currentVendorSize: Ember.computed.alias('currentVendor.size'),
    currentVendorUPC: Ember.computed.alias('currentVendor.upc_number'),
    currentVendorASIN: Ember.computed.alias('currentVendor.ASIN'),
    currentVendorItemNumber: Ember.computed.alias('currentVendor.model_number'),
    currentVendorMapPrice: Ember.computed.alias('currentVendor.map_price'),
    currentVendorMsrpPrice: Ember.computed.alias('currentVendor.msrp_price'),
    currentVendorPriceVariance: Ember.computed.alias('currentVendor.priceVariance'),
    currentVendorUpcArray: Ember.computed.alias('currentVendor.UPC_ARRAY'),
    currentVendorAsinArray: Ember.computed.alias('currentVendor.ASIN_ARRAY'),
    currentVendorProductDescription: Ember.computed.alias('currentVendor.productDescription'),

    actions: {
      submit: function submit() {
        var map_price = Number(this.get('currentVendorMapPrice'));
        var msrp_price = Number(this.get('currentVendorMsrpPrice'));
        var priceVariance = Number(this.get('currentVendorPriceVariance'));
        var params = {
          objectId: this.get('currentVendor.objectId'),
          ASIN: this.get('currentVendorASIN'),
          model_number: this.get('currentVendorItemNumber'),
          upc_number: this.get('currentVendorUPC'),
          title: this.get('currentVendorTitle'),
          map_price: map_price,
          msrp_price: msrp_price,
          itemNumber: this.get('currentVendorItemNumber'),
          SKU: this.get('currentVendorSKU'),
          productDescription: this.get('currentVendorProductDescription'),
          size: this.get('currentVendorSize'),
          priceVariance: priceVariance
        };

        this.saveMapProduct(params);
      }
    }
  });
});