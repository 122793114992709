define("uabide/helpers/map-stage-text", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.mapStageTextAccount = mapStageTextAccount;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function mapStageTextAccount(params) {
    var _params = _slicedToArray(params, 1),
        status = _params[0];

    var text = status ? "" + status : 0;

    switch (text) {
      case "1":
        text = "1st Warning";
        break;
      case "2":
        text = "2nd Warning";
        break;
      case "3":
        text = "3rd Warning";
        break;
      case "4":
        text = "4th Warning";
        break;
      default:
        text = "5th Warning";
    }
    return text;
  }

  exports.default = Ember.Helper.helper(mapStageTextAccount);
});