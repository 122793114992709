define('uabide/components/modals/historical-violations-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      scrollTop: function scrollTop() {
        var body = document.getElementById('historicalViolationsTable');
        body.scrollTop = 0;
      }
    }
  });
});