define('uabide/services/emaildb-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    parseService: Ember.inject.service(),

    uploadEmailDB: function uploadEmailDB(params) {
      return this.get('parseService.cloud').run('uploadEmailDB', params);
    }
  });
});