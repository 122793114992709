define('uabide/controllers/map-clickup-dashboard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  Parse.initialize("bzn8msayuASvFwWDJEY3DdGYDXb7eV9rweY2rr7o", "fRmOrMDotozW5iYypZ9s73yADQP3MiKmbIhQxXGw");
  Parse.serverURL = 'https://uabide.back4app.io/';

  exports.default = Ember.Controller.extend({
    spinner: Ember.inject.service('spinner'),
    accountservice: Ember.inject.service('account-service'),
    parseconverter: Ember.inject.service('parse-converter'),
    accountcontroller: Ember.inject.service('account-controller'),
    listingservice: Ember.inject.service('listing-service'),
    vendorservice: Ember.inject.service('vendor-service'),
    notificationService: Ember.inject.service(),
    accountsService: Ember.inject.service(),
    sessionService: Ember.inject.service(),
    knownSellerEmailArray: [],
    isShowingKnownSellersEmails: false,
    isUnderway: false,
    hasfetchedTasks: false,
    taskArray: null,
    taskCommentArray: null,
    currentTask: null,
    currentFile: null,
    currentFileName: "",
    isShowingChangeTaskStatusModal: false,
    isShowingAddTaskCommentOrAttachmentModal: false,
    isShowingCommentModal: false,
    selectedTask: null,
    commentText: '',
    allComments: [],

    actions: {
      transitionTo: function transitionTo(page) {
        this.transitionToRoute(page);
        Ember.run.later(function () {
          location.reload();
        }, 500);
      },
      startShowingCommentModal: function startShowingCommentModal(task) {

        var self = this;
        self.get('spinner').show('discoverySpinner');
        this.set("taskCommentArray", []);
        var params = { taskId: task.id };

        Parse.Cloud.run("getSingleTask", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          self.set('selectedTask', result);

          // Load the task list without taskId parameter
          Parse.Cloud.run("getTaskComments", params).then(function (result) {
            console.log(params);
            self.get('spinner').hide('discoverySpinner');
            var results = result.comments;
            console.log("results ", results);
            for (var i = 0; i < results.length; i++) {
              var item = results[i];
              if (item.date != null && item.date != undefined) {
                var date = new Date(item.date * 1);
                item.fixedDate = date.toLocaleDateString();
              } else {
                item.fixedDate = "not set";
              }
              item.commentText = urlify(item.comment_text);
            }
            console.log("taskCommentArray ", results);
            self.set("taskCommentArray", results);

            self.set('isShowingCommentModal', true);
            self.set('allComments', []);
          }, function (error) {
            self.get('spinner').hide('discoverySpinner');
            self.get('notificationService').error("Error getting tasks - " + error.message);
          });
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("Error getting tasks - " + error.message);
        });
      },
      closeCommentModal: function closeCommentModal() {
        this.set('selectedTask', null);
        this.set('isShowingCommentModal', false);
      },
      getTaskComments: function (_getTaskComments) {
        function getTaskComments() {
          return _getTaskComments.apply(this, arguments);
        }

        getTaskComments.toString = function () {
          return _getTaskComments.toString();
        };

        return getTaskComments;
      }(function () {
        console.log('COMMENT', getTaskComments);
      }),
      logoutNow: function logoutNow() {
        var _this = this;

        console.log("trying to logout ");
        var self = this;
        this.get('spinner').show('discoverySpinner');
        Parse.User.logOut().then(function () {
          self.get('spinner').hide('discoverySpinner');
          self.get('listingservice').clearAllNow();
          self.get('vendorservice').clearAllNow();
          _this.transitionToRoute('login');
          setTimeout(function () {
            location.reload();
          }, 500);
        });
      },
      toggleAdminDisplay: function toggleAdminDisplay() {
        this.get("accountcontroller").toggleAdminView();
      },
      turnOnTurbo: function turnOnTurbo() {
        this.get('listingservice').updateTurboSettings(true);
      },
      turnOffTurbo: function turnOffTurbo() {
        this.get('listingservice').updateTurboSettings(false);
      },
      loadAccount: function loadAccount() {
        console.log("load account");
        var hasFetchedTasks = this.get("hasFetchedTasks");
        if (hasFetchedTasks === true) {
          return;
        }
        this.set("hasFetchedTasks", true);

        var self = this;
        self.get('spinner').show('discoverySpinner');
        var params = {};

        console.log("load2 account");
        // Load the task list without taskId parameter
        Parse.Cloud.run("getTask", params).then(function (results) {
          console.log(params);
          self.get('spinner').hide('discoverySpinner');
          console.log("load3 account");
          for (var i = 0; i < results.length; i++) {
            var item = results[i];
            if (item.due_date != null && item.due_date != undefined) {
              var date = new Date(item.due_date * 1);
              item.fixedDate = date.toLocaleDateString();
            } else {
              item.fixedDate = "not set";
            }
          }
          self.set("taskArray", results);
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("Error getting tasks - " + error.message);
        });
      },
      startShowingChangeTaskStatusModal: function startShowingChangeTaskStatusModal(item) {
        this.set("currentTask", item);
        this.toggleProperty('isShowingChangeTaskStatusModal');
      },
      toggleShowingChangeTaskStatusModal: function toggleShowingChangeTaskStatusModal() {
        this.toggleProperty('isShowingChangeTaskStatusModal');
      },
      changeTaskStatusModal: function changeTaskStatusModal(status) {
        var currentTask = this.get("currentTask");
        var self = this;
        self.get('spinner').show('discoverySpinner');
        var params = {
          taskId: currentTask.id,
          status: status
        };
        Parse.Cloud.run("updateTask", params).then(function (results) {
          self.get('spinner').hide('discoverySpinner');
          self.set("hasFetchedTasks", false);
          self.send("toggleShowingChangeTaskStatusModal");
          self.send("loadAccount");
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("error updating status -  " + error.message);
        });
      },
      startShowingAddTaskCommentOrAttachmentModal: function startShowingAddTaskCommentOrAttachmentModal(item) {
        var self = this;
        self.get('spinner').show('discoverySpinner');
        var params = { taskId: item.id };
        Parse.Cloud.run("getSingleTask", params).then(function (result) {
          console.log("result is ", result);
          self.get('spinner').hide('discoverySpinner');
          self.set("currentTask", result);
          self.set("currentFile", null);
          self.set("currentFileName", "");
          self.set("commentText", "");
          self.toggleProperty('isShowingAddTaskCommentOrAttachmentModal');
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("Error getting tasks - " + error.message);
        });
      },


      toggleShowingAddTaskCommentOrAttachmentModal: function toggleShowingAddTaskCommentOrAttachmentModal() {
        this.toggleProperty('isShowingAddTaskCommentOrAttachmentModal');
      },
      startKnownSellersEmails: function startKnownSellersEmails() {
        this.set('knownSellerEmailArray', []);
        this.toggleProperty('isShowingKnownSellersEmails');
      },

      toggleKnownSellersEmails: function toggleKnownSellersEmails() {
        this.toggleProperty('isShowingKnownSellersEmails');
      },
      emptyMethod: function emptyMethod() {},
      addFileToTask: function addFileToTask(files) {
        if (files.length > 0) {
          var file = files[0];
          var name = file.name;
          this.set("currentFile", file);
          this.set("currentFileName", name);
        } else {
          this.get('notificationService').warning("No file found");
        }
      },
      startSaveTextAndFile: function startSaveTextAndFile() {
        var currentFile = this.get("currentFile");
        if (currentFile == null) {
          this.send("saveTextAndFile");
          return;
        }
        var name = currentFile.name;
        var parseFile = new Parse.File(process(name), currentFile);

        var self = this;
        this.get('spinner').show('discoverySpinner');
        parseFile.save().then(function () {
          self.set("currentFile", parseFile);
          self.send("saveTextAndFile");
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("error saving file -  " + error.message);
        });
      },
      saveTextAndFile: function saveTextAndFile() {
        console.log("saveTextAndFile");
        var currentTask = this.get("currentTask");
        var comment = "";
        var commentText = this.get("commentText");
        comment = commentText;
        var currentFile = this.get("currentFile");
        var isShowingCommentModal = this.get("isShowingCommentModal");
        var isShowingAddTaskCommentOrAttachmentModal = this.get("isShowingAddTaskCommentOrAttachmentModal");
        if (currentFile == null && commentText == "") {
          console.log("saveTextAndFile close ", commentText);
          if (isShowingCommentModal) {
            this.toggleProperty('isShowingCommentModal');
          }
          if (isShowingAddTaskCommentOrAttachmentModal) {
            this.toggleProperty('isShowingAddTaskCommentOrAttachmentModal');
          }
          return;
        }
        if (currentFile != null) {
          var url = currentFile.url();
          console.log("currentFile.url() ", url);
          console.log("currentFile ", currentFile);

          comment = comment + " \n " + url;
        } else {
          console.log("no file ");
        }
        console.log("comment is ", comment);
        var self = this;
        self.get('spinner').show('discoverySpinner');
        var params = {
          taskId: currentTask.id,
          taskComment: comment
        };
        Parse.Cloud.run("createTaskComment", params).then(function (results) {
          self.get('spinner').hide('discoverySpinner');
          if (isShowingCommentModal) {
            self.toggleProperty('isShowingCommentModal');
            self.send("startShowingCommentModal", currentTask);
          }
          if (isShowingAddTaskCommentOrAttachmentModal) {
            self.toggleProperty('isShowingAddTaskCommentOrAttachmentModal');
          }
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("error saving known seller emails -  " + error.message);
        });
      }
    }
  });


  function removeDuplicates(arr) {
    var seen = {};
    var ret_arr = [];
    for (var i = 0; i < arr.length; i++) {
      if (!(arr[i] in seen)) {
        ret_arr.push(arr[i]);
        seen[arr[i]] = true;
      }
    }
    return ret_arr;
  }

  function process(value) {
    return value == undefined ? '' : value.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase();
  }

  function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return '<a href="' + url + '">' + url + '</a>';
    });
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  }
});