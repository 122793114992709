define('uabide/components/dropdown-calendar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentMonth: new Date(),
    selectedDate: null,

    selectedDateFormatted: Ember.computed('selectedDate', function () {
      var date = this.get('selectedDate');
      return date ? this.get('selectedDate').toLocaleDateString() : 'Select';
    }),

    monthStart: Ember.computed('currentMonth', function () {
      var date = this.get('currentMonth');
      return new Date(date.getFullYear(), date.getMonth(), 1);
    }),

    monthEnd: Ember.computed('currentMonth', function () {
      var date = this.get('currentMonth');
      return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }),

    startDate: Ember.computed('monthStart', function () {
      var start = getSunday(this.get('monthStart'));
      return start;
    }),

    endDate: Ember.computed('monthEnd', function () {
      var end = getSaturday(this.get('monthEnd'));
      return end;
    }),

    currentMonthFormatted: Ember.computed('currentMonth', function () {
      return this.get('currentMonth').toLocaleString('en-US', { year: 'numeric', month: 'long' });
    }),

    MonthDays: Ember.computed('startDate', 'endDate', 'monthStart', function () {
      var days = [];
      var weeks = [];
      var day = new Date(this.get('startDate'));
      var end = new Date(this.get('endDate'));
      var month = this.get('monthStart').getMonth();

      while (day <= end) {
        var week = [];
        for (var i = 0; i < 7; i++) {
          var formattedDate = day.getDate();
          week.push({
            label: formattedDate,
            isDisabled: day.getMonth() !== month,
            date: new Date(day)
          });
          day = addDay(day);
        }
        weeks.push(week);
      }

      return weeks;
    }),

    actions: {
      onDateClick: function onDateClick(day) {
        this.set('selectedDate', day);
        if (this.selectDate) {
          this.selectDate(day);
        }
      },
      nextMonth: function nextMonth() {
        var currentMonth = this.get('currentMonth');
        var next = new Date(currentMonth.setMonth(currentMonth.getMonth() + 1));
        this.set('currentMonth', next);
      },
      prevMonth: function prevMonth() {
        var currentMonth = this.get('currentMonth');
        var prev = new Date(currentMonth.setMonth(currentMonth.getMonth() - 1));
        this.set('currentMonth', prev);
      }
    }
  });


  function getSunday(d) {
    d = new Date(d);
    var day = d.getDay();
    var diff = d.getDate() - day;
    return new Date(d.setDate(diff));
  }

  function getSaturday(d) {
    d = new Date(d);
    var day = d.getDay();
    var diff = d.getDate() - day + 6;
    return new Date(d.setDate(diff));
  }

  function addDay(d) {
    var date = new Date(d);
    date.setDate(date.getDate() + 1);
    return date;
  }
});