define('uabide/controllers/map/overview', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    mapSellersService: Ember.inject.service(),
    mapProductsService: Ember.inject.service(),
    mapListingsService: Ember.inject.service(),

    accountController: Ember.computed.alias('model.accountController'),
    showAdminView: Ember.computed.alias('accountController.showAdminView'),
    currentAccount: Ember.computed.alias('accountController.currentAccount'),
    currentMapRetailersCountRetailers: Ember.computed.alias('model.currentMapRetailersCountRetailers'),
    currentMapCountRetailers3p: Ember.computed.alias('model.currentMapCountRetailers3p'),
    currentMapCountNonCompliantRetailers: Ember.computed.alias('model.currentMapCountNonCompliantRetailers'),
    currentMapCountNonCompliantRetailers3p: Ember.computed.alias('model.currentMapCountNonCompliantRetailers3p'),
    currentMapCountActiveProducts: Ember.computed.alias('model.currentMapCountActiveProducts'),
    currentMapCountInActiveProducts: Ember.computed.alias('model.currentMapCountInActiveProducts'),
    totalListings: Ember.computed.alias('model.totalListings'),
    totalNonCompliantListings: Ember.computed.alias('model.totalNonCompliantListings'),
    stage1Accounts: Ember.computed.alias('model.stage1Accounts'),
    stage2Accounts: Ember.computed.alias('model.stage2Accounts'),
    stage3Accounts: Ember.computed.alias('model.stage3Accounts'),
    inspectAccounts: Ember.computed.alias('model.inspectAccounts'),
    stage5Accounts: Ember.computed.alias('model.stage5Accounts'),

    allValue: 'showAll',
    violateValue: 'violation',
    safeValue: 'compliant',
    retailersValue: 'retailers',
    sellers3pValue: 'sellers3p',
    inactiveValue: 'inactive',
    activeValue: 'active',

    actions: {
      setSellerFilters: function setSellerFilters(type, status) {
        this.get('mapSellersService').clearAllNow();
        this.get('mapSellersService').set('vendorType', type);
        this.get('mapSellersService').set('vendorStatus', status);
        this.get('mapSellersService').set('vendorStage', null);
        this.transitionToRoute('/map/accounts');
      },
      setMapProductsFilters: function setMapProductsFilters(sideLoad) {
        this.get('mapProductsService').clearAllNow();
        this.get('mapProductsService').set('vendorSideLoad', sideLoad);
        this.transitionToRoute('/map/products');
      },
      setMapListingsFilters: function setMapListingsFilters(status) {
        this.get('mapListingsService').clearAllNow();
        this.get('mapListingsService').set('vendorStatus', status);
        this.transitionToRoute('/map/listings?clear=true');
      },
      setSellersStage: function setSellersStage(stage) {
        this.get('mapSellersService').clearAllNow();
        this.get('mapSellersService').set('vendorStage', stage);
        this.get('mapSellersService').set('vendorType', this.get('allValue'));
        this.get('mapSellersService').set('vendorStatus', this.get('allValue'));
        this.transitionToRoute('/map/accounts');
      }
    }
  });
});