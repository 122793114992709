define('uabide/services/map-listings-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    parseService: Ember.inject.service(),

    vendorStatus: 'showAll',
    vendorPlatform: 'showAll',
    umap: null,
    amazonType: null,
    condition: 'new',
    mapProductId: null,
    mapProductListing: null,
    mapSellerId: null,
    vendorSize: null,
    vendorProductState: 'showAll',
    type: null,
    vendorResults: [],
    productVendorResults: [],
    vendorResultsLastCallAmount: 0,
    productVendorResultsLastCallAmount: 0,
    fileArray: [],
    numberDownloadedHistoryFileResults: 0,

    addVendorResults: function addVendorResults(newResults) {
      this.set('vendorResultsLastCallAmount', newResults.length);
      this.get('vendorResults').pushObjects(newResults);
    },
    addProductVendorResults: function addProductVendorResults(newResults) {
      this.set('productVendorResultsLastCallAmount', newResults.length);
      this.get('productVendorResults').pushObjects(newResults);
    },
    addHistoryFileResults: function addHistoryFileResults(newResults) {
      this.set('numberDownloadedHistoryFileResults', newResults.length);
      this.get('fileArray').pushObjects(newResults);
    },
    resetFileForDownload: function resetFileForDownload(newResults) {
      this.set('numberDownloadedHistoryFileResults', 0);
      this.set('fileArray', []);
    },
    clearAllNow: function clearAllNow() {
      this.set('vendorResults', []);
    },
    clearAllProductVendorResultsNow: function clearAllProductVendorResultsNow() {
      this.set('productVendorResults', []);
    },
    setListingTitle: function setListingTitle(mapListing) {
      var title = mapListing.title,
          size = mapListing.size,
          shoppingPlatform = mapListing.shoppingPlatform;

      if (size && shoppingPlatform.toLowerCase() == 'amazon') {
        return title + ' - Size ' + size;
      } else {
        return title;
      }
    },
    getCurrentMapListings: function getCurrentMapListings(params) {
      return this.get('parseService.cloud').run('getCurrentMAPListings', params);
    },
    getCurrentMapListingCount: function getCurrentMapListingCount(params) {
      return this.get('parseService.cloud').run('getCurrentMAPListingCount', params);
    },
    removeMapListing: function removeMapListing(params) {
      return this.get('parseService.cloud').run('removeMapListing', params);
    },
    updateMapListingStageAndCompletedStage: function updateMapListingStageAndCompletedStage(params) {
      return this.get('parseService.cloud').run('updateMapListingStageAndCompletedStage', params);
    },
    updateMapListingStageAndCompletedStageBulk: function updateMapListingStageAndCompletedStageBulk(params) {
      return this.get('parseService.cloud').run('updateMapListingStageAndCompletedStageBulk', params);
    }
  });
});