define("uabide/controllers/report", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  Parse.initialize("bzn8msayuASvFwWDJEY3DdGYDXb7eV9rweY2rr7o", "fRmOrMDotozW5iYypZ9s73yADQP3MiKmbIhQxXGw");
  Parse.serverURL = 'https://uabide.back4app.io/';

  exports.default = Ember.Controller.extend({
    spinner: Ember.inject.service('spinner'),
    actions: {
      logoutNow: function logoutNow() {
        var _this = this;

        //console.log("trying to logout ");
        var self = this;
        this.get('spinner').show('reportSpinner');
        Parse.User.logOut().then(function () {
          self.get('spinner').hide('reportSpinner');
          _this.transitionToRoute('login');
        });
      }
    }
  });
});