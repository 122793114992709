define('uabide/components/modals/closed-date-range-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    startDate: null,
    endDate: null,

    didUpdateAttrs: function didUpdateAttrs() {
      this.set('startDate', this.start);
      this.set('endDate', this.end);
    },


    actions: {
      setStartDate: function setStartDate(date) {
        this.set('startDate', date);
      },
      setEndDate: function setEndDate(date) {
        this.set('endDate', date);
      },
      setDates: function setDates() {
        this.setDateParams(this.get('startDate'), this.get('endDate'));
      }
    }
  });
});