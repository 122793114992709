define('uabide/routes/seller-search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    actions: {
      refreshThisRoute: function refreshThisRoute() {
        console.log("refreshing this route now");
        this.refresh();
      }
    }
  });
});