define('uabide/controllers/maplistings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  Parse.initialize("bzn8msayuASvFwWDJEY3DdGYDXb7eV9rweY2rr7o", "fRmOrMDotozW5iYypZ9s73yADQP3MiKmbIhQxXGw");
  Parse.serverURL = 'https://uabide.back4app.io/';
  var inject = Ember.inject;

  exports.default = Ember.Controller.extend({
    spinner: inject.service('spinner'),
    listingservice: inject.service('listing-service'),
    maplistingservice: inject.service('maplisting-service'),
    parseconverter: inject.service('parse-converter'),
    accountcontroller: inject.service('account-controller'),
    notificationService: Ember.inject.service(),
    numberOfListings: 0,
    numberOfVendors: 0,
    showListings: false,
    checkAll: false,
    checkAllVendor: false,
    showAllContent: true,
    accountName: "",
    value_discovered: "Discovered",
    value_enforce: "Enforce",
    value_need_info: "Need Info",
    value_pending: "Pending",
    value_closed: "Closed",
    value_authorized: "Authorized",
    value_verified: "Verified",
    value_review: "Review",
    value_flagged: "Flagged",
    value_price_flagged: "Price Flag",
    value_resubmit: "Resubmit",
    value_rejected: "Rejected",
    value_consumer: "Consumer",
    value_takedown: "Takedown",
    value_new_seller: "New Seller",
    value_infringer: "Infringer",
    value_notified: "Notified",
    value_unauthorized: "Unauthorized",
    searchMode: "updatedAt",
    compliant_value: "compliant",
    violate_value: "violation",
    direction: "down",
    isShowingFilterListingsModal: false,
    isShowingFilterVendorsModal: false,
    isShowingVendorDetailModal: false,
    listingStatus: null,
    mySearchText: null,
    myAccount: null,
    currentVendor: null,
    currentSellerName: null,
    currentSellerURL: null,
    currentShoppingPlatform: null,
    currentNumberOfInfractions: null,

    currentSellerUrl: null,
    currentSellerInvoice: null,
    currentSellerCompanyContact: null,
    currentSellerCompanyAddress: null,
    currentSellerSupplierAddress: null,
    currentSellerSupplierContact: null,
    currentVendorSubStatus: null,
    currentCompany: null,
    currentSupplier: null,
    currentNotes: null,
    currentSellerStatus: null,
    currentButtonText: null,
    currentInputType: null,
    currentInputTypeEditor: null,
    currentMasterVendor: null,
    currentMasterVendorSellerURLArray: null,
    currentMasterVendorInvoice: null,
    currentMasterVendorSubStatus: null,
    currentMasterVendorCompany: null,
    currentMasterVendorSupplier: null,
    currentMasterVendorNotes: null,
    currentMasterVendorCompanyContact: null,
    currentMasterVendorCompanyAddress: null,
    currentMasterVendorSupplierAddress: null,
    currentMasterVendorSupplierContact: null,
    currentMasterVendorEmail: null,
    currentMasterVendorPhoneNumber: null,
    sellerDetailPopUpFrame: "sellerDetailPopUp",
    currentVendorEmail: null,
    currentVendorPhoneNumber: null,
    display_text: "",

    isShowingMailModal: false,
    currentVendorMail: null,
    letterHistoryList: [],
    currentLetterOrder: null,
    currentLetterImageUrl: null,
    isShowingMailFormModal: false,
    addressName: null,
    addressLine1: null,
    addressLine2: null,
    addressCity: null,
    addressState: null,
    addressPostalCode: null,
    addressCountry: null,
    returnAddress: null,
    useForLater: false,
    isShowingViolationModal: false,
    mapViolationHistoryList: [],
    displayTextObserver: Ember.observer('display_text', function () {
      this.get('notificationService').showDisplayTextNotification(this.get('display_text'));
    }),
    actions: {
      logoutNow: function logoutNow() {
        var _this = this;

        console.log("trying to logout ");
        var self = this;
        this.get('spinner').show('discoverySpinner');
        Parse.User.logOut().then(function () {
          self.get('spinner').hide('discoverySpinner');
          self.get('listingservice').clearAllNow();
          self.get('maplistingservice').clearAllNow();
          _this.transitionToRoute('login');
          setTimeout(function () {
            location.reload();
          }, 500);
        });
      },
      startLoading: function startLoading() {
        console.log("startLoading1");
        var self = this;
        this.get('spinner').show('discoverySpinner');
        var today = new Date();
        var shouldRecount = false;

        var maplistingservice = this.get("maplistingservice");
        var clearAllFilters = maplistingservice.get("clearAllFilters");
        if (clearAllFilters) {
          this.set('mySearchText', null);
          this.get("maplistingservice").set("vendorPlatform", "showAll");
          this.get("maplistingservice").set("condition", "new");
          this.get("maplistingservice").set("clearAllFilters", false);
        }
        var vendorStatus = maplistingservice.get("vendorStatus");
        var vendorResults = maplistingservice.get("vendorResults");
        var vendorPlatform = maplistingservice.get("vendorPlatform");
        var condition = maplistingservice.get("condition");
        var listingservice = this.get("listingservice");
        var turboOn = listingservice.get("turboOn");
        var searchMode = this.get("searchMode");
        var params = {};
        var mySearchText = this.get("mySearchText");
        var direction = this.get('direction');

        if (mySearchText == null || mySearchText == "") {
          params = {
            lastPostDate: today,
            mode: searchMode,
            status: vendorStatus,
            skip: vendorResults.length,
            direction: direction,
            platform: vendorPlatform,
            download: turboOn,
            local: true,
            condition: condition
          };
          if (vendorPlatform == "showAll") {
            shouldRecount = true;
          }
        } else {
          params = {
            lastPostDate: today,
            mode: searchMode,
            status: vendorStatus,
            searchText: mySearchText,
            skip: vendorResults.length,
            direction: direction,
            platform: vendorPlatform,
            download: turboOn,
            local: true,
            condition: condition
          };
        }

        Parse.Cloud.run("getCurrentMAPListings", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          console.log("startLoading2", result.length);
          self.send("recountMAPListings", vendorStatus);
          var resultsArray = [];
          for (var i = 0; i < result.length; i++) {
            var item = result[i];
            var myVendor = self.get('parseconverter').convertToMAPListing(item);
            self.get('accountcontroller').setCurrentAccount(myVendor.account);
            self.set("myAccount", myVendor.account);
            resultsArray.pushObject(myVendor);
          }

          self.get('accountcontroller').setIsAdmin();
          self.get('accountcontroller').addPlatformArray();
          self.get('maplistingservice').addVendorResults(resultsArray);
          self.send("setVendorAmount");

          self.send("refreshThisRoute");
          if (turboOn) {
            self.send("loadMoreItems");
          }
          console.log("startLoading3", result.length);
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("Loading Listing error -  " + error.message);
        });
      },
      recountMAPListings: function recountMAPListings(vendorStatus) {
        if (vendorStatus == null) {
          vendorStatus = "showAll";
        }
        var self = this;
        var params = {
          status: vendorStatus
        };
        Parse.Cloud.run("getCurrentMAPListingCount", params).then(function (statusCount) {
          console.log("getCurrentMAPListingCount");
          self.set('numberOfVendors', statusCount);
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          console.log("getCurrentSellerCount -  " + error.message);
        });
      },
      loadMoreItems: function loadMoreItems() {
        var searchMode = this.get("searchMode");
        var numberDownloadedActivityResults = this.get('maplistingservice').get("vendorResultsLastCallAmount");
        if (numberDownloadedActivityResults > 0) {
          var self = this;
          var historyResults = this.get('maplistingservice').get("vendorResults");
          var lastResult = historyResults.slice(-1)[0];
          if (lastResult != undefined) {
            this.get('spinner').show('discoverySpinner');
            var currentDate = lastResult.createdAt;
            var maplistingservice = this.get("maplistingservice");
            var vendorResults = maplistingservice.get("vendorResults");
            var vendorStatus = maplistingservice.get("vendorStatus");
            var vendorPlatform = maplistingservice.get("vendorPlatform");
            var condition = maplistingservice.get("condition");
            var listingservice = this.get("listingservice");
            var turboOn = listingservice.get("turboOn");
            var params = {};
            var direction = this.get('direction');
            var mySearchText = this.get("mySearchText");

            if (mySearchText == null || mySearchText == "") {
              params = {
                lastPostDate: currentDate,
                mode: searchMode,
                status: vendorStatus,
                skip: vendorResults.length,
                direction: direction,
                platform: vendorPlatform,
                download: turboOn,
                local: true,
                condition: condition
              };
            } else {
              params = {
                lastPostDate: currentDate,
                mode: searchMode,
                status: vendorStatus,
                searchText: mySearchText,
                skip: vendorResults.length,
                direction: direction,
                platform: vendorPlatform,
                download: turboOn,
                local: true,
                condition: condition
              };
            }

            Parse.Cloud.run("getCurrentMAPListings", params).then(function (result) {
              self.get('spinner').hide('discoverySpinner');
              var resultsArray = [];
              for (var i = 0; i < result.length; i++) {
                var item = result[i];
                var myVendor = self.get('parseconverter').convertToMAPListing(item);
                resultsArray.pushObject(myVendor);
              }
              self.get('maplistingservice').addVendorResults(resultsArray);

              self.send("setVendorAmount");
              self.send("refreshThisRoute");
              if (turboOn) {
                self.send("loadMoreItems");
              }
            }, function (error) {
              self.get('spinner').hide('discoverySpinner');
              self.get('notificationService').error("Loading Sku error -  " + error.message);
            });
          }
        }
      },
      showVendorsNow: function showVendorsNow() {
        this.set('showListings', false);
      },
      toggleCheckBoxVendor: function toggleCheckBoxVendor(item) {
        this.get('maplistingservice').addOrRemoveItemFromSelectedItem(item);
        //this.controller.set('toggleChecked', !this.controller.get('toggleChecked'));
      },
      showFiltersNow: function showFiltersNow() {
        this.send("toggleVendorFilterModal");
      },
      updateCheckAllVendor: function updateCheckAllVendor() {
        var checkAllVendor = this.get('checkAllVendor');
        if (checkAllVendor) {
          this.set('checkAllVendor', false);
          this.get('maplistingservice').removeAllSelectedListingsNow();
          this.send("refreshThisRoute");
        } else {
          this.set('checkAllVendor', true);
          this.get('maplistingservice').addAllListingsToSelectedNow();
          this.send("refreshThisRoute");
        }
      },
      updateCheckAllVendorReview: function updateCheckAllVendorReview() {
        var checkAllVendor = this.get('checkAllVendor');
        if (checkAllVendor) {
          this.set('checkAllVendor', false);
          this.get('maplistingservice').removeAllSelectedListingsNowReview();
          this.send("refreshThisRoute");
        } else {
          this.set('checkAllVendor', true);
          this.get('maplistingservice').addAllListingsToSelectedNowReview();
          this.send("refreshThisRoute");
        }
      },
      setSelectedVendorsToValue: function setSelectedVendorsToValue(value) {
        var selectedVendors = this.get('maplistingservice').get('selectedVendors');
        var number = selectedVendors.length;
        var numberString = "";
        var numberText = "";
        if (number > 0) {
          numberString = number.toString();
          numberText = numberString + " Sellers Remaining";
          this.set('display_text', numberText);
        }

        this.set('showAllContent', false);
        this.send("refreshThisRoute");

        var self = this;
        if (selectedVendors.length > 0) {
          number = selectedVendors.length;
          numberString = number.toString();
          numberText = numberString + " SELLERS LEFT";
          this.set('display_text', numberText);
          var itemToHide = selectedVendors[0];
          var params = { vendorId: itemToHide.objectId, status: value };

          this.get('spinner').show('discoverySpinner');
          Parse.Cloud.run("updateVendorStatusNow", params).then(function (result) {
            self.get('spinner').hide('discoverySpinner');
            var mySku = self.get('parseconverter').convertToMAPListing(result);
            self.get('maplistingservice').updateItemInSelectedArray(mySku);
            self.set('showAllContent', true);

            if (value == "Authorized" || value == "Notified" || value == "Unauthorized" || value == "Review" || value == "Verified" || value == "Rejected") {
              var newDate = new Date();
              self.send("setSelectedVendorsMultipleListingsToValue", itemToHide, value, newDate);
              self.send("refreshThisRoute");
            } else {
              self.send("setSelectedVendorsToValue", value);
              self.send("refreshThisRoute");
            }
          }, function (error) {
            self.get('spinner').hide('discoverySpinner');
            self.get('notificationService').error("1S - Error Hiding Sku -  " + error.message);
            // self.get('accountcontroller').setIsAdmin();
            self.set('display_text', "");
            self.set('checkAllVendor', false);
            self.set('showAllContent', true);
            self.send("refreshThisRoute");
          });
        } else {
          // this.get('accountcontroller').setIsAdmin();
          this.set('display_text', "");
          self.get('listingservice').clearAllNow();
          self.set('checkAllVendor', false);
          self.set('showAllContent', true);
          self.send("refreshThisRoute");
        }
      },
      setSelectedVendorsListingsToValue: function setSelectedVendorsListingsToValue(selectedVendor, value, date) {
        var self = this;
        var params = { vendorId: selectedVendor.objectId, status: value, lastDate: date };
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("setVendorsListingsToValue", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          if (result == 0) {
            self.send("setSelectedVendorsToValue", value);
            self.send("refreshThisRoute");
          } else {
            self.send("setSelectedVendorsListingsToValue", selectedVendor, value, result);
          }
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("1 - Error setting listing values Sku -  " + error.message);
        });
      },
      setSelectedVendorsMultipleListingsToValue: function setSelectedVendorsMultipleListingsToValue(selectedVendor, value, date) {
        var self = this;
        var params = { vendorId: selectedVendor.objectId, status: value, lastDate: date };
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("setVendorsMultipleListingsToValue", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          if (result == 0) {
            self.send("setSelectedVendorsToValue", value);
            self.send("refreshThisRoute");
          } else {
            self.send("setSelectedVendorsMultipleListingsToValue", selectedVendor, value, result);
          }
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("2 - Error setting listing values Sku -  " + error.message);
        });
      },


      toggleVendorFilterModal: function toggleVendorFilterModal() {
        this.toggleProperty('isShowingFilterVendorsModal');
      },

      filterStatusByVendor: function filterStatusByVendor(status) {

        if (status == "ClearAll") {
          //this.send("toggleVendorFilterModal");
          this.get("maplistingservice").set("vendorStatus", "violation");
        } else if (status == "ClearAllMain") {
          this.get("maplistingservice").set("vendorStatus", "violation");
          this.set('mySearchText', null);
          this.get("maplistingservice").set("vendorPlatform", "showAll");
          this.get("maplistingservice").set("condition", "new");
        } else {
          //this.send("toggleVendorFilterModal");
          this.get("maplistingservice").set("vendorStatus", status);
        }
        var maplistingservice = this.get("maplistingservice");
        if (maplistingservice.vendorResults.length == 0) {
          this.send("startLoading");
        }
        this.get('maplistingservice').clearAllNow();
      },
      filterStatusByPlatform: function filterStatusByPlatform(platform) {
        this.get("maplistingservice").set("vendorPlatform", platform);
        var maplistingservice = this.get("maplistingservice");
        if (maplistingservice.vendorResults.length == 0) {
          this.send("startLoading");
        }
        this.get('maplistingservice').clearAllNow();
      },
      filterStatusByCondition: function filterStatusByCondition(condition) {
        this.get("maplistingservice").set("condition", condition);
        var maplistingservice = this.get("maplistingservice");
        if (maplistingservice.vendorResults.length == 0) {
          this.send("startLoading");
        }
        this.get('maplistingservice').clearAllNow();
      },
      searchText: function searchText(text) {
        if (text !== '') {
          this.set("mySearchText", text);
          this.get('maplistingservice').clearAllNow();
        }
      },
      sortBy: function sortBy(type) {
        var searchMode = this.get('searchMode');
        var direction = this.get('direction');
        if (searchMode == type) {
          if (direction == "up") {
            this.set('direction', "down");
          } else {
            this.set('direction', "up");
          }
        } else {
          this.set('direction', "up");
        }

        this.set('searchMode', type);
        var maplistingservice = this.get("maplistingservice");
        if (maplistingservice.vendorResults.length == 0) {
          this.send("startLoading");
        }
        this.get('maplistingservice').clearAllNow();
      },
      setVendorAmount: function setVendorAmount() {
        var myAccount = this.get('myAccount');
        this.set('numberOfVendors', myAccount.numberOfMAPListings);
      },
      exportList: function exportList() {
        this.get('maplistingservice').resetFileForDownload();
        this.send('startDownload');
      },
      startDownload: function startDownload() {
        console.log("startDownload1");
        var self = this;
        this.get('spinner').show('discoverySpinner');
        var today = new Date();

        var maplistingservice = this.get("maplistingservice");
        var vendorStatus = maplistingservice.get("vendorStatus");
        var searchMode = this.get("searchMode");
        var vendorPlatform = maplistingservice.get("vendorPlatform");
        var condition = maplistingservice.get("condition");
        var params = {};
        var mySearchText = this.get("mySearchText");
        var direction = this.get('direction');

        if (mySearchText == null || mySearchText == "") {
          params = {
            lastPostDate: today,
            mode: searchMode,
            status: vendorStatus,
            skip: 0,
            direction: direction,
            platform: vendorPlatform,
            download: true,
            local: false,
            condition: condition
          };
        } else {
          params = {
            lastPostDate: today,
            mode: searchMode,
            status: vendorStatus,
            searchText: mySearchText,
            skip: 0,
            direction: direction,
            platform: vendorPlatform,
            download: true,
            local: false,
            condition: condition
          };
        }

        Parse.Cloud.run("getCurrentMAPListings", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          var resultsArray = [];
          for (var i = 0; i < result.length; i++) {
            var item = result[i];
            var myVendor = self.get('parseconverter').convertToMAPListing(item);
            resultsArray.pushObject(myVendor);
          }
          self.get('maplistingservice').addHistoryFileResults(resultsArray);
          if (resultsArray.length > 0) {
            var number = resultsArray.length;
            var numberString = number.toString();
            var numberText = numberString + " MAP Listings Downloaded";
            self.set('display_text', numberText);
            self.send('getMoreFileResults');
          } else {
            self.set('display_text', "");
            self.send('finishDownload');
          }
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("Loading Listing error -  " + error.message);
        });
      },
      getMoreFileResults: function getMoreFileResults() {
        console.log("startDownload2");
        var self = this;
        this.get('spinner').show('discoverySpinner');

        var maplistingservice = this.get("maplistingservice");
        var vendorStatus = maplistingservice.get("vendorStatus");
        var vendorResults = maplistingservice.get("fileArray");
        var lastResult = vendorResults.slice(-1)[0];
        var currentDate = lastResult.createdAt;
        var vendorPlatform = maplistingservice.get("vendorPlatform");
        var condition = maplistingservice.get("condition");
        var searchMode = this.get("searchMode");
        var params = {};
        var mySearchText = this.get("mySearchText");
        var direction = this.get('direction');

        if (mySearchText == null || mySearchText == "") {
          params = {
            lastPostDate: currentDate,
            mode: searchMode,
            status: vendorStatus,
            skip: vendorResults.length,
            direction: direction,
            platform: vendorPlatform,
            download: true,
            local: false,
            condition: condition
          };
        } else {
          params = {
            lastPostDate: currentDate,
            mode: searchMode,
            status: vendorStatus,
            searchText: mySearchText,
            skip: vendorResults.length,
            direction: direction,
            platform: vendorPlatform,
            download: true,
            local: false,
            condition: condition
          };
        }

        Parse.Cloud.run("getCurrentMAPListings", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          var resultsArray = [];
          for (var i = 0; i < result.length; i++) {
            var item = result[i];
            var myVendor = self.get('parseconverter').convertToMAPListing(item);
            resultsArray.pushObject(myVendor);
          }
          self.get('maplistingservice').addHistoryFileResults(resultsArray);
          if (resultsArray.length > 0) {
            var newHistoryResults = self.get('maplistingservice').get("fileArray");
            var number = newHistoryResults.length;
            var numberString = number.toString();
            var numberText = numberString + " MAP Listings Downloaded";
            self.set('display_text', numberText);
            self.send('getMoreFileResults');
          } else {
            self.set('display_text', "");
            self.send('finishDownload');
          }
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("Loading Listing error -  " + error.message);
        });
      },
      finishDownload: function finishDownload() {
        var fileArray = this.get('maplistingservice').get("fileArray");
        var csvContent = "";
        var row = '"' + "Status" + '"' + ',' + '"' + "Title" + '"' + ',' + '"' + "Platform" + '"' + ',' + '"' + "Updated At" + '"' + ',' + '"' + "Current Price" + '"' + ',' + '"' + "MAP Price" + '"' + ',' + '"' + "Difference" + '"' + ',' + '"' + "Seller" + '"' + ',' + '"' + "Condition" + '"' + ',' + '"' + "Model Number" + '"' + ',' + '"' + "Number Of Violations" + '"' + ',' + '"' + "Seller URL" + '"' + ',' + '"' + "Product URL" + '"' + ',' + '"' + "SKU" + '"';

        csvContent += row + "\n";
        fileArray.forEach(function (rowArray) {
          var row = '"' + rowArray.status.replace(/\"/g, "") + '"' + ',' + '"' + rowArray.title.replace(/\"/g, "") + '"' + ',' + '"' + capitalizeFirstLetter(rowArray.shoppingPlatform.replace(/\"/g, "")) + '"' + ',' + '"' + rowArray.updatedAt + '"' + ',' + '"' + rowArray.currentPrice + '"' + ',' + '"' + rowArray.map_price + '"' + ',' + '"' + rowArray.difference + '"' + ',' + '"' + rowArray.mapSeller.sellerName.replace(/\"/g, "") + '"' + ',' + '"' + rowArray.condition.replace(/\"/g, "") + '"' + ',' + '"' + rowArray.mapProduct.model_number.replace(/\"/g, "") + '"' + ',' + '"' + rowArray.numberOfInfractions + '"' + ',' + '"' + rowArray.mapSeller.currentSellerURL.replace(/\"/g, "") + '"' + ',' + '"' + rowArray.currentItemURL.replace(/\"/g, "") + '"' + ',' + '"' + rowArray.mapProduct.SKU.replace(/\"/g, "") + '"';

          csvContent += row + "\n";
        });
        var downloadDate = formatDownloadDate(new Date());
        var myAccount = this.get("myAccount");
        var accountName = myAccount.accountName.replace(/[^0-9a-zA-Z]/g, '');
        download(csvContent, downloadDate + '-' + accountName + '-map_listings.csv', "text/plain;charset=utf-8");
      },
      downloadViolations: function downloadViolations() {
        var fileArray = this.get('mapViolationHistoryList');
        var csvContent = "";
        var row = '"' + "Title" + '"' + ',' + '"' + "Platform" + '"' + ',' + '"' + "Price" + '"' + ',' + '"' + "MAP Price" + '"' + ',' + '"' + "Model Number" + '"' + ',' + '"' + "Seller" + '"' + ',' + '"' + "Seller URL" + '"' + ',' + '"' + "Product URL" + '"' + ',' + '"' + "Condition" + '"' + ',' + '"' + "Difference" + '"' + ',' + '"' + "SKU" + '"';
        csvContent += row + "\n";

        fileArray.forEach(function (rowArray) {
          var row = '"' + rowArray.title.replace(/\"/g, "") + '"' + ',' + '"' + capitalizeFirstLetter(rowArray.shoppingPlatform.replace(/\"/g, "")) + '"' + ',' + '"' + rowArray.price + '"' + ',' + '"' + rowArray.map_price + '"' + ',' + '"' + rowArray.mapProduct.model_number.replace(/\"/g, "") + '"' + ',' + '"' + rowArray.mapSeller.sellerName.replace(/\"/g, "") + '"' + ',' + '"' + rowArray.mapSeller.currentSellerURL.replace(/\"/g, "") + '"' + ',' + '"' + rowArray.itemURL.replace(/\"/g, "") + '"' + ',' + '"' + rowArray.mapListing.condition.replace(/\"/g, "") + '"' + ',' + '"' + rowArray.mapListing.difference + '"' + ',' + '"' + rowArray.mapProduct.SKU.replace(/\"/g, "") + '"';

          csvContent += row + "\n";
        });
        var downloadDate = formatDownloadDate(new Date());
        var myAccount = this.get("myAccount");
        var accountName = myAccount.accountName.replace(/[^0-9a-zA-Z]/g, '');
        download(csvContent, downloadDate + '-' + accountName + '-map_violations.csv', "text/plain;charset=utf-8");
      },
      showDetailScreen: function showDetailScreen(vendor) {
        var self = this;
        var params = {
          objectId: vendor.mapSellerId
        };
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("getSingleMAPSeller", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          console.log("results are ", result);
          if (result.length > 0) {
            var item = result[0];
            var myVendor = self.get('parseconverter').convertToMAPSeller(item);

            self.set('currentVendor', myVendor);
            self.set('currentSellerName', myVendor.sellerName);
            self.set('currentSellerUrl', myVendor.currentSellerURL);
            self.set('currentShoppingPlatform', myVendor.shoppingPlatform);
            self.set('currentNumberOfInfractions', myVendor.numberOfInfractions);

            self.set('currentButtonText', 'Save Notes and Close');
            self.set('currentInputType', true);
            self.set('sellerDetailPopUpFrame', "sellerDetailPopUp");
            self.set('currentInputTypeEditor', true);
            self.toggleProperty('isShowingVendorDetailModal');
          } else {
            self.get('notificationService').warning("MAPSeller not found");
          }
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("MAPSeller error -  " + error.message);
        });
      },

      toggleVendorDetailModal: function toggleVendorDetailModal() {
        this.toggleProperty('isShowingVendorDetailModal');
      },
      setVendorToValue: function setVendorToValue(value) {
        this.set('currentSellerStatus', value);
      },
      setVendorToValueIfReview: function setVendorToValueIfReview(value) {
        var currentVendor = this.get('currentVendor');
        this.set('showAllContent', false);
        this.send("refreshThisRoute");
        var self = this;
        var params = { vendorId: currentVendor.objectId, status: value };

        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("updateVendorStatusNow", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          var mySku = self.get('parseconverter').convertToMAPListing(result);
          self.get('maplistingservice').updateItemInSelectedArray(mySku);
          self.toggleProperty('isShowingVendorDetailModal');
          self.set('showAllContent', true);
          self.send("refreshThisRoute");
          self.send("showDetailScreen", mySku);
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("1S - Error updating vendor -  " + error.message);
          self.set('showAllContent', true);
          self.send("refreshThisRoute");
        });
      },
      updateSellerInfo: function updateSellerInfo() {
        var self = this;
        this.toggleProperty('isShowingVendorDetailModal');
        var accountcontroller = this.get('accountcontroller');
        if (accountcontroller.isEditor) {
          var currentVendor = this.get('currentVendor');
          var currentSellerUrl = this.get('currentSellerUrl');
          var currentSellerInvoice = this.get('currentSellerInvoice');
          var currentVendorSubStatus = this.get('currentVendorSubStatus');

          var currentSellerCompanyContact = this.get('currentSellerCompanyContact');
          var currentSellerCompanyAddress = this.get('currentSellerCompanyAddress');
          var currentSellerSupplierAddress = this.get('currentSellerSupplierAddress');
          var currentSellerSupplierContact = this.get('currentSellerSupplierContact');

          var currentCompany = this.get('currentCompany');
          var currentSupplier = this.get('currentSupplier');
          var currentNotes = this.get('currentNotes');
          var currentSellerStatus = this.get('currentSellerStatus');

          var currentVendorEmail = this.get('currentVendorEmail');
          var currentVendorPhoneNumber = this.get('currentVendorPhoneNumber');

          var currentMasterVendorSellerURLArray = this.get('currentMasterVendorSellerURLArray');
          var currentMasterVendorInvoice = this.get('currentMasterVendorInvoice');
          var currentMasterVendorSubStatus = this.get('currentMasterVendorSubStatus');
          var currentMasterVendorCompany = this.get('currentMasterVendorCompany');
          var currentMasterVendorSupplier = this.get('currentMasterVendorSupplier');
          var currentMasterVendorNotes = this.get('currentMasterVendorNotes');
          var currentMasterVendorCompanyContact = this.get('currentMasterVendorCompanyContact');
          var currentMasterVendorCompanyAddress = this.get('currentMasterVendorCompanyAddress');
          var currentMasterVendorSupplierAddress = this.get('currentMasterVendorSupplierAddress');
          var currentMasterVendorSupplierContact = this.get('currentMasterVendorSupplierContact');
          var currentMasterVendorPhoneNumber = this.get('currentMasterVendorPhoneNumber');
          var currentMasterVendorEmail = this.get('currentMasterVendorEmail');

          var params = {
            vendorObjectId: currentVendor.objectId,
            sellerUrl: currentSellerUrl,
            invoice: currentSellerInvoice,
            vendorSubStatus: currentVendorSubStatus,
            company: currentCompany,
            supplier: currentSupplier,
            notes: currentNotes,
            sellerStatus: currentSellerStatus,
            companyContact: currentSellerCompanyContact,
            companyAddress: currentSellerCompanyAddress,
            supplierAddress: currentSellerSupplierAddress,
            supplierContact: currentSellerSupplierContact,
            email: currentVendorEmail,
            phone: currentVendorPhoneNumber,

            currentMasterVendorSellerURLArray: currentMasterVendorSellerURLArray,
            currentMasterVendorInvoice: currentMasterVendorInvoice,
            currentMasterVendorSubStatus: currentMasterVendorSubStatus,
            currentMasterVendorCompany: currentMasterVendorCompany,
            currentMasterVendorSupplier: currentMasterVendorSupplier,
            currentMasterVendorNotes: currentMasterVendorNotes,
            currentMasterVendorCompanyContact: currentMasterVendorCompanyContact,
            currentMasterVendorCompanyAddress: currentMasterVendorCompanyAddress,
            currentMasterVendorSupplierAddress: currentMasterVendorSupplierAddress,
            currentMasterVendorSupplierContact: currentMasterVendorSupplierContact,
            currentMasterVendorPhoneNumber: currentMasterVendorPhoneNumber,
            currentMasterVendorEmail: currentMasterVendorEmail
          };
          this.get('spinner').show('discoverySpinner');
          Parse.Cloud.run("updateVendorInfo", params).then(function (result) {
            self.get('spinner').hide('discoverySpinner');
            var mySku = self.get('parseconverter').convertToMAPListing(result);
            self.get('maplistingservice').updateItemInVendorArray(mySku);
            if (currentSellerStatus == "Authorized" || currentSellerStatus == "Notified" || currentSellerStatus == "Unauthorized" || currentSellerStatus == "Verified" || currentSellerStatus == "Review" || currentSellerStatus == "Rejected") {
              var newDate = new Date();
              self.send("setSingleVendorsListingsToValue", mySku, currentSellerStatus, newDate);
            }
            self.send("refreshThisRoute");
          }, function (error) {
            self.get('spinner').hide('discoverySpinner');
            self.get('notificationService').error("Loading Sku error -  " + error.message);
          });
        } else {
          var currentVendor1 = this.get('currentVendor');
          var currentNotes1 = this.get('currentNotes');
          var params1 = {
            objectId: currentVendor1.objectId,
            notes: currentNotes1
          };

          this.get('spinner').show('discoverySpinner');
          Parse.Cloud.run("updateSellersNotes", params1).then(function (result) {
            self.get('spinner').hide('discoverySpinner');
            var mySku = self.get('parseconverter').convertToMAPListing(result);
            self.get('maplistingservice').updateItemInVendorArray(mySku);
            self.send("refreshThisRoute");
          }, function (error) {
            self.get('spinner').hide('discoverySpinner');
            self.get('notificationService').error("3 - Error updating notes -  " + error.message);
          });
        }
      },
      setSingleVendorsListingsToValue: function setSingleVendorsListingsToValue(selectedVendor, value, date) {
        var self = this;
        var params = { vendorId: selectedVendor.objectId, status: value, lastDate: date };
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("setVendorsMultipleListingsToValue", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          if (result == 0) {
            self.get('listingservice').clearAllNow();
            self.send("refreshThisRoute");
          } else {
            self.send("setSingleVendorsListingsToValue", selectedVendor, value, result);
          }
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("3 - Error setting listing values Sku -  " + error.message);
        });
      },
      checkState: function checkState(text) {
        if (text == "") {
          this.set('searchData', '');
          this.set('mySearchText', null);
          this.get("maplistingservice").set("vendorStatus", "showAll");
          this.get("maplistingservice").set("vendorPlatform", "showAll");
          this.get("maplistingservice").set("condition", "new");
          this.send("filterStatusByVendor", "ClearAllMain");
        }
      },
      showMailPopUp: function showMailPopUp(vendor) {
        this.set('currentVendorMail', vendor);
        this.toggleProperty('isShowingMailModal');
        var self = this;
        var params = {
          vendorId: listing.vendor.objectId
        };
        //
        self.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("getSentLettersForVendor", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          console.log("getSentLettersForVendor", result);
          var resultsArray = [];
          for (var i = 0; i < result.length; i++) {
            var item = result[i];
            var mySentLetter = self.get('parseconverter').convertToSentLetters(item);
            resultsArray.pushObject(mySentLetter);
          }
          self.set("letterHistoryList", resultsArray);
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("error getting letter info -  " + error.message);
        });
      },
      toggleIsShowingMailModal: function toggleIsShowingMailModal() {
        this.toggleProperty('isShowingMailModal');
      },
      openImageLink: function openImageLink(link) {
        window.open(link);
      },
      openMailLetter: function openMailLetter(order) {
        this.toggleProperty('isShowingMailModal');
        var currentVendorMail = this.get('currentVendorMail');
        var myAccount = this.get('myAccount');
        var self = this;
        var params = {
          objectId: currentVendorMail.objectId
        };
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("getSingleVendor", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          if (result.length > 0) {
            var item = result[0];
            currentVendorMail = self.get('parseconverter').convertToMAPListing(item);

            self.set('currentLetterOrder', order);
            switch (order) {
              case 1:
                self.set("currentLetterImageUrl", currentVendorMail.account.letter1);
                break;
              case 2:
                self.set("currentLetterImageUrl", currentVendorMail.account.letter2);
                break;
              case 3:
                self.set("currentLetterImageUrl", currentVendorMail.account.letter3);
                break;
              default:
                self.set("currentLetterImageUrl", "/img/red_x.png");
            }
            //
            self.set('currentSellerCompanyAddress', currentVendorMail.companyAddress);

            if (currentVendorMail.addressName == "") {
              self.set('addressName', currentVendorMail.sellerName);
            } else {
              self.set('addressName', currentVendorMail.addressName);
            }
            self.set('addressLine1', currentVendorMail.addressLine1);
            self.set('addressLine2', currentVendorMail.addressLine2);
            self.set('addressCity', currentVendorMail.addressCity);
            self.set('addressState', currentVendorMail.addressState);
            self.set('addressPostalCode', currentVendorMail.addressPostalCode);
            self.set('addressCountry', currentVendorMail.addressCountry);
            self.set('returnAddress', myAccount.returnAddress);

            self.toggleProperty('isShowingMailFormModal');
            //
          } else {
            self.get('notificationService').warning("Vendor not found");
          }
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("Vendor notes error -  " + error.message);
        });
      },
      toggleIsShowingMailFormModal: function toggleIsShowingMailFormModal() {
        this.toggleProperty('isShowingMailFormModal');
      },
      sendLettertoVendor: function sendLettertoVendor() {
        var addressName = this.get('addressName');
        var addressLine1 = this.get('addressLine1');
        var addressLine2 = this.get('addressLine2');
        var addressCity = this.get('addressCity');
        var addressState = this.get('addressState');
        var addressPostalCode = this.get('addressPostalCode');
        var addressCountry = this.get('addressCountry');
        var currentVendorMail = this.get('currentVendorMail');
        var currentLetterOrder = this.get('currentLetterOrder');
        this.toggleProperty('isShowingMailFormModal');

        var params = {
          vendorId: currentVendorMail.objectId,
          letterOrder: currentLetterOrder,
          addressName: addressName,
          addressLine1: addressLine1,
          addressLine2: addressLine2,
          addressCity: addressCity,
          addressState: addressState,
          addressPostalCode: addressPostalCode,
          addressCountry: addressCountry
        };
        var self = this;
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("sendLetterToVendor", params).then(function (result) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').success(result);
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("error sending letter -  " + error.message);
        });
      },
      loadAccount: function loadAccount() {
        var self = this;
        var params = {};
        Parse.Cloud.run("getCurrentAccount", params).then(function (results) {
          if (results.length > 0) {
            var item = results[0];
            var myAccount = self.get('parseconverter').convertToAccount(item);
            self.get('accountcontroller').setCurrentAccount(myAccount);
            self.send("refreshThisRoute");
          }
        }, function (error) {
          if (error.code == 209) {
            //self.send("logoutNow");
            self.get('notificationService').error("Please logout and logback in - token expired");
          } else {
            self.get('notificationService').error("Loading Listing error -  " + error.message);
          }
        });
      },
      showViolationScreen: function showViolationScreen(mapListing) {
        var self = this;
        var params = {
          mapListingId: mapListing.objectId
        };
        this.set("currentVendor", mapListing);
        this.get('spinner').show('discoverySpinner');
        Parse.Cloud.run("getMapInfringementsForMapListing", params).then(function (results) {
          self.get('spinner').hide('discoverySpinner');
          var resultsArray = [];
          for (var i = 0; i < results.length; i++) {
            var item = results[i];
            var myVendor = self.get('parseconverter').convertToMAPInfringement(item);
            resultsArray.pushObject(myVendor);
          }
          self.set("mapViolationHistoryList", resultsArray);
          self.toggleProperty('isShowingViolationModal');
        }, function (error) {
          self.get('spinner').hide('discoverySpinner');
          self.get('notificationService').error("showViolationScreen error -  " + error.message);
        });
      },
      toggleShowingViolationModal: function toggleShowingViolationModal() {
        this.toggleProperty('isShowingViolationModal');
      },
      transitionToMAPProtection: function transitionToMAPProtection() {
        this.transitionToRoute('map');
        Ember.run.later(function () {
          location.reload();
        }, 500);
      }
    }
  });


  function download(data, filename, type) {
    var a = document.createElement("a"),
        file = new Blob([data], { type: type });
    if (window.navigator.msSaveOrOpenBlob) // IE10+
      window.navigator.msSaveOrOpenBlob(file, filename);else {
      // Others
      var url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }

  function capitalizeFirstLetter(string) {
    if (string == "amazon" || string == "Amazon") {
      return "Amazon US";
    }
    return string.replace(/(^\w{1})|(\s{1}\w{1})/g, function (match) {
      return match.toUpperCase();
    });
  }
});