define('uabide/components/sub-statuses-count', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    priorityValue: 'priority',
    repliedValue: 'replied'
  });
});