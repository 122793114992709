define('uabide/components/modals/map-pricing-history-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentPage: 1,
    pageSize: 5,

    paginatedPricingHistory: Ember.computed('mapPriceHistory.@each', 'currentPage', 'pageSize', function () {
      var mapPriceHistory = this.get('mapPriceHistory') || [];
      var currentPage = this.get('currentPage');
      var pageSize = this.get('pageSize');
      var startIndex = (currentPage - 1) * pageSize;
      var endIndex = startIndex + pageSize;
      return mapPriceHistory.slice(startIndex, endIndex);
    }),

    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty('isOpen');
      },
      prevPage: function prevPage() {
        var currentPage = this.get('currentPage');
        if (currentPage > 1) {
          this.decrementProperty('currentPage');
        }
      },
      nextPage: function nextPage() {
        var mapPriceHistory = this.get('mapPriceHistory') || [];
        var pageSize = this.get('pageSize');
        var totalPages = Math.ceil(mapPriceHistory.length / pageSize);
        var currentPage = this.get('currentPage');
        if (currentPage < totalPages) {
          this.incrementProperty('currentPage');
        }
      }
    }
  });
});