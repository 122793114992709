define('uabide/components/modals/manage-emails-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentEmailsArray: [],
    deadEmailArray: [],
    respondedEmailArray: [],
    currentEmail: null,
    isShowArrayButtonVisible: true,
    masterVendorId: null,
    didUpdateAttrs: function didUpdateAttrs() {
      var _this = this;

      if (this.emailsArray && this.emailsArray.length) {
        this.set('currentEmailsArray', this.emailsArray.map(function (el) {
          if (_this.showStatuses) {
            return { email: el, isDead: _this.deadEmailArray.includes(el), isResponded: _this.respondedEmailArray.includes(el) };
          } else {
            return { email: el };
          }
        }));
      } else {
        this.set('currentEmailsArray', [{ email: '' }]);
      }

      if (Ember.isPresent(this.singleEmail)) {
        this.set('isShowArrayButtonVisible', !this.singleEmail);
      }
    },


    actions: {
      addNewEmail: function addNewEmail(email) {
        var currentEmailsArray = this.get('currentEmailsArray');
        currentEmailsArray.pushObject({ email: '' });
        this.set('currentEmailsArray', currentEmailsArray);
      },
      removeEmail: function removeEmail(email) {
        if (this.removeEmail && this.emailsArray.includes(email.email)) {
          this.removeEmail(email.email);
        } else {
          var currentEmailsArray = this.get('currentEmailsArray');
          currentEmailsArray.removeObject(email);
          this.get('currentEmailsArray', currentEmailsArray);
        }
      },
      toggleDeadEmail: function toggleDeadEmail(email) {
        this.toggleDeadEmail(email, this.selectedAccount);
      },
      toggleRespondedEmail: function toggleRespondedEmail(email) {
        this.toggleRespondedEmail(email, this.selectedAccount);
      },
      save: function save() {
        var emailsArray = this.get('currentEmailsArray').map(function (el) {
          return el.email;
        });
        this.updateAccountEmails(emailsArray, this.selectedAccount);
      }
    }
  });
});