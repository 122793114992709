define('uabide/routes/reports', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  Parse.initialize("bzn8msayuASvFwWDJEY3DdGYDXb7eV9rweY2rr7o", "fRmOrMDotozW5iYypZ9s73yADQP3MiKmbIhQxXGw");
  Parse.serverURL = 'https://uabide.back4app.io/';

  exports.default = Ember.Route.extend({
    sessionService: Ember.inject.service(),
    accountcontroller: Ember.inject.service('account-controller'),
    vendorservice: Ember.inject.service('vendor-service'),
    listingservice: Ember.inject.service('listing-service'),
    spinner: Ember.inject.service('spinner'),
    currentlyLoading: true,

    beforeModel: function beforeModel() {
      var user = this.get('sessionService.currentUser');
      if (user) {
        if (this.get('sessionService.isMapProtection') && !this.get('sessionService.isFullAccount') && !this.get('sessionService.isAdmin')) {
          this.replaceWith('/map/overview');
        }
      }
    },
    model: function model() {
      var params = this._getReportsParams();
      return Ember.RSVP.hash({
        currentClosedListings: this.get('listingservice').getListingsChangedByTimeAndStatus(_extends({}, params, { status: 'Closed' })),
        currentNewSellerSellers: this.get('vendorservice').getSellersChangedByTimeAndStatus(_extends({}, params, { status: 'New Seller' })),
        currentUnauthorizedSellers: this.get('vendorservice').getSellersChangedByTimeAndStatus(_extends({}, params, { status: 'Unauthorized' })),
        currentClosedSellers: this.get('vendorservice').getSellersChangedByTimeAndStatus(_extends({}, params, { status: 'Unauthorized', isRemoved: true })),
        currentDiscoveredListings: this.get('listingservice').getListingsChangedByTimeAndStatus(_extends({}, params, { status: 'Discovered' })),
        currentPendingListings: this.get('listingservice').getListingsChangedByTimeAndStatus(_extends({}, params, { status: 'Pending' })),
        startDate: params.startDate,
        endDate: params.endDate
      });
    },
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {
        this.set('currentlyLoading', true);
      }
    },


    actions: {
      loading: function loading(transition) {
        var _this = this;

        if (this.get('currentlyLoading')) {
          this.get('spinner').show('applicationSpinner');
          transition.promise.finally(function () {
            _this.set('currentlyLoading', false);
            _this.get('spinner').hide('applicationSpinner');
            return false;
          });
          //this.get('spinner').hide('applicationSpinner');
          return true;
        } else {
          this.get('spinner').hide('applicationSpinner');
          return false;
        }
      },


      refreshThisRoute: function refreshThisRoute() {
        console.log("refreshing this route now");
        this.refresh();
      }
    },

    _getReportsParams: function _getReportsParams() {
      var startDate = moment().subtract(7, 'days');
      var endDate = moment();
      return {
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        isCount: true,
        skip: 0
      };
    }
  });
});