define('uabide/components/modals/sent-notice-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    accountName: Ember.computed.alias('accountNotice.account.sellerName'),
    noticeSubject: Ember.computed.alias('accountNotice.template.subject'),

    actions: {
      submit: function submit() {}
    }
  });
});