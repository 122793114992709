define('uabide/components/modals/custom-date-range-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    startDate: null,
    endDate: null,
    customValue: null,

    didUpdateAttrs: function didUpdateAttrs() {
      if (!this.isOpen) {
        this.set('startDate', null);
        this.set('endDate', null);
        this.set('customValue', null);
      }
    },


    actions: {
      setStartDate: function setStartDate(date) {
        this.set('startDate', date);
      },
      setEndDate: function setEndDate(date) {
        this.set('endDate', date);
      },
      setCustomValue: function setCustomValue(value) {
        this.set('customValue', value);
      },
      setDownloadParams: function setDownloadParams() {
        var customValue = this.get('customValue');
        var startDate = this.get('startDate');
        var endDate = this.get('endDate');
        if (customValue) {
          this.setParams(customValue, { startDate: startDate, endDate: endDate });
        } else {
          this.setParams(startDate, endDate);
        }
      }
    }
  });
});