define('uabide/components/main-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    accountController: Ember.inject.service('account-controller'),

    tagName: 'header',
    classNames: ['u-mb-medium'],

    currentRouteName: Ember.computed('router.currentRouteName', function () {
      var currentRouteNames = this.get('router.currentRouteName').split('.');
      var currentRoute = currentRouteNames.pop().split('-');
      var currentRouteSlug = currentRoute.pop();
      return this.setRouteName(currentRouteSlug);
    }),

    isBackButtonHidden: Ember.computed('router.currentRouteName', function () {
      var currentRouteNames = this.get('router.currentRouteName').split('.');
      var currentRoute = currentRouteNames.pop().split('-');
      if (currentRoute.length > 1) {
        return false;
      } else {
        return true;
      }
    }),

    isSecondaryTitleVisible: Ember.computed('isBackButtonHidden', function () {
      if (this.get('isBackButtonHidden')) {
        return false;
      } else {
        return true;
      }
    }),

    pageTitle: Ember.computed('currentRouteName', 'accountController.currentAccount', function () {
      var lastScanDate = this.get('accountController.currentAccount.lastScanShortDate');
      var time = this.get('accountController.currentAccount.time');
      var isOverviewPage = this.get('currentRouteName') === 'overview';
      var hasOverviewPageScanDate = isOverviewPage && lastScanDate;

      var pageTitle = {
        routeName: this.get('currentRouteName'),
        lastScan: ''
      };

      if (this.get('currentRouteName') == "accounts") {
        pageTitle = {
          routeName: "Sellers",
          lastScan: ''
        };
      }

      if (hasOverviewPageScanDate) {
        pageTitle.lastScan = 'Last Scan ' + lastScanDate + ' - ' + time;
      }

      return pageTitle;
    }),

    actions: {
      goBack: function goBack() {
        this.goBack();
      }
    },

    setRouteName: function setRouteName(slug) {
      switch (slug) {
        case 'violations':
          return 'Active Violations';
          break;
        default:
          return slug;
      }
    }
  });
});